<goa-modal
  #categoryModal
  id="incomplete-modal-dialog"
  [open]="open"
  heading="{{ title }}"
  width="550px"
  [appPreventModalFlicker]="open"
>
  <p *ngIf="incompleteCategoryMessage" id="incomplete-category-message">
    {{ incompleteCategoryMessage }}
  </p>
  <p *ngIf="incompleteProjectMessage" id="incomplete-project-message">
    {{ incompleteProjectMessage }}
  </p>
  <p *ngIf="secondLineMessage" id="incomplete-second-line-message">
    {{ secondLineMessage }}
  </p>
  <p
    *ngIf="incompleteCategoryMessage !== undefined && incompleteProjectMessage !== undefined"
    id="multiple-incomplete-proceed-confirm-question"
  >
    Are you sure you'd like to proceed without them?
  </p>
  <div slot="actions">
    <goa-button-group alignment="end">
      <goa-button #categoryCancel (_click)="closeModal()" type="secondary">{{ 'btnCancel' | res }}</goa-button>
      <goa-button #categoryProceed (_click)="proceedClicked()" type="primary">{{ 'btnProceed' | res }}</goa-button>
    </goa-button-group>
  </div>
</goa-modal>
