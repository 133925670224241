import { Component } from '@angular/core';

@Component({
  selector: 'app-grant-app-guide',
  templateUrl: './grant-app-guide.component.html',
  styleUrls: ['./grant-app-guide.component.scss'],
})
export class GrantAppGuideComponent {
  txtHeading = 'LGFF makes applying easier by bundling similar projects together under the same application.';
  txtSubHead = 'To complete the LGFF application process, follow these 4 simple steps:';
  txtItems: string[] = [
    '1. Create an application for a group of similar projects',
    '2. Select the appropriate functional category(s) for your application',
    '3. Add the details for each project to your application',
    "4. Certify and submit your application - and you're done!",
  ];
  txtBottom = 'You can view all your applications (including drafts) at any time.';
}
