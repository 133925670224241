<div *ngIf="currentCashFlowUpdateId">
  <h2 id="app-heading-2">Review</h2>
  <h3 id="app-heading-3">
    Confirm that the changes below are correct.
    <br />
    Go back to
    <a class="navigation-link" (click)="stepRoute(cashFlowFormSequence.ApplicationFunding)">Application funding</a> or
    <a class="navigation-link" (click)="stepRoute(cashFlowFormSequence.ProjectUpdates)">Project updates</a> to make any
    additional changes.
  </h3>
  <app-cash-flow-update-view
    [cfId]="currentCashFlowUpdateId"
    [orgId]="organizationId"
    [viewType]="viewType"
  ></app-cash-flow-update-view>
  <div class="top-gap" variant="relaxed">
    <goa-button leadingIcon="arrow-up" type="secondary" (click)="scrollToTop()">Back to top</goa-button>
  </div>
</div>
