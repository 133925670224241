import { Action, Selector, State, StateContext } from '@ngxs/store';
import {
  SetCurrentApplicationDraftId,
  SetCurrentOrganizationId,
  SetCurrentApplicationDraftStep,
  ClearContextOnLogout,
  ClearApplicationDraftContext,
  AutoSaveCurrentCashFlowContact,
  SetCurrentSEPOId,
  SetCurrentSEPOStep,
} from '../actions/current-context.action';
import { Injectable } from '@angular/core';
import { FormSequence } from '@app-pot/features/grant-application/models/enums';
import { ApplicationExtService } from '@app-com/api/services';
import {
  SetCurrentCashFlowUpdateId,
  SetCurrentCashFlowUpdateStep,
  SetCurrentCashFlowApplicationId,
  ClearCashFlowUpdateContext,
  SetCurrentCashFlowApplicationTitle,
} from '../actions/current-context.action';

import { CashFlowFormSequence } from '@app-pot/features/cash-flow-updates/enum/cash-flow-form-sequence';

export interface CurrentContextStateModel {
  currentOrganizationId: number;
  currentApplicationDraftId?: number;
  currentApplicationDraftStep?: FormSequence;

  currentCashFlowUpdateId?: number | undefined;
  currentSEPOId?: number | undefined;
  currentCashFlowUpdateStep?: CashFlowFormSequence;
  currentSEPOStep?: CashFlowFormSequence;
  currentCashFlowApplicationId?: number | undefined;
  currentCashFlowApplicationTitle?: string | undefined;
}

@State<CurrentContextStateModel>({
  name: 'current_context',
})
@Injectable()
export class CurrentContextState {
  constructor(private applicationService: ApplicationExtService) {}
  @Selector()
  static getCurrentOrganizationId({ currentOrganizationId }: CurrentContextStateModel) {
    return currentOrganizationId;
  }

  @Selector()
  static getCurrentApplicationDraftId({ currentApplicationDraftId }: CurrentContextStateModel) {
    return currentApplicationDraftId;
  }

  @Selector()
  static getCurrentApplicationDraftStep({ currentApplicationDraftStep }: CurrentContextStateModel) {
    return currentApplicationDraftStep;
  }

  @Selector()
  static getCurrentCashFlowUpdateId({ currentCashFlowUpdateId }: CurrentContextStateModel) {
    return currentCashFlowUpdateId;
  }

  @Selector()
  static getCurrentSEPOId({ currentSEPOId }: CurrentContextStateModel) {
    return currentSEPOId;
  }

  @Selector()
  static getCurrentCashFlowUpdateStep({ currentCashFlowUpdateStep }: CurrentContextStateModel) {
    return currentCashFlowUpdateStep;
  }

  @Selector()
  static getCurrentCashFlowApplicationId({ currentCashFlowApplicationId }: CurrentContextStateModel) {
    return currentCashFlowApplicationId;
  }

  @Selector()
  static getCurrentCashFlowApplicationTitle({ currentCashFlowApplicationTitle }: CurrentContextStateModel) {
    return currentCashFlowApplicationTitle;
  }

  @Action(SetCurrentOrganizationId)
  setOrganizationId({ patchState }: StateContext<CurrentContextStateModel>, { payload }: SetCurrentOrganizationId) {
    patchState({ currentOrganizationId: payload });
  }

  @Action(SetCurrentApplicationDraftId)
  setCurrentApplicationDraftId(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentApplicationDraftId,
  ) {
    patchState({ currentApplicationDraftId: payload });
  }

  @Action(SetCurrentApplicationDraftStep)
  setCurrentApplicationDraftStep(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentApplicationDraftStep,
  ) {
    patchState({ currentApplicationDraftStep: payload });
  }

  @Action(ClearContextOnLogout)
  clearContextOnLogout({ patchState }: StateContext<CurrentContextStateModel>) {
    patchState({
      currentOrganizationId: 0,
      currentApplicationDraftId: 0,
      currentApplicationDraftStep: FormSequence.GeneralInfo,
    });
  }

  @Action(ClearApplicationDraftContext)
  clearApplicationDraftContext({ patchState }: StateContext<CurrentContextStateModel>) {
    patchState({ currentApplicationDraftId: 0, currentApplicationDraftStep: FormSequence.GeneralInfo });
  }

  // @Action(SetCurrentCashFlowContact)
  // setCurrentCashFlowContact(
  //   { patchState }: StateContext<CurrentContextStateModel>,
  //   { payload }: SetCurrentCashFlowContact,
  // ) {
  //   patchState({ currentCashFlow: payload });
  // }

  @Action(AutoSaveCurrentCashFlowContact)
  autoSaveCurrentCashFlowContact() {
    // { payload }: AutoSaveCurrentCashFlowContact, // ctx: StateContext<CurrentContextStateModel>,
    // const draft = { ...ctx.getState().applicationDraft };
    // const applicationRecord: ApplicationDraftRecordDto = {
    //   ...draft.applicationRecord,
    //   primaryOutcomeId: generalInfo.primaryOutcomeId,
    //   description: generalInfo.description,
    //   name: generalInfo.name!,
    //   contactFirstName: generalInfo.contactFirstName,
    //   contactLastName: generalInfo.contactLastName,
    //   contactEmailAddress: generalInfo.contactEmailAddress,
    //   contactPhoneNumber: generalInfo.contactPhoneNumber,
    //   functionalCategories: draft.applicationRecord?.functionalCategories ?? [],
    //   projects: draft.applicationRecord?.projects ?? [],
    // };
  }

  @Action(SetCurrentCashFlowUpdateId)
  setCurrentCashFlowUpdateId(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentCashFlowUpdateId,
  ) {
    patchState({ currentCashFlowUpdateId: payload });
  }

  @Action(SetCurrentCashFlowUpdateStep)
  setCurrentCashFlowUpdateStep(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentCashFlowUpdateStep,
  ) {
    patchState({ currentCashFlowUpdateStep: payload });
  }

  @Action(SetCurrentSEPOId)
  setCurrentSEPOId({ patchState }: StateContext<CurrentContextStateModel>, { payload }: SetCurrentSEPOId) {
    patchState({ currentSEPOId: payload });
  }

  @Action(SetCurrentSEPOStep)
  setCurrentSEPOStep({ patchState }: StateContext<CurrentContextStateModel>, { payload }: SetCurrentSEPOStep) {
    patchState({ currentSEPOStep: payload });
  }

  @Action(SetCurrentCashFlowApplicationId)
  setCurrentCashFlowApplicationId(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentCashFlowApplicationId,
  ) {
    patchState({ currentCashFlowApplicationId: payload });
  }

  @Action(SetCurrentCashFlowApplicationTitle)
  setCurrentCashFlowApplicationTitle(
    { patchState }: StateContext<CurrentContextStateModel>,
    { payload }: SetCurrentCashFlowApplicationTitle,
  ) {
    patchState({ currentCashFlowApplicationTitle: payload });
  }

  @Action(ClearCashFlowUpdateContext)
  clearCashFlowUpdateContext({ patchState }: StateContext<CurrentContextStateModel>) {
    patchState({
      currentCashFlowUpdateId: undefined,
      currentCashFlowApplicationId: undefined,
      currentCashFlowApplicationTitle: undefined,
      currentCashFlowUpdateStep: CashFlowFormSequence.ContactInfo,
    });
  }
}
