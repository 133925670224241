<goa-page-block width="100%">
  <div *ngIf="stepperReady">
    <app-grant-app-guide *ngIf="formStepGenInfo"> </app-grant-app-guide>
    <goa-divider mt="2xl" mb="l" *ngIf="(step$ | async) === FormSequence.GeneralInfo"></goa-divider>
    <goa-form-stepper
      ml="s"
      mr="s"
      [step]="step$ | async"
      class="non-clickable-stepper"
      data-automation-id="grant-application-stepper"
    >
      <goa-form-step
        text="General information"
        status="incomplete"
        [status]="(formStatus$ | async)![FormSequence.GeneralInfo]"
      ></goa-form-step>
      <goa-form-step
        text="Functional categories"
        status="incomplete"
        [status]="(formStatus$ | async)![FormSequence.FunctionalCat]"
      ></goa-form-step>
      <goa-form-step
        text="Projects"
        status="incomplete"
        [status]="(formStatus$ | async)![FormSequence.Proj]"
      ></goa-form-step>
      <goa-form-step
        text="Certification"
        status="incomplete"
        [status]="(formStatus$ | async)![FormSequence.Cert]"
      ></goa-form-step>
    </goa-form-stepper>

    <goa-pages [current]="step$ | async">
      <div><app-grant-application-general-info></app-grant-application-general-info></div>
      <div><app-functional-categories></app-functional-categories></div>

      <div>
        <projects></projects>
      </div>
      <div>
        <app-certification></app-certification>
      </div>
    </goa-pages>
    <div class="flex-space-between">
      <goa-button (_click)="goToApplications()" type="secondary" *ngIf="(step$ | async) == FormSequence.GeneralInfo">{{
        'btnBackToApplications' | res
      }}</goa-button>
      <goa-button (_click)="prevPage()" type="secondary" *ngIf="(step$ | async) != FormSequence.GeneralInfo">{{
        'btnPrevious' | res
      }}</goa-button>
      <goa-button (_click)="onSubmit()" type="primary" *ngIf="(step$ | async) == FormSequence.Cert">{{
        'btnSubmit' | res
      }}</goa-button>
      <goa-button (_click)="nextPage()" type="primary" *ngIf="(step$ | async) != FormSequence.Cert">{{
        'btnNext' | res
      }}</goa-button>
    </div>
    <div class="ht-32px"></div>
    <div class="flex-space-between save-cancel-button-container">
      <goa-button
        class="btn-neg-margin-left"
        (_click)="askCancelDeleteDraftApp()"
        type="tertiary"
        [disabled]="!draftIdExist"
        >{{
          ((applicationType$ | async) == 'DRAFT' ? 'btnCancelDeleteDraftApp' : 'btnCancelWithdrawDraftApp') | res
        }}</goa-button
      >
      <goa-button
        class="btn-neg-margin-right"
        (_click)="onSaveAndCloseApp()"
        type="tertiary"
        [disabled]="!draftIdExist"
        >{{ 'btnSaveClose' | res }}</goa-button
      >
    </div>
    <ng-container *ngIf="autosaveStatus$ | async as autosaveStatus">
      <div
        data-automation-id="autosave-status-msg-container"
        class="autosave-status-msg-container"
        *ngIf="autosaveStatus !== AutosaveStatus.idle"
      >
        <div
          data-automation-id="autosave-msg-content-success"
          class="autosave-msg-content"
          *ngIf="autosaveStatus === AutosaveStatus.success"
        >
          <goa-icon class="autosave-msg-success-icon" type="checkmark" size="small"></goa-icon>
          <div class="autosave-msg-txt">Application has been autosaved</div>
        </div>
        <div
          data-automation-id="autosave-msg-content-inprogress"
          class="autosave-msg-content"
          *ngIf="autosaveStatus === AutosaveStatus.inProgress"
        >
          <mat-spinner class="autosave-msg-inprogress-icon"></mat-spinner>
          <div class="autosave-msg-txt">Saving application...</div>
        </div>
        <div
          data-automation-id="autosave-msg-content-failure"
          class="autosave-msg-content"
          *ngIf="autosaveStatus === AutosaveStatus.failure"
        >
          <goa-icon class="autosave-msg-failure-icon" type="close" size="small"></goa-icon>
          <div class="autosave-msg-txt">Autosave failed</div>
        </div>
      </div>
    </ng-container>
  </div>
</goa-page-block>

<app-confirm-cancel-delete-draft-app
  [pageId]="deleteModalPageId"
  [showBothOnRight]="false"
  [isOpen]="wantCancelDeleteDraftApp"
  (afterNoHandler)="noCancelDeleteDraftAppHandler()"
  (afterYesHandler)="yesDeleteDraftAppHandler()"
>
</app-confirm-cancel-delete-draft-app>

<goa-circular-progress
  variant="fullscreen"
  visible="{{ showWholePageProgressIndicatorModal }}"
  message="{{ 'submitting' | res }}"
></goa-circular-progress>
<div #incompleteModalContainer></div>
