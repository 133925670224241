export class FetchApplicationDrafts {
  static readonly type = '[ApplicationDrafts] Fetch Drafts';
}

export class RemoveApplicationFromStateByDraftId {
  static readonly type = '[ApplicationDrafts] Remove One from Drafts';
  constructor(public applicationDraftId: number) {}
}

export class RemoveReturnedApplicationFromStateByDraftId {
  static readonly type = '[ApplicationDrafts] Remove One from Returned';
  constructor(public applicationDraftId: number) {}
}

export class FetchSubmittedApplication {
  static readonly type = '[Applications] Fetch Submitted applications';
}

export class FetchReturnedApplications {
  static readonly type = '[Applications] Fetch Returned Applications';
}

export class FetchAcceptedApplications {
  static readonly type = '[Applications] Fetch Accepted Applications';
}

export class FetchWithdrawnApplications {
  static readonly type = '[Applications] Fetch Withdrawn Applications';
}
export class FetchCashFlowUpdateApplications {
  static readonly type = '[Applications] Fetch Cash Flow Update Applications';
}
export class RemoveCashFlowUpdateApplication {
  static readonly type = '[Application] Remove One from CashFlowUpdateApplications';
  constructor(public cashFlowId: number) {}
}
