/* tslint:disable */
/* eslint-disable */
export enum CashFlowUpdateStageType {
  Draft = 'Draft',
  Received = 'Received',
  UnderReview = 'UnderReview',
  Recommended = 'Recommended',
  Accepted = 'Accepted',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
