/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { PdfGenerateResponseDto } from '../models/pdf-generate-response-dto';

@Injectable({ providedIn: 'root' })
export class PdfService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `getInternalPdf()` */
  static readonly GetInternalPdfPath = '/api/pdf/internal-pdf/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalPdf$Response(
    params: {
      fileId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, PdfService.GetInternalPdfPath, 'get');
    if (params) {
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/pdf', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalPdf(
    params: {
      fileId: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getInternalPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

  /** Path part for operation `getInternalPdfGenerationStatus()` */
  static readonly GetInternalPdfGenerationStatusPath = '/api/pdf/internal/jobs/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getInternalPdfGenerationStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalPdfGenerationStatus$Response(
    params: {
      jobId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, PdfService.GetInternalPdfGenerationStatusPath, 'get');
    if (params) {
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getInternalPdfGenerationStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getInternalPdfGenerationStatus(
    params: {
      jobId: string;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.getInternalPdfGenerationStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `getPdfGenerationStatus()` */
  static readonly GetPdfGenerationStatusPath = '/api/pdf/{organizationId}/jobs/{jobId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPdfGenerationStatus()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPdfGenerationStatus$Response(
    params: {
      organizationId: number;
      jobId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, PdfService.GetPdfGenerationStatusPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('jobId', params.jobId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPdfGenerationStatus$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPdfGenerationStatus(
    params: {
      organizationId: number;
      jobId: string;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.getPdfGenerationStatus$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `getPdf()` */
  static readonly GetPdfPath = '/api/pdf/{organizationId}/{fileId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `getPdf()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPdf$Response(
    params: {
      organizationId: number;
      fileId: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<any>> {
    const rb = new RequestBuilder(this.rootUrl, PdfService.GetPdfPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('fileId', params.fileId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'blob', accept: 'application/pdf', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<any>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `getPdf$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  getPdf(
    params: {
      organizationId: number;
      fileId: string;
    },
    context?: HttpContext
  ): Observable<any> {
    return this.getPdf$Response(params, context).pipe(
      map((r: StrictHttpResponse<any>): any => r.body)
    );
  }

}
