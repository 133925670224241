import { Component, OnDestroy, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { CashFlowUpdateExtDto } from '@app-com/api/models';
import { ResourcePipe } from '@app-com/pipes';
import { CashFlowReviewVM } from './cash-flow-review-vm';
import { SetCashFlowUpdateStep } from '@app-pot/store/actions/cash-flow-update.action';
import { Router } from '@angular/router';
import { CashFlowFormSequence } from '../enum/cash-flow-form-sequence';
import { ApplicationExtService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CashFlowUpdateViewType } from '@app-com/components/cash-flow-update-view/cash-flow-update-view-type';
import { SetCurrentCashFlowUpdateStep } from '@app-pot/store/actions/current-context.action';
@Component({
  selector: 'app-cash-flow-update-review',
  templateUrl: './cash-flow-update-review.component.html',
  styleUrls: ['./cash-flow-update-review.component.scss'],
})
export class CashFlowUpdateReviewComponent implements OnInit, OnDestroy {
  @Select(CurrentContextState.getCurrentCashFlowUpdateId) getCurrentCashFlowUpdateId$: Observable<number>;
  @Select(CurrentContextState.getCurrentOrganizationId) organizationId$: Observable<number>;
  cashFlowUpdateRecord: CashFlowReviewVM;
  currentCashFlowUpdateId: number;
  organizationId: number;
  sub = new Subscription();
  PageId = 'CASH_FLOW_UPDATE_REVIEW';
  unchangedProjectsTotal: number | undefined;
  cf: CashFlowUpdateExtDto;
  viewType = CashFlowUpdateViewType.Review;
  cashFlowFormSequence = CashFlowFormSequence;
  constructor(
    private store: Store,
    private res: ResourcePipe,
    private router: Router,
    private applicationService: ApplicationExtService,
  ) {}
  ngOnInit(): void {
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.getCurrentCashFlowUpdateId$.subscribe((x) => {
      return (this.currentCashFlowUpdateId = x);
    });
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  stepRoute(stepSequence: number) {
    if (stepSequence === CashFlowFormSequence.ApplicationFunding) {
      this.store.dispatch(new SetCashFlowUpdateStep(stepSequence));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(stepSequence)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/application-funding']);
    }
    if (stepSequence === CashFlowFormSequence.ProjectUpdates) {
      this.store.dispatch(new SetCashFlowUpdateStep(stepSequence));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(stepSequence)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/project-updates']);
    }
  }
}
