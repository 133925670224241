import { ApplicationProjectType } from '@app-com/api/models';

export class ProjectRecordVm {
  id: number;
  detailsShown?: boolean;
  projectName: string;
  description: string;
  primaryCapitalAssetId?: number;
  primaryCapitalAssetTitle?: string;
  additionalCapitalAssetIds?: number[];
  additionalCapitalAssetTitles?: string[];
  estimatedTotalCost?: number;
  lgffFundingAmountRequested?: number;
  fundingFromOtherGrantPrograms?: number;
  fundingFromMunicipalSources?: number;
  address?: string;
  latitude?: number;
  longitude?: number;
  // addressDescription?: string;
  anticipatedStartDate?: string;
  anticipatedEndDate?: string;
  projectType?: ApplicationProjectType;
  quantityNew?: number;
  quantityUpgrade?: number;
  measurementUnit?: string;
  currentAssetRankingId?: number;
  currentAssetRankingTitle?: string;
}
