import { Component, OnInit } from '@angular/core';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-access-forbidden',
  templateUrl: './access-forbidden.component.html',
  styleUrls: ['./access-forbidden.component.scss'],
})
export class AccessForbiddenComponent implements OnInit {
  returnUrl: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    private location: Location,
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      const forbiddenUrl = params['forbiddenUrl'];
      if (forbiddenUrl) {
        this.location.replaceState(forbiddenUrl);
      } else {
        this.router.navigateByUrl('/home');
      }
    });
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
