<goa-modal id="modal" [open]="isOpen" heading="Delete" *ngIf="data" maxwidth="80vw" [appPreventModalFlicker]="isOpen">
  <h4 class="delete-question">Are you sure you want to delete this project?</h4>
  <div class="delete-item-label">Project name</div>
  <div class="delete-item-value">
    {{ data.projectName }}
  </div>
  <div class="delete-item-label">Brief project description</div>
  <div class="delete-item-value project-description">
    {{ data && data.description ? data.description : '-' }}
  </div>
  <div class="delete-item-label">Primary capital asset</div>
  <div class="delete-item-value">
    {{ data.primaryCapitalAssetTitle }}
  </div>
  <ng-container *ngIf="data.additionalCapitalAssetTitles?.length">
    <div class="delete-item-label">Additional capital asset(s)</div>
    <div class="delete-item-value">
      {{ data.additionalCapitalAssetTitles?.join(', ') }}
    </div>
  </ng-container>
  <div class="delete-item-label">Address</div>
  <div class="delete-item-value">
    {{ data.address }}
  </div>
  <div class="delete-item-label">Coordinates</div>
  <div class="delete-item-value">{{ data.latitude }}, {{ data.longitude }}</div>

  <div class="delete-item-label">Project type</div>
  <div class="delete-item-value">
    {{ data.projectType }}
  </div>
  <div class="delete-item-label" *ngIf="data && data.projectType && data.projectType != 'Upgrade'">Quantity: new</div>
  <div class="delete-item-value" *ngIf="data && data.projectType && data.projectType != 'Upgrade'">
    {{ data.quantityNew }}
  </div>
  <div class="delete-item-label" *ngIf="data && data.projectType && data.projectType != 'New'">Quantity: upgrade</div>
  <div class="delete-item-value" *ngIf="data && data.projectType && data.projectType != 'New'">
    {{ data.quantityUpgrade }}
  </div>
  <div class="delete-item-label" *ngIf="data && data.projectType && data.projectType != 'New'">
    Current condition ranking of asset to be upgraded
  </div>
  <div class="delete-item-value" *ngIf="data && data.projectType && data.projectType != 'New'">
    {{ data.currentAssetRankingTitle }}
  </div>
  <div class="delete-item-label">Anticipated start date</div>
  <div class="delete-item-value">
    {{ data.anticipatedStartDate }}
  </div>
  <div class="delete-item-label">Anticipated end date</div>
  <div class="delete-item-value">
    {{ data.anticipatedEndDate }}
  </div>
  <div class="delete-item-label">Estimated total cost</div>
  <div class="delete-item-value">
    {{ data.estimatedTotalCost | safeDollar }}
  </div>
  <div class="delete-item-label">LGFF funding amount requested</div>
  <div class="delete-item-value">
    {{ data.lgffFundingAmountRequested | safeDollar }}
  </div>
  <div class="delete-item-label">Funding from other grant programs</div>
  <div class="delete-item-value">
    {{ data.fundingFromOtherGrantPrograms | safeDollar }}
  </div>
  <div class="delete-item-label">Funding from municipal sources</div>
  <div class="delete-item-value">
    {{ data.fundingFromMunicipalSources | safeDollar }}
  </div>
  <div slot="actions">
    <goa-button-group alignment="end">
      <goa-button type="secondary" (click)="closeModal()">Cancel</goa-button>
      <goa-button type="primary" (click)="deleteProject()">Delete</goa-button>
    </goa-button-group>
  </div>
</goa-modal>
