import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';

@Component({
  selector: 'app-confirm-cancel-delete-draft-app',
  templateUrl: './confirm-cancel-delete-draft-app.component.html',
  styleUrls: ['./confirm-cancel-delete-draft-app.component.scss'],
})
export class ConfirmCancelDeleteDraftAppComponent implements OnInit {
  _isOpen = false;
  @Input() set isOpen(value: boolean) {
    this._isOpen = value;
    this.inputChanged('isOpen');
  }

  _pageId = 'MODEL_CANCEL_DELETE_DRAFT_APP'; // or MODEL_CANCEL_DELETE_DRAFT_IN_LIST
  @Input() set pageId(value: string) {
    if (value && value.length > 0) this._pageId = value;
    this.inputChanged('pageId');
  }

  _titleParam = ''; // to replace %%
  @Input() set titleParam(param: string) {
    if (param && param.length > 0) this._titleParam = param;
    this.inputChanged('titleParam');
  }

  @Input() showBothOnRight = true;

  @Input() dlgMaxWidth = '580px';

  @Output() afterNoHandler = new EventEmitter<void>();
  @Output() afterYesHandler = new EventEmitter<void>();

  titleMain = '';
  titleSub = '';
  buttonNoText = '';
  buttonYesText = '';

  constructor(public res: ResourcePipe) {}

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  inputChanged(changeType = '') {
    // console.log(changeType + ' pageId= ' + this._pageId + ', titleParam= ' + this._titleParam + ', isOpen= ' + this._isOpen);
    if (this._isOpen) {
      this.buttonNoText = this.res.transform('btnNo', this._pageId);
      this.buttonYesText = this.res.transform('btnYes', this._pageId);
      this.titleSub = this.res.transform('body', this._pageId);
      this.titleMain = this.res.transform('title', this._pageId);
      const paramStart = this.titleMain.indexOf('"%%"');
      if (paramStart >= 0) {
        if (this._titleParam.length > 0) {
          this.titleMain = this.titleMain.replace('"%%"', "'" + this._titleParam + "'");
        } else {
          this.titleMain = this.titleMain.replace('"%%"', '');
        }
      }
    }
  }

  ngOnInit(): void {
    this.inputChanged('Confirm init');
  }

  onNoClicked() {
    if (this._isOpen) {
      // prevent multiple-click
      this._isOpen = false;
      document.body.style.overflow = 'auto';
      this.afterNoHandler.emit();
    }
  }

  onYesClicked() {
    if (this._isOpen) {
      // prevent multiple-click
      this._isOpen = false;
      document.body.style.overflow = 'auto';
      this.afterYesHandler.emit();
    }
  }
}
