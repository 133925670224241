import { Component, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarRef } from '@angular/material/snack-bar';

@Component({
  selector: 'app-success-message',
  templateUrl: './success-message.component.html',
  styleUrls: ['./success-message.component.scss'],
})
export class SuccessMessageComponent {
  constructor(
    private snackBarRef: MatSnackBarRef<SuccessMessageComponent>,
    @Inject(MAT_SNACK_BAR_DATA)
    public data: { message: string; showCloseButton: boolean; scrollToTopId?: string; scrollToTopFor?: string },
  ) {}

  close() {
    this.snackBarRef.dismiss();
  }

  scrollToTop() {
    const functionalCategoryHeading = document.getElementById(this.data.scrollToTopId!);
    window.scrollTo({
      left: 0,
      top: functionalCategoryHeading?.offsetTop ?? 0,
      behavior: 'smooth',
    });
  }
}
