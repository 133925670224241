/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { ApplicationDto } from '../models/application-dto';
import { ApplicationListDto } from '../models/application-list-dto';
import { ApplicationListFilter } from '../models/application-list-filter';
import { ApplicationReviewValidationRecordDto } from '../models/application-review-validation-record-dto';
import { CashFlowUpdateDto } from '../models/cash-flow-update-dto';
import { CashFlowUpdateListDto } from '../models/cash-flow-update-list-dto';
import { CashFlowUpdateListFilter } from '../models/cash-flow-update-list-filter';
import { CreateWorknoteDto } from '../models/create-worknote-dto';
import { CsvAllApplicationDataExportDto } from '../models/csv-all-application-data-export-dto';
import { CsvAllCashFlowUpdatedApplicationDataExportDto } from '../models/csv-all-cash-flow-updated-application-data-export-dto';
import { CsvAllFlowUpdatedProjectDataExportDto } from '../models/csv-all-flow-updated-project-data-export-dto';
import { CsvAllProjectDataExportDto } from '../models/csv-all-project-data-export-dto';
import { PdfGenerateResponseDto } from '../models/pdf-generate-response-dto';
import { ProjectDto } from '../models/project-dto';
import { WorknoteDto } from '../models/worknote-dto';

@Injectable({ providedIn: 'root' })
export class ApplicationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `updateAssignedTo()` */
  static readonly UpdateAssignedToPath = '/api/applications/assigned-to/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssignedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo$Response(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateAssignedToPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssignedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.updateAssignedTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `updateName()` */
  static readonly UpdateNamePath = '/api/applications/name/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateName()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateName$Response(
    params: {
      id: number;
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateNamePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateName(
    params: {
      id: number;
      name: string;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.updateName$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `updateDescription()` */
  static readonly UpdateDescriptionPath = '/api/applications/description/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDescription$Response(
    params: {
      id: number;
      description: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateDescriptionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('description', params.description, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateDescription(
    params: {
      id: number;
      description: string;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.updateDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `updateMinisterDescription()` */
  static readonly UpdateMinisterDescriptionPath = '/api/applications/minister-description/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMinisterDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMinisterDescription$Response(
    params: {
      id: number;
      ministerDescription: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateMinisterDescriptionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('ministerDescription', params.ministerDescription, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMinisterDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateMinisterDescription(
    params: {
      id: number;
      ministerDescription: string;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.updateMinisterDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `updateProjectName()` */
  static readonly UpdateProjectNamePath = '/api/applications/project-name/{projectid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectName()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateProjectName$Response(
    params: {
      projectid: number;
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProjectDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateProjectNamePath, 'patch');
    if (params) {
      rb.path('projectid', params.projectid, {});
      rb.query('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectName$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateProjectName(
    params: {
      projectid: number;
      name: string;
    },
    context?: HttpContext
  ): Observable<ProjectDto> {
    return this.updateProjectName$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectDto>): ProjectDto => r.body)
    );
  }

  /** Path part for operation `updateProjectDescription()` */
  static readonly UpdateProjectDescriptionPath = '/api/applications/project-description/{projectid}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateProjectDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateProjectDescription$Response(
    params: {
      projectid: number;
      description: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ProjectDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateProjectDescriptionPath, 'patch');
    if (params) {
      rb.path('projectid', params.projectid, {});
      rb.query('description', params.description, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ProjectDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateProjectDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateProjectDescription(
    params: {
      projectid: number;
      description: string;
    },
    context?: HttpContext
  ): Observable<ProjectDto> {
    return this.updateProjectDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<ProjectDto>): ProjectDto => r.body)
    );
  }

  /** Path part for operation `updateReviewValidationRecord()` */
  static readonly UpdateReviewValidationRecordPath = '/api/applications/review-validation-record/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateReviewValidationRecord()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReviewValidationRecord$Response(
    params: {
      id: number;
      body: ApplicationReviewValidationRecordDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateReviewValidationRecordPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateReviewValidationRecord$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateReviewValidationRecord(
    params: {
      id: number;
      body: ApplicationReviewValidationRecordDto
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.updateReviewValidationRecord$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `recommendApplication()` */
  static readonly RecommendApplicationPath = '/api/applications/recommend/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recommendApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommendApplication$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.RecommendApplicationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recommendApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommendApplication(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.recommendApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `returnApplicationToOrganization()` */
  static readonly ReturnApplicationToOrganizationPath = '/api/applications/return-to-organization/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnApplicationToOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnApplicationToOrganization$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ReturnApplicationToOrganizationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnApplicationToOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnApplicationToOrganization(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.returnApplicationToOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `returnApplicationToGrantAdvisor()` */
  static readonly ReturnApplicationToGrantAdvisorPath = '/api/applications/return-to-grant-advisor/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnApplicationToGrantAdvisor()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnApplicationToGrantAdvisor$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ReturnApplicationToGrantAdvisorPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnApplicationToGrantAdvisor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnApplicationToGrantAdvisor(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.returnApplicationToGrantAdvisor$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `startReviewApplication()` */
  static readonly StartReviewApplicationPath = '/api/applications/start-review/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startReviewApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReviewApplication$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.StartReviewApplicationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startReviewApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReviewApplication(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.startReviewApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `withdrawApplication()` */
  static readonly WithdrawApplicationPath = '/api/applications/withdraw/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdrawApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApplication$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.WithdrawApplicationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdrawApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawApplication(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.withdrawApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `countAllCashFlows()` */
  static readonly CountAllCashFlowsPath = '/api/applications/cashFlows/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `countAllCashFlows()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countAllCashFlows$Response(
    params: {
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CountAllCashFlowsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `countAllCashFlows$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  countAllCashFlows(
    params: {
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.countAllCashFlows$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAllCashFlowUpdates()` */
  static readonly FindAllCashFlowUpdatesPath = '/api/applications/cashFlows';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAllCashFlowUpdates()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllCashFlowUpdates$Response(
    params: {
      take?: number;
      skip?: number;
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CashFlowUpdateListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.FindAllCashFlowUpdatesPath, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CashFlowUpdateListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAllCashFlowUpdates$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAllCashFlowUpdates(
    params: {
      take?: number;
      skip?: number;
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<Array<CashFlowUpdateListDto>> {
    return this.findAllCashFlowUpdates$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CashFlowUpdateListDto>>): Array<CashFlowUpdateListDto> => r.body)
    );
  }

  /** Path part for operation `findOneCashFlowUpdate()` */
  static readonly FindOneCashFlowUpdatePath = '/api/applications/cashFlow/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOneCashFlowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneCashFlowUpdate$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.FindOneCashFlowUpdatePath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOneCashFlowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOneCashFlowUpdate(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.findOneCashFlowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `returnCashFlowUpdateToOrganization()` */
  static readonly ReturnCashFlowUpdateToOrganizationPath = '/api/applications/cashFlow/return-to-organization/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnCashFlowUpdateToOrganization()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnCashFlowUpdateToOrganization$Response(
    params: {
      id: number;
      workNote: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ReturnCashFlowUpdateToOrganizationPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('workNote', params.workNote, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnCashFlowUpdateToOrganization$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnCashFlowUpdateToOrganization(
    params: {
      id: number;
      workNote: string;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.returnCashFlowUpdateToOrganization$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `recommendCashFlowUpdate()` */
  static readonly RecommendCashFlowUpdatePath = '/api/applications/cashFlow/recommend/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `recommendCashFlowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommendCashFlowUpdate$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.RecommendCashFlowUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `recommendCashFlowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  recommendCashFlowUpdate(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.recommendCashFlowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `returnCashFlowUpdateToGrantAdvisor()` */
  static readonly ReturnCashFlowUpdateToGrantAdvisorPath = '/api/applications/cashFlow/return-to-grant-advisor/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnCashFlowUpdateToGrantAdvisor()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnCashFlowUpdateToGrantAdvisor$Response(
    params: {
      id: number;
      assignedToId: string;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ReturnCashFlowUpdateToGrantAdvisorPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
      rb.query('workNote', params.workNote, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnCashFlowUpdateToGrantAdvisor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnCashFlowUpdateToGrantAdvisor(
    params: {
      id: number;
      assignedToId: string;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.returnCashFlowUpdateToGrantAdvisor$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `startReviewCashFlowUpdate()` */
  static readonly StartReviewCashFlowUpdatePath = '/api/applications/cashFlow/start-review/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startReviewCashFlowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReviewCashFlowUpdate$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.StartReviewCashFlowUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startReviewCashFlowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReviewCashFlowUpdate(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.startReviewCashFlowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `withdrawCashFlowUpdate()` */
  static readonly WithdrawCashFlowUpdatePath = '/api/applications/cashFlow/withdraw/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `withdrawCashFlowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawCashFlowUpdate$Response(
    params: {
      id: number;
      workNote: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.WithdrawCashFlowUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('workNote', params.workNote, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `withdrawCashFlowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  withdrawCashFlowUpdate(
    params: {
      id: number;
      workNote: string;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.withdrawCashFlowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `updateCashFlowUpdateAssignedTo()` */
  static readonly UpdateCashFlowUpdateAssignedToPath = '/api/applications/cashFlow/assigned-to/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCashFlowUpdateAssignedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCashFlowUpdateAssignedTo$Response(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateCashFlowUpdateAssignedToPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCashFlowUpdateAssignedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCashFlowUpdateAssignedTo(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.updateCashFlowUpdateAssignedTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `approveCashFlowUpdate()` */
  static readonly ApproveCashFlowUpdatePath = '/api/applications/cashFlow/approve/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveCashFlowUpdate()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveCashFlowUpdate$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.ApproveCashFlowUpdatePath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveCashFlowUpdate$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveCashFlowUpdate(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.approveCashFlowUpdate$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `updateCashFlowMinisterDescription()` */
  static readonly UpdateCashFlowMinisterDescriptionPath = '/api/applications/cashFlow/minister-description/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateCashFlowMinisterDescription()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCashFlowMinisterDescription$Response(
    params: {
      id: number;
      ministerDescription: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<CashFlowUpdateDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.UpdateCashFlowMinisterDescriptionPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('ministerDescription', params.ministerDescription, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<CashFlowUpdateDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateCashFlowMinisterDescription$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateCashFlowMinisterDescription(
    params: {
      id: number;
      ministerDescription: string;
    },
    context?: HttpContext
  ): Observable<CashFlowUpdateDto> {
    return this.updateCashFlowMinisterDescription$Response(params, context).pipe(
      map((r: StrictHttpResponse<CashFlowUpdateDto>): CashFlowUpdateDto => r.body)
    );
  }

  /** Path part for operation `createCashFloeWorknote()` */
  static readonly CreateCashFloeWorknotePath = '/api/applications/cashFlow/worknote/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createCashFloeWorknote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCashFloeWorknote$Response(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorknoteDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CreateCashFloeWorknotePath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorknoteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createCashFloeWorknote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createCashFloeWorknote(
    params: {
      id: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<WorknoteDto> {
    return this.createCashFloeWorknote$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorknoteDto>): WorknoteDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailableCashFlowUpdateActions()` */
  static readonly GetUserAvailableCashFlowUpdateActionsPath = '/api/applications/cashFlow/available-actions/{cashFlowUpdateId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableCashFlowUpdateActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableCashFlowUpdateActions$Response(
    params: {
      cashFlowUpdateId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.GetUserAvailableCashFlowUpdateActionsPath, 'get');
    if (params) {
      rb.path('cashFlowUpdateId', params.cashFlowUpdateId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableCashFlowUpdateActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableCashFlowUpdateActions(
    params: {
      cashFlowUpdateId: number;
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableCashFlowUpdateActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `count()` */
  static readonly CountPath = '/api/applications/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(
    params: {
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CountPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(
    params: {
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_1()` */
  static readonly FindAll_1Path = '/api/applications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      take?: number;
      skip?: number;
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.FindAll_1Path, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      take?: number;
      skip?: number;
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<Array<ApplicationListDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationListDto>>): Array<ApplicationListDto> => r.body)
    );
  }

  /** Path part for operation `findOne_1()` */
  static readonly FindOne_1Path = '/api/applications/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.FindOne_1Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationDto>): ApplicationDto => r.body)
    );
  }

  /** Path part for operation `pdfApplicationDetails()` */
  static readonly PdfApplicationDetailsPath = '/api/applications/pdf/application-details/{organizationId}/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfApplicationDetails()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDetails$Response(
    params: {
      organizationId: number;
      id: number;
      version: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.PdfApplicationDetailsPath, 'get');
    if (params) {
      rb.path('organizationId', params.organizationId, {});
      rb.path('id', params.id, {});
      rb.query('version', params.version, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfApplicationDetails$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfApplicationDetails(
    params: {
      organizationId: number;
      id: number;
      version: string;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfApplicationDetails$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `createApplicationWorknote()` */
  static readonly CreateApplicationWorknotePath = '/api/applications/worknote/{applicationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `createApplicationWorknote()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApplicationWorknote$Response(
    params: {
      applicationId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<WorknoteDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CreateApplicationWorknotePath, 'post');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<WorknoteDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `createApplicationWorknote$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  createApplicationWorknote(
    params: {
      applicationId: number;
      body: CreateWorknoteDto
    },
    context?: HttpContext
  ): Observable<WorknoteDto> {
    return this.createApplicationWorknote$Response(params, context).pipe(
      map((r: StrictHttpResponse<WorknoteDto>): WorknoteDto => r.body)
    );
  }

  /** Path part for operation `csvFindAllApplicationData()` */
  static readonly CsvFindAllApplicationDataPath = '/api/applications/csv/find-all-application-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllApplicationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllApplicationData$Response(
    params: {
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllApplicationDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CsvFindAllApplicationDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllApplicationDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllApplicationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllApplicationData(
    params: {
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllApplicationDataExportDto>> {
    return this.csvFindAllApplicationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllApplicationDataExportDto>>): Array<CsvAllApplicationDataExportDto> => r.body)
    );
  }

  /** Path part for operation `csvFindAllProjectData()` */
  static readonly CsvFindAllProjectDataPath = '/api/applications/csv/find-all-project-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllProjectData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllProjectData$Response(
    params: {
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllProjectDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CsvFindAllProjectDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllProjectDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllProjectData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllProjectData(
    params: {
      body: ApplicationListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllProjectDataExportDto>> {
    return this.csvFindAllProjectData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllProjectDataExportDto>>): Array<CsvAllProjectDataExportDto> => r.body)
    );
  }

  /** Path part for operation `csvFindAllCashFlowUpdateApplicationData()` */
  static readonly CsvFindAllCashFlowUpdateApplicationDataPath = '/api/applications/csv/find-all-cash-flow-update-application-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllCashFlowUpdateApplicationData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllCashFlowUpdateApplicationData$Response(
    params: {
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllCashFlowUpdatedApplicationDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CsvFindAllCashFlowUpdateApplicationDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllCashFlowUpdatedApplicationDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllCashFlowUpdateApplicationData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllCashFlowUpdateApplicationData(
    params: {
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllCashFlowUpdatedApplicationDataExportDto>> {
    return this.csvFindAllCashFlowUpdateApplicationData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllCashFlowUpdatedApplicationDataExportDto>>): Array<CsvAllCashFlowUpdatedApplicationDataExportDto> => r.body)
    );
  }

  /** Path part for operation `csvFindAllCashFlowUpdateProjectData()` */
  static readonly CsvFindAllCashFlowUpdateProjectDataPath = '/api/applications/csv/find-all-cash-flow-update-project-data';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllCashFlowUpdateProjectData()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllCashFlowUpdateProjectData$Response(
    params: {
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvAllFlowUpdatedProjectDataExportDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.CsvFindAllCashFlowUpdateProjectDataPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvAllFlowUpdatedProjectDataExportDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllCashFlowUpdateProjectData$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllCashFlowUpdateProjectData(
    params: {
      body: CashFlowUpdateListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvAllFlowUpdatedProjectDataExportDto>> {
    return this.csvFindAllCashFlowUpdateProjectData$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvAllFlowUpdatedProjectDataExportDto>>): Array<CsvAllFlowUpdatedProjectDataExportDto> => r.body)
    );
  }

  /** Path part for operation `getUserAvailableApplicationActions()` */
  static readonly GetUserAvailableApplicationActionsPath = '/api/applications/available-actions/{applicationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableApplicationActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableApplicationActions$Response(
    params: {
      applicationId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationService.GetUserAvailableApplicationActionsPath, 'get');
    if (params) {
      rb.path('applicationId', params.applicationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableApplicationActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableApplicationActions(
    params: {
      applicationId: number;
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableApplicationActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

}
