import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthService } from '@app-com/api/services';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { Observable, catchError, map, of, take } from 'rxjs';
import { environment } from '@app-pot-env/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard {
  // constructor(private http: HttpClient, private router: Router) {}
  // constructor(private auth: AuthService, private router: Router) {
  constructor(
    private auth: AuthService,
    private router: Router,
    private authStateService: AuthStateService,
  ) {}
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    console.log('[canActivate] Auth guard invoked', state.url);
    // should find a way to reduce api call on each url route
    return this.auth.getExternalProfile().pipe(
      take(1),
      map((user) => {
        if (user) {
          console.log('Authenticated user', user);
          this.authStateService.setAuthenticatedUser(user);
        }
        return !!user;
      }),
      catchError((err) => {
        console.log(err);
        localStorage.setItem('original_redirect', state.url);
        window.location.href = environment.ssoLoginSamlPath as string;
        return of(false);
      }),
    );
  }
}
