import { Component, EventEmitter, Input, Output, TemplateRef, ViewChild } from '@angular/core';

@Component({
  selector: 'app-lgff-goa-modal',
  templateUrl: './lgff-goa-modal.component.html',
  styleUrls: ['./lgff-goa-modal.component.scss'],
})
export class LgffGoaModalComponent {
  @Input() pageId = 'LGFF_GOA_MODAL';
  @Input() modalId = 'lgff-goa-modal'; // assign an id to the element, easier for automation testing.
  @Input() open: boolean = false;
  @Input() headingId: string = 'default_heading'; // Provide the string resource id of the heading.
  @Input() modalContentStringId: string = ''; // Provide the string resource id of the content.
  @Input() maxwidth: string = '550px';
  @Input() closable: boolean = false; // Show close icon and allow clicking the background to close the modal Defaults to false.
  @Input() okBtnLabel = 'ok'; // The string resource Id for the OK btn.
  @Input() resetBtnLabel = 'reset'; // The string resource Id for the reset btn.
  @Input() cancelBtnLabel = 'cancel'; // The string resource Id for the cancel btn.
  @Input() actionBtnNumber = 1; // How many buttons do we need to show in the modal.
  @Input() okBtnDisabled = false;
  @Input() resetBtnDisabled = false;
  @Input() cancelBtnDisabled = false;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() onModalClosed: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() okBtnClickedEvent: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() cancelBtnClickedEvent: EventEmitter<any> = new EventEmitter();
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() resetBtnClickedEvent: EventEmitter<any> = new EventEmitter();

  @ViewChild('defaultFooterTemplate', { static: true }) defaultFooterTemplate: TemplateRef<{ [key: string]: string }>;
  @ViewChild('confirmationFooterTemplate', { static: true }) confirmationFooterTemplate: TemplateRef<{
    [key: string]: string;
  }>;
  @ViewChild('threeActionFooterTemplate', { static: true }) threeActionFooterTemplate: TemplateRef<{
    [key: string]: string;
  }>;

  ngOnInit() {
    return;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getFooterTemplate(): TemplateRef<any> {
    switch (this.actionBtnNumber) {
      case 2:
        return this.confirmationFooterTemplate;
      case 3:
        return this.threeActionFooterTemplate;
      default:
        return this.defaultFooterTemplate;
    }
  }

  @Input() okBtnClicked(): void {
    if (this.okBtnDisabled) {
      return;
    }
    this.okBtnClickedEvent.emit();
    this.onModalClose(null);
  }

  @Input() onResetBtnClicked(): void {}

  @Input() onCancelBtnClicked(): void {
    if (this.cancelBtnDisabled) {
      return;
    }
    this.cancelBtnClickedEvent.emit();
    this.onModalClose(null);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onModalClose(event: Event | null): void {
    console.log('file: lgff-goa-modal.component.ts:80 ~ onModalClose ~ event:', event);
    this.onModalClosed.emit();
  }
}
