import { Component, OnInit, Renderer2 } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss'],
})
export class NotFoundComponent implements OnInit {
  constructor(
    private router: Router,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this._clearCircularProgressStyles();
  }

  private _clearCircularProgressStyles() {
    // Remove the scroll styles applied to the body tag by 'circular-progress' if navigation occurs from a component while 'circular-progress' is visible.
    this.renderer.setStyle(document.body, 'overflow', '');
    this.renderer.setStyle(document.body, 'padding-right', '');
  }

  goHomepage() {
    this.router.navigateByUrl('/');
  }
}
