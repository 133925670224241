import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';

import { AuthService } from '@app-com/api/services';
import { environment } from '@app-pot-env/environment';

@Injectable({
  providedIn: 'root',
})
export class TimeoutService {
  private timeoutVisibility = new BehaviorSubject<boolean>(false);
  private timeoutVisibility$: Observable<boolean> = this.timeoutVisibility.asObservable();

  private expRemainTimeOutputStr = new BehaviorSubject<string>(' ');
  private expRemainTimeOutputStr$: Observable<string> = this.expRemainTimeOutputStr.asObservable();

  timeoutShowAheadExpSeconds = 60; // config, show timeout-dialog, 60 seconds before userExp
  timeoutDlgShowStaySeconds = 59; // config, timeout-dialog showing max stay, 60 seconds
  checkLoginExpireInterval = 55; // config, re-check userExp, prevent user logged-out, expired,extended

  userExp = -1; // user token expire clock-time, in seconds (not ms)
  lastCheckLoginExpireTime = 0;

  expRemainMin = 0;
  expRemainSec = 0;
  expRemainTimeStr = '';

  timeoutDlgToShowTicks = -1; // how many seconds has passed, before reach timeoutDlgNextShowWaitSeconds, to show dialog
  timeoutDlgShowingTicks = -1; // how many seconds has passed, after showing dialog
  wantShowLoginTimeoutDlg = false; // control dialog on/off
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  timeoutHandler: any = undefined;
  sub = new Subscription();

  logoutUrl = environment.maConnectDashboard as string;

  constructor(private auth: AuthService) {
    setTimeout(() => {
      this.onInit();
    }, 5000); // start timeout-monitor after each reload
  }

  public onInit(): void {
    this.initCountDownTimer();

    this.timeoutHandler = setInterval(() => {
      this.checkFrontTimeout();
    }, 1000);
  }

  public onDestroy(): void {
    this.initCountDownTimer();
    if (this.timeoutHandler) {
      clearInterval(this.timeoutHandler);
      this.timeoutHandler = undefined;
    }
    this.sub.unsubscribe();
  }

  public getTimeoutVisibility(): Observable<boolean> {
    return this.timeoutVisibility$;
  }

  public setTimeoutVisibility(isMapVisible: boolean) {
    this.timeoutVisibility.next(isMapVisible);
  }

  public getExpRemainTimeOutputStr(): Observable<string> {
    return this.expRemainTimeOutputStr$;
  }

  public setExpRemainTimeOutputStr(dueStr: string) {
    this.expRemainTimeOutputStr.next(dueStr);
  }

  public setLogoutUrl(url: string) {
    this.logoutUrl = url;
  }

  // user.exp - cur-seconds, in relative seconds
  get expRemainTotalSeconds(): number {
    let remains = -1;
    if (this.userExp > 0) {
      const curTimeSeconds = Math.trunc(Date.now() / 1000); // ms => seconds
      remains = this.userExp - curTimeSeconds;
      if (remains < 0) remains = -1;
    }
    return remains;
  }

  // how many relative seconds to count, before showing dialog from cur-time
  get timeoutDlgNextShowWaitSeconds() {
    let nextShowSeconds = this.expRemainTotalSeconds - this.timeoutShowAheadExpSeconds;
    if (nextShowSeconds < 0) nextShowSeconds = -1;
    return nextShowSeconds;
  }

  public setWantShowLoginTimeoutDlg(wantShow: boolean) {
    if (this.wantShowLoginTimeoutDlg !== wantShow) {
      this.wantShowLoginTimeoutDlg = wantShow;
      this.setTimeoutVisibility(wantShow);
    }
  }

  private initCountDownTimer(closeOrHideDisalog = true) {
    this.userExp = -1;
    this.lastCheckLoginExpireTime = 0;

    this.expRemainMin = 0;
    this.expRemainSec = 0;
    this.expRemainTimeStr = '';

    this.timeoutDlgToShowTicks = -1;
    this.timeoutDlgShowingTicks = -1;
    if (closeOrHideDisalog) {
      this.wantShowLoginTimeoutDlg = false;
      this.setWantShowLoginTimeoutDlg(false);
    }
  }

  // new user login, logged-out, or user expiration time updated. exp=0 means no user logged out, stop monitor
  public startUserTimeoutWithExp(exp: number) {
    // exp is in seconds
    if (this.userExp === exp) return;

    this.initCountDownTimer(false);
    this.userExp = exp;
    if (exp > 0) {
      const remainTotSecs = this.expRemainTotalSeconds;
      if (remainTotSecs > 1) {
        // treat as valid expiration time, before do timeout
        this.expRemainMin = Math.trunc(remainTotSecs / 60); // minutes
        this.expRemainSec = Math.trunc(remainTotSecs - this.expRemainMin * 60); // seconds
        this.timeoutDlgToShowTicks = 0; // start ticking, before show dialog
      } else {
        this.userExp = -1; // stop all timeout logic
      }
    }
  }

  public checkFrontTimeout() {
    // keep for later debug, when we need add more timer logics
    if (this.userExp >= 0 && (this.timeoutDlgToShowTicks + this.timeoutDlgShowingTicks) % 10 === 1) {
      let showStr = 'expire remains= ' + this.expRemainMin + ':' + this.expRemainSec;
      if (this.wantShowLoginTimeoutDlg) {
        showStr += ', showing= ' + this.timeoutDlgShowingTicks;
      } else {
        const toShowSecondsLeft = this.timeoutDlgNextShowWaitSeconds; // - this.timeoutDlgToShowTicks;
        if (toShowSecondsLeft >= 0) {
          showStr += ', toShowDelt = ' + toShowSecondsLeft;
        }
      }
      console.warn(showStr);
    }

    this.checkTokenExpire(); // loop monitor user.ext changes every checkLoginExpireInterval
    if (this.userExp <= 0) {
      return; // skip below launch, close actions
    }
    if (this.userExp > 0 && !this.wantShowLoginTimeoutDlg && this.timeoutDlgToShowTicks >= 0) {
      this.timeoutDlgToShowTicks++;
    } else if (this.userExp > 0 && this.wantShowLoginTimeoutDlg) {
      this.timeoutDlgShowingTicks++;
      this.generateExpRemainTimeOutputStr(); // generate remain-time-string for timeout-dialog
    }
    this.remainTimeDecreseByOne();

    if (this.wantShowLoginTimeoutDlg && this.timeoutDlgShowingTicks >= this.timeoutDlgShowStaySeconds) {
      // auto close idle dialog
      this.closeTimeoutDialog();
    } else if (
      !this.wantShowLoginTimeoutDlg &&
      this.timeoutDlgToShowTicks > 0 &&
      this.timeoutDlgNextShowWaitSeconds <= 0
    ) {
      // launch idle dialog
      this.launchTimeoutDialog();
    }
  }

  private remainTimeDecreseByOne() {
    if (this.expRemainMin > 0 || this.expRemainSec > 0) {
      if (this.expRemainSec > 0) {
        this.expRemainSec = this.expRemainSec - 1;
      } else if (this.expRemainMin > 0) {
        this.expRemainSec = 59;
        this.expRemainMin = this.expRemainMin - 1;
      }
    }
  }

  private generateExpRemainTimeOutputStr() {
    const totSecondsToClose = this.timeoutDlgShowStaySeconds - this.timeoutDlgShowingTicks;
    let minToClose = 0;
    let secToClose = 0;
    const lastExpireDueTime = this.expRemainTimeStr;

    if (totSecondsToClose > 0) {
      minToClose = Math.trunc(totSecondsToClose / 60);
      secToClose = totSecondsToClose - minToClose * 60;

      this.expRemainTimeStr = '';
      if (minToClose > 1) {
        this.expRemainTimeStr += minToClose + ' munutes';
      } else if (minToClose === 1) {
        this.expRemainTimeStr += '1 munute';
      }
      if (secToClose > 0) {
        if (this.expRemainTimeStr.length > 0) {
          this.expRemainTimeStr += ' ';
        }
        if (secToClose > 1) {
          this.expRemainTimeStr += secToClose + ' seconds';
        } else if (secToClose === 1) {
          this.expRemainTimeStr += '1 second';
        }
      }
    } else {
      this.expRemainTimeStr += '0 second';
    }
    if (lastExpireDueTime !== this.expRemainTimeStr) {
      this.setExpRemainTimeOutputStr(this.expRemainTimeStr);
    }
  }

  // loop monitor user.ext changes every checkLoginExpireInterval
  private checkTokenExpire() {
    const curTimeSeconds = Math.trunc(Date.now() / 1000); // ms => seconds
    const hasValidUserExp = this.userExp > curTimeSeconds;
    // const toShowSecondsLeft = this.timeoutDlgNextShowWaitSeconds; // - this.timeoutDlgToShowTicks;
    const nextCheckTm = this.lastCheckLoginExpireTime + this.checkLoginExpireInterval;

    if (!hasValidUserExp) {
      // user expired, logged out
      if (this.wantShowLoginTimeoutDlg) {
        // user logged out when dialog is showing, auto close timeout dialog now
        this.closeTimeoutDialog();
        return;
      }
      // else will call tokenExpiry() below
    } else if (this.wantShowLoginTimeoutDlg) {
      return; // when timeout dialog is showing, YES will do checkTokenExpire by itself, no need checkTokenExpire here
    } else if (!this.wantShowLoginTimeoutDlg && curTimeSeconds < nextCheckTm) {
      return; // reduce frequence
    }
    this.lastCheckLoginExpireTime = curTimeSeconds;

    this.auth.tokenExpiry().subscribe({
      next: (newExp) => {
        if (newExp <= 0 || newExp <= curTimeSeconds + 5) {
          console.error('tokenExpiry returned exp is expired or almost expired');
          // alert('tokenExpiry returned exp is expired or almost expired');
          if (hasValidUserExp) {
            this.initCountDownTimer(); // reset timeout for non-signedin or auto-signedout user
            window.location.href = this.logoutUrl;
          }
        } else if (newExp !== this.userExp) {
          // exp extended, or valid-user-signed-in, (re)start timeout for signed-in or exp-changed user
          console.warn(
            'Old user exp= ' + this.userExp + ', new user exp= ' + newExp + '; delt= ' + (newExp - this.userExp),
          );
          this.startUserTimeoutWithExp(newExp); // recalc + schedule next timeout
        }
      },
      error: (error) => {
        console.error('checkTokenExpire error: ' + error);
        // alert('checkTokenExpire error');
        this.initCountDownTimer(); // reset timeout for non-signedin or auto-signedout user
        window.location.href = this.logoutUrl;
      },
    });
  }

  private closeTimeoutDialog() {
    // console.warn('Timeout dialog closed ' + bAutoClose ? 'automaticaly' : 'by no click');
    this.timeoutDlgShowingTicks = 0;
    this.timeoutDlgToShowTicks = -1; // stop count tick
    this.setWantShowLoginTimeoutDlg(false);
    this.setTimeoutVisibility(false);
    // logout
    window.location.href = this.logoutUrl;
  }

  private launchTimeoutDialog() {
    // console.warn('Start show timeout dialog');
    this.timeoutDlgToShowTicks = -1; // stop count tick
    this.timeoutDlgShowingTicks = 0; // start count showing seconds
    this.setWantShowLoginTimeoutDlg(true);
    this.setTimeoutVisibility(true);
  }
}
