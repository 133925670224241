import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';

@Component({
  selector: 'app-edit-sepo-certify-submit',
  templateUrl: './edit-sepo-certify-submit.component.html',
  styleUrl: './edit-sepo-certify-submit.component.scss',
})
export class EditSepoCertifySubmitComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  currentStep: EditSepoSequence | undefined;
  submitting = false;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  constructor(
    private store: Store,
    private router: Router,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.Certification) {
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('submit') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.Complete,
                [EditSepoSequence.Certification]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            //TODO: Call submit API here or open a dialog for confirmation then submit in that modal
            /*Demo code, to Be removed*/
            this.submitting = true;
            this.timeoutIds.push(
              setTimeout(() => {
                this.submitting = false;
                this.router.navigate(['']);
              }, 3000),
            );
            /*Demo code, to Be removed*/
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/immediate-outcomes');
            this.router.navigate(['edit-sepo/immediate-outcomes']);
          }
        }
      }),
    );
  }
  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    return true;
  }
}
