import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { SuccessMessageComponent } from './components/success-message/success-message.component';

@Injectable({
  providedIn: 'root',
})
export class SnackBarService {
  constructor(private snackBar: MatSnackBar) {}

  showSuccessMessage(message: string, showCloseButton: boolean = false, duration: number = 20000) {
    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: { message, showCloseButton },
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
    });
  }

  showSuccessMessageWithScrollToTopLink(
    message: string,
    scrollToTopId: string,
    scrollToTopFor: string,
    showCloseButton: boolean = false,
    duration: number = 20000,
  ) {
    this.snackBar.openFromComponent(SuccessMessageComponent, {
      data: { message, showCloseButton, scrollToTopId, scrollToTopFor },
      duration,
      verticalPosition: 'bottom',
      horizontalPosition: 'left',
    });
  }

  hideSuccessMessage() {
    this.snackBar.dismiss();
  }
}
