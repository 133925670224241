import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'safeStr',
})
export class SafeStrPipe implements PipeTransform {
  transform(str: string | number | undefined | null, defaultString = '-'): string {
    if (str == null || str == undefined) {
      return defaultString;
    }
    if (typeof str == 'string') {
      if (str.trim().length == 0) {
        return defaultString;
      } else {
        return str;
      }
    } else if (typeof str == 'number') {
      return (str || 0) + '';
    } else {
      return str;
    }
  }
}
