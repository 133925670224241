import { Injectable } from '@angular/core';
import { HttpErrorResponse, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';
@Injectable({
  providedIn: 'root',
})
@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
  constructor(private router: Router) {}
  msg = '';
  errorStatusCode: string;

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler) {
    return next.handle(request).pipe(
      catchError((error: HttpErrorResponse) => {
        console.log('Error:', error);
        this.errorStatusCode = error.status.toString();
        if (this.errorStatusCode == '404') {
          this.router.navigate(['page-not-found']);
        }
        if (this.errorStatusCode == '500' || (error instanceof HttpErrorResponse && error.status === 0)) {
          if (!this.router.url.includes('general-error')) {
            const currentUrl = this.router.url;
            this.router.navigate(['general-error'], {
              queryParams: { returnUrl: currentUrl },
            });
          }
        } else if (this.errorStatusCode == '403') {
          if (!this.router.url.includes('access-forbidden')) {
            const currentUrl = this.router.url;
            this.router.navigate(['access-forbidden'], {
              queryParams: { forbiddenUrl: currentUrl },
            });
          }
        }
        return throwError(() => new Error(error.error));
      }),
    );
  }
}
