<div class="table-container">
  <goa-table class="goa-table" [width]="tableWidth">
    <!-- Table header -->
    <thead>
      <tr *ngIf="showAdditionalHeader">
        <ng-container *ngFor="let additionalHeader of additionalHeaders">
          <td
            [attr.colspan]="additionalHeader.colspan || 1"
            [attr.rowspan]="additionalHeader.rowspan || 1"
            [ngClass]="additionalHeader.headerClassName ? additionalHeader.headerClassName : ''"
          >
            {{ additionalHeader.displayName }}
          </td>
        </ng-container>
      </tr>

      <tr>
        <ng-container *ngFor="let header of headers">
          <th
            [attr.colspan]="header.colspan || 1"
            [attr.rowspan]="header.rowspan || 1"
            [ngClass]="header.headerClassName ? header.headerClassName : ''"
          >
            {{ header.displayName }}
          </th>
        </ng-container>
      </tr>
    </thead>

    <!-- Table body -->
    <tbody>
      <ng-container *ngFor="let row of tableData">
        <tr>
          <!-- Display parent row data, apply rowspan for nested child rows -->
          <td
            *ngFor="let header of headers.slice(0, parentColumnIndent)"
            [attr.rowspan]="row[childKey]?.length || 1"
            [ngClass]="header?.cellClassName"
          >
            <!-- Check if a template exists for this fieldName, else fallback to plain text -->
            <ng-container *ngIf="cellTemplates[header.fieldName]; else plainText">
              <ng-container
                *ngTemplateOutlet="cellTemplates[header.fieldName]; context: { $implicit: row[header.fieldName] }"
              >
              </ng-container>
            </ng-container>
            <ng-template #plainText>
              {{ getValue(row, header.fieldName) }}
            </ng-template>
          </td>

          <!-- First child row for this parent row -->
          <ng-container *ngIf="row[childKey]?.length > 0">
            <td *ngFor="let header of headers?.slice(parentColumnIndent)" [ngClass]="header?.cellClassName">
              <ng-container *ngIf="cellTemplates[header.fieldName]; else plainTextChild">
                <ng-container
                  *ngTemplateOutlet="
                    cellTemplates[header.fieldName];
                    context: { $implicit: row[childKey][0][header.fieldName] }
                  "
                >
                </ng-container>
              </ng-container>
              <ng-template #plainTextChild>
                {{ getValue(row[childKey][0], header.fieldName) }}
              </ng-template>
            </td>
          </ng-container>
        </tr>

        <!-- Remaining nested child rows  -->
        <tr *ngFor="let childRow of row[childKey]?.slice(1)">
          <td *ngFor="let header of headers?.slice(parentColumnIndent)" [ngClass]="header?.cellClassName">
            <ng-container *ngIf="cellTemplates[header.fieldName]; else plainTextChildRow">
              <ng-container
                *ngTemplateOutlet="cellTemplates[header.fieldName]; context: { $implicit: childRow[header.fieldName] }"
              >
              </ng-container>
            </ng-container>
            <ng-template #plainTextChildRow>
              {{ getValue(childRow, header.fieldName) }}
            </ng-template>
          </td>
        </tr>
      </ng-container>
    </tbody>

    <!-- Totals template -->
    <!-- <ng-container>
      <tr style="background-color: #dcdcdc">
        <td style="text-align: end" [attr.colspan]="7">Totals</td>
        <td *ngFor="let amount of totals">
          {{ amount.value | currency }}
        </td>
      </tr>
    </ng-container> -->
  </goa-table>
</div>
