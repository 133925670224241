import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationListFilter, ApplicationReturnedDto, ApplicationStatusType } from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { ApplicationUrlLocationHistoryState } from '@app-pot/features/grant-application/models/application-url-location-history-state';
import { ApplicationStatus } from '@app-pot/features/grant-application/models/enums';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { DeleteApplicationDraft } from '@app-pot/store/actions/application-draft.action';
import { RemoveReturnedApplicationFromStateByDraftId } from '@app-pot/store/actions/applications.action';
import { ClearApplicationDraftContext } from '@app-pot/store/actions/current-context.action';
import {
  LoadAllCapitalAssetTypes,
  LoadFunctionalCategoryTypes,
  LoadPrimaryOutcomes,
} from '@app-pot/store/actions/lookup-value.action';
import { ApplicationsState } from '@app-pot/store/state/applications.state';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CapitalAssetTypeLV, LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { Select, Store } from '@ngxs/store';
import { combineLatest, delay, filter, Observable, Subscription, take, takeWhile } from 'rxjs';
import { ViewApplicationTab } from '../view-application-tab.enum';
import { ReturnedApplicationVm } from './returned-application-vm';
import { ActiveTabService } from '../active-tab.service';
import { ApplicationExtService } from '@app-com/api/services';

@Component({
  selector: 'app-returned-applications',
  templateUrl: './returned-applications.component.html',
  styleUrls: ['../common-tab-style.scss'],
})
export class ReturnedApplicationsComponent implements OnInit, OnDestroy {
  @Select(LookupValueState.getPrimaryOutcomes) primaryOutcomes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getFunctionalCategoryTypes) functionalCategoryTypes$: Observable<LookupValue[]>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;

  pageId = 'RETURNED_APPLICATIONS';
  applications: ReturnedApplicationVm[];
  filteredApplications: ReturnedApplicationVm[];
  pageApplications: ReturnedApplicationVm[];
  page: number = 1;
  perPage: number = 10; //Pagination disabled for now
  showPerPageControl = true;
  openWithdrawModal = false;
  appNameToWithdraw: string;
  appIdToWithdraw: number;
  isAlive = true;
  isLoading: boolean;
  sub = new Subscription();
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  currentPage = 1;
  perPageCount = 25;
  pageSizeOptions = [10, 25, 50, 100];
  returnedApplicationsCount: number = 0;
  currentOrganizationId: number = 0;
  searchTerm: string = '';

  constructor(
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private snackBarService: SnackBarService,
    private activeTabService: ActiveTabService,
    private applicationService: ApplicationExtService,
  ) {
    this.sub.add(
      this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
        this.currentOrganizationId = currentOrganizationId;
      }),
    );

    this.getReturnedApplicationCount();
  }

  ngOnInit(): void {
    this._loadReturnedApplicationsList();
    this.sub.add(
      this.activatedRoute.url.subscribe((segments) => {
        this.activeTabService.setActiveTab(segments[0].toString());
      }),
    );
    this.sub.add(
      this.store.select(ApplicationsState.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
    this.sub.add(
      this.activeTabService
        .getActiveTabSearch()
        .pipe(
          delay(500),
          filter((search) => search !== undefined),
        )
        .subscribe((search) => {
          if (search !== undefined) {
            this.searchTerm = search;
            this.searchTableData();
          }
        }),
    );
  }

  public configQuery(organizationId: number) {
    const body: ApplicationListFilter = {
      organizationId: { eq: organizationId },
      statuses: [ApplicationStatusType.Returned],
    };
    if (this.searchTerm) {
      body.SEARCH = this.searchTerm?.toLocaleLowerCase();
    }

    const query = {
      take: this.perPageCount,
      skip: (this.currentPage - 1) * this.perPageCount,
      body: body,
      organizationId: organizationId,
    };
    return query;
  }

  public getReturnedApplicationCount() {
    this.applicationService.countByOrganization(this.configQuery(this.currentOrganizationId)).subscribe((count) => {
      this.timeoutIds.push(
        setTimeout(() => {
          this.returnedApplicationsCount = count;
        }),
      );
    });
  }

  public searchTableData(): void {
    this.isLoading = true;
    const primaryOutcomes = this.store.selectSnapshot(LookupValueState.getPrimaryOutcomes);
    const functionalCategoryTypes = this.store.selectSnapshot(LookupValueState.getFunctionalCategoryTypes);
    const allCapitalAssetTypes = this.store.selectSnapshot(LookupValueState.getAllCapitalAssetTypes);

    this.applicationService.findAllReturned(this.configQuery(this.currentOrganizationId)).subscribe({
      next: (value) => {
        this.isLoading = false;
        this.filteredApplications = this._getReturnedApplicationsVm(
          value,
          primaryOutcomes,
          functionalCategoryTypes,
          allCapitalAssetTypes,
        );

        this.pageApplications = this.filteredApplications; //.slice(0, this.perPage);
        if (this.searchTerm) {
          this.returnedApplicationsCount = this.filteredApplications?.length;
          this.currentPage = 1;
          this.perPageCount = 25;
        } else {
          this.getReturnedApplicationCount();
        }
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  private _loadReturnedApplicationsList() {
    this.store.dispatch(LoadPrimaryOutcomes);
    this.store.dispatch(LoadFunctionalCategoryTypes);
    this.store.dispatch(LoadAllCapitalAssetTypes);

    this.sub.add(
      combineLatest([this.primaryOutcomes$, this.functionalCategoryTypes$, this.allCapitalAssetTypes$])
        .pipe(takeWhile(() => this.isAlive))
        .subscribe(([primaryOutcomes, functionalCategoryTypes, allCapitalAssetTypes]) => {
          this.applicationService.findAllReturned(this.configQuery(this.currentOrganizationId)).subscribe({
            next: (applications) => {
              this.applications = this._getReturnedApplicationsVm(
                applications,
                primaryOutcomes,
                functionalCategoryTypes,
                allCapitalAssetTypes,
              );
              this.filteredApplications = this.applications;
              this.pageApplications = this.filteredApplications; //.slice(0, this.perPage);
            },
          });
        }),
    );
  }

  private _getReturnedApplicationsVm(
    fetchReturnedApplications: ApplicationReturnedDto[],
    primaryOutcomes: LookupValue[],
    functionalCategoryTypes: LookupValue[],
    allCapitalAssetTypes: CapitalAssetTypeLV[],
  ): ReturnedApplicationVm[] {
    const returnedApplications: ReturnedApplicationVm[] = fetchReturnedApplications
      .map((_) => ({
        draftId: _.draft_id!,
        name: _.workingDraftRecord.name,
        number: _.idTxt,
        primaryFunctionalCategory: functionalCategoryTypes.find(
          (x) =>
            x.id ==
            _.workingDraftRecord.functionalCategories.find((y) => y.isPrimary ?? false)?.functionalCategoryTypeId,
        )?.title,
        primaryOutcome: primaryOutcomes.find((x) => x.id == _.workingDraftRecord.primaryOutcomeId)?.title,
        returnedDate: CommUtilsService.getDateStrMonDdYear(_.returnedAt!),
        projects: _.workingDraftRecord.projects.map((p) => ({
          name: p.name,
          primaryCapitalAsset: allCapitalAssetTypes.find((x) => x.id == p.capitalAssetTypeId)?.title ?? '',
          additionalCapitalAsset: p.additionalCapitalAssetTypeIds
            ?.map((x) => {
              const match = allCapitalAssetTypes.find((y) => y.id === x);
              return match ? match.title.replace(/\([^)]*\)/g, '').trim() : 'invalid id';
            })
            .join(', '),
          lgffFundingRequested: p.amountRequestedFromLGFF!,
          anticipatedStart: CommUtilsService.getDateStrMonDdYear(p.anticipatedStartDate!),
        })),
        showProjects: false,
      }))
      .sort((a, b) => new Date(a.returnedDate).getTime() - new Date(b.returnedDate).getTime());
    return returnedApplications;
  }

  toggleProjectList(applicationId: number) {
    this.pageApplications = this.pageApplications.map((_) => {
      if (_.draftId === applicationId) return { ..._, showProjects: !_.showProjects };
      return _;
    });
  }

  handleSort(event: Event) {
    if (!(event instanceof CustomEvent)) return;
    const { sortBy, sortDir } = event.detail;
    if (sortBy === 'acceptedDate') {
      this.filteredApplications.sort(
        (a: ReturnedApplicationVm, b: ReturnedApplicationVm) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (new Date(a[sortBy]) > new Date(b[sortBy]) ? 1 : -1) * sortDir,
      );
    } else {
      this.filteredApplications.sort(
        (a: ReturnedApplicationVm, b: ReturnedApplicationVm) =>
          // @ts-expect-error @typescript-eslint/ban-ts-comment
          (a[sortBy].toLowerCase() > b[sortBy].toLowerCase() ? 1 : -1) * sortDir,
      );
    }

    //const offset = (this.page - 1) * this.perPage;
    this.pageApplications = this.filteredApplications; //.slice(offset, offset + this.perPage);
  }

  handlePageChange(event: Event) {
    const e = event as CustomEvent;
    this.currentPage = e.detail.page;
    this.searchTableData();
    this.getReturnedApplicationCount();
  }

  handlePerPageChange(event: Event) {
    const e = event as CustomEvent;
    this.perPageCount = parseInt(e.detail.value, 10);
    this.searchTableData();
    this.getReturnedApplicationCount();
  }

  onEditClick(applicationId: number) {
    this.store.dispatch(new ClearApplicationDraftContext());
    const state: ApplicationUrlLocationHistoryState = { applicationDraftId: applicationId };
    this.router.navigate(['grant-application'], { state });
  }

  onDeleteClick(applicationId: number) {
    if (!this.openWithdrawModal) {
      this.appIdToWithdraw = applicationId;
      this.appNameToWithdraw = this.filteredApplications.find((app) => app.draftId == applicationId)?.name ?? '';
      this.openWithdrawModal = true;
    }
  }
  noWithdrawHandler() {
    this.openWithdrawModal = false;
  }

  yesWithdrawHandler() {
    if (!this.openWithdrawModal) return;
    this.openWithdrawModal = false;

    const successConfirm = "Your '" + this.appNameToWithdraw + "' application was successfully withdrawn.";

    this.store.dispatch(new RemoveReturnedApplicationFromStateByDraftId(this.appIdToWithdraw!));

    this.sub.add(
      this.store
        .dispatch(new DeleteApplicationDraft(this.appIdToWithdraw!))
        .pipe(take(1))
        .subscribe({
          next: () => {
            this.snackBarService.showSuccessMessage(successConfirm);
            this.router.navigate(['/view-applications', ViewApplicationTab.Returned]);
            this.timeoutIds.push(
              setTimeout(() => {
                window.location.reload();
              }, 1500),
            );
          },
          error: (error) => {
            console.log('DeleteApplicationDraft in backend failed');
            console.log(error);
          },
        }),
    );
  }

  onAppInfoClick(applicationId: number) {
    const organizationIdSelected = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    const url =
      '/application-summary/' + ApplicationStatus.Returned + '/' + organizationIdSelected + '/' + applicationId;
    const width = Math.min(1024, window.screen.availWidth - 150);
    const height = Math.min(1439, window.screen.availHeight - 100);
    window.open(
      url,
      '_blank',
      `toolbar=yes, scrollbars=yes, resizable=yes,
       width=${width}, height=${height}`,
    );
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }
}
