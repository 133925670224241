<div id="app-summary">
  <div id="app-summary-alberta-logo">
    <div style="padding: 51px 0px 24px; display: flex">
      <div class="align-logo">
        <a target="_blank" href="https://www.alberta.ca/">
          <app-svg-alberta-logo></app-svg-alberta-logo>
        </a>
      </div>
      <div>
        <label id="logo-label">LGFF Capital</label>
      </div>
    </div>
    <goa-divider></goa-divider>
  </div>
  <h2 id="app-summary-heading-h2">{{ 'applicationSummaryHeading' | res: pageId }}</h2>
  <h3 id="app-summary-user-organization-info-heading-h3">{{ organizationTitle + ' - ' + organizationLgCode }}</h3>
  <div id="application-summary-h3-div"><h3 id="application-summary-h3">Application details</h3></div>
  <div id="application-summary-div">
    <table id="application-summary-table">
      <tr>
        <td id="col-1">Application name:</td>
        <td id="col-2">{{ applicationSummary.applicationName }}</td>
      </tr>
      <tr>
        <td id="col-1">Application no.:</td>
        <td id="col-2">{{ applicationSummary.applicationID }}</td>
      </tr>
      <tr>
        <td id="col-1">{{ applicationStatus == 'returned' ? 'Returned date' : 'Created date:' }}</td>
        <td id="col-2">{{ CommUtilsService.getDateStrMonDdYear(applicationSummary.createdDate ?? '') }}</td>
      </tr>
      <tr>
        <td id="col-1">Submitted date:</td>
        <td id="col-2" *ngIf="applicationSummary.submittedDate != ''">
          {{ CommUtilsService.getDateStrMonDdYear(applicationSummary.submittedDate ?? '') }}
        </td>
        <td id="col-2" *ngIf="applicationSummary.submittedDate === ''">N/A</td>
      </tr>
      <tr *ngIf="applicationSummary.acceptedDate != ''">
        <td id="col-1">Accepted date:</td>
        <td id="col-2" *ngIf="applicationSummary.acceptedDate != ''">
          {{ CommUtilsService.getDateStrMonDdYear(applicationSummary.acceptedDate ?? '') }}
        </td>
      </tr>
      <tr *ngIf="applicationSummary.withdrawnAt != ''">
        <td id="col-1">Withdrawn date:</td>
        <td id="col-2" *ngIf="applicationSummary.withdrawnAt != ''">
          {{ CommUtilsService.getDateStrMonDdYear(applicationSummary.withdrawnAt ?? '') }}
        </td>
      </tr>
    </table>
  </div>

  <ng-container *ngIf="applicationSummary.primaryOutcomeTitle != ''">
    <div id="application-summary-h3-div">
      <h3 id="application-summary-h3">Primary outcome & functional categories</h3>
    </div>
    <div id="application-summary-h4-div"><h4 id="application-summary-h4">Primary outcome</h4></div>
    <ul>
      <li>{{ applicationSummary.primaryOutcomeTitle }}</li>
    </ul>
    <ng-container *ngIf="applicationSummary.primaryCategory != ''">
      <div id="application-summary-h4-div"><h4 id="application-summary-h4">Primary functional category</h4></div>
      <ul>
        <li>{{ applicationSummary.primaryCategory }}</li>
      </ul>
    </ng-container>

    <ng-container *ngIf="applicationSummary.functionalCategories && applicationSummary.functionalCategories.length > 1">
      <div id="application-summary-h4-div"><h4 id="application-summary-h4">Additional functional categories</h4></div>
      <ul>
        <ng-container *ngFor="let category of applicationSummary.functionalCategories">
          <ng-container *ngIf="category.isPrimary == false">
            <li>{{ category.title }}</li>
          </ng-container>
        </ng-container>
      </ul>
    </ng-container>

    <ng-container
      *ngIf="applicationSummary.applicationCapitalAssets && applicationSummary.applicationCapitalAssets.length > 0"
    >
      <div id="application-summary-h3-div"><h3 id="application-summary-h3">Primary capital assets</h3></div>
      <ul>
        <ng-container *ngFor="let asset of applicationSummary.applicationCapitalAssets">
          <li>{{ asset.title }}</li>
        </ng-container>
      </ul>
    </ng-container>
  </ng-container>
  <div id="application-summary-h3-div"><h3 id="application-summary-h3">Financial details</h3></div>
  <div id="financial-summary-div">
    <table id="financial-summary-table">
      <tr id="financial-summary-table-header-row">
        <th id="ft-col-1">&nbsp;</th>
        <th id="ft-col-2">Requested</th>
        <th id="ft-col-2">Accepted</th>
        <th id="ft-col-2">Expended to date</th>
      </tr>
      <tr id="financial-summary-table-total-cost-row">
        <td id="ft-col-1">Total project costs:</td>
        <td id="ft-col-2">{{ applicationSummary.totalProjectCost | safeDollar }}</td>
        <td id="ft-col-2" *ngIf="applicationSummary.acceptedDate === ''">N/A</td>
        <td id="ft-col-2" *ngIf="applicationSummary.acceptedDate != ''">
          {{ applicationSummary.totalProjectCost | safeDollar }}
        </td>
        <td id="ft-col-2">N/A</td>
      </tr>
      <tr id="financial-summary-table-total-funding-row">
        <td id="ft-col-1">Total LGFF funding:</td>
        <td id="ft-col-2">{{ applicationSummary.totalFundingAmountRequested | safeDollar }}</td>
        <td id="ft-col-2" *ngIf="applicationSummary.acceptedDate === ''">N/A</td>
        <td id="ft-col-2" *ngIf="applicationSummary.acceptedDate != ''">
          {{ applicationSummary.totalFundingAmountRequested | safeDollar }}
        </td>
        <td id="ft-col-2">N/A</td>
      </tr>
    </table>
  </div>
  <div style="height: 149px"></div>
  <goa-circular-progress variant="fullscreen" visible="{{ showProgress }}"></goa-circular-progress>
</div>
