<div class="form">
  <h2 id="gen-info-heading-1">{{ 'contactSectionHeading1' | res: pageId }}</h2>

  <div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
    <goa-callout type="emergency" heading="{{ 'errorCalloutHeading' | res: pageId }}" *ngIf="showErrorFieldsCallout">
      <ng-container *ngFor="let uiDef of pageUiDefs">
        <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link"
          >{{ uiDef.labelText }}<br
        /></a>
      </ng-container>
    </goa-callout>
  </div>

  <h3 id="general-application-contact-info-h3">{{ 'contactSectionHeading2' | res: pageId }}</h3>

  <form [formGroup]="contactForm">
    <div class="general-section">
      <div *ngIf="UiDefFirstName; let UiDef" class="wd-500px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            autocomplete="off"
            type="text"
            width="31.25rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
      <goa-spacer vSpacing="xl"></goa-spacer>

      <div *ngIf="UiDefLastName; let UiDef" class="wd-500px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            type="text"
            width="31.25rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
      <goa-spacer vSpacing="xl"></goa-spacer>

      <div *ngIf="UiDefPhone; let UiDef" class="wd-500px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            type="tel"
            width="251px"
            height="42px"
            appInputFormat
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (keypress)="acceptPhoneNumberOnly($event)"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
            <div slot="leadingContent">
              <span class="leading-content-text">+1</span>
            </div>
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
      <goa-spacer vSpacing="xl"></goa-spacer>

      <div *ngIf="UiDefEmail; let UiDef" class="wd-500px pb-12px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            type="email"
            width="31.25rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
    </div>
    <div class="ht-120px"></div>
  </form>
</div>
