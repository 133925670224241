<div class="resources-page">
  <h2>{{ 'resourcesHeading' | res: pageId }}</h2>
  <goa-block gap="m">
    <div>
      <iframe
        width="480"
        height="270"
        src="https://www.youtube.com/embed/-kMozEMPlHY?si=XAsrxCZtA0tSfGm8"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <div class="video-resource-title">LGFF Capital application submission process video</div>
      <div class="video-resource-date">March 5, 2024</div>
    </div>
    <div>
      <iframe
        width="480"
        height="270"
        src="https://www.youtube.com/embed/afo2YGvynU8?si=FXGQw_etEDuVT5FR"
        title="YouTube video player"
        frameborder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
        referrerpolicy="strict-origin-when-cross-origin"
        allowfullscreen
      ></iframe>
      <div class="video-resource-title">LGFF Capital online mapping video</div>
      <div class="video-resource-date">March 6, 2024</div>
    </div>
  </goa-block>
  <div style="height: 2rem"></div>
  <div class="guide-link-column">
    <a href="{{ 'lgffWebsiteLink' | res: pageId }}" target="_blank"
      >{{ 'lgffWebsite' | res: pageId }}&nbsp;<goa-icon type="open"></goa-icon
    ></a>
  </div>
  <div class="guide-link-column">
    <a href="{{ 'lgffGuidelinesLink' | res: pageId }}" target="_blank"
      >{{ 'lgffGuidelines' | res: pageId }}&nbsp;<goa-icon type="open"></goa-icon
    ></a>
  </div>
  <div class="guide-link-column">
    <a href="{{ 'msiOperatingLink' | res: pageId }}" target="_blank"
      >{{ 'msiOperating' | res: pageId }}&nbsp;<goa-icon type="open"></goa-icon
    ></a>
  </div>
  <div class="more-info-column">
    <div id="more-info-line1">{{ 'moreInformationText' | res: pageId }}</div>
    <div id="more-info-line2">
      For information about your Alberta.ca Account for Organizations and the Operating portal, please see the
      <a href="{{ 'portalUserGuideLink' | res: pageId }}" target="_blank">Operating Portal User Guide</a> and
      <a href="{{ 'organizationSignUpGuideLink' | res: pageId }}" target="_blank"
        >Alberta.ca Account for Organizations Sign Up Guide</a
      >.
    </div>
  </div>
</div>
<div style="height: 366px"></div>
