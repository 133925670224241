<div>
  <div id="total-lgff-funding-available">
    {{ 'totalLgffFundingAvailable' | res: pageId }}:
    <span>{{ totalLgffFundingAvailable | currency: '$' : 'symbol' : '1.0-0' }}</span>
  </div>

  <app-lgff-goa-nested-rows-table
    [headers]="headers"
    [showAdditionalHeader]="true"
    [additionalHeaders]="additionalHeaders"
    [tableData]="applications"
    childKey="projects"
    tableWidth="136.1875rem"
    [parentColumnIndent]="parentColumnIndent"
    [cellTemplates]="{
      edit: editTemplate
    }"
  ></app-lgff-goa-nested-rows-table>

  <ng-template #editTemplate let-name>
    <goa-icon size="small" class="action-icon" type="pencil"></goa-icon>
  </ng-template>
</div>
