<goa-modal
  id="modal"
  [open]="isOpen"
  maxwidth="550px"
  heading="Cancel and delete cash flow update"
  [appPreventModalFlicker]="isOpen"
>
  <p>Are you sure you want to cancel and delete this cash flow update? This can't be undone.</p>
  <div slot="actions">
    <goa-button-group alignment="end">
      <goa-button (_click)="closeModal(false)" type="secondary">No, go back to the update</goa-button>
      <goa-button (_click)="closeModal(true)">Yes, cancel and delete</goa-button>
    </goa-button-group>
  </div>
</goa-modal>
