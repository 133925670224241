/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { EventDto } from '../models/event-dto';
import { NotificationTemplateDto } from '../models/notification-template-dto';
import { SubscriberDto } from '../models/subscriber-dto';
import { SubscriptionDto } from '../models/subscription-dto';

@Injectable({ providedIn: 'root' })
export class EventService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `sendEvent()` */
  static readonly SendEventPath = '/api/event/events';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendEvent()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEvent$Response(
    params: {
      body: EventDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EventService.SendEventPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendEvent$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  sendEvent(
    params: {
      body: EventDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.sendEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `configureEvent()` */
  static readonly ConfigureEventPath = '/api/event';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configureEvent()` instead.
   *
   * This method doesn't expect any request body.
   */
  configureEvent$Response(
    params?: {
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EventService.ConfigureEventPath, 'patch');
    if (params) {
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `configureEvent$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  configureEvent(
    params?: {
    },
    context?: HttpContext
  ): Observable<void> {
    return this.configureEvent$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `subscribeToNotificationType()` */
  static readonly SubscribeToNotificationTypePath = '/api/event/subscriptions';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `subscribeToNotificationType()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscribeToNotificationType$Response(
    params: {
      body: SubscriptionDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EventService.SubscribeToNotificationTypePath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `subscribeToNotificationType$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  subscribeToNotificationType(
    params: {
      body: SubscriptionDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.subscribeToNotificationType$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `addSubscriber()` */
  static readonly AddSubscriberPath = '/api/event/subscribers';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addSubscriber()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSubscriber$Response(
    params: {
      body: SubscriberDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EventService.AddSubscriberPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addSubscriber$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addSubscriber(
    params: {
      body: SubscriberDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.addSubscriber$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

  /** Path part for operation `configureNotifications()` */
  static readonly ConfigureNotificationsPath = '/api/event/configure-notifications';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `configureNotifications()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  configureNotifications$Response(
    params: {
      body: NotificationTemplateDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<void>> {
    const rb = new RequestBuilder(this.rootUrl, EventService.ConfigureNotificationsPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'text', accept: '*/*', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: undefined }) as StrictHttpResponse<void>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `configureNotifications$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  configureNotifications(
    params: {
      body: NotificationTemplateDto
    },
    context?: HttpContext
  ): Observable<void> {
    return this.configureNotifications$Response(params, context).pipe(
      map((r: StrictHttpResponse<void>): void => r.body)
    );
  }

}
