import { CashFlowProjectUpdateDto } from '@app-com/api/models';

export interface ViewCashFlowUpdateVM {
  id: number;
  idTxt: string;
  applicationId: number;
  applicationIdTxt: string;
  applicationName: string;
  previousAcceptedFunding: number;
  updatedTotalFunding: number;
  previousTotalLgffFundingAcceptedTxt: string;
  updatedTotalLgffFundingAcceptedTxt: string;
  detailsShown?: boolean;
  projects?: CashFlowProjectUpdateDto[];
  status?: string;
  statusTitle?: string;
  statusSortSequence?: number;
}
export enum CashFlowUpdateStatusSequence {
  Draft = 1,
  Returned = 2,
  Submitted = 3,
  InReview = 4,
  Accepted = 5,
  Withdrawn = 6,
}
