<div class="application-list-container">
  <goa-block class="loading-container" alignment="center">
    <goa-circular-progress variant="inline" size="large" [visible]="isLoading"></goa-circular-progress>
  </goa-block>
  <goa-table id="table-container" width="100%" (_sort)="handleSort($event)">
    <thead>
      <tr>
        <th class="detail-icon-column"></th>
        <th class="minwidth-10rem">
          <goa-table-sort-header name="name">{{ 'name' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-10rem">
          <goa-table-sort-header name="number">{{ 'number' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-15rem">
          <goa-table-sort-header name="primaryFunctionalCategory">{{
            'primaryFunctionalCategory' | res: pageId
          }}</goa-table-sort-header>
        </th>
        <th class="minwidth-15rem">
          <goa-table-sort-header name="primaryOutcome">{{ 'primaryOutcome' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-10rem">
          <goa-table-sort-header name="returnedDate">{{ 'returnedDate' | res: pageId }}</goa-table-sort-header>
        </th>
        <th class="minwidth-10rem">{{ 'actions' | res: pageId }}</th>
      </tr>
    </thead>
    <tbody>
      <tr *ngIf="!pageApplications || pageApplications.length === 0">
        <td></td>
        <td colspan="6">
          <div class="font-italic pl-1rem">No results found in Returned</div>
        </td>
      </tr>
      <ng-container *ngFor="let application of pageApplications">
        <tr>
          <td>
            <goa-icon
              class="action-icon"
              [type]="application.showProjects ? 'chevron-down' : 'chevron-forward'"
              (click)="toggleProjectList(application.draftId)"
            ></goa-icon>
          </td>
          <td>{{ application.name }}</td>
          <td>{{ application.number }}</td>
          <td>{{ application.primaryFunctionalCategory }}</td>
          <td>{{ application.primaryOutcome }}</td>
          <td>{{ application.returnedDate }}</td>
          <td>
            <goa-block alignment="center" gap="l">
              <goa-tooltip content="Edit" position="bottom">
                <goa-icon class="action-icon" type="pencil" (click)="onEditClick(application.draftId)"></goa-icon
              ></goa-tooltip>
              <goa-tooltip content="Summary" position="bottom">
                <goa-icon
                  class="action-icon"
                  type="information-circle"
                  (click)="onAppInfoClick(application.draftId)"
                ></goa-icon
              ></goa-tooltip>
              <div class="action-download-tooltip-container">
                <goa-tooltip content="PDF" position="bottom">
                  <app-download-returned-application-pdf
                    class="action-icon"
                    [applicationId]="application.draftId"
                  ></app-download-returned-application-pdf
                ></goa-tooltip>
              </div>
              <goa-tooltip content="Withdraw" position="bottom">
                <goa-icon
                  class="action-icon"
                  type="return-down-back"
                  (click)="onDeleteClick(application.draftId)"
                ></goa-icon
              ></goa-tooltip>
            </goa-block>
          </td>
        </tr>
        <tr *ngIf="application.showProjects" class="projects-container">
          <td></td>
          <td colspan="6">
            <ng-container *ngIf="application.projects.length === 0; else projectList">
              <div>No Projects found for expanded row.</div>
            </ng-container>
            <ng-template #projectList>
              <table>
                <thead>
                  <tr>
                    <th>Project name</th>
                    <th>Primary capital asset</th>
                    <th>Additional capital asset</th>
                    <th class="numeric-column">LGFF funding requested</th>
                    <th>Anticipated start</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let project of application.projects">
                    <td>{{ project.name }}</td>
                    <td>{{ project.primaryCapitalAsset }}</td>
                    <td>{{ project.additionalCapitalAsset }}</td>
                    <td class="numeric-column">{{ project.lgffFundingRequested | safeDollar }}</td>
                    <td>{{ project.anticipatedStart }}</td>
                  </tr>
                </tbody>
              </table>
            </ng-template>
          </td>
        </tr>
      </ng-container>
    </tbody>
  </goa-table>
  <div style="height: 1rem"></div>
  <goa-block alignment="center" style="font-size: 18px">
    <goa-block mb="m" alignment="center">
      <span>Show</span>
      <goa-dropdown (_change)="handlePerPageChange($event)" [value]="perPageCount" width="8.5ch" relative="true">
        <goa-dropdown-item *ngFor="let size of pageSizeOptions" value="{{ size }}"></goa-dropdown-item>
      </goa-dropdown>
      <span style="width: 100px">
        of
        {{ returnedApplicationsCount }} items</span
      >
    </goa-block>
    <goa-spacer hspacing="fill"></goa-spacer>
    <app-lgff-goa-table-pagination
      [totalItemCount]="returnedApplicationsCount"
      [perPageCount]="perPageCount"
      [pageNumber]="currentPage"
      (pageChanged)="handlePageChange($event)"
    >
    </app-lgff-goa-table-pagination>
  </goa-block>
</div>

<app-confirm-cancel-delete-draft-app
  pageId="MODEL_CANCEL_WITHDRAW_RETURNED_IN_LIST"
  [titleParam]="appNameToWithdraw"
  [showBothOnRight]="true"
  [isOpen]="openWithdrawModal"
  (afterNoHandler)="noWithdrawHandler()"
  (afterYesHandler)="yesWithdrawHandler()"
>
</app-confirm-cancel-delete-draft-app>
