import { Injectable } from '@angular/core';
import { ApplicationExtService } from '@app-com/api/services';
import { CashFlowFormSequence } from '@app-pot/features/cash-flow-updates/enum/cash-flow-form-sequence';
import { AutosaveStatus, FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { Action, Selector, State, StateContext, Store } from '@ngxs/store';
import { patch } from '@ngxs/store/operators';
import {
  AutosaveCashFlowUpdateContact,
  SetCashFlowCta,
  SetApplicationDetails,
  SetCashFlowUpdateContact,
  SetCashFlowUpdateStep,
  SetCashFlowUpdateStepperStatus,
  SetCashFlowValidationResult,
  CancelAndDeleteCashFlowUpdate,
  SetCashFlowApplicationFunding,
  ResetApiCallStatus,
  AutosaveCashFlowUpdateApplicationFunding,
  SetCashFlowSubmit,
  ResetCashFlowValidationResult,
  ResetCashFlowUpdate,
  FetchCashFlowUpdateApplication,
  SetCashFlowProjectUpdate,
  DeleteCashFlowUpdate,
  ToggleAreThereOtherSourcesOfFunding,
  CashFlowProjectUpdatesErrorCallout,
} from '../actions/cash-flow-update.action';
import { ApplicationExtDto, CashFlowUpdateExtDto } from '@app-com/api/models';
import { AutoSaveState } from './application-draft.state';
import { CurrentContextState } from './current-context.state';
import { SetCurrentCashFlowUpdateId } from '../actions/current-context.action';
import { tap } from 'rxjs';

export class CashFlowUpdateStateModel {
  cashFlowUpdateStepperStatus: {
    [CashFlowFormSequence.ContactInfo]: FormStatusCodes;
    [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes;
    [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes;
    [CashFlowFormSequence.Review]: FormStatusCodes;
    [CashFlowFormSequence.Certification]: FormStatusCodes;
  };
  toggleAreThereOtherSourcesOfFunding: boolean | undefined;
  errorCallOut: boolean | undefined;
  cashFlowStep: CashFlowFormSequence;
  cashFlow: Partial<CashFlowUpdateExtDto>;
  autosaveStatus: AutoSaveState;
  isValid: boolean | undefined;
  cta: ['cancel' | 'confirm' | 'save' | 'previous' | 'submit' | 'reset'] | undefined;
  applicationDetails: ApplicationExtDto;
  apiCallSucceded: {
    cancelAndDelete: boolean | undefined;
  };
  runSubmit: boolean | undefined;
  submitStatus: AutosaveStatus;
}

@State<CashFlowUpdateStateModel>({
  name: 'cashflowupdates',
  defaults: {
    cashFlowUpdateStepperStatus: {
      [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
      [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
      [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
      [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
      [CashFlowFormSequence.Certification]: FormStatusCodes.NotStarted,
    },
    errorCallOut: undefined,
    toggleAreThereOtherSourcesOfFunding: undefined,
    cashFlowStep: CashFlowFormSequence.ContactInfo,
    cta: undefined,
    isValid: undefined,
    cashFlow: {},
    autosaveStatus: { callsInProgress: 0, status: AutosaveStatus.idle },
    applicationDetails: {} as ApplicationExtDto,
    apiCallSucceded: {
      cancelAndDelete: undefined,
    },
    runSubmit: false,
    submitStatus: AutosaveStatus.idle,
  },
})
@Injectable()
export class CashFlowUpdateState {
  currentOrganizationId: number;
  currentCashFlowUpdateId: number | undefined;
  constructor(
    private applicationService: ApplicationExtService,
    private store: Store,
  ) {
    this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
      this.currentOrganizationId = currentOrganizationId;
    });

    this.store.select(CurrentContextState.getCurrentCashFlowUpdateId).subscribe((currentCashFlowUpdateId) => {
      this.currentCashFlowUpdateId = currentCashFlowUpdateId;
    });
  }

  @Selector()
  static getCashFlowUpdateStepperStatus({ cashFlowUpdateStepperStatus }: CashFlowUpdateStateModel) {
    return { ...cashFlowUpdateStepperStatus };
  }

  @Selector()
  static getCashFlowUpdateStep({ cashFlowStep }: CashFlowUpdateStateModel) {
    return cashFlowStep;
  }

  @Selector()
  static getCashFlowUpdateCta({ cta }: CashFlowUpdateStateModel) {
    return cta;
  }

  @Selector()
  static getCashFlowUpdateValidationResult({ isValid }: CashFlowUpdateStateModel) {
    return isValid;
  }

  @Selector()
  static getCashFlowUpdate({ cashFlow }: CashFlowUpdateStateModel) {
    return cashFlow;
  }

  @Selector()
  static getCashFlowUpdateAppDetails({ cashFlow }: CashFlowUpdateStateModel) {
    return cashFlow.applicationUpdatesRecord;
  }

  @Selector()
  static getCashFlowUpdateProjectUpdates({ cashFlow }: CashFlowUpdateStateModel) {
    return cashFlow.applicationUpdatesRecord?.projectUpdates;
  }

  // @Selector([CashFlowUpdateState.getCashFlowUpdateAppDetails])
  // static getCashFlowUpdateEditProject(cashFlowApplication: CashFlowApplicationUpdatesRecord) {
  //   return (projectId: number) => cashFlowApplication.projectUpdates?.find((pr) => pr.projectId == projectId);
  // }

  @Selector()
  static getCashFlowApplicationReasonForFundingUpdate(state: CashFlowUpdateStateModel) {
    return state.cashFlow?.applicationUpdatesRecord?.reasonForFundingUpdate;
  }

  @Selector()
  static getApplicationDetails({ applicationDetails }: CashFlowUpdateStateModel) {
    return applicationDetails;
  }

  @Selector()
  static getCashFlowUpdateCancelAndDeleteApiCallStatus({ apiCallSucceded }: CashFlowUpdateStateModel) {
    return apiCallSucceded.cancelAndDelete;
  }

  @Selector()
  static getAutosaveStatus(state: CashFlowUpdateStateModel) {
    return state.autosaveStatus.status;
  }

  @Selector()
  static getSubmitStatus(state: CashFlowUpdateStateModel) {
    return state.submitStatus;
  }

  @Selector()
  static getToggleAreThereOtherSourcesOfFunding(state: CashFlowUpdateStateModel) {
    return state.toggleAreThereOtherSourcesOfFunding;
  }

  @Selector()
  static getErrorCallout(state: CashFlowUpdateStateModel) {
    return state.errorCallOut;
  }

  @Action(FetchCashFlowUpdateApplication)
  fetchCashFlowUpdateApplication({ setState }: StateContext<CashFlowUpdateStateModel>) {
    if (!this.currentCashFlowUpdateId || !this.currentOrganizationId) {
      return;
    }
    this.applicationService
      .findOneCashFlowUpdate({
        organizationId: this.currentOrganizationId,
        id: this.currentCashFlowUpdateId,
      })

      .subscribe({
        next: (cashFlowDetails: CashFlowUpdateExtDto) => {
          setState(
            patch({
              cashFlow: { ...cashFlowDetails },
            }),
          );
          // console.log('current store state', getState());
        },
      });
  }

  @Action(SetApplicationDetails)
  setApplicationDetails(
    { getState, setState }: StateContext<CashFlowUpdateStateModel>,
    { applicationDetails }: SetApplicationDetails,
  ) {
    console.log('Cash Flow Application details [State]', applicationDetails);
    setState(
      patch({
        applicationDetails: {
          ...getState().applicationDetails,
          ...applicationDetails,
        },
      }),
    );
  }

  @Action(SetCashFlowUpdateStepperStatus)
  setFormStepperStatus(
    { getState, setState }: StateContext<CashFlowUpdateStateModel>,
    { cashFlowUpdateStepperStatus }: SetCashFlowUpdateStepperStatus,
  ) {
    console.log('Cash Flow Form Stepper Status [State]', cashFlowUpdateStepperStatus);
    setState(
      patch({
        cashFlowUpdateStepperStatus: {
          ...getState().cashFlowUpdateStepperStatus,
          ...cashFlowUpdateStepperStatus,
        },
      }),
    );
  }

  @Action(SetCashFlowUpdateStep)
  setFormStep({ getState, setState }: StateContext<CashFlowUpdateStateModel>, { cashFlowStep }: SetCashFlowUpdateStep) {
    console.log('Cash Flow Step [State]', cashFlowStep);
    setState(patch({ cashFlowStep: cashFlowStep }));
    console.log('Current State after change in Cash Flow Form Step', getState());
  }

  @Action(SetCashFlowCta)
  setCashFlowCta({ setState }: StateContext<CashFlowUpdateStateModel>, { cta }: SetCashFlowCta) {
    const newCta: ['cancel' | 'confirm' | 'save' | 'previous' | 'submit' | 'reset'] = [cta ? cta[0] : 'confirm'];
    setState(patch({ cta: [...newCta] }));
  }

  @Action(SetCashFlowValidationResult)
  setCashFlowValidationResult(
    { setState }: StateContext<CashFlowUpdateStateModel>,
    { isValid }: SetCashFlowValidationResult,
  ) {
    setState(patch({ isValid: isValid }));
  }

  @Action(SetCashFlowSubmit)
  setCashFlowSubmit(ctx: StateContext<CashFlowUpdateStateModel>, { runSubmit }: SetCashFlowSubmit) {
    console.log(
      'file: cash-flow-update.state.ts:177 ~ CashFlowUpdateState ~ setCashFlowSubmit ~ runSubmit:',
      runSubmit,
    );
    ctx.setState(patch({ runSubmit: runSubmit }));
    this._submitCashFlow(ctx);
  }

  @Action(ResetCashFlowValidationResult)
  resetCashFlowValidationResult({ setState }: StateContext<CashFlowUpdateStateModel>) {
    setState(patch({ isValid: undefined }));
  }

  @Action(SetCashFlowUpdateContact)
  setCashFlowUpdateContact(ctx: StateContext<CashFlowUpdateStateModel>, { payload }: SetCashFlowUpdateContact) {
    console.log('Cash Flow Update Contact [Action]', payload);
    ctx.setState(patch({ cashFlow: { ...ctx.getState().cashFlow, ...payload } }));
    this._autosave(ctx, false);
    console.log('Current State after change in Cash Flow Form Contact', ctx.getState());
  }

  @Action(ResetCashFlowUpdate)
  resetCashFlowUpdate(ctx: StateContext<CashFlowUpdateStateModel>) {
    ctx.setState(
      patch({
        cashFlow: {},
        cashFlowUpdateStepperStatus: {
          [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
          [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
          [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
          [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
          [CashFlowFormSequence.Certification]: FormStatusCodes.NotStarted,
        },
        cashFlowStep: CashFlowFormSequence.ContactInfo,
        toggleAreThereOtherSourcesOfFunding: undefined,
        errorCallOut: undefined,
      }),
    );
  }

  @Action(AutosaveCashFlowUpdateContact)
  autosaveCashFlowUpdateContact(
    ctx: StateContext<CashFlowUpdateStateModel>,
    { payload }: AutosaveCashFlowUpdateContact,
  ) {
    //console.log('AutosaveCashFlowUpdateContact[Action]', payload);
    ctx.setState(patch({ cashFlow: { ...ctx.getState().cashFlow, ...payload } }));
    //console.log('Current State after AutosaveCashFlowUpdateContact[Action]', ctx.getState());
    this._autosave(ctx);
  }

  @Action(AutosaveCashFlowUpdateApplicationFunding)
  autosaveCashFlowUpdateApplicationFunding(
    ctx: StateContext<CashFlowUpdateStateModel>,
    { payload }: AutosaveCashFlowUpdateApplicationFunding,
  ) {
    ctx.setState(patch({ cashFlow: { ...ctx.getState().cashFlow, ...payload } }));
    this._autosave(ctx);
  }

  @Action(ResetApiCallStatus)
  resetApiCallStatus(ctx: StateContext<CashFlowUpdateStateModel>, { calltype }: ResetApiCallStatus) {
    console.log('Reset api call status type', calltype);
    ctx.setState(patch({ apiCallSucceded: patch({ cancelAndDelete: false }) }));
  }

  @Action(CancelAndDeleteCashFlowUpdate)
  cancelAndDeleteCashFlowUpdate({ getState, setState }: StateContext<CashFlowUpdateStateModel>) {
    const currentOrganizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);

    if (getState().cashFlow && getState().cashFlow.id) {
      this.applicationService
        .removeCashFlowUpdate({
          organizationId: currentOrganizationId,
          id: getState().cashFlow.id!,
        })
        .pipe(
          tap(() => {
            setState(
              patch({
                apiCallSucceded: { cancelAndDelete: true },
              }),
            );
          }),
        )
        .subscribe({
          next: () => {},
          error: (err) => {
            console.error('Error occurred [state]', err);
          },
        });
      setState(
        patch({
          cashFlowUpdateStepperStatus: {
            [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.Certification]: FormStatusCodes.NotStarted,
          },
          cashFlowStep: CashFlowFormSequence.ContactInfo,
          cta: undefined,
          isValid: undefined,
          cashFlow: {},
          applicationDetails: {} as ApplicationExtDto,
        }),
      );
    } else {
      //no api call required as its a new cash flow thats not been persisted yet
      setState(
        patch({
          cashFlowUpdateStepperStatus: {
            [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
            [CashFlowFormSequence.Certification]: FormStatusCodes.NotStarted,
          },
          cashFlowStep: CashFlowFormSequence.ContactInfo,
          cta: undefined,
          isValid: undefined,
          cashFlow: {},
          applicationDetails: {} as ApplicationExtDto,
          apiCallSucceded: { cancelAndDelete: true },
        }),
      );
    }
  }

  @Action(SetCashFlowApplicationFunding)
  setCashFlowUpdateApplicationFunding(
    { getState, setState }: StateContext<CashFlowUpdateStateModel>,
    { payload }: SetCashFlowApplicationFunding,
  ) {
    setState(patch({ cashFlow: { ...getState().cashFlow, applicationUpdatesRecord: { ...payload } } }));
  }

  @Action(CashFlowProjectUpdatesErrorCallout)
  cashFlowProjectUpdatesErrorCallout(
    { setState }: StateContext<CashFlowUpdateStateModel>,
    { callout }: CashFlowProjectUpdatesErrorCallout,
  ) {
    setState(patch({ errorCallOut: callout }));
  }

  @Action(ToggleAreThereOtherSourcesOfFunding)
  toggleAreThereOtherSourcesOfFunding(
    { setState }: StateContext<CashFlowUpdateStateModel>,
    { areThereOtherSourcesOfFunding }: ToggleAreThereOtherSourcesOfFunding,
  ) {
    setState(patch({ toggleAreThereOtherSourcesOfFunding: areThereOtherSourcesOfFunding }));
  }

  @Action(SetCashFlowProjectUpdate)
  setCashFlowProjectUpdate(ctx: StateContext<CashFlowUpdateStateModel>, { payload }: SetCashFlowProjectUpdate) {
    const projects = ctx.getState().cashFlow?.applicationUpdatesRecord?.projectUpdates;
    if (projects !== undefined) {
      const updatedCashFlowProjectId = projects?.findIndex((pr) => pr.projectId == payload?.projectId);
      const updatedProjects = [
        ...(projects?.slice(0, updatedCashFlowProjectId) ?? []),
        payload!,
        ...(projects?.slice(updatedCashFlowProjectId + 1) ?? []),
      ];
      ctx.setState(
        patch({ cashFlow: patch({ applicationUpdatesRecord: patch({ projectUpdates: updatedProjects }) }) }),
      );
      this._autosave(ctx, false);
    }
  }

  private _submitCashFlow({ getState, setState }: StateContext<CashFlowUpdateStateModel>) {
    if (getState().cashFlow && getState().cashFlow.id) {
      this.applicationService
        .submitCashFlow({
          organizationId: this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId),
          id: getState().cashFlow.id ?? 14,
        })
        .subscribe({
          next: () => {
            setState(
              patch({
                submitStatus: AutosaveStatus.success,
              }),
            );
          },
          error: () => {
            setState(
              patch({
                submitStatus: AutosaveStatus.failure,
              }),
            );
          },
        });
    } else {
      setState(
        patch({
          submitStatus: AutosaveStatus.failure,
        }),
      );
    }
  }
  @Action(DeleteCashFlowUpdate)
  deleteCashFlowUpdate({ setState }: StateContext<CashFlowUpdateStateModel>, { cashFlowId }: DeleteCashFlowUpdate) {
    console.log('DeleteCashFlowUpdate  Id=' + cashFlowId);
    // reset memory state
    setState(
      patch({
        cashFlow: {},
      }),
    );

    // delete draft in backend
    return this.applicationService.removeCashFlowUpdate({ organizationId: this.currentOrganizationId, id: cashFlowId });
  }
  private _autosave(
    { getState, setState, dispatch }: StateContext<CashFlowUpdateStateModel>,
    autosave: boolean = true,
  ) {
    //Get shared state from Current Context store
    const currentOrganizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    const currentApplicationId = this.store.selectSnapshot(CurrentContextState.getCurrentCashFlowApplicationId);

    if (autosave) {
      setState(
        patch({
          autosaveStatus: {
            callsInProgress: getState().autosaveStatus.callsInProgress + 1,
            status: AutosaveStatus.inProgress,
          },
        }),
      );
    }

    if (getState().cashFlow && getState().cashFlow.id) {
      const {
        applicationUpdatesRecord = {},
        contactFirstName,
        contactLastName,
        contactEmailAddress,
        contactPhoneNumber,
      } = getState().cashFlow;
      this.applicationService
        .updateCashFlowUpdate({
          organizationId: currentOrganizationId,
          id: getState().cashFlow.id!,
          body: {
            applicationUpdatesRecord,
            contactFirstName,
            contactLastName,
            contactPhoneNumber,
            contactEmailAddress,
          },
        })
        .subscribe({
          next: (savedCashFlow) => {
            if (autosave) {
              const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
              setState(
                patch({
                  autosaveStatus: {
                    callsInProgress: callsInProgress,
                    status: callsInProgress === 0 ? AutosaveStatus.success : AutosaveStatus.inProgress,
                  },
                }),
              );
            }
            setState(
              patch({
                cashFlow: savedCashFlow,
              }),
            );
          },
          error: (err) => {
            console.error('Error occurred [state]', err);
            if (autosave) {
              const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
              setState(
                patch({
                  autosaveStatus: {
                    callsInProgress: callsInProgress,
                    status: callsInProgress === 0 ? AutosaveStatus.failure : AutosaveStatus.inProgress,
                  },
                }),
              );
            }
          },
        });
    } else {
      const {
        applicationUpdatesRecord = {},
        contactFirstName,
        contactLastName,
        contactEmailAddress,
        contactPhoneNumber,
      } = getState().cashFlow;
      this.applicationService
        .createCashFlowUpdate({
          organizationId: currentOrganizationId,
          applicationId: currentApplicationId!,
          body: {
            applicationUpdatesRecord,
            contactFirstName,
            contactLastName,
            contactPhoneNumber,
            contactEmailAddress,
          },
        })
        .subscribe({
          next: (savedCashFlow: CashFlowUpdateExtDto) => {
            // console.log('[Cash flow created]Persisted cash flow', savedCashFlow);
            if (autosave) {
              const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
              setState(
                patch({
                  autosaveStatus: {
                    callsInProgress: callsInProgress,
                    status: callsInProgress === 0 ? AutosaveStatus.success : AutosaveStatus.inProgress,
                  },
                }),
              );
            }
            setState(
              patch({
                cashFlow: savedCashFlow,
              }),
            );

            dispatch(new SetCurrentCashFlowUpdateId(savedCashFlow.id));
          },
          error: (err) => {
            console.error('Error occurred [state]', err);
            if (autosave) {
              const callsInProgress = getState().autosaveStatus.callsInProgress - 1;
              setState(
                patch({
                  autosaveStatus: {
                    callsInProgress: callsInProgress,
                    status: callsInProgress === 0 ? AutosaveStatus.failure : AutosaveStatus.inProgress,
                  },
                }),
              );
            }
          },
        });
    }
  }
}
