<goa-modal
  id="{{ modalId }}"
  [open]="open"
  heading="{{ headingId | res: pageId }}"
  [maxwidth]="maxwidth"
  [closable]="closable"
  [appPreventModalFlicker]="open"
  (_close)="onModalClose($event)"
>
  <ng-container *ngIf="modalContentStringId">
    <p>{{ modalContentStringId | res: pageId }}</p>
  </ng-container>
  <ng-container *ngIf="!modalContentStringId">
    <ng-content></ng-content>
  </ng-container>
  <div slot="actions">
    <ng-container *ngTemplateOutlet="getFooterTemplate() || defaultFooterTemplate"></ng-container>
  </div>
</goa-modal>

<ng-template #defaultFooterTemplate>
  <goa-button-group alignment="end">
    <goa-button type="primary" (_click)="okBtnClicked()">{{ okBtnLabel | res: pageId }}</goa-button>
  </goa-button-group>
</ng-template>
<ng-template #confirmationFooterTemplate>
  <goa-button-group alignment="end">
    <goa-button type="secondary" disabled="{{ cancelBtnDisabled }}" (click)="onCancelBtnClicked()">
      {{ cancelBtnLabel | res: pageId }}</goa-button
    >
    <goa-button type="primary" disabled="{{ okBtnDisabled }}" (click)="okBtnClicked()">
      {{ okBtnLabel | res: pageId }}
    </goa-button>
  </goa-button-group>
</ng-template>
<ng-template #threeActionFooterTemplate>
  <goa-button-group alignment="end">
    <goa-button type="secondary" disabled="{{ cancelBtnDisabled }}" (click)="onCancelBtnClicked()">
      {{ cancelBtnLabel | res: pageId }}
    </goa-button>
    <goa-button type="secondary" disabled="{{ resetBtnDisabled }}" (click)="onResetBtnClicked()">
      {{ resetBtnLabel | res: pageId }}</goa-button
    >
    <goa-button type="primary" disabled="{{ okBtnDisabled }}" (click)="okBtnClicked()">
      {{ okBtnLabel | res: pageId }}
    </goa-button>
  </goa-button-group>
</ng-template>
