import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[appInputFormat]',
})
export class InputFormatDirective {
  private el: HTMLInputElement;

  constructor(private elementRef: ElementRef) {
    this.el = this.elementRef.nativeElement;
  }

  @HostListener('keypress', ['$event.target.value'])
  onkeypress() {
    if (this.el.value.replaceAll('-', '').length >= 3) {
      const n = this.el.value.replaceAll('-', '').length % 3;
      if (n == 0 && this.el.value.length <= 10) {
        if (this.el.value.slice(-1) == '-') {
          this.el.value = this.el.value.slice(0, this.el.value.length - 1);
        }
        this.el.value = this.el.value + '-';
      }
    }
  }

  @HostListener('blur', ['$event.target.value'])
  onBlur() {
    const num = this.el.value.replaceAll('-', '');
    if (num.length > 3) {
      this.el.value = num.slice(0, 3) + '-' + num.slice(3, 6) + '-' + num.slice(6);
    }
    if (this.el.value.slice(-1) == '-') {
      this.el.value = this.el.value.slice(0, this.el.value.length - 1);
    }
  }
}
