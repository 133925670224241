const albertaCoords = [
  { lng: -112.4096, lat: 49.0001 },
  { lng: -114.0702, lat: 49.0007 },
  { lng: -114.0719, lat: 49.0024 },
  { lng: -114.0718, lat: 49.006 },
  { lng: -114.0591, lat: 49.0184 },
  { lng: -114.0542, lat: 49.0267 },
  { lng: -114.0571, lat: 49.028 },
  { lng: -114.0623, lat: 49.0375 },
  { lng: -114.0639, lat: 49.0455 },
  { lng: -114.0766, lat: 49.0537 },
  { lng: -114.0813, lat: 49.0598 },
  { lng: -114.0896, lat: 49.0585 },
  { lng: -114.0968, lat: 49.0599 },
  { lng: -114.1072, lat: 49.0651 },
  { lng: -114.1161, lat: 49.0739 },
  { lng: -114.1299, lat: 49.0782 },
  { lng: -114.1329, lat: 49.0826 },
  { lng: -114.14, lat: 49.0872 },
  { lng: -114.1533, lat: 49.0996 },
  { lng: -114.1534, lat: 49.1032 },
  { lng: -114.1491, lat: 49.1074 },
  { lng: -114.1498, lat: 49.1181 },
  { lng: -114.1579, lat: 49.1237 },
  { lng: -114.1661, lat: 49.1356 },
  { lng: -114.1655, lat: 49.1371 },
  { lng: -114.1491, lat: 49.1437 },
  { lng: -114.1619, lat: 49.1517 },
  { lng: -114.1613, lat: 49.1548 },
  { lng: -114.1662, lat: 49.1567 },
  { lng: -114.1749, lat: 49.1636 },
  { lng: -114.1953, lat: 49.169 },
  { lng: -114.204, lat: 49.1698 },
  { lng: -114.2127, lat: 49.1734 },
  { lng: -114.2171, lat: 49.1841 },
  { lng: -114.2192, lat: 49.1849 },
  { lng: -114.2215, lat: 49.1891 },
  { lng: -114.2305, lat: 49.1893 },
  { lng: -114.2347, lat: 49.1854 },
  { lng: -114.2405, lat: 49.1847 },
  { lng: -114.2467, lat: 49.1873 },
  { lng: -114.2571, lat: 49.177 },
  { lng: -114.2694, lat: 49.181 },
  { lng: -114.2799, lat: 49.178 },
  { lng: -114.2873, lat: 49.178 },
  { lng: -114.2894, lat: 49.1804 },
  { lng: -114.2885, lat: 49.1831 },
  { lng: -114.2901, lat: 49.186 },
  { lng: -114.301, lat: 49.1908 },
  { lng: -114.3105, lat: 49.192 },
  { lng: -114.3184, lat: 49.2 },
  { lng: -114.3369, lat: 49.1994 },
  { lng: -114.3396, lat: 49.196 },
  { lng: -114.3456, lat: 49.1936 },
  { lng: -114.3556, lat: 49.1966 },
  { lng: -114.3589, lat: 49.1984 },
  { lng: -114.3609, lat: 49.2021 },
  { lng: -114.3662, lat: 49.2037 },
  { lng: -114.3773, lat: 49.2029 },
  { lng: -114.3868, lat: 49.205 },
  { lng: -114.399, lat: 49.2104 },
  { lng: -114.4021, lat: 49.2133 },
  { lng: -114.3939, lat: 49.2194 },
  { lng: -114.4058, lat: 49.2253 },
  { lng: -114.4011, lat: 49.2278 },
  { lng: -114.4029, lat: 49.2327 },
  { lng: -114.4027, lat: 49.2416 },
  { lng: -114.3836, lat: 49.2462 },
  { lng: -114.3799, lat: 49.2482 },
  { lng: -114.3814, lat: 49.2508 },
  { lng: -114.3877, lat: 49.2516 },
  { lng: -114.3935, lat: 49.2572 },
  { lng: -114.4093, lat: 49.2597 },
  { lng: -114.4145, lat: 49.2622 },
  { lng: -114.4297, lat: 49.2613 },
  { lng: -114.4397, lat: 49.2646 },
  { lng: -114.4479, lat: 49.2644 },
  { lng: -114.4494, lat: 49.2673 },
  { lng: -114.449, lat: 49.2766 },
  { lng: -114.4512, lat: 49.2804 },
  { lng: -114.4468, lat: 49.2884 },
  { lng: -114.4512, lat: 49.2926 },
  { lng: -114.454, lat: 49.2981 },
  { lng: -114.4612, lat: 49.3041 },
  { lng: -114.4668, lat: 49.3044 },
  { lng: -114.4713, lat: 49.3067 },
  { lng: -114.4776, lat: 49.3124 },
  { lng: -114.485, lat: 49.3107 },
  { lng: -114.4921, lat: 49.3138 },
  { lng: -114.4922, lat: 49.3222 },
  { lng: -114.4789, lat: 49.3268 },
  { lng: -114.4817, lat: 49.3292 },
  { lng: -114.4826, lat: 49.3375 },
  { lng: -114.4876, lat: 49.3473 },
  { lng: -114.4957, lat: 49.3525 },
  { lng: -114.501, lat: 49.3528 },
  { lng: -114.5069, lat: 49.3566 },
  { lng: -114.5166, lat: 49.3545 },
  { lng: -114.5247, lat: 49.3574 },
  { lng: -114.5241, lat: 49.3621 },
  { lng: -114.5318, lat: 49.3718 },
  { lng: -114.5263, lat: 49.3751 },
  { lng: -114.5366, lat: 49.3796 },
  { lng: -114.5477, lat: 49.3773 },
  { lng: -114.5669, lat: 49.3769 },
  { lng: -114.5681, lat: 49.3801 },
  { lng: -114.5761, lat: 49.3867 },
  { lng: -114.5734, lat: 49.3896 },
  { lng: -114.5865, lat: 49.3923 },
  { lng: -114.5906, lat: 49.3961 },
  { lng: -114.592, lat: 49.4049 },
  { lng: -114.5984, lat: 49.4126 },
  { lng: -114.5966, lat: 49.4201 },
  { lng: -114.6008, lat: 49.4247 },
  { lng: -114.6008, lat: 49.428 },
  { lng: -114.5986, lat: 49.4339 },
  { lng: -114.6051, lat: 49.4389 },
  { lng: -114.6029, lat: 49.4436 },
  { lng: -114.5984, lat: 49.4454 },
  { lng: -114.5989, lat: 49.4498 },
  { lng: -114.6034, lat: 49.4528 },
  { lng: -114.5984, lat: 49.4548 },
  { lng: -114.5994, lat: 49.4666 },
  { lng: -114.5939, lat: 49.4694 },
  { lng: -114.5935, lat: 49.4714 },
  { lng: -114.5955, lat: 49.4801 },
  { lng: -114.5936, lat: 49.4841 },
  { lng: -114.5971, lat: 49.4909 },
  { lng: -114.5929, lat: 49.4932 },
  { lng: -114.5965, lat: 49.5019 },
  { lng: -114.585, lat: 49.5125 },
  { lng: -114.5826, lat: 49.518 },
  { lng: -114.5832, lat: 49.5202 },
  { lng: -114.5774, lat: 49.5213 },
  { lng: -114.5727, lat: 49.5247 },
  { lng: -114.5732, lat: 49.5326 },
  { lng: -114.5698, lat: 49.537 },
  { lng: -114.5742, lat: 49.5464 },
  { lng: -114.5721, lat: 49.5483 },
  { lng: -114.574, lat: 49.5506 },
  { lng: -114.573, lat: 49.557 },
  { lng: -114.5789, lat: 49.558 },
  { lng: -114.5882, lat: 49.5654 },
  { lng: -114.5934, lat: 49.5611 },
  { lng: -114.6073, lat: 49.5593 },
  { lng: -114.6089, lat: 49.5549 },
  { lng: -114.6125, lat: 49.554 },
  { lng: -114.6139, lat: 49.5505 },
  { lng: -114.6196, lat: 49.5464 },
  { lng: -114.6265, lat: 49.5494 },
  { lng: -114.6304, lat: 49.5491 },
  { lng: -114.6379, lat: 49.5436 },
  { lng: -114.648, lat: 49.543 },
  { lng: -114.6546, lat: 49.5452 },
  { lng: -114.6626, lat: 49.552 },
  { lng: -114.6763, lat: 49.5533 },
  { lng: -114.6833, lat: 49.5565 },
  { lng: -114.6905, lat: 49.5537 },
  { lng: -114.6958, lat: 49.5466 },
  { lng: -114.7009, lat: 49.5522 },
  { lng: -114.7103, lat: 49.5585 },
  { lng: -114.7103, lat: 49.5629 },
  { lng: -114.7141, lat: 49.5661 },
  { lng: -114.7197, lat: 49.5674 },
  { lng: -114.7246, lat: 49.5716 },
  { lng: -114.7244, lat: 49.5759 },
  { lng: -114.7326, lat: 49.5765 },
  { lng: -114.7346, lat: 49.5803 },
  { lng: -114.7372, lat: 49.5959 },
  { lng: -114.7471, lat: 49.6024 },
  { lng: -114.747, lat: 49.6071 },
  { lng: -114.7532, lat: 49.6096 },
  { lng: -114.7482, lat: 49.6139 },
  { lng: -114.7473, lat: 49.6194 },
  { lng: -114.7234, lat: 49.6239 },
  { lng: -114.7162, lat: 49.6306 },
  { lng: -114.7087, lat: 49.6313 },
  { lng: -114.7064, lat: 49.6337 },
  { lng: -114.7083, lat: 49.6359 },
  { lng: -114.7031, lat: 49.6386 },
  { lng: -114.7002, lat: 49.6368 },
  { lng: -114.6978, lat: 49.6311 },
  { lng: -114.691, lat: 49.6327 },
  { lng: -114.6908, lat: 49.6348 },
  { lng: -114.6876, lat: 49.6358 },
  { lng: -114.6871, lat: 49.6379 },
  { lng: -114.6812, lat: 49.639 },
  { lng: -114.6798, lat: 49.6414 },
  { lng: -114.6746, lat: 49.6435 },
  { lng: -114.6677, lat: 49.6377 },
  { lng: -114.6621, lat: 49.6434 },
  { lng: -114.657, lat: 49.6609 },
  { lng: -114.6575, lat: 49.6636 },
  { lng: -114.6688, lat: 49.6701 },
  { lng: -114.6698, lat: 49.6729 },
  { lng: -114.6682, lat: 49.6756 },
  { lng: -114.6646, lat: 49.6766 },
  { lng: -114.6701, lat: 49.6841 },
  { lng: -114.669, lat: 49.691 },
  { lng: -114.6759, lat: 49.6943 },
  { lng: -114.6766, lat: 49.6961 },
  { lng: -114.6683, lat: 49.7038 },
  { lng: -114.6474, lat: 49.713 },
  { lng: -114.643, lat: 49.7172 },
  { lng: -114.6389, lat: 49.7178 },
  { lng: -114.6363, lat: 49.7217 },
  { lng: -114.6383, lat: 49.7244 },
  { lng: -114.6336, lat: 49.7304 },
  { lng: -114.6331, lat: 49.7341 },
  { lng: -114.637, lat: 49.738 },
  { lng: -114.6429, lat: 49.7391 },
  { lng: -114.6463, lat: 49.7433 },
  { lng: -114.6422, lat: 49.7463 },
  { lng: -114.6442, lat: 49.7496 },
  { lng: -114.651, lat: 49.7551 },
  { lng: -114.6514, lat: 49.7587 },
  { lng: -114.6573, lat: 49.7608 },
  { lng: -114.6591, lat: 49.7651 },
  { lng: -114.6444, lat: 49.773 },
  { lng: -114.641, lat: 49.7814 },
  { lng: -114.636, lat: 49.7848 },
  { lng: -114.6422, lat: 49.7958 },
  { lng: -114.6415, lat: 49.8013 },
  { lng: -114.6437, lat: 49.8056 },
  { lng: -114.6419, lat: 49.808 },
  { lng: -114.6475, lat: 49.8173 },
  { lng: -114.6474, lat: 49.8193 },
  { lng: -114.6429, lat: 49.8223 },
  { lng: -114.6399, lat: 49.8277 },
  { lng: -114.6506, lat: 49.8384 },
  { lng: -114.6548, lat: 49.8467 },
  { lng: -114.6612, lat: 49.8518 },
  { lng: -114.6627, lat: 49.8548 },
  { lng: -114.6614, lat: 49.8623 },
  { lng: -114.6714, lat: 49.8645 },
  { lng: -114.6705, lat: 49.8684 },
  { lng: -114.673, lat: 49.8739 },
  { lng: -114.6727, lat: 49.8783 },
  { lng: -114.6851, lat: 49.887 },
  { lng: -114.6918, lat: 49.8963 },
  { lng: -114.6888, lat: 49.9019 },
  { lng: -114.6898, lat: 49.9065 },
  { lng: -114.6873, lat: 49.9171 },
  { lng: -114.6902, lat: 49.9216 },
  { lng: -114.6914, lat: 49.9331 },
  { lng: -114.6897, lat: 49.9361 },
  { lng: -114.6949, lat: 49.9419 },
  { lng: -114.6853, lat: 49.9552 },
  { lng: -114.6833, lat: 49.9671 },
  { lng: -114.6706, lat: 49.9679 },
  { lng: -114.6636, lat: 49.9665 },
  { lng: -114.6559, lat: 49.9681 },
  { lng: -114.6515, lat: 49.9755 },
  { lng: -114.6543, lat: 49.9858 },
  { lng: -114.652, lat: 49.9927 },
  { lng: -114.6549, lat: 49.997 },
  { lng: -114.665, lat: 50.0 },
  { lng: -114.6666, lat: 50.0033 },
  { lng: -114.6634, lat: 50.0075 },
  { lng: -114.6612, lat: 50.0151 },
  { lng: -114.6577, lat: 50.0191 },
  { lng: -114.6596, lat: 50.0267 },
  { lng: -114.6577, lat: 50.0297 },
  { lng: -114.6619, lat: 50.0339 },
  { lng: -114.6664, lat: 50.0354 },
  { lng: -114.6629, lat: 50.0396 },
  { lng: -114.6711, lat: 50.0431 },
  { lng: -114.6761, lat: 50.0481 },
  { lng: -114.6664, lat: 50.0509 },
  { lng: -114.6656, lat: 50.061 },
  { lng: -114.6625, lat: 50.0649 },
  { lng: -114.6652, lat: 50.0637 },
  { lng: -114.6799, lat: 50.0641 },
  { lng: -114.6817, lat: 50.0673 },
  { lng: -114.6913, lat: 50.0713 },
  { lng: -114.692, lat: 50.0778 },
  { lng: -114.6952, lat: 50.0812 },
  { lng: -114.6974, lat: 50.0869 },
  { lng: -114.6991, lat: 50.0888 },
  { lng: -114.7041, lat: 50.0897 },
  { lng: -114.7028, lat: 50.091 },
  { lng: -114.7037, lat: 50.0948 },
  { lng: -114.7108, lat: 50.1022 },
  { lng: -114.7194, lat: 50.1057 },
  { lng: -114.7208, lat: 50.1109 },
  { lng: -114.7279, lat: 50.1132 },
  { lng: -114.736, lat: 50.1204 },
  { lng: -114.7335, lat: 50.1213 },
  { lng: -114.725, lat: 50.1407 },
  { lng: -114.7273, lat: 50.1484 },
  { lng: -114.7273, lat: 50.1578 },
  { lng: -114.7253, lat: 50.1614 },
  { lng: -114.7326, lat: 50.1693 },
  { lng: -114.7307, lat: 50.1712 },
  { lng: -114.7309, lat: 50.176 },
  { lng: -114.7239, lat: 50.1839 },
  { lng: -114.7255, lat: 50.1861 },
  { lng: -114.7247, lat: 50.1902 },
  { lng: -114.731, lat: 50.194 },
  { lng: -114.7389, lat: 50.2027 },
  { lng: -114.7376, lat: 50.2088 },
  { lng: -114.7396, lat: 50.2117 },
  { lng: -114.749, lat: 50.2149 },
  { lng: -114.7448, lat: 50.2182 },
  { lng: -114.7453, lat: 50.2223 },
  { lng: -114.7558, lat: 50.2318 },
  { lng: -114.7629, lat: 50.2347 },
  { lng: -114.762, lat: 50.2409 },
  { lng: -114.768, lat: 50.2431 },
  { lng: -114.7671, lat: 50.247 },
  { lng: -114.7702, lat: 50.25 },
  { lng: -114.7665, lat: 50.254 },
  { lng: -114.7668, lat: 50.2582 },
  { lng: -114.7641, lat: 50.2652 },
  { lng: -114.7581, lat: 50.2717 },
  { lng: -114.7521, lat: 50.2732 },
  { lng: -114.7517, lat: 50.2753 },
  { lng: -114.7551, lat: 50.2814 },
  { lng: -114.7525, lat: 50.2858 },
  { lng: -114.7528, lat: 50.2938 },
  { lng: -114.7551, lat: 50.2992 },
  { lng: -114.7622, lat: 50.2993 },
  { lng: -114.7694, lat: 50.3026 },
  { lng: -114.7762, lat: 50.2998 },
  { lng: -114.7803, lat: 50.2998 },
  { lng: -114.7827, lat: 50.3114 },
  { lng: -114.7804, lat: 50.3153 },
  { lng: -114.7986, lat: 50.3265 },
  { lng: -114.8, lat: 50.3298 },
  { lng: -114.7954, lat: 50.3309 },
  { lng: -114.7922, lat: 50.3358 },
  { lng: -114.7748, lat: 50.3438 },
  { lng: -114.7719, lat: 50.3475 },
  { lng: -114.7662, lat: 50.3507 },
  { lng: -114.7686, lat: 50.3524 },
  { lng: -114.7686, lat: 50.3588 },
  { lng: -114.7749, lat: 50.3625 },
  { lng: -114.7845, lat: 50.3628 },
  { lng: -114.7891, lat: 50.3581 },
  { lng: -114.8039, lat: 50.3649 },
  { lng: -114.8088, lat: 50.3707 },
  { lng: -114.8192, lat: 50.3691 },
  { lng: -114.8172, lat: 50.3749 },
  { lng: -114.8125, lat: 50.3783 },
  { lng: -114.8139, lat: 50.3819 },
  { lng: -114.8203, lat: 50.3845 },
  { lng: -114.8248, lat: 50.3933 },
  { lng: -114.8313, lat: 50.3903 },
  { lng: -114.8383, lat: 50.3936 },
  { lng: -114.8548, lat: 50.3922 },
  { lng: -114.8623, lat: 50.3945 },
  { lng: -114.8664, lat: 50.3994 },
  { lng: -114.8652, lat: 50.4054 },
  { lng: -114.8601, lat: 50.4077 },
  { lng: -114.8641, lat: 50.4115 },
  { lng: -114.8636, lat: 50.4173 },
  { lng: -114.8596, lat: 50.421 },
  { lng: -114.8598, lat: 50.4238 },
  { lng: -114.8625, lat: 50.4255 },
  { lng: -114.8684, lat: 50.4258 },
  { lng: -114.873, lat: 50.4302 },
  { lng: -114.8747, lat: 50.4396 },
  { lng: -114.8878, lat: 50.4356 },
  { lng: -114.8955, lat: 50.4395 },
  { lng: -114.9054, lat: 50.4485 },
  { lng: -114.9122, lat: 50.4514 },
  { lng: -114.9124, lat: 50.4549 },
  { lng: -114.9162, lat: 50.458 },
  { lng: -114.9174, lat: 50.4615 },
  { lng: -114.9269, lat: 50.4684 },
  { lng: -114.9284, lat: 50.4718 },
  { lng: -114.9323, lat: 50.4735 },
  { lng: -114.9317, lat: 50.4785 },
  { lng: -114.9357, lat: 50.4808 },
  { lng: -114.9372, lat: 50.4863 },
  { lng: -114.9458, lat: 50.4896 },
  { lng: -114.9513, lat: 50.4976 },
  { lng: -114.9533, lat: 50.4979 },
  { lng: -114.959, lat: 50.5044 },
  { lng: -114.9626, lat: 50.5125 },
  { lng: -114.9677, lat: 50.5161 },
  { lng: -114.9775, lat: 50.5296 },
  { lng: -114.9783, lat: 50.535 },
  { lng: -114.9832, lat: 50.5397 },
  { lng: -114.9914, lat: 50.5444 },
  { lng: -114.9927, lat: 50.5479 },
  { lng: -115.0112, lat: 50.5673 },
  { lng: -115.0137, lat: 50.5709 },
  { lng: -115.0151, lat: 50.5824 },
  { lng: -115.0205, lat: 50.5847 },
  { lng: -115.0271, lat: 50.5831 },
  { lng: -115.0336, lat: 50.5793 },
  { lng: -115.0495, lat: 50.5752 },
  { lng: -115.0521, lat: 50.5714 },
  { lng: -115.0633, lat: 50.5832 },
  { lng: -115.0737, lat: 50.5884 },
  { lng: -115.0853, lat: 50.5897 },
  { lng: -115.1009, lat: 50.5779 },
  { lng: -115.1159, lat: 50.5718 },
  { lng: -115.1175, lat: 50.5698 },
  { lng: -115.1317, lat: 50.5718 },
  { lng: -115.1361, lat: 50.5744 },
  { lng: -115.1485, lat: 50.5708 },
  { lng: -115.1546, lat: 50.5733 },
  { lng: -115.1736, lat: 50.5676 },
  { lng: -115.175, lat: 50.5614 },
  { lng: -115.1777, lat: 50.5582 },
  { lng: -115.1899, lat: 50.5551 },
  { lng: -115.1909, lat: 50.5541 },
  { lng: -115.1899, lat: 50.549 },
  { lng: -115.1837, lat: 50.544 },
  { lng: -115.1862, lat: 50.5398 },
  { lng: -115.1909, lat: 50.5351 },
  { lng: -115.2022, lat: 50.533 },
  { lng: -115.2061, lat: 50.5281 },
  { lng: -115.2081, lat: 50.5278 },
  { lng: -115.2169, lat: 50.5365 },
  { lng: -115.221, lat: 50.537 },
  { lng: -115.2225, lat: 50.5397 },
  { lng: -115.231, lat: 50.5449 },
  { lng: -115.2319, lat: 50.5507 },
  { lng: -115.2296, lat: 50.5515 },
  { lng: -115.2284, lat: 50.5541 },
  { lng: -115.2298, lat: 50.5592 },
  { lng: -115.2349, lat: 50.5676 },
  { lng: -115.2419, lat: 50.5712 },
  { lng: -115.2393, lat: 50.5749 },
  { lng: -115.235, lat: 50.5748 },
  { lng: -115.2302, lat: 50.5775 },
  { lng: -115.2396, lat: 50.5892 },
  { lng: -115.2473, lat: 50.5881 },
  { lng: -115.2601, lat: 50.5963 },
  { lng: -115.2719, lat: 50.5979 },
  { lng: -115.2779, lat: 50.6011 },
  { lng: -115.2804, lat: 50.6055 },
  { lng: -115.2847, lat: 50.6088 },
  { lng: -115.2876, lat: 50.6082 },
  { lng: -115.2954, lat: 50.613 },
  { lng: -115.2982, lat: 50.6202 },
  { lng: -115.2969, lat: 50.6262 },
  { lng: -115.3052, lat: 50.6259 },
  { lng: -115.3115, lat: 50.6286 },
  { lng: -115.3118, lat: 50.6304 },
  { lng: -115.3095, lat: 50.6315 },
  { lng: -115.3101, lat: 50.6353 },
  { lng: -115.313, lat: 50.6376 },
  { lng: -115.3119, lat: 50.6382 },
  { lng: -115.2996, lat: 50.6393 },
  { lng: -115.2884, lat: 50.6376 },
  { lng: -115.2855, lat: 50.6389 },
  { lng: -115.2821, lat: 50.647 },
  { lng: -115.2742, lat: 50.6489 },
  { lng: -115.2813, lat: 50.6538 },
  { lng: -115.2817, lat: 50.6571 },
  { lng: -115.2859, lat: 50.6603 },
  { lng: -115.2823, lat: 50.6632 },
  { lng: -115.2984, lat: 50.6833 },
  { lng: -115.3008, lat: 50.6907 },
  { lng: -115.2936, lat: 50.6984 },
  { lng: -115.3016, lat: 50.7006 },
  { lng: -115.3088, lat: 50.7078 },
  { lng: -115.3147, lat: 50.7163 },
  { lng: -115.3107, lat: 50.7216 },
  { lng: -115.3123, lat: 50.7239 },
  { lng: -115.3183, lat: 50.7265 },
  { lng: -115.3276, lat: 50.7219 },
  { lng: -115.3351, lat: 50.7242 },
  { lng: -115.3392, lat: 50.7221 },
  { lng: -115.3499, lat: 50.7213 },
  { lng: -115.3546, lat: 50.7227 },
  { lng: -115.3634, lat: 50.7179 },
  { lng: -115.3702, lat: 50.7173 },
  { lng: -115.3699, lat: 50.7143 },
  { lng: -115.3776, lat: 50.7109 },
  { lng: -115.3756, lat: 50.7079 },
  { lng: -115.3789, lat: 50.7053 },
  { lng: -115.3864, lat: 50.708 },
  { lng: -115.3912, lat: 50.7065 },
  { lng: -115.4037, lat: 50.7123 },
  { lng: -115.4138, lat: 50.7113 },
  { lng: -115.4167, lat: 50.7137 },
  { lng: -115.4169, lat: 50.7199 },
  { lng: -115.4158, lat: 50.7222 },
  { lng: -115.4114, lat: 50.7232 },
  { lng: -115.4108, lat: 50.7283 },
  { lng: -115.4136, lat: 50.7318 },
  { lng: -115.4124, lat: 50.7336 },
  { lng: -115.4147, lat: 50.7357 },
  { lng: -115.4196, lat: 50.7366 },
  { lng: -115.4363, lat: 50.7557 },
  { lng: -115.4471, lat: 50.7532 },
  { lng: -115.4523, lat: 50.7564 },
  { lng: -115.4589, lat: 50.7572 },
  { lng: -115.464, lat: 50.7536 },
  { lng: -115.4703, lat: 50.752 },
  { lng: -115.475, lat: 50.7548 },
  { lng: -115.4812, lat: 50.7552 },
  { lng: -115.4848, lat: 50.7607 },
  { lng: -115.4819, lat: 50.7686 },
  { lng: -115.4976, lat: 50.7823 },
  { lng: -115.5039, lat: 50.7852 },
  { lng: -115.5122, lat: 50.7864 },
  { lng: -115.5159, lat: 50.7845 },
  { lng: -115.517, lat: 50.7802 },
  { lng: -115.5325, lat: 50.7882 },
  { lng: -115.5331, lat: 50.7929 },
  { lng: -115.5355, lat: 50.7955 },
  { lng: -115.5386, lat: 50.7966 },
  { lng: -115.5447, lat: 50.7949 },
  { lng: -115.5509, lat: 50.7971 },
  { lng: -115.5529, lat: 50.8025 },
  { lng: -115.5605, lat: 50.8047 },
  { lng: -115.5607, lat: 50.8102 },
  { lng: -115.5665, lat: 50.8147 },
  { lng: -115.5618, lat: 50.8217 },
  { lng: -115.5624, lat: 50.8249 },
  { lng: -115.5608, lat: 50.8265 },
  { lng: -115.5643, lat: 50.829 },
  { lng: -115.5649, lat: 50.8322 },
  { lng: -115.5731, lat: 50.8377 },
  { lng: -115.5782, lat: 50.8405 },
  { lng: -115.6066, lat: 50.8456 },
  { lng: -115.6181, lat: 50.8357 },
  { lng: -115.6236, lat: 50.8341 },
  { lng: -115.627, lat: 50.8334 },
  { lng: -115.6314, lat: 50.8368 },
  { lng: -115.6394, lat: 50.838 },
  { lng: -115.643, lat: 50.8422 },
  { lng: -115.6427, lat: 50.846 },
  { lng: -115.6454, lat: 50.8544 },
  { lng: -115.6439, lat: 50.8573 },
  { lng: -115.651, lat: 50.8659 },
  { lng: -115.6508, lat: 50.8724 },
  { lng: -115.6489, lat: 50.8741 },
  { lng: -115.629, lat: 50.8798 },
  { lng: -115.6169, lat: 50.8794 },
  { lng: -115.6127, lat: 50.8853 },
  { lng: -115.6044, lat: 50.8873 },
  { lng: -115.5998, lat: 50.886 },
  { lng: -115.5999, lat: 50.8904 },
  { lng: -115.5926, lat: 50.8926 },
  { lng: -115.5779, lat: 50.8883 },
  { lng: -115.5621, lat: 50.8943 },
  { lng: -115.5679, lat: 50.8991 },
  { lng: -115.5722, lat: 50.9074 },
  { lng: -115.5782, lat: 50.9098 },
  { lng: -115.5785, lat: 50.9134 },
  { lng: -115.5832, lat: 50.9138 },
  { lng: -115.5842, lat: 50.9158 },
  { lng: -115.5999, lat: 50.9179 },
  { lng: -115.6098, lat: 50.9263 },
  { lng: -115.6054, lat: 50.9297 },
  { lng: -115.6055, lat: 50.9334 },
  { lng: -115.5977, lat: 50.937 },
  { lng: -115.597, lat: 50.9399 },
  { lng: -115.6108, lat: 50.9468 },
  { lng: -115.6146, lat: 50.9512 },
  { lng: -115.6117, lat: 50.9589 },
  { lng: -115.622, lat: 50.9655 },
  { lng: -115.626, lat: 50.9704 },
  { lng: -115.6255, lat: 50.9737 },
  { lng: -115.6283, lat: 50.9809 },
  { lng: -115.6362, lat: 50.982 },
  { lng: -115.6488, lat: 50.9975 },
  { lng: -115.6574, lat: 51.0013 },
  { lng: -115.6706, lat: 51.0 },
  { lng: -115.6777, lat: 51.0072 },
  { lng: -115.6886, lat: 51.0136 },
  { lng: -115.6922, lat: 51.0198 },
  { lng: -115.6905, lat: 51.0254 },
  { lng: -115.6924, lat: 51.0286 },
  { lng: -115.7068, lat: 51.0202 },
  { lng: -115.7136, lat: 51.0198 },
  { lng: -115.7171, lat: 51.0174 },
  { lng: -115.7268, lat: 51.0187 },
  { lng: -115.7392, lat: 51.0234 },
  { lng: -115.74, lat: 51.0249 },
  { lng: -115.7353, lat: 51.0296 },
  { lng: -115.7431, lat: 51.0339 },
  { lng: -115.7481, lat: 51.0306 },
  { lng: -115.767, lat: 51.0394 },
  { lng: -115.7721, lat: 51.0461 },
  { lng: -115.7691, lat: 51.0478 },
  { lng: -115.7736, lat: 51.0577 },
  { lng: -115.7613, lat: 51.0608 },
  { lng: -115.7594, lat: 51.0637 },
  { lng: -115.7615, lat: 51.0669 },
  { lng: -115.7589, lat: 51.0692 },
  { lng: -115.759, lat: 51.0712 },
  { lng: -115.7534, lat: 51.0744 },
  { lng: -115.7577, lat: 51.0751 },
  { lng: -115.7693, lat: 51.0684 },
  { lng: -115.7763, lat: 51.0665 },
  { lng: -115.7806, lat: 51.0699 },
  { lng: -115.7836, lat: 51.0693 },
  { lng: -115.7892, lat: 51.0724 },
  { lng: -115.8028, lat: 51.0696 },
  { lng: -115.804, lat: 51.0757 },
  { lng: -115.8123, lat: 51.0834 },
  { lng: -115.8128, lat: 51.0865 },
  { lng: -115.8255, lat: 51.0827 },
  { lng: -115.8334, lat: 51.0759 },
  { lng: -115.839, lat: 51.0774 },
  { lng: -115.8425, lat: 51.0765 },
  { lng: -115.8437, lat: 51.0796 },
  { lng: -115.8476, lat: 51.0818 },
  { lng: -115.8577, lat: 51.0791 },
  { lng: -115.8679, lat: 51.0893 },
  { lng: -115.883, lat: 51.086 },
  { lng: -115.8947, lat: 51.0861 },
  { lng: -115.9009, lat: 51.0937 },
  { lng: -115.9045, lat: 51.091 },
  { lng: -115.9038, lat: 51.0829 },
  { lng: -115.9072, lat: 51.0841 },
  { lng: -115.9236, lat: 51.0831 },
  { lng: -115.9266, lat: 51.0886 },
  { lng: -115.9303, lat: 51.0909 },
  { lng: -115.9325, lat: 51.0967 },
  { lng: -115.9415, lat: 51.097 },
  { lng: -115.9485, lat: 51.0992 },
  { lng: -115.9505, lat: 51.1021 },
  { lng: -115.9504, lat: 51.1069 },
  { lng: -115.9572, lat: 51.1152 },
  { lng: -115.9676, lat: 51.116 },
  { lng: -115.9665, lat: 51.1186 },
  { lng: -115.9683, lat: 51.1221 },
  { lng: -115.987, lat: 51.1263 },
  { lng: -115.9985, lat: 51.1243 },
  { lng: -116.0087, lat: 51.126 },
  { lng: -116.0127, lat: 51.1301 },
  { lng: -116.0127, lat: 51.1333 },
  { lng: -116.0156, lat: 51.1381 },
  { lng: -116.0099, lat: 51.1437 },
  { lng: -116.0097, lat: 51.1544 },
  { lng: -116.0074, lat: 51.1563 },
  { lng: -116.0199, lat: 51.1572 },
  { lng: -116.0257, lat: 51.163 },
  { lng: -116.0305, lat: 51.1632 },
  { lng: -116.0368, lat: 51.17 },
  { lng: -116.0239, lat: 51.1762 },
  { lng: -116.0206, lat: 51.1826 },
  { lng: -116.0213, lat: 51.187 },
  { lng: -116.0061, lat: 51.1902 },
  { lng: -116, lat: 51.1939 },
  { lng: -116.0043, lat: 51.2041 },
  { lng: -116.004, lat: 51.2079 },
  { lng: -116.0064, lat: 51.2087 },
  { lng: -116.0065, lat: 51.2107 },
  { lng: -116.002, lat: 51.2154 },
  { lng: -116.0076, lat: 51.222 },
  { lng: -116.0165, lat: 51.2236 },
  { lng: -116.0266, lat: 51.2198 },
  { lng: -116.0492, lat: 51.2274 },
  { lng: -116.0597, lat: 51.2392 },
  { lng: -116.0652, lat: 51.2479 },
  { lng: -116.0743, lat: 51.2491 },
  { lng: -116.0813, lat: 51.2518 },
  { lng: -116.0918, lat: 51.2508 },
  { lng: -116.1117, lat: 51.2533 },
  { lng: -116.1145, lat: 51.2564 },
  { lng: -116.1195, lat: 51.2554 },
  { lng: -116.1251, lat: 51.2593 },
  { lng: -116.1344, lat: 51.2605 },
  { lng: -116.1441, lat: 51.2647 },
  { lng: -116.1532, lat: 51.2634 },
  { lng: -116.154, lat: 51.268 },
  { lng: -116.1591, lat: 51.2758 },
  { lng: -116.1529, lat: 51.2833 },
  { lng: -116.1516, lat: 51.2871 },
  { lng: -116.158, lat: 51.2901 },
  { lng: -116.1602, lat: 51.296 },
  { lng: -116.1641, lat: 51.2978 },
  { lng: -116.1821, lat: 51.2954 },
  { lng: -116.1885, lat: 51.3015 },
  { lng: -116.1977, lat: 51.2996 },
  { lng: -116.2034, lat: 51.2952 },
  { lng: -116.2068, lat: 51.2962 },
  { lng: -116.219, lat: 51.2916 },
  { lng: -116.2191, lat: 51.2945 },
  { lng: -116.2266, lat: 51.2997 },
  { lng: -116.2271, lat: 51.3015 },
  { lng: -116.236, lat: 51.3027 },
  { lng: -116.2434, lat: 51.3014 },
  { lng: -116.26, lat: 51.3098 },
  { lng: -116.268, lat: 51.3119 },
  { lng: -116.2691, lat: 51.3162 },
  { lng: -116.2746, lat: 51.3194 },
  { lng: -116.2748, lat: 51.3282 },
  { lng: -116.2813, lat: 51.3301 },
  { lng: -116.2867, lat: 51.3345 },
  { lng: -116.2877, lat: 51.3421 },
  { lng: -116.29, lat: 51.3431 },
  { lng: -116.2773, lat: 51.3547 },
  { lng: -116.2799, lat: 51.3596 },
  { lng: -116.2777, lat: 51.3617 },
  { lng: -116.2934, lat: 51.3648 },
  { lng: -116.3002, lat: 51.3709 },
  { lng: -116.3027, lat: 51.3713 },
  { lng: -116.3115, lat: 51.385 },
  { lng: -116.2983, lat: 51.392 },
  { lng: -116.2919, lat: 51.3972 },
  { lng: -116.2937, lat: 51.4016 },
  { lng: -116.2925, lat: 51.4036 },
  { lng: -116.2825, lat: 51.4068 },
  { lng: -116.2879, lat: 51.4187 },
  { lng: -116.2933, lat: 51.4256 },
  { lng: -116.2943, lat: 51.4299 },
  { lng: -116.2855, lat: 51.4353 },
  { lng: -116.2881, lat: 51.4485 },
  { lng: -116.2844, lat: 51.4494 },
  { lng: -116.2841, lat: 51.4565 },
  { lng: -116.2925, lat: 51.4618 },
  { lng: -116.3087, lat: 51.4628 },
  { lng: -116.3151, lat: 51.4656 },
  { lng: -116.3223, lat: 51.4625 },
  { lng: -116.338, lat: 51.4643 },
  { lng: -116.3466, lat: 51.4695 },
  { lng: -116.3606, lat: 51.4709 },
  { lng: -116.3679, lat: 51.4786 },
  { lng: -116.369, lat: 51.483 },
  { lng: -116.3798, lat: 51.4883 },
  { lng: -116.3939, lat: 51.5057 },
  { lng: -116.3963, lat: 51.5126 },
  { lng: -116.3935, lat: 51.518 },
  { lng: -116.3954, lat: 51.5255 },
  { lng: -116.3879, lat: 51.5274 },
  { lng: -116.3821, lat: 51.531 },
  { lng: -116.3909, lat: 51.5456 },
  { lng: -116.4014, lat: 51.5465 },
  { lng: -116.422, lat: 51.5551 },
  { lng: -116.4287, lat: 51.5542 },
  { lng: -116.429, lat: 51.5565 },
  { lng: -116.4374, lat: 51.5583 },
  { lng: -116.4406, lat: 51.5571 },
  { lng: -116.4648, lat: 51.5658 },
  { lng: -116.465, lat: 51.5682 },
  { lng: -116.4688, lat: 51.5699 },
  { lng: -116.4687, lat: 51.5731 },
  { lng: -116.4733, lat: 51.5827 },
  { lng: -116.466, lat: 51.5941 },
  { lng: -116.469, lat: 51.6017 },
  { lng: -116.4764, lat: 51.6077 },
  { lng: -116.491, lat: 51.6123 },
  { lng: -116.489, lat: 51.6167 },
  { lng: -116.4996, lat: 51.6234 },
  { lng: -116.4999, lat: 51.6277 },
  { lng: -116.5135, lat: 51.6276 },
  { lng: -116.5299, lat: 51.6317 },
  { lng: -116.5365, lat: 51.6387 },
  { lng: -116.5459, lat: 51.6382 },
  { lng: -116.5701, lat: 51.6471 },
  { lng: -116.5706, lat: 51.6529 },
  { lng: -116.5804, lat: 51.6531 },
  { lng: -116.595, lat: 51.6618 },
  { lng: -116.597, lat: 51.6646 },
  { lng: -116.5962, lat: 51.6665 },
  { lng: -116.5901, lat: 51.6681 },
  { lng: -116.5875, lat: 51.673 },
  { lng: -116.5914, lat: 51.6777 },
  { lng: -116.587, lat: 51.6825 },
  { lng: -116.5867, lat: 51.6855 },
  { lng: -116.5814, lat: 51.6875 },
  { lng: -116.5836, lat: 51.6924 },
  { lng: -116.5895, lat: 51.6942 },
  { lng: -116.5812, lat: 51.6973 },
  { lng: -116.5847, lat: 51.7013 },
  { lng: -116.5876, lat: 51.7093 },
  { lng: -116.5854, lat: 51.7128 },
  { lng: -116.5882, lat: 51.7159 },
  { lng: -116.5945, lat: 51.7175 },
  { lng: -116.5985, lat: 51.7239 },
  { lng: -116.6065, lat: 51.7255 },
  { lng: -116.6105, lat: 51.724 },
  { lng: -116.6171, lat: 51.7252 },
  { lng: -116.6312, lat: 51.7314 },
  { lng: -116.6336, lat: 51.7355 },
  { lng: -116.6294, lat: 51.7372 },
  { lng: -116.6281, lat: 51.7449 },
  { lng: -116.6403, lat: 51.7511 },
  { lng: -116.6503, lat: 51.7537 },
  { lng: -116.6512, lat: 51.7561 },
  { lng: -116.6496, lat: 51.7628 },
  { lng: -116.651, lat: 51.7668 },
  { lng: -116.6469, lat: 51.7766 },
  { lng: -116.6444, lat: 51.7784 },
  { lng: -116.6458, lat: 51.7827 },
  { lng: -116.6432, lat: 51.7867 },
  { lng: -116.6552, lat: 51.7945 },
  { lng: -116.656, lat: 51.7969 },
  { lng: -116.6543, lat: 51.8013 },
  { lng: -116.6591, lat: 51.8052 },
  { lng: -116.6671, lat: 51.8045 },
  { lng: -116.6668, lat: 51.8061 },
  { lng: -116.6796, lat: 51.813 },
  { lng: -116.6924, lat: 51.8063 },
  { lng: -116.6945, lat: 51.8036 },
  { lng: -116.7036, lat: 51.8015 },
  { lng: -116.7068, lat: 51.8024 },
  { lng: -116.7155, lat: 51.7987 },
  { lng: -116.7251, lat: 51.8048 },
  { lng: -116.7442, lat: 51.8066 },
  { lng: -116.749, lat: 51.8051 },
  { lng: -116.7545, lat: 51.7999 },
  { lng: -116.7596, lat: 51.7875 },
  { lng: -116.7654, lat: 51.7846 },
  { lng: -116.7729, lat: 51.7748 },
  { lng: -116.7822, lat: 51.7689 },
  { lng: -116.7851, lat: 51.764 },
  { lng: -116.7915, lat: 51.764 },
  { lng: -116.7961, lat: 51.7581 },
  { lng: -116.8064, lat: 51.7505 },
  { lng: -116.8068, lat: 51.7471 },
  { lng: -116.8099, lat: 51.7451 },
  { lng: -116.8112, lat: 51.7406 },
  { lng: -116.8193, lat: 51.7386 },
  { lng: -116.8186, lat: 51.7356 },
  { lng: -116.808, lat: 51.7279 },
  { lng: -116.798, lat: 51.7236 },
  { lng: -116.7998, lat: 51.7213 },
  { lng: -116.8001, lat: 51.7169 },
  { lng: -116.807, lat: 51.7131 },
  { lng: -116.8088, lat: 51.7104 },
  { lng: -116.8097, lat: 51.7053 },
  { lng: -116.8193, lat: 51.7069 },
  { lng: -116.8204, lat: 51.7048 },
  { lng: -116.8366, lat: 51.7033 },
  { lng: -116.8386, lat: 51.7048 },
  { lng: -116.8491, lat: 51.7054 },
  { lng: -116.8536, lat: 51.7083 },
  { lng: -116.8608, lat: 51.7078 },
  { lng: -116.8703, lat: 51.7044 },
  { lng: -116.8765, lat: 51.7057 },
  { lng: -116.8782, lat: 51.7042 },
  { lng: -116.896, lat: 51.7027 },
  { lng: -116.9206, lat: 51.7098 },
  { lng: -116.917, lat: 51.7136 },
  { lng: -116.926, lat: 51.7175 },
  { lng: -116.93, lat: 51.7215 },
  { lng: -116.9264, lat: 51.7237 },
  { lng: -116.9265, lat: 51.7291 },
  { lng: -116.9325, lat: 51.7295 },
  { lng: -116.9436, lat: 51.7339 },
  { lng: -116.9445, lat: 51.7417 },
  { lng: -116.9543, lat: 51.7432 },
  { lng: -116.9557, lat: 51.7461 },
  { lng: -116.959, lat: 51.7478 },
  { lng: -116.9606, lat: 51.752 },
  { lng: -116.9764, lat: 51.7602 },
  { lng: -116.9728, lat: 51.7613 },
  { lng: -116.972, lat: 51.763 },
  { lng: -116.9737, lat: 51.7695 },
  { lng: -116.9632, lat: 51.7743 },
  { lng: -116.9661, lat: 51.7829 },
  { lng: -116.9649, lat: 51.7858 },
  { lng: -116.9661, lat: 51.7877 },
  { lng: -116.9612, lat: 51.7909 },
  { lng: -116.9684, lat: 51.7983 },
  { lng: -116.989, lat: 51.8111 },
  { lng: -116.9842, lat: 51.8203 },
  { lng: -116.9882, lat: 51.8229 },
  { lng: -116.9863, lat: 51.8268 },
  { lng: -116.9812, lat: 51.8284 },
  { lng: -116.9789, lat: 51.8336 },
  { lng: -116.9798, lat: 51.8356 },
  { lng: -116.9811, lat: 51.8367 },
  { lng: -117.0057, lat: 51.8361 },
  { lng: -117.0194, lat: 51.8429 },
  { lng: -117.0335, lat: 51.8564 },
  { lng: -117.0343, lat: 51.8598 },
  { lng: -117.0306, lat: 51.8645 },
  { lng: -117.0309, lat: 51.8689 },
  { lng: -117.0233, lat: 51.88 },
  { lng: -117.0144, lat: 51.8849 },
  { lng: -117.0108, lat: 51.8897 },
  { lng: -117.0195, lat: 51.8914 },
  { lng: -117.0313, lat: 51.8989 },
  { lng: -117.0329, lat: 51.9015 },
  { lng: -117.0467, lat: 51.9102 },
  { lng: -117.0523, lat: 51.9156 },
  { lng: -117.0593, lat: 51.9173 },
  { lng: -117.0777, lat: 51.9292 },
  { lng: -117.0862, lat: 51.9369 },
  { lng: -117.0976, lat: 51.9397 },
  { lng: -117.0968, lat: 51.9433 },
  { lng: -117.1021, lat: 51.9502 },
  { lng: -117.1009, lat: 51.953 },
  { lng: -117.1032, lat: 51.9565 },
  { lng: -117.1202, lat: 51.9557 },
  { lng: -117.1333, lat: 51.9683 },
  { lng: -117.1449, lat: 51.9727 },
  { lng: -117.1636, lat: 51.9726 },
  { lng: -117.1887, lat: 51.9766 },
  { lng: -117.196, lat: 51.9791 },
  { lng: -117.1972, lat: 51.9801 },
  { lng: -117.197, lat: 51.9877 },
  { lng: -117.2017, lat: 51.9929 },
  { lng: -117.2164, lat: 51.9973 },
  { lng: -117.2261, lat: 52.0086 },
  { lng: -117.2336, lat: 52.0118 },
  { lng: -117.232, lat: 52.0165 },
  { lng: -117.2227, lat: 52.0174 },
  { lng: -117.2168, lat: 52.0222 },
  { lng: -117.2147, lat: 52.0285 },
  { lng: -117.227, lat: 52.0361 },
  { lng: -117.232, lat: 52.0411 },
  { lng: -117.2403, lat: 52.0363 },
  { lng: -117.2503, lat: 52.0351 },
  { lng: -117.2521, lat: 52.0386 },
  { lng: -117.2706, lat: 52.0489 },
  { lng: -117.2684, lat: 52.0558 },
  { lng: -117.2945, lat: 52.0651 },
  { lng: -117.2971, lat: 52.0689 },
  { lng: -117.3051, lat: 52.0743 },
  { lng: -117.3021, lat: 52.0775 },
  { lng: -117.2962, lat: 52.0792 },
  { lng: -117.292, lat: 52.0854 },
  { lng: -117.2986, lat: 52.0943 },
  { lng: -117.3173, lat: 52.194 },
  { lng: -117.3272, lat: 52.1899 },
  { lng: -117.334, lat: 52.1486 },
  { lng: -117.3813, lat: 52.1377 },
  { lng: -117.5002, lat: 52.1443 },
  { lng: -117.5099, lat: 52.1578 },
  { lng: -117.5231, lat: 52.1582 },
  { lng: -117.6113, lat: 52.1443 },
  { lng: -117.6293, lat: 52.1748 },
  { lng: -117.6635, lat: 52.1979 },
  { lng: -117.7432, lat: 52.194 },
  { lng: -117.7413, lat: 52.203 },
  { lng: -117.8183, lat: 52.2264 },
  { lng: -117.8222, lat: 52.2464 },
  { lng: -117.8396, lat: 52.2741 },
  { lng: -117.7961, lat: 52.2925 },
  { lng: -117.778, lat: 52.3189 },
  { lng: -117.7529, lat: 52.3164 },
  { lng: -117.723, lat: 52.3556 },
  { lng: -117.7048, lat: 52.3575 },
  { lng: -117.7058, lat: 52.3653 },
  { lng: -117.7298, lat: 52.3823 },
  { lng: -117.7665, lat: 52.4177 },
  { lng: -117.8397, lat: 52.4211 },
  { lng: -117.8869, lat: 52.4261 },
  { lng: -117.9668, lat: 52.4697 },
  { lng: -117.988, lat: 52.5002 },
  { lng: -118.0035, lat: 52.4926 },
  { lng: -118.0133, lat: 52.4839 },
  { lng: -118.0218, lat: 52.4723 },
  { lng: -118.0185, lat: 52.4593 },
  { lng: -118.0211, lat: 52.4555 },
  { lng: -118.0421, lat: 52.459 },
  { lng: -118.0507, lat: 52.4571 },
  { lng: -118.0529, lat: 52.4496 },
  { lng: -118.0304, lat: 52.4381 },
  { lng: -118.0407, lat: 52.4185 },
  { lng: -118.0442, lat: 52.3985 },
  { lng: -118.138, lat: 52.4075 },
  { lng: -118.142, lat: 52.4024 },
  { lng: -118.164, lat: 52.3937 },
  { lng: -118.1821, lat: 52.3828 },
  { lng: -118.1789, lat: 52.3795 },
  { lng: -118.1783, lat: 52.3754 },
  { lng: -118.1801, lat: 52.3726 },
  { lng: -118.2007, lat: 52.3727 },
  { lng: -118.2128, lat: 52.3703 },
  { lng: -118.2193, lat: 52.3742 },
  { lng: -118.226, lat: 52.3803 },
  { lng: -118.2203, lat: 52.3851 },
  { lng: -118.2209, lat: 52.3976 },
  { lng: -118.2339, lat: 52.4029 },
  { lng: -118.2408, lat: 52.4029 },
  { lng: -118.2444, lat: 52.408 },
  { lng: -118.2399, lat: 52.4176 },
  { lng: -118.2398, lat: 52.427 },
  { lng: -118.2427, lat: 52.4325 },
  { lng: -118.2513, lat: 52.4398 },
  { lng: -118.2553, lat: 52.4495 },
  { lng: -118.1934, lat: 52.4778 },
  { lng: -118.2355, lat: 52.4902 },
  { lng: -118.2891, lat: 52.5385 },
  { lng: -118.2728, lat: 52.566 },
  { lng: -118.3326, lat: 52.5802 },
  { lng: -118.3336, lat: 52.6062 },
  { lng: -118.3525, lat: 52.6106 },
  { lng: -118.3545, lat: 52.6337 },
  { lng: -118.301, lat: 52.6541 },
  { lng: -118.2901, lat: 52.6777 },
  { lng: -118.3433, lat: 52.7089 },
  { lng: -118.3428, lat: 52.7386 },
  { lng: -118.4224, lat: 52.7758 },
  { lng: -118.413, lat: 52.806 },
  { lng: -118.3996, lat: 52.8245 },
  { lng: -118.4023, lat: 52.8425 },
  { lng: -118.387, lat: 52.8471 },
  { lng: -118.3889, lat: 52.8489 },
  { lng: -118.4032, lat: 52.8508 },
  { lng: -118.4445, lat: 52.8522 },
  { lng: -118.4525, lat: 52.8554 },
  { lng: -118.4584, lat: 52.8596 },
  { lng: -118.4612, lat: 52.8694 },
  { lng: -118.4559, lat: 52.8723 },
  { lng: -118.4494, lat: 52.8791 },
  { lng: -118.4483, lat: 52.8853 },
  { lng: -118.4768, lat: 52.9008 },
  { lng: -118.5001, lat: 52.9063 },
  { lng: -118.5109, lat: 52.9051 },
  { lng: -118.5222, lat: 52.8981 },
  { lng: -118.5294, lat: 52.9002 },
  { lng: -118.5437, lat: 52.9084 },
  { lng: -118.5619, lat: 52.9012 },
  { lng: -118.5695, lat: 52.8889 },
  { lng: -118.5754, lat: 52.8852 },
  { lng: -118.6081, lat: 52.8795 },
  { lng: -118.6138, lat: 52.8837 },
  { lng: -118.6143, lat: 52.9001 },
  { lng: -118.6067, lat: 52.904 },
  { lng: -118.6203, lat: 52.9137 },
  { lng: -118.621, lat: 52.9163 },
  { lng: -118.6134, lat: 52.936 },
  { lng: -118.6385, lat: 52.9519 },
  { lng: -118.6513, lat: 52.9555 },
  { lng: -118.6602, lat: 52.9639 },
  { lng: -118.6611, lat: 52.9726 },
  { lng: -118.6712, lat: 52.9783 },
  { lng: -118.6698, lat: 52.9834 },
  { lng: -118.6493, lat: 52.9915 },
  { lng: -118.649, lat: 53.0003 },
  { lng: -118.6408, lat: 52.9993 },
  { lng: -118.6526, lat: 53.0184 },
  { lng: -118.6498, lat: 53.0251 },
  { lng: -118.6553, lat: 53.0348 },
  { lng: -118.6688, lat: 53.0425 },
  { lng: -118.6797, lat: 53.0413 },
  { lng: -118.6857, lat: 53.035 },
  { lng: -118.6959, lat: 53.0328 },
  { lng: -118.7006, lat: 53.0444 },
  { lng: -118.7103, lat: 53.0522 },
  { lng: -118.7264, lat: 53.0602 },
  { lng: -118.7461, lat: 53.0523 },
  { lng: -118.7564, lat: 53.0438 },
  { lng: -118.7589, lat: 53.038 },
  { lng: -118.7762, lat: 53.0453 },
  { lng: -118.7591, lat: 53.0658 },
  { lng: -118.7698, lat: 53.0745 },
  { lng: -118.7544, lat: 53.0828 },
  { lng: -118.7487, lat: 53.0883 },
  { lng: -118.7488, lat: 53.0972 },
  { lng: -118.7368, lat: 53.1065 },
  { lng: -118.7295, lat: 53.1155 },
  { lng: -118.7323, lat: 53.1192 },
  { lng: -118.7725, lat: 53.133 },
  { lng: -118.784, lat: 53.1449 },
  { lng: -118.7805, lat: 53.1476 },
  { lng: -118.7793, lat: 53.1509 },
  { lng: -118.7865, lat: 53.1592 },
  { lng: -118.7954, lat: 53.1631 },
  { lng: -118.8058, lat: 53.1652 },
  { lng: -118.8173, lat: 53.1718 },
  { lng: -118.8234, lat: 53.1729 },
  { lng: -118.8315, lat: 53.183 },
  { lng: -118.8478, lat: 53.1859 },
  { lng: -118.8621, lat: 53.1909 },
  { lng: -118.8796, lat: 53.2059 },
  { lng: -118.9094, lat: 53.2097 },
  { lng: -118.916, lat: 53.2118 },
  { lng: -118.9234, lat: 53.2252 },
  { lng: -118.9475, lat: 53.2389 },
  { lng: -118.9558, lat: 53.2365 },
  { lng: -118.9653, lat: 53.2368 },
  { lng: -118.9752, lat: 53.2416 },
  { lng: -119.0238, lat: 53.2319 },
  { lng: -119.0262, lat: 53.2264 },
  { lng: -119.0183, lat: 53.2194 },
  { lng: -118.9994, lat: 53.2127 },
  { lng: -118.9994, lat: 53.1928 },
  { lng: -119.0086, lat: 53.1861 },
  { lng: -119.0199, lat: 53.1702 },
  { lng: -119.0288, lat: 53.1642 },
  { lng: -118.9999, lat: 53.1486 },
  { lng: -119.0251, lat: 53.1346 },
  { lng: -119.0246, lat: 53.1267 },
  { lng: -119.048, lat: 53.1268 },
  { lng: -119.0469, lat: 53.1449 },
  { lng: -119.0686, lat: 53.1575 },
  { lng: -119.0885, lat: 53.1655 },
  { lng: -119.1195, lat: 53.1618 },
  { lng: -119.1415, lat: 53.1817 },
  { lng: -119.146, lat: 53.1912 },
  { lng: -119.1756, lat: 53.1846 },
  { lng: -119.1961, lat: 53.1855 },
  { lng: -119.219, lat: 53.194 },
  { lng: -119.2332, lat: 53.1935 },
  { lng: -119.2327, lat: 53.1818 },
  { lng: -119.2428, lat: 53.1786 },
  { lng: -119.2571, lat: 53.1766 },
  { lng: -119.2532, lat: 53.1848 },
  { lng: -119.2515, lat: 53.199 },
  { lng: -119.2881, lat: 53.24 },
  { lng: -119.2914, lat: 53.25 },
  { lng: -119.3015, lat: 53.2498 },
  { lng: -119.3005, lat: 53.2562 },
  { lng: -119.3253, lat: 53.2696 },
  { lng: -119.3405, lat: 53.2871 },
  { lng: -119.3297, lat: 53.2966 },
  { lng: -119.356, lat: 53.3123 },
  { lng: -119.3469, lat: 53.3263 },
  { lng: -119.3559, lat: 53.3401 },
  { lng: -119.3907, lat: 53.3608 },
  { lng: -119.4061, lat: 53.3681 },
  { lng: -119.4104, lat: 53.3654 },
  { lng: -119.4367, lat: 53.3576 },
  { lng: -119.4592, lat: 53.3572 },
  { lng: -119.4816, lat: 53.3632 },
  { lng: -119.5001, lat: 53.3625 },
  { lng: -119.5, lat: 53.366 },
  { lng: -119.5175, lat: 53.3701 },
  { lng: -119.5335, lat: 53.3659 },
  { lng: -119.5485, lat: 53.3675 },
  { lng: -119.5785, lat: 53.3753 },
  { lng: -119.5984, lat: 53.3835 },
  { lng: -119.6045, lat: 53.3834 },
  { lng: -119.5975, lat: 53.3679 },
  { lng: -119.5983, lat: 53.3657 },
  { lng: -119.619, lat: 53.3638 },
  { lng: -119.6241, lat: 53.3596 },
  { lng: -119.6253, lat: 53.3635 },
  { lng: -119.6282, lat: 53.3656 },
  { lng: -119.6375, lat: 53.3691 },
  { lng: -119.6689, lat: 53.3678 },
  { lng: -119.6764, lat: 53.3712 },
  { lng: -119.6803, lat: 53.3781 },
  { lng: -119.6798, lat: 53.3819 },
  { lng: -119.6954, lat: 53.3906 },
  { lng: -119.7088, lat: 53.3884 },
  { lng: -119.7254, lat: 53.3888 },
  { lng: -119.7311, lat: 53.4022 },
  { lng: -119.7362, lat: 53.4085 },
  { lng: -119.7584, lat: 53.4264 },
  { lng: -119.76, lat: 53.4313 },
  { lng: -119.7568, lat: 53.4376 },
  { lng: -119.7585, lat: 53.4402 },
  { lng: -119.7715, lat: 53.4458 },
  { lng: -119.7811, lat: 53.4667 },
  { lng: -119.7906, lat: 53.4754 },
  { lng: -119.7906, lat: 53.4793 },
  { lng: -119.7794, lat: 53.4886 },
  { lng: -119.7803, lat: 53.4918 },
  { lng: -119.8001, lat: 53.5 },
  { lng: -119.8125, lat: 53.5 },
  { lng: -119.8302, lat: 53.5147 },
  { lng: -119.8329, lat: 53.5193 },
  { lng: -119.8465, lat: 53.5117 },
  { lng: -119.8502, lat: 53.5077 },
  { lng: -119.8539, lat: 53.4999 },
  { lng: -119.8702, lat: 53.5 },
  { lng: -119.8755, lat: 53.5063 },
  { lng: -119.8899, lat: 53.5125 },
  { lng: -119.8995, lat: 53.5191 },
  { lng: -119.9003, lat: 53.533 },
  { lng: -119.8623, lat: 53.5485 },
  { lng: -119.861, lat: 53.5565 },
  { lng: -119.8633, lat: 53.5616 },
  { lng: -119.8731, lat: 53.5714 },
  { lng: -119.8915, lat: 53.5777 },
  { lng: -119.8962, lat: 53.5815 },
  { lng: -119.9029, lat: 53.59 },
  { lng: -119.9059, lat: 53.5968 },
  { lng: -119.9107, lat: 53.6013 },
  { lng: -119.9232, lat: 53.6053 },
  { lng: -119.9304, lat: 53.6104 },
  { lng: -119.9259, lat: 53.6145 },
  { lng: -119.9027, lat: 53.6202 },
  { lng: -119.8939, lat: 53.6195 },
  { lng: -119.8794, lat: 53.6168 },
  { lng: -119.8541, lat: 53.6077 },
  { lng: -119.8294, lat: 53.6091 },
  { lng: -119.8167, lat: 53.6041 },
  { lng: -119.8017, lat: 53.6043 },
  { lng: -119.7931, lat: 53.6024 },
  { lng: -119.7875, lat: 53.5989 },
  { lng: -119.784, lat: 53.594 },
  { lng: -119.7762, lat: 53.5912 },
  { lng: -119.7592, lat: 53.591 },
  { lng: -119.7487, lat: 53.5934 },
  { lng: -119.7392, lat: 53.6022 },
  { lng: -119.7148, lat: 53.6117 },
  { lng: -119.7134, lat: 53.6146 },
  { lng: -119.7264, lat: 53.6283 },
  { lng: -119.7372, lat: 53.6368 },
  { lng: -119.7345, lat: 53.6632 },
  { lng: -119.7556, lat: 53.6649 },
  { lng: -119.7608, lat: 53.6716 },
  { lng: -119.7684, lat: 53.675 },
  { lng: -119.7736, lat: 53.6797 },
  { lng: -119.7755, lat: 53.6829 },
  { lng: -119.7732, lat: 53.6856 },
  { lng: -119.7767, lat: 53.6902 },
  { lng: -119.7838, lat: 53.6953 },
  { lng: -119.7868, lat: 53.6992 },
  { lng: -119.7867, lat: 53.7013 },
  { lng: -119.7929, lat: 53.7061 },
  { lng: -119.7974, lat: 53.7078 },
  { lng: -119.805, lat: 53.7066 },
  { lng: -119.8343, lat: 53.6971 },
  { lng: -119.8396, lat: 53.6974 },
  { lng: -119.8451, lat: 53.7025 },
  { lng: -119.842, lat: 53.708 },
  { lng: -119.8443, lat: 53.7147 },
  { lng: -119.8542, lat: 53.7136 },
  { lng: -119.8609, lat: 53.7153 },
  { lng: -119.8704, lat: 53.7148 },
  { lng: -119.8895, lat: 53.7105 },
  { lng: -119.9034, lat: 53.7098 },
  { lng: -119.908, lat: 53.7106 },
  { lng: -119.9157, lat: 53.7173 },
  { lng: -119.9132, lat: 53.7204 },
  { lng: -119.9008, lat: 53.723 },
  { lng: -119.8975, lat: 53.7307 },
  { lng: -119.9021, lat: 53.7384 },
  { lng: -119.8957, lat: 53.7449 },
  { lng: -119.8874, lat: 53.75 },
  { lng: -119.8926, lat: 53.7575 },
  { lng: -119.8858, lat: 53.7685 },
  { lng: -119.8856, lat: 53.7753 },
  { lng: -119.8882, lat: 53.7795 },
  { lng: -119.9423, lat: 53.7759 },
  { lng: -119.9592, lat: 53.7811 },
  { lng: -119.983, lat: 53.7972 },
  { lng: -119.9713, lat: 53.8016 },
  { lng: -119.9774, lat: 53.8054 },
  { lng: -120, lat: 53.8062 },
  { lng: -120, lat: 60 },
  { lng: -110, lat: 60 },
  { lng: -110.0001, lat: 55.3312 },
  { lng: -110.0057, lat: 54.7477 },
  { lng: -110.0057, lat: 54.3259 },
  { lng: -110.0044, lat: 54.2775 },
  { lng: -110.0057, lat: 54.237 },
  { lng: -110.0067, lat: 53.6245 },
  { lng: -110.0054, lat: 53.4786 },
  { lng: -110.0067, lat: 53.0542 },
  { lng: -110.0046, lat: 52.9872 },
  { lng: -110.0054, lat: 52.6036 },
  { lng: -110.003, lat: 52.2737 },
  { lng: -110.0051, lat: 52.0556 },
  { lng: -110.0041, lat: 51.8816 },
  { lng: -110.0051, lat: 51.679 },
  { lng: -110.003, lat: 51.4614 },
  { lng: -110.0051, lat: 51.2699 },
  { lng: -110.005, lat: 50.9206 },
  { lng: -110.003, lat: 50.8338 },
  { lng: -110.0042, lat: 50.7174 },
  { lng: -110.0034, lat: 50.4833 },
  { lng: -110.0035, lat: 50.4546 },
  { lng: -110.0057, lat: 50.426 },
  { lng: -110.0078, lat: 50.2798 },
  { lng: -110.0072, lat: 50.2219 },
  { lng: -110.0038, lat: 50.2222 },
  { lng: -110.005, lat: 49.0001 },
  { lng: -112.4096, lat: 49.0001 },
];

export default albertaCoords;
