import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';

@Directive({
  selector: '[appPreventModalFlicker]',
  standalone: true,
})
export class PreventModalFlickerDirective implements OnChanges {
  @Input() appPreventModalFlicker: boolean;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if ('appPreventModalFlicker' in changes && changes['appPreventModalFlicker'].previousValue != undefined) {
      const isOpen = changes['appPreventModalFlicker'].currentValue;
      if (isOpen) {
        const scrollbarWidth = window.innerWidth - document.documentElement.clientWidth;
        // Hide the scrollbar by adding padding-right to the body
        document.body.style.paddingRight = scrollbarWidth + 'px';
        document.body.style.overflow = 'hidden'; // Hide overflow to prevent double scrollbars
      } else {
        //Remove the padding-right from the body to show the scrollbar
        document.body.style.paddingRight = '';
        document.body.style.overflow = ''; // Restore overflow
      }
    }
  }
}
