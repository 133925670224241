import { Component, EventEmitter, Input, Output } from '@angular/core';
@Component({
  selector: 'app-incomplete-modal',
  templateUrl: './incomplete-modal.component.html',
  styleUrls: ['./incomplete-modal.component.scss'],
})
export class IncompleteModalComponent {
  @Input() open: boolean;
  @Input() title: string;
  @Input() incompleteCategoryMessage?: string;
  @Input() incompleteProjectMessage?: string;
  @Input() secondLineMessage?: string;
  @Output() proceed = new EventEmitter<void>();
  @Output() close = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor() {}

  closeModal() {
    this.close.emit();
    document.body.style.overflow = 'auto';
    this.open = false;
  }

  proceedClicked() {
    this.proceed.emit();
    document.body.style.overflow = 'auto';
    this.open = false;
  }
}
