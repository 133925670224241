<div>
  <h2 id="app-funding-heading-h2">Application funding</h2>
  <h3 id="app-funding-subheading">Review your original funding source selections below and adjust if needed.</h3>

  <div>
    <goa-callout
      type="emergency"
      heading="{{ 'errorCalloutHeading' | res: pageId }}"
      *ngIf="
        showErrorFieldsCallout ||
        shouldShowOtherSourcesOfFundingYesButNoSourcesSelected ||
        shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty
      "
    >
      <div *ngIf="showErrorFieldsCallout">
        <ng-container *ngFor="let uiDef of pageUiDefs">
          <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link"
            >{{ uiDef.labelText }}<br
          /></a>
        </ng-container>
      </div>

      <div
        *ngIf="
          shouldShowOtherSourcesOfFundingYesButNoSourcesSelected ||
          shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty
        "
      >
        <a (click)="jumpToField('question-yes-checkbox')" class="validation-message-link"
          >{{ 'areThereOtherSourcesErrorMsg' | res: pageId }}<br
        /></a>
      </div>
    </goa-callout>
  </div>

  <form [formGroup]="functionalCategoriesSources">
    <goa-form-item
      id="func-cat-radio-other-source-of-funding"
      class="question1-section"
      label="Are there other sources of provincial or federal grant funding?"
    >
      <goa-radio-group
        name="areThereOtherSourcesOfFunding"
        id="areThereOtherSourcesOfFunding"
        formControlName="areThereOtherSourcesOfFunding"
        goaValue
        [error]="shouldShowOtherFundingSourceValidationMessage"
        (click)="otherSourcesOfFundingYesClicked()"
      >
        <goa-radio-item name="areThereOtherSourcesOfFunding" label="Yes" [value]="false"> </goa-radio-item>
      </goa-radio-group>

      <goa-form-item
        id="question-yes-checkbox"
        class="nestedStepOne"
        label="Select all that apply."
        name="questionYesItem"
        *ngIf="areThereOtherSourcesOfFunding"
      >
        <div style="height: 4px"></div>
        <goa-checkbox
          name="isFundingFromAMWWP"
          formControlName="isFundingFromAMWWP"
          text="Alberta Municipal Water/Wastewater Partnership (AMWWP)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
          (_change)="handleChecked()"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromCCBF"
          formControlName="isFundingFromCCBF"
          text="Canada Community-Building Fund (CCBF)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
          (_change)="handleChecked()"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromSTIP"
          formControlName="isFundingFromSTIP"
          text="Strategic Transportation Infrastructure Program (STIP)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
          (_change)="handleChecked()"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromWaterForLife"
          formControlName="isFundingFromWaterForLife"
          text="Water for Life (W4L)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
          (_change)="handleChecked()"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromMSI"
          formControlName="isFundingFromMSI"
          text="Municipal Sustainability Initiative (MSI)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
          (_change)="handleChecked()"
        ></goa-checkbox>

        <goa-checkbox
          class="last-checkbox"
          name="isFundingFromOther"
          formControlName="isFundingFromOther"
          text="Other (specify)"
          checked="false"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
          (_change)="handleChecked()"
        ></goa-checkbox>

        <goa-form-item
          class="nestedStepTwo specify-last"
          label="Please specify"
          *ngIf="isFundingFromOther"
          [ngClass]="{ 'checkbox-nested-input': !shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty }"
        >
          <goa-input
            type="text"
            formControlName="fundingFromOther"
            goaValue
            width="600px"
            name="fundingFromOther"
            value=""
            [error]="shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty"
            (blur)="onFcsTxtBoxBlur('fundingFromOther')"
          ></goa-input>
          <div class="field-validation-error-msg" *ngIf="shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty">
            <goa-icon size="small" type="warning" theme="filled"></goa-icon>
            <span>{{ 'otherNoSpecifiedErrorMsg' | res: pageId }}</span>
          </div>
        </goa-form-item>

        <div class="field-validation-error-msg" *ngIf="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ 'noSourceSelectedErrorMsg' | res: pageId }}</span>
        </div>
      </goa-form-item>

      <goa-radio-group
        name="questionNo"
        id="questionNo"
        formControlName="noOtherSourceOfFunding"
        goaValue
        [error]="shouldShowOtherFundingSourceValidationMessage"
        (click)="handleOpen($event)"
      >
        <goa-radio-item name="questionNo" label="No" [value]="false"></goa-radio-item>
      </goa-radio-group>

      <div class="field-validation-error-msg" *ngIf="shouldShowOtherFundingSourceValidationMessage">
        <goa-icon size="small" type="warning" theme="filled"></goa-icon>
        <span>{{ 'otherSourceOfFundingErrorMsg' | res: pageId }}</span>
      </div>
      <div class="help-msg-text">
        Indicate if there are funding sources from other provincial or federal grant programs for all projects on this
        application.
      </div>
    </goa-form-item>
    <div *ngIf="shouldShowReasonTextBox">
      <div *ngIf="UiDefReasonForUpdate; let UiDef">
        <goa-form-item
          [attr.id]="UiDef.idWrap"
          [label]="UiDef.labelText"
          [helptext]="UiDef.helptext"
          requirement="required"
          labelsize="regular"
        >
          <goa-textarea
            rows="4"
            width="37.5rem"
            id="reason-textarea"
            [showcount]="true"
            countby="character"
            maxcount="250"
            name="{{ UiDef.name }}"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            [error]="UiDef.errorMsg"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
          ></goa-textarea>
          <div class="field-validation-error-msg" *ngIf="UiDef.errorMsg">
            <goa-icon size="small" type="warning" theme="filled"></goa-icon>
            <span>{{ 'reasonForUpdateNull' | res: pageId }}</span>
          </div>
        </goa-form-item>
      </div>
    </div>
  </form>

  <app-no-grant-funding-dialog [isOpen]="showDialog" (closeDialog)="handleClose($event)"> </app-no-grant-funding-dialog>
</div>
