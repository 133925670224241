import { Component } from '@angular/core';

@Component({
  selector: 'app-cash-flow-guide',
  templateUrl: './cash-flow-guide.component.html',
  styleUrls: ['./cash-flow-guide.component.scss'],
})
export class CashFlowGuideComponent {
  txtHeading: string = 'To complete any Application cash flow updates, follow these 5 simple steps:';
  txtItems: string[] = [
    '1. Enter your application contact information',
    '2. Review your application funding and make any updates if necessary',
    '3. Make any necessary updates to your projects',
    "4. Review the summary of any updates you've made",
    "5. Certify and submit your updates - and you're done!",
  ];
  txtBottom = 'You can view all your cash flow updates (including drafts) at any time.';
}
