<goa-one-column-layout *ngIf="layout === Layout.Application">
  <section slot="header">
    <goa-microsite-header
      [type]="portalEnv === 'PROD' ? 'beta' : 'alpha'"
      [version]="portalVersion"
    ></goa-microsite-header>
    <app-pot-header></app-pot-header>
    <app-grant-application-banner></app-grant-application-banner>
  </section>
  <router-outlet></router-outlet>
  <section slot="footer">
    <app-pot-footer></app-pot-footer>
  </section>
</goa-one-column-layout>
<app-error-page-layout *ngIf="layout === Layout.Error"></app-error-page-layout>
<app-popup-layout *ngIf="layout === Layout.Popup"></app-popup-layout>
