import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ExternalUserDto } from '@app-com/api/models';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthStateService {
  private authenticatedUser = new BehaviorSubject<ExternalUserDto | null>(null);
  private authenticatedUser$: Observable<ExternalUserDto | null> = this.authenticatedUser.asObservable();

  private homeMenuClicked = new BehaviorSubject<string>('');
  private homeMenuClicked$: Observable<string> = this.homeMenuClicked.asObservable();

  constructor(private http: HttpClient) {
    const lastMenuClicked = sessionStorage.getItem('home_menu_clicked_name');
    if (lastMenuClicked && lastMenuClicked.length > 2) {
      const lastMenuPair = lastMenuClicked.split(';;');
      if (lastMenuClicked[0]?.length > 0 && Date.now() - Number(lastMenuPair[1]) < 30000) {
        this.setHomeMenuClicked(lastMenuPair[0]);
      }
    }
  }

  public getAuthenticatedUser(): Observable<ExternalUserDto | null> {
    return this.authenticatedUser$;
  }

  public setAuthenticatedUser(user: ExternalUserDto | null) {
    this.authenticatedUser.next(user);
  }

  public getHomeMenuClicked(): Observable<string> {
    return this.homeMenuClicked$;
  }

  public setHomeMenuClicked(name: string) {
    const saveData = name + ';;' + Date.now();
    sessionStorage.setItem('home_menu_clicked_name', saveData);

    this.homeMenuClicked.next(name);
  }
}
