import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoButtonAction,
  SetEditSepoCurrentStep,
  SetEditSepoStepperStatus,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';

@Component({
  selector: 'app-edit-sepo-contact-info',
  templateUrl: './edit-sepo-contact-info.component.html',
  styleUrl: './edit-sepo-contact-info.component.scss',
})
export class EditSepoContactInfoComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;

  constructor(
    private store: Store,
    private router: Router,
  ) {
    super();
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
                [EditSepoSequence.Certification]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.router.navigate(['edit-sepo/asset-management-and-funds']);
          }
        }
      }),
    );
  }

  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    return true;
  }
}
