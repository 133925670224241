import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoButtonAction,
  SetEditSepoCurrentStep,
  SetEditSepoStepperStatus,
  SetSEPOContact,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { debounceTime, Observable, Subscription, takeWhile } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import { SepoExtDto } from '@app-com/api/models';

@Component({
  selector: 'app-edit-sepo-contact-info',
  templateUrl: './edit-sepo-contact-info.component.html',
  styleUrl: './edit-sepo-contact-info.component.scss',
})
export class EditSepoContactInfoComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  public PageIds = PageIds;
  CurrentPageDef = PageIds.sepo.contact;
  public ValidationPatterns = ValidationPatterns;

  contactForm: FormGroup;
  UiDefFirstName: UiFieldCtrDef;
  UiDefLastName: UiFieldCtrDef;
  UiDefPhone: UiFieldCtrDef;
  UiDefEmail: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  isAlive = true;
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;

  @Select(EditSepoState.getSepoDetails)
  sepo$: Observable<SepoExtDto>;

  constructor(
    private store: Store,
    private router: Router,
    private formBuilder: FormBuilder,
  ) {
    super();
  }
  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
                [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted,
                [EditSepoSequence.Certification]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.router.navigate(['edit-sepo/asset-management-and-funds']);
          }
        }

        if (buttonAction && buttonAction.indexOf('save') >= 0) {
          if (this.validateOnNext()) {
            this.router.navigate(['/view-sepo']);
          }
        }
      }),
    );

    this.sub.add(
      this.sepo$?.subscribe((sepoDetails) => {
        this.contactForm?.patchValue({
          [this.UiDefFirstName.nameCtr]: sepoDetails.contactFirstName ?? '',
          [this.UiDefLastName.nameCtr]: sepoDetails.contactLastName ?? '',
          [this.UiDefPhone.nameCtr]: sepoDetails.contactPhoneNumber ?? '',
          [this.UiDefEmail.nameCtr]: sepoDetails.contactEmailAddress ?? '',
        });
      }),
    );

    this.createContactForm();
    this.getSepoContactDataFromStore();
    this.pageUiDefs.forEach((uiDef) => {
      uiDef.formCtr.valueChanges
        .pipe(takeWhile(() => this.isAlive))
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (uiDef.focusedInNonBlankOrErrorField || uiDef.focusedOutFieldByTrueBlurEvent) {
            CommUtilsService.getUiFieldErrorList(uiDef, uiDef.focusedOutFieldByTrueBlurEvent ?? false, 'valueChanges');
          }
        });
    });
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return baseHasError;
  }

  getSepoContact(): Pick<
    SepoExtDto,
    'contactFirstName' | 'contactLastName' | 'contactEmailAddress' | 'contactPhoneNumber'
  > {
    const uiValue = this.contactForm.value;
    return {
      contactFirstName: uiValue[this.UiDefFirstName.nameCtr] ?? '',
      contactLastName: uiValue[this.UiDefLastName.nameCtr] ?? '',
      contactEmailAddress: uiValue[this.UiDefEmail.nameCtr] ?? '',
      contactPhoneNumber: uiValue[this.UiDefPhone.nameCtr] ?? '',
    };
  }

  createContactForm() {
    this.contactForm = this.formBuilder.group({});
    this.UiDefFirstName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.firstName);
    this.contactForm.addControl(this.UiDefFirstName.nameCtr, this.UiDefFirstName.formCtr);

    this.UiDefLastName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.lastName);
    this.contactForm.addControl(this.UiDefLastName.nameCtr, this.UiDefLastName.formCtr);

    this.UiDefPhone = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.phone);
    this.contactForm.addControl(this.UiDefPhone.nameCtr, this.UiDefPhone.formCtr);

    this.UiDefEmail = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.email);
    this.contactForm.addControl(this.UiDefEmail.nameCtr, this.UiDefEmail.formCtr);

    this.pageUiDefs = [this.UiDefFirstName, this.UiDefLastName, this.UiDefPhone, this.UiDefEmail];

    this.contactForm.setValue({
      [this.UiDefFirstName.nameCtr]: '',
      [this.UiDefLastName.nameCtr]: '',
      [this.UiDefPhone.nameCtr]: '',
      [this.UiDefEmail.nameCtr]: '',
    });
  }

  getSepoContactDataFromStore() {
    const storeSepo = this.store.selectSnapshot(EditSepoState.getSepoDetails);

    if (storeSepo) {
      this.contactForm?.patchValue({
        [this.UiDefFirstName.nameCtr]: storeSepo.contactFirstName ?? '',
        [this.UiDefLastName.nameCtr]: storeSepo.contactLastName ?? '',
        [this.UiDefPhone.nameCtr]: storeSepo.contactPhoneNumber ?? '',
        [this.UiDefEmail.nameCtr]: storeSepo.contactEmailAddress ?? '',
      });
    }
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedOutFieldByTrueBlurEvent = true;
    }
    this.timeoutIds.push(
      setTimeout(() => {
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');
      }, 400),
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  acceptPhoneNumberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return (charCode >= 48 && charCode <= 57) || charCode == 45;
  }

  validateContact() {
    if (this.contactForm) {
      this.contactForm?.markAllAsTouched();

      this.pageUiDefs.forEach((uiDef) => {
        CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
      });
      return this.contactForm.valid;
    }
    return true;
  }

  autosave() {}

  validateOnPrevious(): boolean {
    return true;
  }

  validateOnNext(): boolean {
    if (this.validateContact()) {
      this.store.dispatch(new SetSEPOContact(this.getSepoContact()));
      return true;
    }
    this.jumpToField('gen-info-heading-1');
    return false;
  }
}
