import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'safeDollar',
})
export class SafeDollarPipe implements PipeTransform {
  formatOptions = {
    // style: 'currency',
    // currency: '$',
    minimumFractionDigits: 0,
  };
  dollarStr = new Intl.NumberFormat('en-CA', this.formatOptions);

  transform(val: number | string | undefined | null, defaultString = '$0'): string {
    if (val == null || val == undefined) {
      return defaultString;
    }
    if (typeof val == 'number') {
      // format to $123,456,789
      return '$' + this.dollarStr.format(val || 0);
    } else if (typeof val == 'string') {
      // convert to number, then
      // format to $123,456,789
      const numStr = val.replace(/[^0-9]/g, '');
      const numVal = parseInt(numStr, 10) ?? 0;
      return '$' + this.dollarStr.format(numVal);
    } else {
      return val + '';
    }
  }
}
