<div class="center-wrapper">
  <div class="centered-div">
    <div class="warning-icon-container">
      <goa-icon type="warning" size="xlarge"></goa-icon>
    </div>
    <div class="warning-icon-underline"></div>
    <div class="general-error-msg-title">We are experiencing a problem.</div>
    <div class="general-error-msg">
      <div>We are unable to load the site at this time. Please try again later.</div>
      <div>We apologize for the inconvenience and thank you for your patience.</div>
    </div>
    <div class="buttons-container">
      <div><goa-button type="secondary" (click)="returnToMAConnect()">Return to MAConnect</goa-button></div>
      <div class="reload-page-btn-container" (click)="reloadPage()">
        <goa-button type="primary">Reload page</goa-button>
      </div>
    </div>
  </div>
</div>
