<goa-modal
  id="modal"
  [open]="_isOpen"
  heading="{{ titleMainShow }}"
  width="{{ dlgMaxWidth }}"
  maxwidth="{{ dlgMaxWidth }}"
  [appPreventModalFlicker]="_isOpen"
>
  <h4 class="delete-question">{{ titleSubShow }}</h4>
  <div slot="actions">
    <div *ngIf="!showBothOnRight" class="flex-space-between">
      <goa-button type="secondary" (click)="onNoClicked()">{{ 'btnSignOut' | res: pageId }}</goa-button>
      <goa-button type="primary" (click)="onYesClicked()">{{ 'btnContinueSession' | res: pageId }}</goa-button>
    </div>
    <div *ngIf="showBothOnRight" class="flex-float-to-right">
      <goa-button type="secondary" (click)="onNoClicked()" class="pr-1rem">{{ 'btnSignOut' | res: pageId }}</goa-button>
      <goa-button type="primary" (click)="onYesClicked()">{{ 'btnContinueSession' | res: pageId }}</goa-button>
    </div>
  </div>
</goa-modal>
