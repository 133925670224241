import { AfterViewInit, Component, OnInit } from '@angular/core';
import { AuthService } from '@app-com/api/services';
import { environment } from '@app-pot-env/environment';
import { ClearContextOnLogout } from '@app-pot/store/actions/current-context.action';
import { Store } from '@ngxs/store';
import { take } from 'rxjs';

@Component({
  selector: 'app-logout',
  templateUrl: './logout.component.html',
  styleUrls: ['./logout.component.scss'],
})
export class LogoutComponent implements OnInit, AfterViewInit {
  constructor(
    private auth: AuthService,
    private store: Store,
  ) {}

  ngOnInit(): void {
    console.log('Project Portal logout init.');
  }

  ngAfterViewInit(): void {
    this.store
      .dispatch(new ClearContextOnLogout())
      .pipe(take(1))
      .subscribe(() =>
        this.auth.logout().subscribe({
          next: () => {
            window.location.href = environment.maConnectDashboard as string;
          },
          error: (err: unknown) => console.error(err),
          complete: () => console.log('logged out success'),
        }),
      );
  }
}
