import { Injectable } from '@angular/core';
import { visibleFeatures } from '@app-com/constants/feature-flags';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root',
})
export class FeatureFlagService {
  private flags: BehaviorSubject<{ [key: string]: boolean }> = new BehaviorSubject({});
  constructor() {
    // Initialize flags here or load from an API/local storage
    this.setInitialFlags();
  }
  private setInitialFlags() {
    Object.entries(visibleFeatures).forEach(([key, value]) => {
      this.setFlag(key, value);
    });
  }
  setFlag(flagName: string, isEnabled: boolean): void {
    const currentFlags = this.flags.value;
    this.flags.next({ ...currentFlags, [flagName]: isEnabled });
  }
  getFlag(flagName: string): Observable<boolean> {
    return this.flags.asObservable().pipe(map((flags) => !!flags[flagName]));
  }
  isEnabled(flagName: string): boolean {
    return !!this.flags.value[flagName];
  }
  getAllFlags(): Observable<{ [key: string]: boolean }> {
    return this.flags.asObservable();
  }
}
