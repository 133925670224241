<div id="allocations-payments-page">
  <div id="allocations-payments-title-row" class="row">
    <h1 id="allocations-payments-page-title" class="col-lg-5 col-md-12 col-sm-12 align-middle">
      {{ 'title' | res: pageId }}
    </h1>
    <div class="col-lg-7 col-sm-12">
      <div class="row">
        <div class="col-lg-8 offset-lg-4 justify-content-end search-bar-container" style="display: flex">
          <goa-input
            goaValue
            width="330px"
            id="search-field"
            [formControl]="searchFormCtrl"
            [value]="searchFormCtrl.value"
            type="search"
            handleTrailingIconClick="true"
            placeholder="{{ 'searchPlaceHolder' | res: pageId }}"
            (keyup.enter)="applySearchFilter()"
            leadingIcon="search"
          ></goa-input>
          <goa-button id="search-btn" #searchBtn (_click)="applySearchFilter()" type="primary">
            {{ 'searchLabel' | res: pageId }}
          </goa-button>
        </div>
      </div>
    </div>
  </div>
  <div id="total-amount">
    <div id="total-allocation">{{ 'totalAlloc' | res: pageId }}:</div>
    {{ totalAllocAmount | currency: '$' : 'symbol' : '1.0-0' }}
    <br />
    <div id="total-paid">{{ 'totalPaid' | res: pageId }}:</div>
    {{ totalPaidAmount | currency: '$' : 'symbol' : '1.0-0' }}
  </div>
  <app-lgff-goa-table
    [tableId]="pageId"
    [resPageId]="pageId"
    [colDef]="colDef"
    [tableData]="displayedData"
    [totalCount]="filteredData.length"
    [currentPage]="currentPage"
    [perPageCount]="perPageCount"
    [displayPaginator]="displayPaginator"
    [pageSizeOptions]="pageSizeOptions"
    (sortClicked)="handleSort($event)"
    (perPageChanged)="handlePerPageChange($event)"
    (pageChanged)="handlePageChange($event)"
  ></app-lgff-goa-table>
</div>

<ng-template #allocPaymentCurrencyColTemplate let-element let-column="column" let-colDef="colDef">
  {{ element[column] | currency: '$' : 'symbol' : '1.0-0' }}
</ng-template>

<ng-template #allocPaymentStatusColTemplate let-element let-column="column" let-colDef="colDef">
  <goa-badge
    [type]="colDef.misc.getBadgeType(element['paymentStatus'])"
    [content]="element['paymentStatusLabel']"
  ></goa-badge>
</ng-template>

<ng-template #allocPaymentCriteriaMetColTemplate let-element let-column="column" let-colDef="colDef">
  {{ element[column] + '' | res }}
</ng-template>
