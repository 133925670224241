<div>
  <div class="application-list-container">
    <goa-block class="loading-container" alignment="center">
      <goa-circular-progress variant="inline" size="large" [visible]="isLoading"></goa-circular-progress>
    </goa-block>
    <goa-table id="table-container" width="100%" (_sort)="handleSort($event)">
      <thead>
        <tr>
          <th class="detail-icon-column"></th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="status">{{ 'status' | res: pageId }}</goa-table-sort-header>
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="idTxt">{{ 'cashFlowUpdateId' | res: pageId }}</goa-table-sort-header>
          </th>
          <th class="minwidth-15rem">
            <goa-table-sort-header name="applicationName">{{ 'applicationName' | res: pageId }}</goa-table-sort-header>
          </th>
          <th class="minwidth-10rem">
            <goa-table-sort-header name="applicationIdTxt">
              {{ 'applicationNumber' | res: pageId }}</goa-table-sort-header
            >
          </th>

          <th class="minwidth-15rem goa-table-number-header">
            <goa-table-sort-header name="previousAcceptedFunding">
              {{ 'previousAcceptedFunding' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-13rem goa-table-number-header">
            <goa-table-sort-header name="updatedTotalFunding">
              {{ 'updatedTotalFunding' | res: pageId }}</goa-table-sort-header
            >
          </th>
          <th class="minwidth-10rem">{{ 'actions' | res: pageId }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngIf="!filteredApplications || filteredApplications.length === 0">
          <td></td>
          <td colspan="7">
            <div class="font-italic pl-1rem">No results found in Cash flow updates</div>
          </td>
        </tr>
        <ng-container *ngFor="let application of pageApplicationList">
          <tr>
            <td>
              <goa-icon
                class="action-icon"
                [type]="application.detailsShown ? 'chevron-down' : 'chevron-forward'"
                (click)="toggleApplicationDetails(application.id)"
              ></goa-icon>
            </td>
            <td>
              <goa-badge [type]="getBadgeType(application.status)" content="{{ application.statusTitle }}"></goa-badge>
            </td>
            <td>{{ application.idTxt }}</td>
            <td>{{ application.applicationName }}</td>
            <td>{{ application.applicationIdTxt }}</td>
            <td class="td-money-data-style">{{ application.previousAcceptedFunding | safeDollar }}</td>
            <td
              class="td-money-data-style"
              *ngIf="
                !(
                  application.updatedTotalFunding == application.previousAcceptedFunding &&
                  application.status === cashFlowUpdateStatusType.Draft
                )
              "
            >
              {{ application.updatedTotalFunding | safeDollar }}
            </td>
            <td
              class="td-money-data-style"
              *ngIf="
                application.updatedTotalFunding == application.previousAcceptedFunding &&
                application.status === cashFlowUpdateStatusType.Draft
              "
            >
              N/A
            </td>
            <td>
              <goa-block alignment="center" gap="l">
                <goa-tooltip content="Summary" position="bottom">
                  <goa-icon
                    class="action-icon"
                    type="information-circle"
                    (click)="onAppInfoClick(application.id)"
                  ></goa-icon
                ></goa-tooltip>
                <goa-tooltip content="Delete cash flow" position="bottom">
                  <goa-icon
                    [ngClass]="disableCashFlowEditDelete(application.status) ? 'inactive-icon' : 'action-icon'"
                    class="action-icon"
                    type="trash"
                    (click)="onCashFlowUpdateDeleteClicked(application.id)"
                  ></goa-icon
                ></goa-tooltip>
                <goa-tooltip content="Update cash flow" position="bottom">
                  <goa-icon
                    [ngClass]="disableCashFlowEditDelete(application.status) ? 'inactive-icon' : 'action-icon'"
                    class="action-icon"
                    type="pencil"
                    theme="outline"
                    size="medium"
                    (click)="onEditClick(application.id)"
                  ></goa-icon
                ></goa-tooltip>
              </goa-block>
            </td>
          </tr>
          <tr *ngIf="application.detailsShown" class="projects-container">
            <td></td>
            <td colspan="7">
              <ng-container *ngIf="application.projects?.length === 0; else projectList">
                <div class="font-italic pl-1rem">No Projects found for expanded row.</div>
              </ng-container>
              <ng-template #projectList>
                <table>
                  <thead>
                    <tr>
                      <th width="168px">Project name</th>
                      <th width="145px">Project number</th>
                      <th width="217px">Primary capital asset</th>
                      <th width="233px">Additional capital asset</th>
                      <th width="165px" class="numeric-column">Previous accepted LGFF funding</th>
                      <th width="108px">Previous accepted anticipated start</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container *ngFor="let project of application.projects; index as ii">
                      <tr>
                        <td>{{ project.projectName }}</td>
                        <td>{{ project.projectIdTxt }}</td>
                        <td>{{ getCapitalAssetTypeTitle(project.capitalAssetTypeId) }}</td>
                        <td>{{ getAdditionalCapitalAssetTypeTitle(project) }}</td>
                        <td class="numeric-column">
                          {{ project.amountRequestedFromLGFF_Previous ?? 0 | safeDollar }}
                        </td>
                        <td>
                          {{ CommUtilsService.getDateStrMonDdYear(project.anticipatedStartDate_Previous!) }}
                        </td>
                      </tr>
                    </ng-container>
                  </tbody>
                </table>
              </ng-template>
            </td>
          </tr>
        </ng-container>
      </tbody>
    </goa-table>
    <div style="height: 1rem"></div>
    <goa-block alignment="center" style="font-size: 18px">
      <goa-block mb="m" alignment="center">
        <span>Show</span>
        <goa-dropdown (_change)="handlePerPageChange($event)" [value]="perPageCount" width="8.5ch" relative="true">
          <goa-dropdown-item *ngFor="let size of pageSizeOptions" value="{{ size }}"></goa-dropdown-item>
        </goa-dropdown>
        <span style="width: 100px">
          of
          {{ applications.length }} items</span
        >
      </goa-block>
      <goa-spacer hspacing="fill"></goa-spacer>
      <goa-pagination
        *ngIf="displayPaginator"
        [itemcount]="filteredApplications.length"
        [perpagecount]="perPageCount"
        [pagenumber]="currentPage"
        (_change)="handlePageChange($event)"
      ></goa-pagination>
    </goa-block>
  </div>
</div>
<app-lgff-goa-modal
  [id]="'cash-flow-update-delete-modal'"
  [pageId]="pageId"
  [headingId]="modalCashFlowUpdateDeleteHeadingId"
  [open]="openCashFlowUpdateDeleteModal"
  [actionBtnNumber]="2"
  [okBtnLabel]="modalOkCashFlowUpdateDeleteBtnLabel"
  [cancelBtnLabel]="modalCancelBtnLabel"
  [modalContentStringId]="modalCashFlowUpdateDeleteContentStringId"
  (okBtnClickedEvent)="onCashFlowUpdateDeleteClickedEvent()"
  (onModalClosed)="closeCashFlowUpdateDeleteModal()"
>
</app-lgff-goa-modal>

<div *ngIf="currentCashFlowUpdateId" id="divCashFlowUpdateSummaryModal">
  <goa-modal id="summaryModal" [open]="openSummaryModal" maxwidth="1084px" [closable]="true" (_click)="onClose()">
    <app-cash-flow-update-view
      [cfId]="currentCashFlowUpdateId"
      [orgId]="organizationIdSelected"
      [viewType]="viewType"
      (PrintContent)="PrintContent()"
      (closeModal)="onClose()"
    ></app-cash-flow-update-view>
  </goa-modal>
</div>
