import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * The CommaFormatNumberDirective automatically
 * formats numeric input by adding commas to separate thousands. It enforces input
 * validation to allow only positive integers while disallowing non-numeric characters,
 * as well as negative values and decimals.
 */
@Directive({
  standalone: true,
  selector: '[commaFormatNumber]',
})
export class CommaFormatNumberDirective {
  @Input() allowZero: boolean = true;

  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event']) onBlur() {
    const inputElement = this.el.nativeElement.shadowRoot.querySelector('input');
    if (inputElement) {
      const inputValue = inputElement.value ?? '';
      if (inputValue && inputValue.length > 0) {
        const parsedNumber = parseFloat(inputValue.replace(/,/g, ''));
        if (parsedNumber === 0 && !this.allowZero) {
          inputElement.value = '';
        }
      }
    }
  }

  @HostListener('input') onInput() {
    const input = this.el.nativeElement.shadowRoot.querySelector('input')?.value ?? '';
    if (input && input.length > 0) {
      const numericValue = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      const formattedValue = formatNumberWithCommas(numericValue);
      this.el.nativeElement.shadowRoot.querySelector('input').value = formattedValue;
    }
  }
}

/**
 * This function can format positive integers with commas to separate thousands when
 * populating default values or editing forms from a TypeScript file.
 * @param value
 * @returns
 */
export function formatNumberWithCommas(value: string | number | undefined): string | null {
  if (value == null || value == undefined) return null;
  const valueString = value.toString();

  // Remove leading zeros except for a single zero
  const trimmedValueString = valueString.replace(/^0+(?!$)/, '');

  const absoluteValueString = !isNaN(Number(trimmedValueString)) ? Math.abs(Number(trimmedValueString)).toString() : '';

  const parts = [];
  const length = absoluteValueString.length;
  for (let i = length - 1, j = 1; i >= 0; i--, j++) {
    parts.unshift(absoluteValueString[i]);
    if (j % 3 === 0 && i !== 0) {
      parts.unshift(', ');
    }
  }
  const sign = !isNaN(Number(trimmedValueString)) && Number(trimmedValueString) < 0 ? '-' : '';
  return sign + parts.join('');
}
