<footer>
  <div style="padding: 48px 72px 48px 72px">
    <div class="topHeader">{{ 'navHeader' | res: pageId }}</div>
    <goa-divider mt="m" mb="m"></goa-divider>
    <div class="nav-table" width="100%">
      <div class="row">
        <div class="nav-header column">{{ 'subHeading1' | res: pageId }}</div>
        <div class="nav-header column">{{ 'subHeading2' | res: pageId }}</div>
      </div>
      <div class="row">
        <div class="column">{{ 'subHeading1Phone' | res: pageId }}</div>
        <div class="column">{{ 'subHeading2Phone' | res: pageId }}</div>
      </div>
      <div class="row">
        <a class="column" href="mailto:MA.LGFFCapital@gov.ab.ca">{{ 'subHeading1Email' | res: pageId }}</a>
        <a class="column" href="mailto:MA.LGFFAccess@gov.ab.ca">{{ 'subHeading2Email' | res: pageId }}</a>
        <goa-divider mb="l"></goa-divider>
      </div>
    </div>
    <div class="row">
      <div class="column">
        <span class="links">
          <a target="_blank" href="https://www.alberta.ca/usingthissite">Using Alberta.ca</a>
          <a target="_blank" href="https://www.alberta.ca/disclaimer">Disclaimer</a>
          <a target="_blank" href="https://www.alberta.ca/privacystatement">Privacy</a>
        </span>
      </div>
      <div class="column align-logo">
        <span>
          <a target="_blank" href="https://www.alberta.ca/">
            <app-svg-alberta-logo></app-svg-alberta-logo>
          </a>
        </span>
      </div>
    </div>
    <div class="underLogoText">© {{ currentYear }} Government of Alberta</div>
  </div>
  <div id="footerunderline"></div>
</footer>
