import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { ProjectRecordVm } from '../../models';
import { ProjectFormComponent } from '../project-form/project-form.component';
import { MapService } from '@app-pot/shared/services/map.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'project-edit',
  templateUrl: './project-edit.component.html',
  styleUrls: ['./project-edit.component.scss'],
})
export class ProjectEditComponent {
  @ViewChild(ProjectFormComponent) projectFormComponentRef!: ProjectFormComponent;
  @Input() isOpen = false;
  @Input() project?: ProjectRecordVm;

  @Output() afterCloseHandler = new EventEmitter<void>();
  @Output() afterSaveHandler = new EventEmitter<ProjectRecordVm>();
  isMapVisible: boolean;
  sub = new Subscription();
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  // eslint-disable-next-line @typescript-eslint/no-empty-function
  constructor(private mapService: MapService) {
    this.sub.add(
      this.mapService.getEditModeMapVisibility().subscribe((isVisible) => {
        this.isMapVisible = isVisible;
      }),
    );
  }

  closeModal() {
    this.isOpen = false;
    document.body.style.overflow = 'auto';
    this.mapService.setEditModeMapVisibility(false);
    this.mapService.setMapVisibility(true);
    this.afterCloseHandler.emit();
  }

  saveProject() {
    if (this.projectFormComponentRef.hasProjectFormAnyError(true)) {
      this.timeoutIds.push(
        setTimeout(() => {
          this.jumpToField('edit-project-form-callout-top');
        }, 200),
      );
      return;
    }
    const updatedProject = this.projectFormComponentRef.getProjectFormValue();
    this.afterSaveHandler.emit(updatedProject);
    this.closeModal();
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
      // window.scrollTo(0, fieldElement.offsetTop ?? 0);
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }
  ngOnDestroy(): void {
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }
}
