<div class="snack-container">
  <div class="icon-tick">
    <goa-icon type="checkmark-circle"></goa-icon>
  </div>
  <div class="snack-message-container">
    <div>{{ data.message }}</div>
    <div *ngIf="data.scrollToTopId">
      <div class="scroll-to-top" (click)="scrollToTop()">Scroll to top</div>
      {{ data.scrollToTopFor }}
    </div>
  </div>
</div>
<goa-icon-button
  class="close-button"
  *ngIf="data.showCloseButton"
  icon="close"
  variant="destructive"
  (click)="close()"
></goa-icon-button>
