/* tslint:disable */
/* eslint-disable */
export enum SepoStageType {
  Draft = 'Draft',
  Received = 'Received',
  UnderReview = 'UnderReview',
  Recommended = 'Recommended',
  RecommendedInBatch = 'RecommendedInBatch',
  InManagerReview = 'InManagerReview',
  Certified = 'Certified',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
