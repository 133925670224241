import { CreateCashFlowUpdateExtDto } from '@app-com/api/models';
import { FormSequence } from '@app-pot/features/grant-application/models/enums';
import { CashFlowFormSequence } from '@app-pot/features/cash-flow-updates/enum/cash-flow-form-sequence';

export class SetCurrentOrganizationId {
  static readonly type = '[CurrentContext] SetCurrentOrganizationId';
  constructor(public payload: number) {}
}

export class SetCurrentApplicationDraftId {
  static readonly type = '[CurrentContext] SetCurrentApplicationDraftId';
  constructor(public payload?: number) {}
}

export class SetCurrentApplicationDraftStep {
  static readonly type = '[CurrentContext] SetCurrentApplicationDraftStep';
  constructor(public payload?: FormSequence) {}
}

export class ClearContextOnLogout {
  static readonly type = '[CurrentContext] ClearContextOnLogout';
  constructor() {}
}

export class ClearApplicationDraftContext {
  static readonly type = '[CurrentContext] ClearApplicationDraftContext';
  constructor() {}
}

export class AutoSaveCurrentCashFlowContact {
  static readonly type = '[CurrentContext] Auto save Current CashFlow Contact';
  constructor(public payload: Partial<CreateCashFlowUpdateExtDto>) {}
}
export class SetCurrentCashFlowUpdateId {
  static readonly type = '[CurrentContext] SetCurrentCashFlowUpdateId';
  constructor(public payload?: number) {}
}

export class SetCurrentCashFlowUpdateStep {
  static readonly type = '[CurrentContext] SetCurrentCashFlowUpdateStep';
  constructor(public payload?: CashFlowFormSequence) {}
}

export class SetCurrentSEPOId {
  static readonly type = '[CurrentContext] SetCurrentSEPOId';
  constructor(public payload?: number) {}
}

export class SetCurrentSEPOStep {
  static readonly type = '[CurrentContext] SetCurrentSEPOStep';
  constructor(public payload?: CashFlowFormSequence) {}
}

export class SetCurrentCashFlowApplicationId {
  static readonly type = '[CurrentContext] SetCurrentCashFlowApplicationId';
  constructor(public payload?: number) {}
}

export class SetCurrentCashFlowApplicationTitle {
  static readonly type = '[CurrentContext] SetCurrentCashFlowApplicationTitle';
  constructor(public payload?: string) {}
}

export class ClearCashFlowUpdateContext {
  static readonly type = '[CurrentContext] ClearCashFlowUpdateContext';
  constructor() {}
}
