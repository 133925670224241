<div class="form">
  <h2 id="certification-form-h2">{{ 'heading1' | res: pageId }}</h2>
  <h3 id="certification-form-h3">{{ 'heading2' | res: pageId }}</h3>
  <div class="mb-56px" *ngIf="!certificationObject.valid && certificationObject.touched">
    <goa-callout
      type="emergency"
      heading="{{ 'errorCalloutHeading' | res: pageId }}"
      *ngIf="!certificationObject.valid && certificationObject.touched"
    >
      <a (click)="jumpToField('certification-form-acceptance')" class="validation-message-link"
        >{{ 'sectionHeading' | res: pageId }}<br
      /></a>
    </goa-callout>
  </div>
  <form [formGroup]="certificationObject">
    <div style="width: 700px; margin-bottom: 28px" id="certification-form-acceptance">
      <goa-form-item
        id="certification-form-item"
        style="padding-bottom: 12px; line-height: 28px"
        label="{{ 'sectionHeading' | res: pageId }}"
      >
        <goa-radio-group
          name="acceptanceRadio"
          id="acceptanceRadio"
          formControlName="acceptanceRadio"
          goaValue
          [error]="!certificationObject.valid && certificationObject.touched"
        >
          <goa-radio-item
            name="selfAcceptanceRadio"
            label="{{ getSelfAcceptance() }}"
            [value]="acceptanceRadio.Cao"
          ></goa-radio-item>
          <goa-radio-item
            name="representativeAcceptanceRadio"
            label="{{ getRepresentativeAcceptance() }}"
            [value]="acceptanceRadio.CaoRepresentative"
          ></goa-radio-item>
        </goa-radio-group>
        <div class="field-validation-error-msg" *ngIf="!certificationObject.valid && certificationObject.touched">
          <goa-icon type="warning" theme="filled"></goa-icon>
          {{ 'selectACertificationErrorMsg' | res: pageId }}
        </div>
      </goa-form-item>
    </div>
    <goa-table width="979px;">
      <tbody>
        <tr class="disclaimer-section">
          <td>
            <h3>{{ 'legalDisclaimerTitleTxt' | res: pageId }}</h3>
            {{ 'legalDisclaimerTxt' | res: pageId }}
            <a href="mailto:{{ 'email' | res: pageId }}">{{ 'email' | res: pageId }}</a
            >.
          </td>
        </tr>
      </tbody>
    </goa-table>
    <div style="height: 48px"></div>
    <app-download-draft-application-pdf
      textPosition="after"
      [linkText]="'downloadPdfLabel' | res: pageId"
      [applicationId]="applicationDraftId ?? 0"
    ></app-download-draft-application-pdf>
    <div style="height: 101px"></div>
  </form>
</div>
