import { Injectable } from '@angular/core';
import { LookupValueService, OrganizationService } from '@app-com/api/services';
import { Action, Selector, State, StateContext } from '@ngxs/store';
import { tap } from 'rxjs';
import {
  LoadAssetConditionRankings,
  LoadCapitalAssetTypes,
  LoadAllCapitalAssetTypes,
  LoadFunctionalCategoryTypes,
  LoadPrimaryOutcomes,
  LoadOrganizations,
} from '../actions/lookup-value.action';

export interface LookupValue {
  id: number;
  title: string;
}

export interface AssetConditionRankingLV extends LookupValue {
  description: string;
}

export interface CapitalAssetTypeLV extends LookupValue {
  functionalCategoryTypeId: number;
  measurementUnit: string;
}

export interface organizationLV extends LookupValue {
  lgCode: string;
}

export interface LookupValueStateModel {
  primaryOutcomes: LookupValue[];
  functionalCategoryTypes: LookupValue[];
  capitalAssetTypes: CapitalAssetTypeLV[];
  assetConditionRankings: AssetConditionRankingLV[];
  allCapitalAssetTypes: CapitalAssetTypeLV[];
  organizations: LookupValue[];
}

@Injectable()
@State<LookupValueStateModel>({
  name: 'lookupValues',
  defaults: {
    primaryOutcomes: [],
    functionalCategoryTypes: [],
    capitalAssetTypes: [],
    assetConditionRankings: [],
    allCapitalAssetTypes: [],
    organizations: [],
  },
})
export class LookupValueState {
  constructor(
    private lookupValueService: LookupValueService,
    private organizationService: OrganizationService,
  ) {}

  @Action(LoadPrimaryOutcomes)
  loadPrimaryOutcomes({ patchState, getState }: StateContext<LookupValueStateModel>) {
    if (getState().primaryOutcomes.length > 0) return;
    return this.lookupValueService.findAllPrimaryOutcomes().pipe(
      tap((primaryOutcomes: LookupValue[]) => {
        patchState({ primaryOutcomes });
      }),
    );
  }

  @Action(LoadAssetConditionRankings)
  loadAssetConditionRankings({ patchState, getState }: StateContext<LookupValueStateModel>) {
    if (getState().assetConditionRankings.length > 0) return;
    return this.lookupValueService.findAllAssetConditionRankings().pipe(
      tap((assetConditionRankings: AssetConditionRankingLV[]) => {
        patchState({ assetConditionRankings });
      }),
    );
  }

  @Action(LoadFunctionalCategoryTypes)
  loadFunctionalCategoryTypes({ patchState, getState }: StateContext<LookupValueStateModel>) {
    if (getState().functionalCategoryTypes.length > 0) return;
    return this.lookupValueService.findAllFunctionalCategoryTypes().pipe(
      tap((functionalCategoryTypes: LookupValue[]) => {
        patchState({ functionalCategoryTypes });
      }),
    );
  }

  @Action(LoadCapitalAssetTypes)
  loadCapitalAssetTypes(
    { patchState, getState }: StateContext<LookupValueStateModel>,
    { functionalCategoryTypeId }: LoadCapitalAssetTypes,
  ) {
    if (!functionalCategoryTypeId || functionalCategoryTypeId <= 0) {
      patchState({ capitalAssetTypes: [] }); // invalid parameter
      return;
    }
    const existingCapitalAssetTypes = getState().capitalAssetTypes.find(
      (_) => _.functionalCategoryTypeId === functionalCategoryTypeId,
    );
    if (existingCapitalAssetTypes) return; // capitalAssetTypes array no need change, reuse it

    const newCapitalAssetTypes = getState().allCapitalAssetTypes.filter(
      (_) => _.functionalCategoryTypeId === functionalCategoryTypeId,
    );
    if (newCapitalAssetTypes && newCapitalAssetTypes.length > 0) {
      // find capitalAssetTypes array from allCapitalAssetTypes array
      patchState({ capitalAssetTypes: newCapitalAssetTypes });
      return;
    }

    return this.lookupValueService.findAllCapitalAssetTypes().pipe(
      tap((capitalAssetTypes) => {
        const capitalAssetTypesWithMeasurement = capitalAssetTypes.map<CapitalAssetTypeLV>((_) => {
          return {
            id: _.id,
            title: _.title,
            functionalCategoryTypeId: _.functionalCategoryType.id,
            measurementUnit: _.measurementUnit?.title ?? '',
          };
        });
        capitalAssetTypesWithMeasurement.sort((a, b) => {
          const aTitle = (a.title ?? '').toLowerCase();
          const bTitle = (b.title ?? '').toLowerCase();
          if (aTitle < bTitle) return -1;
          else if (aTitle > bTitle) return 1;
          else return 0;
        });
        const filteredCapitalAssetTypes = capitalAssetTypesWithMeasurement.filter(
          (_) => !functionalCategoryTypeId || _.functionalCategoryTypeId === functionalCategoryTypeId,
        );
        patchState({ capitalAssetTypes: filteredCapitalAssetTypes });
        patchState({ allCapitalAssetTypes: capitalAssetTypesWithMeasurement });
      }),
    );
  }

  @Action(LoadAllCapitalAssetTypes)
  loadAllCapitalAssetTypes({ patchState, getState }: StateContext<LookupValueStateModel>) {
    const existingCapitalAssetTypes = getState().allCapitalAssetTypes;
    if (existingCapitalAssetTypes?.length > 0) return;

    return this.lookupValueService.findAllCapitalAssetTypes().pipe(
      tap((capitalAssetTypes) => {
        const capitalAssetTypesWithMeasurement = capitalAssetTypes.map<CapitalAssetTypeLV>((_) => {
          return {
            id: _.id,
            title: _.title,
            functionalCategoryTypeId: _.functionalCategoryType.id,
            measurementUnit: _.measurementUnit?.title ?? '',
          };
        });
        capitalAssetTypesWithMeasurement.sort((a, b) => {
          const aTitle = (a.title ?? '').toLowerCase();
          const bTitle = (b.title ?? '').toLowerCase();
          if (aTitle < bTitle) return -1;
          else if (aTitle > bTitle) return 1;
          else return 0;
        });
        patchState({ allCapitalAssetTypes: capitalAssetTypesWithMeasurement });
      }),
    );
  }

  @Action(LoadOrganizations)
  loadOrganizations({ patchState, getState }: StateContext<LookupValueStateModel>) {
    if (getState().organizations.length > 0) return;
    return this.organizationService.findAll().pipe(
      tap((organizations) => {
        const organizationList = organizations.map<organizationLV>((_) => {
          return {
            id: _.id,
            title: _.legalName,
            lgCode: _.lgCode,
          };
        });
        patchState({ organizations: organizationList });
      }),
    );
  }

  @Selector()
  static getOrganizations(state: LookupValueStateModel) {
    return state.organizations;
  }

  @Selector()
  static getPrimaryOutcomes(state: LookupValueStateModel) {
    return state.primaryOutcomes;
  }

  @Selector()
  static getAssetConditionRankings(state: LookupValueStateModel) {
    return state.assetConditionRankings;
  }

  @Selector()
  static getFunctionalCategoryTypes(state: LookupValueStateModel) {
    return state.functionalCategoryTypes;
  }

  @Selector()
  static getCapitalAssetTypes(state: LookupValueStateModel) {
    return state.capitalAssetTypes;
  }

  @Selector()
  static getAllCapitalAssetTypes(state: LookupValueStateModel) {
    return state.allCapitalAssetTypes;
  }
}
