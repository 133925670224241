import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { GoogleMap } from '@angular/google-maps';

import albertaCoords from './coord';
import { Select } from '@ngxs/store';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Observable, Subject, Subscription, combineLatest, interval, take, takeUntil } from 'rxjs';
import { ExternalUserDto } from '@app-com/api/models';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { MapService } from '@app-pot/shared/services/map.service';
import { redMarker, blueMarker } from './marker.svg';
import { FormBuilder } from '@angular/forms';

@Component({
  selector: 'app-googlemap',
  templateUrl: './googlemap.component.html',
  styleUrls: ['./googlemap.component.scss'],
})
export class GooglemapComponent implements AfterViewInit, OnInit, OnDestroy, OnChanges {
  @Input() formMode: 'ADD' | 'EDIT';
  @Input() locationDetails: { address: string; coord: { lat: number; lng: number } } | undefined;
  @Input() selectedProjectLocation: { latitude: number; longitude: number } | undefined;
  @Input() isMapVisible: boolean;
  @Output() populateAddress = new EventEmitter<{
    selectionConfirmed: boolean;
    address?: string;
    coord?: { lat: number; lng: number };
  }>();
  @ViewChild('mapSearchField') searchField: ElementRef;
  @ViewChild(GoogleMap, { static: false }) map: GoogleMap;
  @Select(CurrentContextState.getCurrentOrganizationId) organizationId$: Observable<number>;

  subscription = new Subscription();
  selectedAdvancedMarkerElement: google.maps.marker.AdvancedMarkerElement =
    new google.maps.marker.AdvancedMarkerElement();
  tempAdvancedMarkerElement: google.maps.marker.AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement();
  unsubscribe$ = new Subject<void>();
  latLngBounds = new google.maps.LatLngBounds(
    { lat: 46.500385, lng: -122.718748 },
    { lat: 63.588963, lng: -103.03125 },
  );

  idPrefix = '';
  locationStartSpotId = 'location-start-spot-id';
  location: string; // user's organizalittion center address
  nearbySearchResults: google.maps.places.PlaceResult[] | null;
  mapOptions: google.maps.MapOptions = {
    mapTypeId: google.maps.MapTypeId.ROADMAP,
    mapId: 'DEMO_MAP_ID',
    zoomControl: true,
    scrollwheel: false,
    disableDoubleClickZoom: true,
    zoom: 14,
    // center:
    restriction: {
      latLngBounds: this.latLngBounds,
      strictBounds: true,
    },
  };

  markerInfoContent = '';
  markerOptions: google.maps.MarkerOptions = {
    draggable: false,
  };
  advancedMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
  markers: google.maps.Marker[] = [];
  previouslySelectedLocation: boolean; // in Edit mode, or has already selected an address in ADD mode
  tempselectedPlace: string | undefined;
  tempselectedPlaceCoord: google.maps.LatLng | undefined;
  selectedPlace: string | undefined;
  selectedPlaceCoord: google.maps.LatLng | undefined;
  locationConfirmation = false;
  locationConfirmed = false;
  abPolygon: google.maps.Polygon;
  authenticatedUser: ExternalUserDto;
  showDialog = false;
  tempPinSvg: HTMLElement;
  selectedPinSvg: HTMLElement;

  confirmChangeAddressDialog = false;
  sub = new Subscription();
  isInitialized = false;

  constructor(
    private auth: AuthStateService,
    private ref: ChangeDetectorRef,
    private mapService: MapService,
    private formBuilder: FormBuilder,
  ) {}

  ngOnChanges(): void {
    this.previouslySelectedLocation = this.formMode === 'ADD' ? false : true;
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  ngAfterViewInit(): void {
    (this.searchField.nativeElement as HTMLInputElement).setAttribute('placeholder', '');
    this.abPolygon = new google.maps.Polygon({
      paths: albertaCoords,
      strokeColor: '#FF0000',
      strokeOpacity: 0.3,
      strokeWeight: 2,
      fillOpacity: 0.1,
    });
    if (this.map && this.map.googleMap) {
      if (this.abPolygon) {
        this.abPolygon.setMap(this.map.googleMap);
        this.abPolygon.addListener('click', (mapsMouseEvent: google.maps.MapMouseEvent) => {
          if (mapsMouseEvent && mapsMouseEvent.latLng) {
            const abCoord = google.maps.geometry.poly.containsLocation(mapsMouseEvent.latLng, this.abPolygon);
            if (!abCoord) {
              return;
            }
            this.reverseGeoCodeCoord(mapsMouseEvent.latLng);
          }
        });
      }
      this.selectedAdvancedMarkerElement.map = this.map.googleMap;
      this.tempAdvancedMarkerElement.map = this.map.googleMap;
      if (this.location) {
        this.codeAddress(this.location);
      }
      if (
        this.selectedProjectLocation &&
        this.selectedProjectLocation.latitude &&
        this.selectedProjectLocation.longitude
      ) {
        // For map in edit dialog
        const selecetdLocation = new google.maps.LatLng(
          this.selectedProjectLocation.latitude,
          this.selectedProjectLocation.longitude,
        );
        this.selectedAdvancedMarkerElement.position = selecetdLocation;
        this.selectedAdvancedMarkerElement.content = this.selectedPinSvg;
      }
    }

    interval(1000)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.autoComplete();
      });
  }

  autoComplete() {
    // Specify just the place data fields that you need.
    const autoComplete = new google.maps.places.Autocomplete(this.searchField.nativeElement, {
      bounds: this.latLngBounds,
      fields: ['place_id', 'geometry', 'name', 'formatted_address'],
      componentRestrictions: {
        country: 'CA',
      },
    });

    autoComplete.addListener('place_changed', () => {
      const tempselectedPlace = autoComplete.getPlace();
      console.log('[Autocomplete] Selected place', tempselectedPlace);
      if (!tempselectedPlace.place_id) {
        this.locationConfirmation = false;
        return;
      }

      //Check to see if place selected lies with AB bound
      if (tempselectedPlace && tempselectedPlace.geometry && tempselectedPlace.geometry.location) {
        const selectedPlaceinAB = google.maps.geometry.poly.containsLocation(
          tempselectedPlace.geometry.location,
          this.abPolygon,
        );
        if (!selectedPlaceinAB) {
          this.locationConfirmation = false;
          return;
        }
      }

      // Selected place within AB bounds
      this.tempselectedPlace = tempselectedPlace.formatted_address?.slice(
        0,
        tempselectedPlace.formatted_address.lastIndexOf(','),
      );
      if (
        tempselectedPlace &&
        tempselectedPlace.formatted_address &&
        tempselectedPlace.name &&
        tempselectedPlace.formatted_address?.indexOf(tempselectedPlace.name) < 0
      ) {
        this.tempselectedPlace = `${tempselectedPlace.name}, ${this.tempselectedPlace}`;
      }

      this.tempselectedPlaceCoord = tempselectedPlace.geometry?.location;

      this.locationConfirmation = true;
      if (tempselectedPlace && tempselectedPlace.geometry && tempselectedPlace.geometry.location) {
        this.map.googleMap?.setCenter(tempselectedPlace.geometry.location);
      }

      this.tempAdvancedMarkerElement.position = tempselectedPlace.geometry?.location;
      this.tempAdvancedMarkerElement.content = this.tempPinSvg;
      if (!this.tempAdvancedMarkerElement.map) {
        this.tempAdvancedMarkerElement.map = this.map.googleMap;
      }
      this.ref.detectChanges();
    });
  }

  ngOnInit() {
    if (this.formMode === 'EDIT') {
      this.idPrefix = 'edit-';
      this.locationStartSpotId = this.idPrefix + this.locationStartSpotId;
    }
    combineLatest([this.auth.getAuthenticatedUser(), this.organizationId$])
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe({
        next: ([user, id]) => {
          if (user && user.roles && user.roles.length > 0) {
            this.authenticatedUser = user;
            this.location = user.roles.find((role) => role.id == id)?.name ?? user?.roles[0].name;
          }
        },
      });
    const parser = new DOMParser();

    this.tempPinSvg = parser.parseFromString(redMarker, 'image/svg+xml').documentElement;
    this.selectedPinSvg = parser.parseFromString(blueMarker, 'image/svg+xml').documentElement;
  }

  codeAddress(location = 'Calgary') {
    new google.maps.Geocoder().geocode(
      {
        componentRestrictions: {
          country: 'CA',
          locality: location,
          administrativeArea: location,
        },
      },
      (results, status) => {
        if (status === 'OK' && results?.length) {
          if (
            this.locationExistsinAddressComponent(results[0].formatted_address, 'Calgary') ||
            this.locationExistsinAddressComponent(results[0].formatted_address, 'Edmonton')
          ) {
            if (this.map && this.map.googleMap) {
              this.map.googleMap.setZoom(10);
            }
          } else {
            if (this.map && this.map.googleMap) {
              this.map.googleMap.setZoom(15);
            }
          }
          if (this.map && this.map.googleMap) {
            this.map.googleMap.setCenter(results[0].geometry.location);
          }
        } else {
          console.error('Geocode was not successful for the following reason: ' + status);
        }
      },
    );
  }

  locationExistsinAddressComponent(address: string, location: string): boolean {
    return address.toLowerCase().includes(location.toLowerCase());
  }

  reverseGeoCodeCoord(latlng: google.maps.LatLng) {
    const geocoder = new google.maps.Geocoder();
    geocoder
      .geocode({ location: latlng })
      .then(({ results }) => {
        if (results.length > 0) {
          this.tempAdvancedMarkerElement.content = this.tempPinSvg;
          this.tempAdvancedMarkerElement.position = latlng;
          this.tempAdvancedMarkerElement.map = this.map.googleMap;
          this.locationConfirmation = true;

          //bug 2574 fix to allow choosing any point(even if it does not have a civic address) on google map
          const tempSelectedResult = results.find((result) => {
            if (result.geometry) {
              const { lat, lng } = result.geometry.location;
              if (latlng.lat() === lat() && latlng.lng() === lng()) {
                return true;
              }
              return false;
            }
            return false;
          });

          if (tempSelectedResult) {
            if (tempSelectedResult.formatted_address.indexOf(',') > 0) {
              this.tempselectedPlace = tempSelectedResult.formatted_address.slice(
                0,
                tempSelectedResult.formatted_address.lastIndexOf(','),
              );
            } else {
              this.tempselectedPlace = tempSelectedResult.formatted_address;
            }

            this.tempselectedPlaceCoord = tempSelectedResult.geometry?.location;
          } else {
            if (results[0].formatted_address.indexOf(',') > 0) {
              this.tempselectedPlace = results[0].formatted_address.slice(
                0,
                results[0].formatted_address.lastIndexOf(','),
              );
            } else {
              this.tempselectedPlace = results[0].formatted_address;
            }

            //bug 2574 fix to allow choosing any point(even if it does not have a civic address) on google map
            this.tempselectedPlaceCoord = latlng;
          }

          this.ref.detectChanges();
        }
      })
      .catch((e) => console.log('Geocoder failed due to: ' + e));
  }

  selectLocation() {
    if (this.tempselectedPlace && this.tempselectedPlaceCoord) {
      if (!this.previouslySelectedLocation) {
        this.previouslySelectedLocation = true;
        this.newSelectedLocationConfirmed();
      } else {
        this.showDialog = true;
        this.ref.detectChanges();
      }
      (this.searchField.nativeElement as HTMLInputElement).value = '';
    }
  }

  newSelectedLocationConfirmed() {
    if (this.tempselectedPlace && this.tempselectedPlaceCoord) {
      this.selectedPlace = this.tempselectedPlace;
      this.selectedPlaceCoord = this.tempselectedPlaceCoord;
      this.locationConfirmation = false;
      this.locationConfirmed = true;
      this.selectedAdvancedMarkerElement.position = this.tempselectedPlaceCoord;
      this.selectedAdvancedMarkerElement.content = this.selectedPinSvg;
      this.tempAdvancedMarkerElement.map = null;

      this.populateAddress.emit({
        selectionConfirmed: true,
        address: this.tempselectedPlace,
        coord: { lat: this.tempselectedPlaceCoord.lat(), lng: this.tempselectedPlaceCoord.lng() },
      });
    }
  }

  closeDialog(data: { selectedLocationConfirmed: boolean }) {
    this.showDialog = false;
    this.locationConfirmation = false;
    if (data.selectedLocationConfirmed) {
      this.newSelectedLocationConfirmed();
    } else {
      this.populateAddress.emit({ selectionConfirmed: false });
      this.tempAdvancedMarkerElement.map = null;
    }
  }
}
