import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-no-grant-funding-dialog',
  templateUrl: './no-grant-funding-dialog.component.html',
  styleUrl: './no-grant-funding-dialog.component.scss',
})
export class NoGrantFundingDialogComponent {
  @Input() isOpen: boolean;
  @Output() closeDialog = new EventEmitter<{ isYesClicked: boolean }>();

  closeModal(closeModal: boolean) {
    this.closeDialog.emit({ isYesClicked: closeModal });
  }
}
