import { Component, OnInit } from '@angular/core';
import { environment } from '@app-pot-env/environment';
@Component({
  selector: 'app-popup-layout',
  templateUrl: './popup-layout.component.html',
  styleUrls: ['./popup-layout.component.scss'],
})
export class PopupLayoutComponent implements OnInit {
  portalEnv: string;
  ngOnInit(): void {
    this.portalEnv = environment.portalEnvironment as string;
  }
}
