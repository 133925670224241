export class CookieCheckService {
  setTemporaryCookie(): void {
    document.cookie = 'tempCookie=test; SameSite=Lax';
  }

  areCookiesEnabled(): boolean {
    this.setTemporaryCookie();
    const isCookieEnabled = document.cookie.indexOf('tempCookie=test') > -1;
    this.deleteTemporaryCookie();
    return isCookieEnabled;
  }

  deleteTemporaryCookie(): void {
    document.cookie = 'tempCookie=test; expires=Thu, 01 Jan 1970 00:00:00 GMT; SameSite=Lax';
  }
}
