import { Directive, Input, TemplateRef, ViewContainerRef, OnInit, OnDestroy } from '@angular/core';
import { FeatureFlagService } from '@app-com/services/feature-flag.service';
import { Subscription } from 'rxjs';
@Directive({
  standalone: true,
  selector: '[appFeatureFlag]',
})
export class FeatureFlagDirective implements OnInit, OnDestroy {
  @Input('appFeatureFlag') flagName: string;
  private subscription: Subscription;
  constructor(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private featureFlagService: FeatureFlagService,
  ) {}
  ngOnInit() {
    this.subscription = this.featureFlagService.getFlag(this.flagName).subscribe((isEnabled) => {
      if (isEnabled) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    });
  }
  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}
