import { Component } from '@angular/core';

@Component({
  selector: 'app-base-stepper',
  templateUrl: './base-stepper.component.html',
  styleUrls: ['./base-stepper.component.scss'],
})
export abstract class BaseStepperComponent {
  // This function will be called when the user clicks the Previous button.
  // If the current page is valid, return true
  // If the current page is invalid, return false
  abstract validateOnPrevious(): boolean;

  // This function will be called when the user clicks the Next button.
  // If the current page is valid, return true
  // If the current page is invalid, return false
  abstract validateOnNext(): boolean;

  constructor() {}

  jumpToField(fieldName: string) {
    if (document.getElementById(fieldName)) {
      const fieldElement = document.getElementById(fieldName);
      window.scrollTo(0, fieldElement?.offsetTop ?? 0);
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }
}
