import { Directive, HostListener, ElementRef } from '@angular/core';

@Directive({
  selector: '[inputPercentage]',
})
export class PercentageDirective {
  constructor(private elementRef: ElementRef) {}

  @HostListener('input') onInput() {
    const input = this.elementRef.nativeElement.shadowRoot.querySelector('input')?.value ?? '';
    if (input && input.length > 0) {
      const numericValue = input.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      const formattedValue = numericValue.replace(/^0+/, ''); // remove leading zeros
      const validPercentage = +formattedValue > 100 ? formattedValue.slice(0, 2) : formattedValue; // donot allow % input greater than 100
      this.elementRef.nativeElement.shadowRoot.querySelector('input').value = validPercentage;
    }
  }
}
