/* tslint:disable */
/* eslint-disable */
export enum ApplicationStageType {
  Received = 'Received',
  UnderReview = 'UnderReview',
  Recommended = 'Recommended',
  RecommendedInBatch = 'RecommendedInBatch',
  InManagerReview = 'InManagerReview',
  InMinisterReview = 'InMinisterReview',
  MinisterAccepted = 'MinisterAccepted',
  Completed = 'Completed',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
