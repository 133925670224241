import { Component } from '@angular/core';
import '@abgov/web-components';

@Component({
  selector: 'app-cookie-disabled',
  templateUrl: './cookie-disabled.component.html',
  styleUrls: ['./cookie-disabled.component.scss'],
})
export class CookieDisabledComponent {
  reloadPage() {
    location.reload();
  }
}
