import { Component, OnDestroy, OnInit } from '@angular/core';
import { CashFlowFormSequence } from './enum/cash-flow-form-sequence';
import { Select, Store } from '@ngxs/store';
import { AutosaveStatus, FormStatusCodes } from '../grant-application/models/enums';
import { Observable, Subscription } from 'rxjs';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CashFlowUpdateState } from '@app-pot/store/state/cash-flow-update.state';
import {
  SetCashFlowUpdateStepperStatus,
  SetCashFlowUpdateStep,
  SetCashFlowCta,
  SetApplicationDetails,
  FetchCashFlowUpdateApplication,
  CancelAndDeleteCashFlowUpdate,
  ResetApiCallStatus,
  ResetCashFlowUpdate,
} from '@app-pot/store/actions/cash-flow-update.action';
import { ActivatedRoute, Router } from '@angular/router';
import { ApplicationExtService } from '@app-com/api/services';
import { ApplicationExtDto, PdfGenerateResponseDto } from '@app-com/api/models';
import { createCashflowFormStatus } from './cash-flow.utils';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { SetCurrentCashFlowUpdateStep } from '@app-pot/store/actions/current-context.action';
import { RefreshService } from '@app-pot/shared/services/refresh.service';

@Component({
  selector: 'app-cash-flow-updates',
  templateUrl: './cash-flow-updates.component.html',
  styleUrls: ['./cash-flow-updates.component.scss'],
})
export class CashFlowUpdatesComponent implements OnInit, OnDestroy {
  @Select(CashFlowUpdateState.getCashFlowUpdateStep) step$: Observable<CashFlowFormSequence>;
  @Select(CashFlowUpdateState.getCashFlowUpdateStepperStatus) formStatus$: Observable<{
    [CashFlowFormSequence.ContactInfo]: FormStatusCodes;
    [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes;
    [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes;
    [CashFlowFormSequence.Review]: FormStatusCodes;
    [CashFlowFormSequence.Certification]: FormStatusCodes;
  }>;
  @Select(CashFlowUpdateState.getAutosaveStatus) autosaveStatus$: Observable<AutosaveStatus>;
  @Select(CashFlowUpdateState.getCashFlowUpdateCancelAndDeleteApiCallStatus)
  getCashFlowUpdateCancelAndDeleteApiCallStatus$: Observable<[boolean]>;
  @Select(CashFlowUpdateState.getErrorCallout)
  getErrorCallout$: Observable<boolean | undefined>;
  pdfGenerationApiCall: () => Observable<PdfGenerateResponseDto>;
  errorCallout: boolean | undefined;
  formStatus: { 1: FormStatusCodes; 2: FormStatusCodes; 3: FormStatusCodes; 4: FormStatusCodes; 5: FormStatusCodes };

  constructor(
    private store: Store,
    private router: Router,
    private applicationService: ApplicationExtService,
    private snackBarService: SnackBarService,
    private refreshService: RefreshService,
    private route: ActivatedRoute,
  ) {}
  sub = new Subscription();
  formStepContactInfo = false;
  isExportingSummary = false;
  cashFlowApplicationId: number | undefined;
  organizationId: number | undefined;
  cashFlowApplicationTitle: string | undefined;
  currentStep: number | undefined;
  cashFlowUpdateId: number | undefined;
  showDialog = false;
  isValid = false;

  ngOnInit(): void {
    this.cashFlowUpdateId = this.store.selectSnapshot(CurrentContextState.getCurrentCashFlowUpdateId);
    this.cashFlowApplicationId = this.store.selectSnapshot(CurrentContextState.getCurrentCashFlowApplicationId);
    this.currentStep = this.store.selectSnapshot(CurrentContextState.getCurrentCashFlowUpdateStep);
    this.cashFlowApplicationTitle = this.store.selectSnapshot(CurrentContextState.getCurrentCashFlowApplicationTitle);
    this.organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);

    if (this.cashFlowUpdateId && this.cashFlowUpdateId > 0 && this.currentStep) {
      const stepperStatus: Partial<{
        [Item in CashFlowFormSequence]: FormStatusCodes;
      }> = {};
      this.store.dispatch(new SetCashFlowUpdateStep(this.currentStep));
      for (let step: number = 1; step < this.currentStep; step++) {
        switch (step) {
          case CashFlowFormSequence.ContactInfo:
            stepperStatus[CashFlowFormSequence.ContactInfo] = FormStatusCodes.Complete;
            break;
          case CashFlowFormSequence.ApplicationFunding:
            stepperStatus[CashFlowFormSequence.ApplicationFunding] = FormStatusCodes.Complete;
            break;
          case CashFlowFormSequence.ProjectUpdates:
            stepperStatus[CashFlowFormSequence.ProjectUpdates] = FormStatusCodes.Complete;
            break;
          case CashFlowFormSequence.Review:
            stepperStatus[CashFlowFormSequence.Review] = FormStatusCodes.Complete;
            break;
          case CashFlowFormSequence.Certification:
            stepperStatus[CashFlowFormSequence.Certification] = FormStatusCodes.Complete;
            break;
        }
      }
      switch (this.currentStep) {
        case CashFlowFormSequence.ContactInfo:
          stepperStatus[CashFlowFormSequence.ContactInfo] = FormStatusCodes.NotStarted;
          break;
        case CashFlowFormSequence.ApplicationFunding:
          stepperStatus[CashFlowFormSequence.ApplicationFunding] = FormStatusCodes.NotStarted;
          break;
        case CashFlowFormSequence.ProjectUpdates:
          stepperStatus[CashFlowFormSequence.ProjectUpdates] = FormStatusCodes.NotStarted;
          break;
        case CashFlowFormSequence.Review:
          stepperStatus[CashFlowFormSequence.Review] = FormStatusCodes.NotStarted;
          break;
        case CashFlowFormSequence.Certification:
          stepperStatus[CashFlowFormSequence.Certification] = FormStatusCodes.NotStarted;
          break;
      }
      this.store.dispatch(new SetCashFlowUpdateStepperStatus(stepperStatus));
    }

    this.sub.add(
      this.step$.subscribe((step) => {
        this.currentStep = step;
        this.formStepContactInfo = !!step && step == CashFlowFormSequence.ContactInfo;
      }),
    );

    this.sub.add(
      this.getErrorCallout$.subscribe((callout) => {
        this.errorCallout = callout;
      }),
    );

    this.applicationService
      .findAllByOrganization({
        organizationId: this.organizationId,
        body: { id: { eq: this.cashFlowApplicationId } },
        skip: 0,
      })
      .subscribe({
        next: (appDetails) => {
          if (appDetails.length > 0) {
            this.store.dispatch(new SetApplicationDetails(appDetails[0]));
          }
        },
      });

    this.sub.add(
      this.getCashFlowUpdateCancelAndDeleteApiCallStatus$.subscribe((status) => {
        if (status) {
          this.store.dispatch(new ResetApiCallStatus('cancelAndDelete'));
          this.router.navigate(['view-applications/accepted']);
        }
      }),
    );

    this.pdfGenerationApiCall = () =>
      this.applicationService.pdfApplicationDetails({
        id: this.cashFlowApplicationId as number,
        organizationId: this.organizationId as number,
        version: 'Current',
      });

    this.store.dispatch(FetchCashFlowUpdateApplication);
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.store.dispatch(new SetApplicationDetails({} as ApplicationExtDto));
    this.store.dispatch(new SetCashFlowUpdateStep(1));
    this.store.dispatch(new ResetCashFlowUpdate());
  }

  get CashFlowFormSequence(): typeof CashFlowFormSequence {
    return CashFlowFormSequence;
  }
  get AutosaveStatus(): typeof AutosaveStatus {
    return AutosaveStatus;
  }
  private _initializeFormStepper() {
    const initialFormStatus = createCashflowFormStatus({
      [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
    });

    this._updateFormStatus(initialFormStatus);
  }

  private _updateFormStatus(status: { [key in CashFlowFormSequence]: FormStatusCodes }) {
    this.store.dispatch(new SetCashFlowUpdateStepperStatus(status));
  }

  confirm() {
    if (this.currentStep === CashFlowFormSequence.ProjectUpdates) {
      const updatedFormStatus = createCashflowFormStatus({
        [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.Complete,
        [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
      });

      this._updateFormStatus(updatedFormStatus);

      if (this.errorCallout) {
        return;
      }
      this.store.dispatch(new SetCashFlowUpdateStep(4));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(4)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/review']);
    } else if (this.currentStep === CashFlowFormSequence.Review) {
      const updatedFormStatus = createCashflowFormStatus({
        [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.Complete,
        [CashFlowFormSequence.Review]: FormStatusCodes.Complete,
        [CashFlowFormSequence.Certification]: FormStatusCodes.NotStarted,
      });

      this._updateFormStatus(updatedFormStatus);

      this.store.dispatch(new SetCashFlowUpdateStep(5));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(5)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/submit']);
    } else if (this.currentStep === CashFlowFormSequence.Certification) {
      this.store.dispatch(new SetCashFlowCta(['submit']));
      return;
    }
    this.store.dispatch(new SetCashFlowCta(['confirm']));
  }

  prev() {
    if (this.currentStep === CashFlowFormSequence.ApplicationFunding) {
      const updatedFormStatus = createCashflowFormStatus({
        [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
      });

      this._updateFormStatus(updatedFormStatus);
      this.store.dispatch(new SetCashFlowCta(['previous']));
      this.store.dispatch(new SetCashFlowUpdateStep(1));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(1)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates']);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['./'], { relativeTo: this.route });
      });
    } else if (this.currentStep === CashFlowFormSequence.ProjectUpdates) {
      const updatedFormStatus = createCashflowFormStatus({
        [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
      });

      this._updateFormStatus(updatedFormStatus);
      this.store.dispatch(new SetCashFlowCta(['previous']));
      this.store.dispatch(new SetCashFlowUpdateStep(2));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(2)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/application-funding']);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['application-funding'], { relativeTo: this.route });
      });
    } else if (this.currentStep === CashFlowFormSequence.Review) {
      const updatedFormStatus = createCashflowFormStatus({
        [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
      });

      this._updateFormStatus(updatedFormStatus);
      this.store.dispatch(new SetCashFlowCta(['previous']));
      this.store.dispatch(new SetCashFlowUpdateStep(3));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(3)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/project-updates']);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['project-updates'], { relativeTo: this.route });
      });
    } else if (this.currentStep === CashFlowFormSequence.Certification) {
      const updatedFormStatus = createCashflowFormStatus({
        [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.Complete,
        [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.Complete,
        [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
      });

      this._updateFormStatus(updatedFormStatus);
      this.store.dispatch(new SetCashFlowCta(['previous']));
      this.store.dispatch(new SetCashFlowUpdateStep(4));
      this.store.dispatch(new SetCurrentCashFlowUpdateStep(4)); // Persist current cash flow update step in session storage to fix page refresh issues
      this.router.navigate(['cash-flow-updates/review']);
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => {
        this.router.navigate(['review'], { relativeTo: this.route });
      });
    }
  }
  cancelAndDeleteCashFlow() {
    this.store.dispatch(new SetCashFlowCta(['cancel']));
    this.showDialog = true;
  }
  saveAndClose() {
    this.store.dispatch(new SetCashFlowCta(['save']));
    this.snackBarService.showSuccessMessage('Your cash flow update draft was successfully saved.');
    this.router.navigate(['view-applications/cash-flow-updates']);
  }

  closeCancelCashFlowDialog(data: { cancelAndDelete: boolean }) {
    if (data.cancelAndDelete) {
      this.store.dispatch(new CancelAndDeleteCashFlowUpdate());
    }
    this.showDialog = false;
  }
}
