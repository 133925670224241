import { Component, OnInit } from '@angular/core';
import { ApplicationStatusType } from '@app-com/api/models';
import { ApplicationExtService, PdfService } from '@app-com/api/services';
import { DownloadPdfLinkComponent } from '@app-pot/shared/components/download-pdf-link/download-pdf-link.component';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-download-accepted-applications-list-pdf',
  template:
    '<app-download-pdf-link [linkText]="linkText" [pdfGenerationApiCall]="pdfGenerationApiCall"></app-download-pdf-link>',
})
export class DownloadAcceptedApplicationsListPdfComponent extends DownloadPdfLinkComponent implements OnInit {
  constructor(
    private readonly applicationService: ApplicationExtService,
    pdfService: PdfService,
    store: Store,
  ) {
    super(pdfService, store);
  }

  ngOnInit(): void {
    const organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.pdfGenerationApiCall = () =>
      this.applicationService.pdfApplicationList({
        organizationId: organizationId,
        body: { statuses: [ApplicationStatusType.Accepted] },
      });
  }
}
