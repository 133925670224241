/* tslint:disable */
/* eslint-disable */
export enum ApplicationStatusType {
  Submitted = 'Submitted',
  InReview = 'InReview',
  Accepted = 'Accepted',
  Completed = 'Completed',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
