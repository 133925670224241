<goa-modal
  id="modal"
  [open]="isOpen"
  maxwidth="550px"
  heading="Other sources of funding"
  [appPreventModalFlicker]="isOpen"
>
  <p>
    Are you sure you'd like to change 'Are there other sources of provincial or federal grant funding?' to 'No'? This
    value will remove the 'Funding from other grant programs' value(s) in your projects.
  </p>
  <div slot="actions">
    <goa-button-group alignment="end">
      <goa-button (_click)="closeModal(false)" type="secondary">Cancel</goa-button>
      <goa-button (_click)="closeModal(true)">Yes</goa-button>
    </goa-button-group>
  </div>
</goa-modal>
