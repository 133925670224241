import { Injectable } from '@angular/core';

import { Subject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class RefreshService {
  private reload = new Subject<string>();
  private reload$: Observable<string> = this.reload.asObservable();

  public getReload(): Observable<string> {
    return this.reload$;
  }
  public setReload(path: string) {
    return this.reload.next(path);
  }
}
