import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-lgff-goa-table-pagination',
  templateUrl: './lgff-goa-table-pagination.component.html',
  styleUrl: './lgff-goa-table-pagination.component.scss',
})
export class LgffGoaTablePaginationComponent implements OnChanges {
  @Input() totalItemCount: number;
  @Input() perPageCount: number;
  @Input() pageSizeOptions = [10, 25, 50, 100];
  @Output() pageChanged = new EventEmitter<Event>();
  @Input() pageNumber: number = 1;
  pageCount: number[] = [];

  ngOnChanges(changes: SimpleChanges): void {
    if (this.totalItemCount && this.perPageCount) {
      this.pageCount = Array.from(
        { length: Math.ceil(this.totalItemCount / this.perPageCount) },
        (val, index) => index + 1,
      );
    }
    if (
      changes['perPageCount'] &&
      changes['perPageCount']['currentValue'] &&
      changes['perPageCount']['previousValue']
    ) {
      this.pageNumber = 1;
    }
  }

  onPageChange(event: Event) {
    this.pageNumber = Number((event as CustomEvent).detail.value);
    this.pageChanged.emit(new CustomEvent('onPageChange', { detail: { page: this.pageNumber } }));
  }

  onPreviousPage() {
    if (this.pageNumber > 1) {
      this.pageNumber = this.pageNumber - 1;
      this.pageChanged.emit(new CustomEvent('onPreviousPage', { detail: { page: this.pageNumber } }));
    }
  }

  onNextPage() {
    if (this.pageNumber < this.pageCount.length) {
      this.pageNumber = this.pageNumber + 1;
      this.pageChanged.emit(new CustomEvent('onNextPage', { detail: { page: this.pageNumber } }));
    }
  }
}
