<div class="center-wrapper">
  <div class="centered-div">
    <div class="warning-icon-container">
      <goa-icon type="warning" size="xlarge"></goa-icon>
    </div>
    <div class="warning-icon-underline"></div>
    <div class="general-error-msg-title">404 Page not found</div>
    <div class="general-error-msg">
      <div>The page you are looking for does't exist, or another error occured.</div>
      <div>Please go to the homepage.</div>
    </div>
    <div class="buttons-container">
      <goa-button type="primary" (click)="goHomepage()">Go to homepage</goa-button>
    </div>
  </div>
</div>
