import { Component, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { GrantApplicationGeneralInfoComponent } from './grant-application-general-info/grant-application-general-info.component';
import { ApplicationDraftRecordDto } from '@app-com/api/models';
import { FormSequence, FormStatusCodes } from './models/enums';
import { FunctionalCategoriesComponent } from './functional-categories/functional-categories.component';
import { Observable, Subject, Subscription, combineLatest, filter, take, takeUntil } from 'rxjs';
import { ProjectsComponent } from './projects/projects.component';
import { Select, Store } from '@ngxs/store';
import {
  AutoSaveApplicationDraft,
  DeleteApplicationDraft,
  ResetAutosaveState,
  SaveApplicationDraft,
  SetApplicationDraftId,
  SetFormStep,
  SetFormStepperStatus,
  SetFunctionalCategories,
  SetGeneralInfo,
  SetProjects,
  SubmitApplication,
} from '@app-pot/store/actions/application-draft.action';
import {
  RemoveApplicationFromStateByDraftId,
  RemoveReturnedApplicationFromStateByDraftId,
} from '@app-pot/store/actions/applications.action';

import { ApplicationGrantState, EditApplicationType } from '@app-pot/store/state/application-draft.state';
import { ResourcePipe } from '@app-com/pipes/resource/resource.pipe';
import { Router } from '@angular/router';
import { LookupValue, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { CertificationComponent } from './certification/certification.component';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { IncompleteModalService } from './incomplete-modal/incomplete-modal.service';
import { IncompleteModalComponent } from './incomplete-modal/incomplete-modal.component';
import { MapService } from '@app-pot/shared/services/map.service';
import { ApplicationExtService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { LoadFunctionalCategoryTypes } from '@app-pot/store/actions/lookup-value.action';
import { Location } from '@angular/common';
import {
  ClearApplicationDraftContext,
  SetCurrentApplicationDraftStep,
} from '@app-pot/store/actions/current-context.action';
import { AutosaveStatus } from './models/enums/autosave-status';
import { ViewApplicationTab } from '../view-applications/view-application-tab.enum';
import { ApplicationUrlLocationHistoryState } from './models/application-url-location-history-state';

@Component({
  selector: 'app-grant-application',
  templateUrl: './grant-application.component.html',
  styleUrls: ['./grant-application.component.scss'],
})
export class GrantApplicationComponent implements OnInit, OnDestroy {
  @Select(ApplicationGrantState.getFormStep) step$: Observable<FormSequence>;
  @Select(ApplicationGrantState.getFormStepperStatus) formStatus$: Observable<{
    [FormSequence.GeneralInfo]: FormStatusCodes;
    [FormSequence.FunctionalCat]: FormStatusCodes;
    [FormSequence.Proj]: FormStatusCodes;
    [FormSequence.Cert]: FormStatusCodes;
  }>;
  @Select(ApplicationGrantState.getApiCallSucceded) apiSuccess$: Observable<boolean>;
  @Select(ApplicationGrantState.getAutosaveStatus) autosaveStatus$: Observable<AutosaveStatus>;
  @Select(ApplicationGrantState.getApplicationType) applicationType$: Observable<EditApplicationType>;

  @ViewChild(GrantApplicationGeneralInfoComponent)
  generalInfoComponentRef!: GrantApplicationGeneralInfoComponent;
  @ViewChild(FunctionalCategoriesComponent) functionalCategoriesComponentRef!: FunctionalCategoriesComponent;
  @ViewChild(ProjectsComponent) projectsComponentRef!: ProjectsComponent;
  @ViewChild(CertificationComponent) certComponentRef!: CertificationComponent;
  @ViewChild('incompleteModalContainer', { read: ViewContainerRef }) incompleteModalContainer: ViewContainerRef;

  deleteModalPageId: string;
  wantCancelDeleteDraftApp = false;
  sub = new Subscription();
  showWholePageProgressIndicatorModal = false;
  draftIdExist = false;
  functionalCategoryTypes: LookupValue[];
  stepperReady = false;
  formStepGenInfo = false;
  unsubscribe$ = new Subject<void>();
  timeoutIds: ReturnType<typeof setTimeout>[] = [];

  constructor(
    private store: Store,
    private _res: ResourcePipe,
    private router: Router,
    private snackBarService: SnackBarService,
    private applicationService: ApplicationExtService,
    private location: Location,
    public incompleteModalService: IncompleteModalService,
    public mapService: MapService,
  ) {}

  ngOnInit(): void {
    this._setApplicationDraftId();
    this.ApplicationIdPresent();

    this.store.dispatch(new ResetAutosaveState());
    this.store.dispatch(new LoadFunctionalCategoryTypes());

    this.sub.add(
      this.store.select(LookupValueState.getFunctionalCategoryTypes).subscribe((functionalCategoryTypes) => {
        this.functionalCategoryTypes = functionalCategoryTypes;
      }),
    );

    this.sub.add(
      this.store.select(ApplicationGrantState.fetchApplicationDto).subscribe((fetchedApplication) => {
        if (fetchedApplication) {
          this._initializeFormStepper(fetchedApplication);
        }
      }),
    );

    this.mapService.setEditModeMapVisibility(false);
    this.mapService.setMapVisibility(false);

    this.sub.add(
      this.step$.subscribe((step) => {
        this.formStepGenInfo = !!step && step == FormSequence.GeneralInfo;
      }),
    );

    //To delay initializing the form stepper to avoid unexpected behaviors (for example, radio buttons in the form not working properly otherwise).
    this.stepperReady = true;
  }

  private _setApplicationDraftId(): void {
    const isSet = this._setApplicationDraftIdFromNavigationExtras();
    if (!isSet) this._setApplicationDraftIdFromSession();
  }

  private _getStepOnInit(applicationDraftId: number): FormSequence {
    const applicationDraftIdInSession = this.store.selectSnapshot(CurrentContextState.getCurrentApplicationDraftId);
    if (applicationDraftId !== applicationDraftIdInSession) return FormSequence.GeneralInfo;
    const stepInSession = this.store.selectSnapshot(CurrentContextState.getCurrentApplicationDraftStep);
    return stepInSession ?? FormSequence.GeneralInfo;
  }

  private _setApplicationDraftIdFromNavigationExtras(): boolean {
    const locationState = this.location.getState() as ApplicationUrlLocationHistoryState;
    const applicationDraftId = locationState?.applicationDraftId ?? 0;
    if (applicationDraftId !== 0) {
      const step = this._getStepOnInit(applicationDraftId);
      this.store.dispatch(new SetFormStep(step));
      this.store.dispatch(new SetApplicationDraftId(applicationDraftId));
      return true;
    }
    return false;
  }

  private _setApplicationDraftIdFromSession(): void {
    const currentApplicationDraftIdFromSession = this.store.selectSnapshot(
      CurrentContextState.getCurrentApplicationDraftId,
    );
    if (currentApplicationDraftIdFromSession) {
      const currentOrganizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
      this.applicationService
        .findOneDraft({ organizationId: currentOrganizationId, id: +currentApplicationDraftIdFromSession })
        .subscribe({
          next: (_) => {
            const step = this._getStepOnInit(_.id);
            this.store.dispatch(new SetFormStep(step));
            this.store.dispatch(new SetApplicationDraftId(_.id));
          },
          error: () => {
            console.log('An error occurred while restoring the draft from the session. The draft ID is being reset.');
            this.store.dispatch(new SetApplicationDraftId(0));
          },
        });
    } else {
      this.store.dispatch(new SetApplicationDraftId(0));
    }
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  get FormSequence(): typeof FormSequence {
    return FormSequence;
  }

  get AutosaveStatus(): typeof AutosaveStatus {
    return AutosaveStatus;
  }

  goToApplications(applicationType: EditApplicationType | undefined = undefined) {
    applicationType =
      applicationType ??
      this.store.selectSnapshot(ApplicationGrantState.getApplicationType) ??
      EditApplicationType.Draft;
    if (applicationType == EditApplicationType.Draft) {
      this.router.navigate(['/view-applications/drafts']);
    } else if (applicationType == EditApplicationType.Returned) {
      this.router.navigate(['/view-applications/returned']);
    }
  }

  askCancelDeleteDraftApp() {
    if (!this.wantCancelDeleteDraftApp) {
      const applicationType = this.store.selectSnapshot(ApplicationGrantState.getApplicationType);
      this.deleteModalPageId =
        applicationType == EditApplicationType.Draft
          ? 'MODEL_CANCEL_DELETE_DRAFT_APP'
          : 'MODEL_CANCEL_WITHDRAW_RETURNED_APP';
      this.wantCancelDeleteDraftApp = true;
    }
  }

  private _hasIncompleteItemsOnSaveAndClose(): boolean {
    const hasIncompleteCategory =
      this.functionalCategoriesComponentRef.shouldShowIncompleteCategoryModalOnSaveAndClose();
    const hasIncompleteProject = this.projectsComponentRef.hasIncompleteProject();
    return hasIncompleteCategory || hasIncompleteProject;
  }

  private _hasOtherSourceFundingOnSaveAndClose(): boolean {
    return this.functionalCategoriesComponentRef.shouldShowOtherSourceFundingChangedToNoModalOnSaveAndClose();
  }

  private _openIncompleteDialogForSaveAndClose(): IncompleteModalComponent {
    const hasIncompleteCategory =
      this.functionalCategoriesComponentRef.shouldShowIncompleteCategoryModalOnSaveAndClose();
    const hasIncompleteProject = this.projectsComponentRef.hasIncompleteProject();
    const incompleteCategory = this.functionalCategoriesComponentRef.functionalCategories.controls['category'].value;
    const incompleteProject = this.projectsComponentRef.projectsFromRef.projectsForm.value['projectName'];
    let incompletCategoryMessage: string | undefined = undefined;
    let incompletProjectMessage: string | undefined = undefined;
    if (hasIncompleteCategory) {
      let category = ' ';
      if (incompleteCategory !== null && incompleteCategory !== undefined && incompleteCategory !== '') {
        category = ` ("${this.functionalCategoryTypes.find((x) => x.id === incompleteCategory)?.title}") `;
      }
      incompletCategoryMessage = `There is an unfinished category${category}that hasn't been added.`;
    }
    if (hasIncompleteProject) {
      const project = incompleteProject && incompleteProject.trim() !== '' ? ` ("${incompleteProject}") ` : ' ';
      incompletProjectMessage = `There is an unfinished project${project}that hasn't been added.`;
    }

    let title = '';
    const areYouSureToProceedQuestion = "Are you sure you'd like to proceed without it?";
    if (hasIncompleteCategory && hasIncompleteProject) {
      title = 'Incomplete category and project';
    } else if (hasIncompleteCategory) {
      title = 'Incomplete category';
      incompletCategoryMessage += ' ' + areYouSureToProceedQuestion;
    } else if (hasIncompleteProject) {
      title = 'Incomplete project';
      incompletProjectMessage += ' ' + areYouSureToProceedQuestion;
    }

    const modal = this.incompleteModalService.openModal(
      this.incompleteModalContainer,
      title,
      incompletCategoryMessage,
      incompletProjectMessage,
    );
    return modal;
  }

  private _saveAndCloseApp() {
    let generalInfo = this.generalInfoComponentRef.GetGeneralInfo();
    if (!generalInfo.name) {
      generalInfo = {
        ...generalInfo,
        name: `No Name drafted on ${this.generalInfoComponentRef._getCurrentDateTime()}`,
      };
    }
    this.store.dispatch(new SetGeneralInfo(generalInfo)),
      this.store.dispatch(new SetFunctionalCategories(this.functionalCategoriesComponentRef.GetFunctionalCategories())),
      this.store.dispatch(new SetProjects(this.projectsComponentRef.getProjects()));
    this.sub.add(
      combineLatest([
        this.store.dispatch(new AutoSaveApplicationDraft()),
        this.apiSuccess$.pipe(filter((apiSuccess) => apiSuccess !== undefined)),
      ]).subscribe({
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        next: ([_, apiSuccess]) => {
          if (apiSuccess) {
            console.log('Save and close succeded');
            this.snackBarService.showSuccessMessage(
              `Your ${generalInfo?.name} application was successfully saved`,
              false,
              2000,
            );
            this.store.dispatch(new ClearApplicationDraftContext());
            const applicationType = this.store.selectSnapshot(ApplicationGrantState.getApplicationType);
            this.store.dispatch(new SetApplicationDraftId(0)); //this will reset the applicationType to Draft, so store current applicationType in a variable before this call
            this.goToApplications(applicationType);
          } else {
            console.log('Save and close failed');
          }
        },
      }),
    );
  }

  onSaveAndCloseApp() {
    if (this._hasIncompleteItemsOnSaveAndClose()) {
      const modal = this._openIncompleteDialogForSaveAndClose();
      this.sub.add(
        modal.proceed.pipe(take(1)).subscribe(() => {
          this.incompleteModalService.closeModal();
          this._saveAndCloseApp();
        }),
      );
      return;
    } else if (
      this.store.selectSnapshot(ApplicationGrantState.getFormStep) === FormSequence.FunctionalCat &&
      this._hasOtherSourceFundingOnSaveAndClose()
    ) {
      const modal = this._openOtherSourceFundingChangedToNoNext();
      this.sub.add(
        modal.proceed.pipe(take(1)).subscribe(() => {
          this.incompleteModalService.closeModal();
          this._saveAndCloseApp();
        }),
      );
      return;
    } else {
      this._saveAndCloseApp();
    }
  }

  onSubmit() {
    const currentStep = this.store.selectSnapshot(ApplicationGrantState.getFormStep);
    if (currentStep === FormSequence.Cert) {
      if (this.certComponentRef && this.certComponentRef.validateOnNext()) {
        this.showWholePageProgressIndicatorModal = true;
        this.sub.add(
          this.store
            .dispatch(new SubmitApplication(this.certComponentRef.getApplicationCertifiedbyRoleSelection()))
            .subscribe({
              next: () => {
                this.showWholePageProgressIndicatorModal = false;
                this.snackBarService.showSuccessMessage(
                  `Your '${
                    this.generalInfoComponentRef.GetGeneralInfo().name
                  }' application was successfully submitted.`,
                );
                this.store.dispatch(new ClearApplicationDraftContext());
                this.router.navigate(['/view-applications', ViewApplicationTab.Submitted]);
              },
              error: (error) => {
                this.showWholePageProgressIndicatorModal = false;
                console.log(error);
                this.router.navigate(['general-error'], {
                  queryParams: { returnUrl: '/grant-application' },
                });
              },
            }),
        );
      }
    }
  }

  prevPage() {
    //TODO: The below code is using to show the "Partially Complete" status.
    //This need to be refactored and also consider the Edit mode
    //We have no validation needed on Previous button. This is the earlier code restored just to bring the stepper status
    //functionality back.

    this.projectsComponentRef.handleGrantFundingError(false);
    this.projectsComponentRef.showOtherGrantChangedToNoCallout = false;
    const currentStep = this.store.selectSnapshot(ApplicationGrantState.getFormStep);
    if (currentStep === FormSequence.FunctionalCat) {
      this.functionalCategoriesComponentRef.validateOnPrevious();
    } else if (currentStep === FormSequence.Proj) {
      this.projectsComponentRef.validateOnPrevious();
      this.mapService.setMapVisibility(false);
    } else if (currentStep == FormSequence.Cert) {
      this.mapService.setMapVisibility(true);
    }

    if (currentStep > 1) {
      this.store.dispatch(new SetFormStep(currentStep - 1));
      this.store.dispatch(new SetCurrentApplicationDraftStep(currentStep - 1));
    }
    this.snackBarService.hideSuccessMessage();
    this._scrollToTop();
  }

  private _openIncompleteDialogForFunctionalCategoriesNext(): IncompleteModalComponent {
    const incompleteCategory = this.functionalCategoriesComponentRef.functionalCategories.controls['category'].value;

    let category = ' ';
    if (incompleteCategory !== null && incompleteCategory !== undefined && incompleteCategory !== '') {
      category = ` ("${this.functionalCategoryTypes.find((x) => x.id === incompleteCategory)?.title}") `;
    }

    const incompleteCategoryMessage =
      `There is an unfinished category${category}that hasn't been added. ` +
      `Are you sure you'd like to proceed without it?`;

    const title = 'Incomplete category';

    const modal = this.incompleteModalService.openModal(
      this.incompleteModalContainer,
      title,
      incompleteCategoryMessage,
    );
    return modal;
  }

  private _openOtherSourceFundingChangedToNoNext(): IncompleteModalComponent {
    const title = 'Other sources of funding';
    const incompleteCategoryMessage =
      "Are you sure you'd like to change 'Are there other sources of provincial or federal grant funding?' to 'No'?";
    const secondLineMessage = "This will remove the 'Funding from other grant programs' value(s) in your projects.";

    const modal = this.incompleteModalService.openModal(
      this.incompleteModalContainer,
      title,
      incompleteCategoryMessage,
      undefined,
      secondLineMessage,
    );
    return modal;
  }

  private _openIncompleteDialogForProjectsNext(): IncompleteModalComponent {
    const incompleteProject = this.projectsComponentRef.projectsFromRef.projectsForm.value['projectName'];
    const project = incompleteProject && incompleteProject.trim() !== '' ? ` ("${incompleteProject}") ` : ' ';
    const incompletProjectMessage =
      `There is an unfinished project${project}that hasn't been added.` +
      ` Are you sure you'd like to proceed without it?`;
    const title = 'Incomplete project';

    const modal = this.incompleteModalService.openModal(
      this.incompleteModalContainer,
      title,
      undefined,
      incompletProjectMessage,
    );
    return modal;
  }

  private _handleGeneralInformationNext() {
    if (!this.generalInfoComponentRef) return;
    if (!this.generalInfoComponentRef.validateOnNext()) {
      //This will also show the validation callout
      this.generalInfoComponentRef.jumpToField('gen-info-heading-1');
      return;
    }
    this.store.dispatch(new SetGeneralInfo(this.generalInfoComponentRef.GetGeneralInfo()));
    this._afterNextHandle();
  }

  private _handleFunctionalCategoriesNext(proceed = false) {
    if (!this.functionalCategoriesComponentRef) return;
    if (!this.functionalCategoriesComponentRef.validateOnNext()) return; //This will also show the validation callout
    if (this.functionalCategoriesComponentRef.shouldShowIncompleteCategoryModalOnNext() && !proceed) {
      const modal = this._openIncompleteDialogForFunctionalCategoriesNext();
      this.sub.add(
        modal.proceed.pipe(take(1)).subscribe(() => {
          this.incompleteModalService.closeModal();
          this._handleFunctionalCategoriesNext(true);
        }),
      );
      return;
    }
    if (this.functionalCategoriesComponentRef.shouldShowOtherSourceFundingChangedToNoModalOnNext() && !proceed) {
      const modal = this._openOtherSourceFundingChangedToNoNext();
      this.sub.add(
        modal.proceed.pipe(take(1)).subscribe(() => {
          this.incompleteModalService.closeModal();
          this._handleFunctionalCategoriesNext(true);
        }),
      );
      return;
    }
    this.store.dispatch(new SetFunctionalCategories(this.functionalCategoriesComponentRef.GetFunctionalCategories()));
    this.mapService.setMapVisibility(true);
    this.functionalCategoriesComponentRef.clearAdditionalCategory();
    this._afterNextHandle();
  }

  private _handleProjectSourcesOfFunding() {
    const projects = this.projectsComponentRef.getProjects();
    const hasOtherSourcesOfFunding = this.functionalCategoriesComponentRef.areThereOtherSourcesOfFunding;

    if (hasOtherSourcesOfFunding) {
      return projects.some((proj) => proj.fundingFromOtherGrantPrograms && proj.fundingFromOtherGrantPrograms > 0);
    }
    return true;
  }

  private _handleProjectsNext(proceedWithIncompleteProjects = false) {
    if (!this.projectsComponentRef) return;
    this.projectsComponentRef.showOtherGrantChangedToNoCallout = false;
    if (!this.projectsComponentRef.validateOnNext()) return; // inside will show the validation callout

    const grantProgramFunding = this._handleProjectSourcesOfFunding();
    if (!grantProgramFunding) {
      this.projectsComponentRef.handleGrantFundingError(true);
      return;
    }
    this.projectsComponentRef.handleGrantFundingError(false);

    if (this.projectsComponentRef.hasIncompleteProject()) {
      if (!proceedWithIncompleteProjects) {
        const modal = this._openIncompleteDialogForProjectsNext();
        this.sub.add(
          modal.proceed.pipe(take(1)).subscribe(() => {
            this.incompleteModalService.closeModal();
            if (this.projectsComponentRef.projectsFromRef) this.projectsComponentRef.projectsFromRef.resetForm(); // clear incomplete data and errors
            this.timeoutIds.push(
              setTimeout(() => {
                this.projectsComponentRef.clearProject();
                this._handleProjectsNext(true);
              }, 2100), // this timeout needs to be greater than the timeout in resetform function
            );
          }),
        );
      }
      return; // stay current page
    } else {
      // can go next, no incomplete-data
      this.store.dispatch(new SetProjects(this.projectsComponentRef.getProjects()));
      this.projectsComponentRef.projectsFromRef?.resetForm(); // clear incomplete data and errors
      this.mapService.setMapVisibility(false);
      this._afterNextHandle();
    }
  }

  private _afterNextHandle() {
    this.snackBarService.hideSuccessMessage();
    const currentStep = this.store.selectSnapshot(ApplicationGrantState.getFormStep);
    this.store.dispatch(new SaveApplicationDraft());
    this.store.dispatch(new SetCurrentApplicationDraftStep(this._getNextStep(currentStep)));
    this.timeoutIds.push(
      setTimeout(() => {
        this._scrollToTop();
      }, 0),
    );
  }

  private _getNextStep(currentStep: FormSequence): FormSequence {
    if (currentStep <= 4) {
      return currentStep + 1;
    }
    return currentStep;
  }

  nextPage() {
    const currentStep = this.store.selectSnapshot(ApplicationGrantState.getFormStep);
    if (currentStep === FormSequence.GeneralInfo) {
      this._handleGeneralInformationNext();
    } else if (currentStep === FormSequence.FunctionalCat) {
      this._handleFunctionalCategoriesNext();
    } else if (currentStep === FormSequence.Proj) {
      this._handleProjectsNext();
    }
  }

  private _initializeFormStepper(fetchedApplication: ApplicationDraftRecordDto | null) {
    const existingDraft = this.store.selectSnapshot(ApplicationGrantState.existingApplicationDraft);
    let currentFormStatus = {
      [FormSequence.GeneralInfo]: FormStatusCodes.InComplete,
      [FormSequence.FunctionalCat]: FormStatusCodes.InComplete,
      [FormSequence.Proj]: FormStatusCodes.InComplete,
      [FormSequence.Cert]: FormStatusCodes.InComplete,
    };
    if (existingDraft) {
      currentFormStatus = {
        ...currentFormStatus,
        [FormSequence.GeneralInfo]: FormStatusCodes.Complete,
      };

      if (
        fetchedApplication &&
        fetchedApplication.functionalCategories &&
        fetchedApplication.functionalCategories?.length
      ) {
        currentFormStatus = {
          ...currentFormStatus,
          [FormSequence.FunctionalCat]: FormStatusCodes.Complete,
        };
      }

      if (fetchedApplication && fetchedApplication.projects && fetchedApplication.projects?.length) {
        currentFormStatus = {
          ...currentFormStatus,
          [FormSequence.Proj]: FormStatusCodes.Complete,
        };
      }

      this.store.dispatch(new SetFormStepperStatus(currentFormStatus));
    }
  }

  private _scrollToTop() {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }

  public noCancelDeleteDraftAppHandler() {
    this.wantCancelDeleteDraftApp = false;
  }

  public ApplicationIdPresent() {
    this.sub.add(
      this.store.select(ApplicationGrantState.getApplicationDraftId).subscribe((id) => {
        this.draftIdExist = !!id;
      }),
    );
  }

  public yesDeleteDraftAppHandler() {
    const applicationType = this.store.selectSnapshot(ApplicationGrantState.getApplicationType);
    this.wantCancelDeleteDraftApp = false;
    this.store
      .select(ApplicationGrantState.getApplicationDraftId)
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe((draftId) => {
        console.log('want cancel delete draft id: ' + draftId);

        if (!draftId || draftId <= 0) {
          console.log('DeleteApplicationDraft with an invalid draft id!');
          this.router.navigate(['/view-applications', ViewApplicationTab.Drafts]);
        } else {
          // this.store.select(ApplicationGrantState.fetchGrantApplicationForm) seems get blank JSON

          const generalInfo = this.generalInfoComponentRef.GetGeneralInfo();
          const appName = (generalInfo?.name ?? '').length > 0 ? generalInfo?.name + '' : 'Draft - ' + draftId;

          console.log('want cancel delete draft ' + appName + ' with draft id ' + draftId);

          let successConfirm = '';
          if (applicationType == EditApplicationType.Draft) {
            successConfirm = this._res.transform('deleteSuccessConfirm', 'DRAFT_APPLICATIONS');
            successConfirm = successConfirm.replace('%%', appName);
            this.store.dispatch(new RemoveApplicationFromStateByDraftId(draftId));
          } else if (applicationType == EditApplicationType.Returned) {
            successConfirm = this._res.transform('deleteSuccessConfirm', 'RETURNED_APPLICATIONS');
            successConfirm = successConfirm.replace('%%', appName);
            this.store.dispatch(new RemoveReturnedApplicationFromStateByDraftId(draftId));
          }

          this.store
            .dispatch(new DeleteApplicationDraft(draftId))
            .pipe(take(1), takeUntil(this.unsubscribe$))
            .subscribe({
              next: (data) => {
                console.log('DeleteApplicationDraft in backend successed');
                console.log(data);
                this.snackBarService.showSuccessMessage(successConfirm);
                this.goToApplications();
              },
              error: (error) => {
                console.log('DeleteApplicationDraft in backend failed');
                console.log(error);
                this.goToApplications();
              },
            });
        }
      });
  }
}
