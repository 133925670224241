/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { AddToBatchDto } from '../models/add-to-batch-dto';
import { ApplicationBatchDto } from '../models/application-batch-dto';
import { ApplicationBatchListDto } from '../models/application-batch-list-dto';
import { ApplicationBatchListFilter } from '../models/application-batch-list-filter';
import { CsvApplicationBatch } from '../models/csv-application-batch';
import { CreateApplicationBatchDto } from '../models/create-application-batch-dto';
import { CsvMinisterAppendixApplicationDto } from '../models/csv-minister-appendix-application-dto';
import { PdfGenerateResponseDto } from '../models/pdf-generate-response-dto';

@Injectable({ providedIn: 'root' })
export class ApplicationBatchService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `create_1()` */
  static readonly Create_1Path = '/api/application-batch/create';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `create()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create$Response(
    params: {
      body: CreateApplicationBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.Create_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `create$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  create(
    params: {
      body: CreateApplicationBatchDto
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.create$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `count_1()` */
  static readonly Count_1Path = '/api/application-batch/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count$Response(
    params: {
      body: ApplicationBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.Count_1Path, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  count(
    params: {
      body: ApplicationBatchListFilter
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_2()` */
  static readonly FindAll_2Path = '/api/application-batch';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll$Response(
    params: {
      take?: number;
      skip?: number;
      body: ApplicationBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<ApplicationBatchListDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.FindAll_2Path, 'post');
    if (params) {
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<ApplicationBatchListDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  findAll(
    params: {
      take?: number;
      skip?: number;
      body: ApplicationBatchListFilter
    },
    context?: HttpContext
  ): Observable<Array<ApplicationBatchListDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<ApplicationBatchListDto>>): Array<ApplicationBatchListDto> => r.body)
    );
  }

  /** Path part for operation `findOne_2()` */
  static readonly FindOne_2Path = '/api/application-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.FindOne_2Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `exists()` */
  static readonly ExistsPath = '/api/application-batch/exists/{name}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `exists()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists$Response(
    params: {
      name: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.ExistsPath, 'get');
    if (params) {
      rb.path('name', params.name, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `exists$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  exists(
    params: {
      name: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.exists$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `updateAssignedTo_1()` */
  static readonly UpdateAssignedTo_1Path = '/api/application-batch/assigned-to/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateAssignedTo()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo$Response(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.UpdateAssignedTo_1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('assignedToId', params.assignedToId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateAssignedTo$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  updateAssignedTo(
    params: {
      id: number;
      assignedToId?: string;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.updateAssignedTo$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `addApplicationsToBatch()` */
  static readonly AddApplicationsToBatchPath = '/api/application-batch/add-to-batch/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `addApplicationsToBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addApplicationsToBatch$Response(
    params: {
      id: number;
      body: AddToBatchDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.AddApplicationsToBatchPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `addApplicationsToBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  addApplicationsToBatch(
    params: {
      id: number;
      body: AddToBatchDto
    },
    context?: HttpContext
  ): Observable<number> {
    return this.addApplicationsToBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `removeApplicationFromBatch()` */
  static readonly RemoveApplicationFromBatchPath = '/api/application-batch/remove-from-batch/{id}/{applicationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `removeApplicationFromBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeApplicationFromBatch$Response(
    params: {
      id: number;
      applicationId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.RemoveApplicationFromBatchPath, 'post');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('applicationId', params.applicationId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `removeApplicationFromBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  removeApplicationFromBatch(
    params: {
      id: number;
      applicationId: number;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.removeApplicationFromBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `sendToGrantAdvisor()` */
  static readonly SendToGrantAdvisorPath = '/api/application-batch/send-to-grant-advisor/{id}/{applicationId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `sendToGrantAdvisor()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendToGrantAdvisor$Response(
    params: {
      id: number;
      applicationId: number;
      assignedToId: string;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<boolean>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.SendToGrantAdvisorPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.path('applicationId', params.applicationId, {});
      rb.query('assignedToId', params.assignedToId, {});
      rb.query('workNote', params.workNote, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: String((r as HttpResponse<any>).body) === 'true' }) as StrictHttpResponse<boolean>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `sendToGrantAdvisor$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  sendToGrantAdvisor(
    params: {
      id: number;
      applicationId: number;
      assignedToId: string;
      workNote?: string;
    },
    context?: HttpContext
  ): Observable<boolean> {
    return this.sendToGrantAdvisor$Response(params, context).pipe(
      map((r: StrictHttpResponse<boolean>): boolean => r.body)
    );
  }

  /** Path part for operation `startReviewApplication_1()` */
  static readonly StartReviewApplication_1Path = '/api/application-batch/start-review/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `startReviewApplication()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReviewApplication$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.StartReviewApplication_1Path, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `startReviewApplication$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  startReviewApplication(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.startReviewApplication$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `approveByManager()` */
  static readonly ApproveByManagerPath = '/api/application-batch/approve-manager/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveByManager()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveByManager$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.ApproveByManagerPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveByManager$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveByManager(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.approveByManager$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `approveByMinister()` */
  static readonly ApproveByMinisterPath = '/api/application-batch/approve-minister/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `approveByMinister()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveByMinister$Response(
    params: {
      id: number;
      'accepted-at': string;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.ApproveByMinisterPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.query('accepted-at', params['accepted-at'], {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `approveByMinister$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  approveByMinister(
    params: {
      id: number;
      'accepted-at': string;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.approveByMinister$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `returnToNew()` */
  static readonly ReturnToNewPath = '/api/application-batch/return-to-new/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `returnToNew()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnToNew$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.ReturnToNewPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `returnToNew$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  returnToNew(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.returnToNew$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `cancelBatch()` */
  static readonly CancelBatchPath = '/api/application-batch/cancel/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `cancelBatch()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelBatch$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ApplicationBatchDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.CancelBatchPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ApplicationBatchDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `cancelBatch$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  cancelBatch(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ApplicationBatchDto> {
    return this.cancelBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<ApplicationBatchDto>): ApplicationBatchDto => r.body)
    );
  }

  /** Path part for operation `getUserAvailableApplicationActions_1()` */
  static readonly GetUserAvailableApplicationActions_1Path = '/api/application-batch/available-actions/{batchId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `GetUserAvailableApplicationActions()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableApplicationActions$Response(
    params: {
      batchId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<any>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.GetUserAvailableApplicationActions_1Path, 'get');
    if (params) {
      rb.path('batchId', params.batchId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<any>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `GetUserAvailableApplicationActions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  GetUserAvailableApplicationActions(
    params: {
      batchId: number;
    },
    context?: HttpContext
  ): Observable<Array<any>> {
    return this.GetUserAvailableApplicationActions$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<any>>): Array<any> => r.body)
    );
  }

  /** Path part for operation `csvFindAllApplicationBatch()` */
  static readonly CsvFindAllApplicationBatchPath = '/api/application-batch/csv/find-all-batch-application';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvFindAllApplicationBatch()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllApplicationBatch$Response(
    params: {
      body: ApplicationBatchListFilter
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvApplicationBatch>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.CsvFindAllApplicationBatchPath, 'post');
    if (params) {
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvApplicationBatch>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvFindAllApplicationBatch$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  csvFindAllApplicationBatch(
    params: {
      body: ApplicationBatchListFilter
    },
    context?: HttpContext
  ): Observable<Array<CsvApplicationBatch>> {
    return this.csvFindAllApplicationBatch$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvApplicationBatch>>): Array<CsvApplicationBatch> => r.body)
    );
  }

  /** Path part for operation `pdfMinisterAppendixReport()` */
  static readonly PdfMinisterAppendixReportPath = '/api/application-batch/pdf/minister-appendix/{batchId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `pdfMinisterAppendixReport()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfMinisterAppendixReport$Response(
    params: {
      batchId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<PdfGenerateResponseDto>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.PdfMinisterAppendixReportPath, 'get');
    if (params) {
      rb.path('batchId', params.batchId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<PdfGenerateResponseDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `pdfMinisterAppendixReport$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  pdfMinisterAppendixReport(
    params: {
      batchId: number;
    },
    context?: HttpContext
  ): Observable<PdfGenerateResponseDto> {
    return this.pdfMinisterAppendixReport$Response(params, context).pipe(
      map((r: StrictHttpResponse<PdfGenerateResponseDto>): PdfGenerateResponseDto => r.body)
    );
  }

  /** Path part for operation `csvMinisterAppendix()` */
  static readonly CsvMinisterAppendixPath = '/api/application-batch/csv/minister-appendix/{batchId}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `csvMinisterAppendix()` instead.
   *
   * This method doesn't expect any request body.
   */
  csvMinisterAppendix$Response(
    params: {
      batchId: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<CsvMinisterAppendixApplicationDto>>> {
    const rb = new RequestBuilder(this.rootUrl, ApplicationBatchService.CsvMinisterAppendixPath, 'get');
    if (params) {
      rb.path('batchId', params.batchId, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<CsvMinisterAppendixApplicationDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `csvMinisterAppendix$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  csvMinisterAppendix(
    params: {
      batchId: number;
    },
    context?: HttpContext
  ): Observable<Array<CsvMinisterAppendixApplicationDto>> {
    return this.csvMinisterAppendix$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<CsvMinisterAppendixApplicationDto>>): Array<CsvMinisterAppendixApplicationDto> => r.body)
    );
  }

}
