import { Component, EventEmitter, Output } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
  CashFlowProjectUpdateDto,
  CashFlowUpdateExtDto,
  CashFlowUpdateFilter,
  CashFlowUpdateStatusType,
} from '@app-com/api/models';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import {
  FetchCashFlowUpdateApplications,
  RemoveCashFlowUpdateApplication,
} from '@app-pot/store/actions/applications.action';
import { LoadAllCapitalAssetTypes } from '@app-pot/store/actions/lookup-value.action';
import { ApplicationsState } from '@app-pot/store/state/applications.state';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { CapitalAssetTypeLV, LookupValueState } from '@app-pot/store/state/lookup-value.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, combineLatest, take, tap, filter, delay } from 'rxjs';
import { ActiveTabService } from '../active-tab.service';
import {
  CashFlowUpdateStatusSequence,
  ViewCashFlowUpdateVM,
} from '@app-pot/features/grant-application/models/view-cash-flow-update-vm';

import {
  ClearCashFlowUpdateContext,
  SetCurrentCashFlowApplicationId,
  SetCurrentCashFlowApplicationTitle,
  SetCurrentCashFlowUpdateId,
} from '@app-pot/store/actions/current-context.action';
import { DeleteCashFlowUpdate, ResetCashFlowUpdate } from '@app-pot/store/actions/cash-flow-update.action';
import { SnackBarService } from '@app-pot/shared/snack-bar.service';
import { ResourcePipe } from '@app-com/pipes';
import { CashFlowUpdateViewType } from '@app-com/components/cash-flow-update-view/cash-flow-update-view-type';
import { ApplicationExtService } from '@app-com/api/services';

@Component({
  selector: 'app-cash-flow-update-applications',
  templateUrl: './cash-flow-update-applications.component.html',
  styleUrls: ['../common-tab-style.scss'],
})
export class CashFlowUpdateApplicationsComponent {
  @Select(ApplicationsState.fetchCashFlowUpdateApplications) fetchApplications$: Observable<CashFlowUpdateExtDto[]>;
  @Select(LookupValueState.getAllCapitalAssetTypes) allCapitalAssetTypes$: Observable<CapitalAssetTypeLV[]>;
  @Output() filterTableData: EventEmitter<string> = new EventEmitter<string>();

  pageId = 'CASH_FLOW_APPLICATIONS';
  currentPage = 1;
  perPageCount = 25;
  pageSizeOptions = [10, 25, 50, 100];
  displayPaginator = true;
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  pageApplicationList: ViewCashFlowUpdateVM[];
  applications: ViewCashFlowUpdateVM[];
  filteredApplications: ViewCashFlowUpdateVM[];
  expandableRows: true;
  isAlive = true;
  allCapitalAssetTypesCache: CapitalAssetTypeLV[] = [];
  sub = new Subscription();
  organizationIdSelected: number;
  modalCashFlowUpdateDeleteContentStringId = 'areYouSureCashFlowUpdateDelete';
  modalCashFlowUpdateDeleteHeadingId = 'completeCashFlowUpdateDeleteLabel';
  modalOkCashFlowUpdateDeleteBtnLabel = 'yesCashFlowUpdateDelete';
  openCashFlowUpdateDeleteModal = false;
  modalCancelBtnLabel = 'noCancel';
  CommUtilsService = CommUtilsService;
  currentCashFlowUpdateId: number;
  openSummaryModal: boolean;
  cf: CashFlowUpdateExtDto;
  viewType = CashFlowUpdateViewType.Summary;
  cashFlowUpdateStatusType = CashFlowUpdateStatusType;
  isLoading: boolean;
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private activeTabService: ActiveTabService,
    private snackBarService: SnackBarService,
    private res: ResourcePipe,
    private cashflowService: ApplicationExtService,
  ) {
    this.sub.add(
      this.store.select(CurrentContextState.getCurrentOrganizationId).subscribe((currentOrganizationId) => {
        this.organizationIdSelected = currentOrganizationId;
      }),
    );
    this.sub.add(
      this.store.select(ApplicationsState.isLoading).subscribe((isLoading) => {
        this.isLoading = isLoading;
      }),
    );
  }

  ngOnInit(): void {
    this.loadCashFlowTable();
    this.sub.add(
      this.allCapitalAssetTypes$.subscribe((data) => {
        this.allCapitalAssetTypesCache = data ?? [];
      }),
    );
    this.sub.add(
      this.activatedRoute.url.subscribe((segments) => {
        this.activeTabService.setActiveTab(segments[0].toString());
      }),
    );
    this.sub.add(
      this.activeTabService
        .getActiveTabSearch()
        .pipe(
          delay(500),
          filter((search) => search !== undefined),
        )
        .subscribe((search) => {
          if (search !== undefined) {
            this.searchTableData(search);
          }
        }),
    );
  }

  public searchTableData(param: string): void {
    this.isLoading = true;
    const searchTerm = param.toLowerCase();

    this.cashflowService.findAllCashFlowUpdates(this.configQuery(searchTerm, this.organizationIdSelected)).subscribe({
      next: (value) => {
        this.isLoading = false;
        const filteredData = value.length > 0 ? value.map((application) => this.toViewApplications(application)) : [];
        this.filteredApplications = filteredData;
        this.reloadPerPageApplicationList(this.currentPage, filteredData);
      },
      error: () => {
        this.isLoading = false;
      },
    });
  }

  configQuery(searchTerm: string, orgId: number) {
    const body: CashFlowUpdateFilter = {};
    if (searchTerm) {
      body.SEARCH = searchTerm;
    }
    const query = {
      take: this.perPageCount,
      skip: (this.currentPage - 1) * this.perPageCount,
      body: body,
      organizationId: orgId,
    };
    return query;
  }

  loadCashFlowTable() {
    this.store.dispatch(new FetchCashFlowUpdateApplications());
    this.store.dispatch(new LoadAllCapitalAssetTypes());
    this.sub.add(
      this.sub.add(
        combineLatest([this.fetchApplications$])
          .pipe(
            tap(([applications]) => {
              this.displayPaginator = false;
              this.applications = applications.map((application) => this.toViewApplications(application));
              this.timeoutIds.push(
                setTimeout(() => {
                  this.displayPaginator = true;
                }),
              );
              this.applications.sort(
                (a: ViewCashFlowUpdateVM, b: ViewCashFlowUpdateVM) =>
                  (a.statusSortSequence! > b.statusSortSequence! ? -1 : 1) * -1,
              );
              this.filteredApplications = this.applications;

              this.reloadPerPageApplicationList(this.currentPage);
            }),
          )
          .subscribe(),
      ),
    );
  }

  handlePageChange(event: Event) {
    const e = event as CustomEvent;
    this.currentPage = e.detail.page;
    this.reloadPerPageApplicationList(this.currentPage);
  }
  reloadPerPageApplicationList(currentPage: number, applications?: ViewCashFlowUpdateVM[]) {
    let offset = (currentPage - 1) * this.perPageCount;
    if (this.filteredApplications.length <= offset) {
      offset = 0;
      this.currentPage = 1;
    }
    this.pageApplicationList = (applications ? applications : this.filteredApplications).slice(
      offset,
      offset + this.perPageCount,
    );
  }
  handlePerPageChange(event: Event) {
    const e = event as CustomEvent;
    this.perPageCount = parseInt(e.detail.value);
    this.reloadPerPageApplicationList(this.currentPage);
  }
  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
  }

  toViewApplications(application: CashFlowUpdateExtDto) {
    const uiData = {
      id: application.id,
      applicationId: application.applicationId,
      applicationIdTxt: application.applicationIdTxt,
      status: application.status,
      statusTitle: application.statusTitle,
      statusSortSequence: this.getStatusSortSequence(application.status),
      idTxt: application.idTxt,
      applicationName: application.applicationName,
      previousAcceptedFunding: application.previousTotalLgffFundingAccepted,
      updatedTotalFunding: application.previousTotalLgffFundingAccepted + application.totalUpdatedVarianceAmount,
      updatedTotalLgffFundingAcceptedTxt:
        application.updatedTotalLgffFundingAcceptedTxt ?? application.updatedTotalLgffFundingAccepted?.toLocaleString(),
      previousTotalLgffFundingAcceptedTxt:
        application.previousTotalLgffFundingAcceptedTxt ??
        application.updatedTotalLgffFundingAccepted?.toLocaleString(),

      projects: application?.applicationUpdatesRecord.projectUpdates ?? [],
    };
    return uiData;
  }

  disableCashFlowEditDelete(status: string | undefined): boolean {
    if (status === CashFlowUpdateStatusType.Draft || status === CashFlowUpdateStatusType.Returned) {
      return false;
    }
    return true;
  }

  getStatusSortSequence(status: string): number {
    if (status === CashFlowUpdateStatusType.Draft) {
      return CashFlowUpdateStatusSequence.Draft;
    } else if (status === CashFlowUpdateStatusType.Returned) {
      return CashFlowUpdateStatusSequence.Returned;
    } else if (status === CashFlowUpdateStatusType.Submitted) {
      return CashFlowUpdateStatusSequence.Submitted;
    } else if (status === CashFlowUpdateStatusType.InReview) {
      return CashFlowUpdateStatusSequence.InReview;
    } else if (status === CashFlowUpdateStatusType.Accepted) {
      return CashFlowUpdateStatusSequence.Accepted;
    } else if (status === CashFlowUpdateStatusType.Withdrawn) {
      return CashFlowUpdateStatusSequence.Withdrawn;
    } else {
      return 0;
    }
  }

  getBadgeType(stage: string | undefined) {
    if (stage == CashFlowUpdateStatusType.Accepted) {
      return 'success';
    }

    if (stage == CashFlowUpdateStatusType.Returned) {
      return 'emergency';
    }

    return 'information';
  }
  onClose() {
    this.openSummaryModal = false;
  }
  onAppInfoClick(applicationId: number) {
    this.currentCashFlowUpdateId = applicationId;
    this.openSummaryModal = true;
  }
  // sort handler
  handleSort(event: Event) {
    if (!(event instanceof CustomEvent)) return;
    const { sortBy, sortDir } = (event as CustomEvent).detail;
    const sortByField: string = sortBy;
    if (sortByField === 'status') {
      this.filteredApplications.sort(
        (a: ViewCashFlowUpdateVM, b: ViewCashFlowUpdateVM) =>
          ((a['statusSortSequence'] ?? 0) > (b['statusSortSequence'] ?? 0) ? 1 : -1) * sortDir,
      );
    } else if (sortByField === 'idTxt' || sortByField === 'applicationIdTxt' || sortByField === 'applicationName') {
      this.filteredApplications.sort(
        (a: ViewCashFlowUpdateVM, b: ViewCashFlowUpdateVM) =>
          ((a[sortByField] ?? '').toLowerCase() > (b[sortByField] ?? '').toLowerCase() ? 1 : -1) * sortDir,
      );
    } else if (sortByField === 'previousAcceptedFunding' || sortByField === 'updatedTotalFunding') {
      this.filteredApplications.sort(
        (a: ViewCashFlowUpdateVM, b: ViewCashFlowUpdateVM) =>
          ((+a[sortByField] ?? 0) > (+b[sortByField] ?? 0) ? 1 : -1) * sortDir,
      );
    }

    this.reloadPerPageApplicationList(this.currentPage);
  }

  public getCapitalAssetTypeTitle(id: number | undefined): string {
    if (!id || id <= 0) return 'invalid id';

    const title = this.allCapitalAssetTypesCache.find((item) => item.id === id)?.title ?? '' + id;
    return title;
  }
  getAdditionalCapitalAssetTypeTitle(project: CashFlowProjectUpdateDto) {
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    if (!project['additionalCapitalAssetTypes'] || project['additionalCapitalAssetTypes'].length == 0) {
      return '-';
    }
    // @ts-expect-error @typescript-eslint/ban-ts-comment
    return (project['additionalCapitalAssetTypes'] as CapitalAssetTypeLV[])
      .map((cat: CapitalAssetTypeLV) => {
        return cat.title.replace(/\([^)]*\)/g, '').trim();
      })
      .join(', ');
  }

  toggleApplicationDetails(applicationId: number) {
    this.filteredApplications = this.filteredApplications.map((_) => {
      if (_.id === applicationId) return { ..._, detailsShown: !_.detailsShown };
      return _;
    });
    this.reloadPerPageApplicationList(this.currentPage);
  }

  onEditClick(cashFlowId: number) {
    this.store.dispatch(new ClearCashFlowUpdateContext());
    this.store.dispatch(new SetCurrentCashFlowUpdateId(cashFlowId));
    const applicationId = this.filteredApplications.find((x) => x.id == cashFlowId)?.applicationId;
    this.store.dispatch(new SetCurrentCashFlowApplicationId(applicationId));
    const applicationName = this.filteredApplications.find((x) => x.id == cashFlowId)?.applicationName;
    const applicationNumber = this.filteredApplications.find((x) => x.id == cashFlowId)?.applicationIdTxt;
    const applicationTitle = applicationNumber + ' - ' + applicationName;
    this.store.dispatch(new SetCurrentCashFlowApplicationTitle(applicationTitle));
    this.store.dispatch(new ResetCashFlowUpdate());
    this.router.navigate(['/cash-flow-updates'], { state: { cashFlowApplicationId: applicationId } });
  }

  onCashFlowUpdateDeleteClicked(id: number) {
    this.currentCashFlowUpdateId = id;
    const idTxt = this.filteredApplications.find((x) => x.id == this.currentCashFlowUpdateId)?.idTxt ?? '';
    this.modalCashFlowUpdateDeleteContentStringId = this.res
      .transform('areYouSureCashFlowUpdateDelete', this.pageId)
      .replace('%%', idTxt);
    this.openCashFlowUpdateDeleteModal = true;
  }
  closeCashFlowUpdateDeleteModal() {
    this.openCashFlowUpdateDeleteModal = false;
    this.reloadPerPageApplicationList(this.currentPage);
  }
  onCashFlowUpdateDeleteClickedEvent() {
    const idTxt = this.filteredApplications.find((x) => x.id == this.currentCashFlowUpdateId)?.idTxt;
    this.store.dispatch(new ResetCashFlowUpdate());
    this.store.dispatch(new RemoveCashFlowUpdateApplication(this.currentCashFlowUpdateId));

    this.store
      .dispatch(new DeleteCashFlowUpdate(this.currentCashFlowUpdateId))
      .pipe(take(1))
      .subscribe({
        next: (result) => {
          console.log(result);
          this.snackBarService.showSuccessMessage('Cash flow update ' + idTxt + ' successfully deleted.');
          this.store.dispatch(new ClearCashFlowUpdateContext());
        },
        error: (error: Error) => {
          console.log(error);
        },
      });
  }
  PrintContent() {
    const DocumentContainer = document.getElementById('divCashFlowUpdateSummaryModal');
    const WindowObject = window.open(
      'PrintWindow',
      'width=auto,height=100%,top=0,left=0,toolbars=no,scrollbars=no,status=no,resizable=yes',
    );
    WindowObject?.document.write('<link rel="stylesheet" type="text/css" href="/styles.css">');
    WindowObject?.document.writeln(DocumentContainer?.innerHTML ?? '');
    WindowObject?.document.close();
    setTimeout(function () {
      WindowObject?.focus();
      WindowObject?.print();
      WindowObject?.close();
    }, 50);
  }
}
