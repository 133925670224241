import { Component, Input, OnInit } from '@angular/core';
import { ApplicationExtService, PdfService } from '@app-com/api/services';
import { DownloadPdfLinkComponent } from '@app-pot/shared/components/download-pdf-link/download-pdf-link.component';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';

@Component({
  selector: 'app-download-submitted-application-pdf',
  template:
    '<app-download-pdf-link iconSize="medium" [linkText]="linkText" [pdfGenerationApiCall]="pdfGenerationApiCall"></app-download-pdf-link>',
})
export class DownloadSubmittedApplicationPdfComponent extends DownloadPdfLinkComponent implements OnInit {
  @Input() applicationId: number;
  constructor(
    private readonly applicationService: ApplicationExtService,
    pdfService: PdfService,
    store: Store,
  ) {
    super(pdfService, store);
  }

  ngOnInit(): void {
    const organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    this.pdfGenerationApiCall = () =>
      this.applicationService.pdfApplicationDetails({ organizationId, id: this.applicationId, version: 'Current' });
  }
}
