import { Component, OnInit, Renderer2 } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from '@app-pot-env/environment';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-general-error',
  templateUrl: './general-error.component.html',
  styleUrls: ['./general-error.component.scss'],
})
export class GeneralErrorComponent implements OnInit {
  returnUrl: string;
  unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private renderer: Renderer2,
  ) {}

  ngOnInit(): void {
    this.route.queryParams.pipe(takeUntil(this.unsubscribe$)).subscribe((params) => {
      this.returnUrl = params['returnUrl'] || '/';
    });
    this._clearCircularProgressStyles();
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private _clearCircularProgressStyles() {
    // Remove the scroll styles applied to the body tag by 'circular-progress' if navigation occurs from a component while 'circular-progress' is visible.
    this.renderer.setStyle(document.body, 'overflow', '');
    this.renderer.setStyle(document.body, 'padding-right', '');
  }

  returnToMAConnect() {
    window.location.href = environment.ssoLoginSamlPath as string;
  }
  reloadPage() {
    this.router.navigateByUrl(this.returnUrl);
  }
}
