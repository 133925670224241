import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-landing',
  templateUrl: './landing.component.html',
  styleUrls: ['./landing.component.scss'],
})
export class LandingComponent implements OnInit {
  constructor(
    private route: ActivatedRoute,
    private router: Router,
  ) {}

  ngOnInit(): void {
    console.log('Project Portal landing page.');
    const redirect = localStorage.getItem('original_redirect');
    if (redirect) {
      this.router.navigate([redirect]);
    } else {
      this.router.navigate(['/home']);
    }
  }
}
