import { Component, OnDestroy, OnInit } from '@angular/core';
import { ChildrenOutletContexts, NavigationEnd, Router } from '@angular/router';
import { environment } from '@app-pot-env/environment';
import { Loader } from '@googlemaps/js-api-loader';
import { AuthStateService } from './shared/services/auth-state.service';
import { Subscription } from 'rxjs';

export enum Layout {
  Application = 'Application',
  Error = 'Error',
  Popup = 'Popup',
}

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  layout: Layout = Layout.Application;
  private sub: Subscription;
  portalEnv: string;
  portalVersion: string;

  constructor(
    private router: Router,
    public contexts: ChildrenOutletContexts,
    public authService: AuthStateService,
  ) {
    this.sub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.layout = this._getLayout(event.urlAfterRedirects);
      }
    });
  }

  ngOnDestroy(): void {
    if (this.sub) {
      this.sub.unsubscribe();
    }
  }

  ngOnInit() {
    this.portalEnv = environment.portalEnvironment as string;
    this.portalVersion =
      environment.portalEnvironment === 'PROD'
        ? (environment.portalVersion as string)
        : `${environment.portalEnvironment} ${environment.portalVersion}`;
    const loader = new Loader({
      apiKey: environment.mapsApiKey,
      version: 'weekly',
      libraries: ['core', 'maps', 'marker', 'places'],
    });
    loader.load().then(() => {
      console.log('Google Maps API loaded');
    });
  }

  private _getLayout(url: string) {
    if (this._isErrorPage(url)) {
      return Layout.Error;
    }
    if (this._isPopup(url)) {
      return Layout.Popup;
    }
    return Layout.Application;
  }

  private _isErrorPage(url: string) {
    if (url.includes('/access-forbidden') || url.includes('/page-not-found') || url.includes('/general-error')) {
      return true;
    }
    return false;
  }

  private _isPopup(url: string) {
    if (url.includes('/application-summary')) {
      return true;
    }
    return false;
  }

  get Layout(): typeof Layout {
    return Layout;
  }
}
