export class Category {
  categoryId?: number;
  isPrimary?: boolean;
  cost?: number;
}
export class CategoryWithTitle extends Category {
  categoryTitle?: string;
}

export class CategorySources {
  areThereOtherSourcesOfFunding?: boolean;
  questionNo?: boolean;
  isFundingFromAMWWP?: boolean;
  isFundingFromCCBF?: boolean;
  specifyOther?: boolean;
  isFundingFromSTIP?: boolean;
  isFundingFromMSI?: boolean;
  isFundingFromWaterForLife?: boolean;
  fundingFromOther?: string;
  isOwnedByLocalGovernment?: boolean;
  isNonProfitOrganization?: boolean;
  nonProfitOwnership?: string;
  isRegionalService?: boolean;
  regionalServiceCommissionOwnership?: string;
  isLocalGovt?: boolean;
  localGovernmentOwnership?: string;
}
