<h2 id="project-heading-h1" class="page-heading">Projects</h2>
<h3 id="project-heading-h3" class="page-description">
  Provide estimated project details, including financial information for your selected primary functional category:
</h3>

<div
  class="mb-56px"
  *ngIf="showErrorFieldsCallout || showGrantFundingError || totalProjectsAllowed == projectRecords.length"
>
  <goa-callout type="emergency" heading="{{ 'errorCalloutHeading' | res: pageId }}" *ngIf="showErrorFieldsCallout">
    <a (click)="jumpToField('project-your-projects-table')" class="validation-message-link"
      >{{ errorMsgAtLeastOneProject }}<br
    /></a>
  </goa-callout>

  <goa-callout
    [id]="fundingGrantCalloutId"
    type="emergency"
    heading="{{ 'errorFundingGrantProgramTitle' | res: pageId }}"
    *ngIf="showGrantFundingError"
  >
    <span>{{ 'errorFundingGrantProgramDescription' | res: pageId }}</span>
  </goa-callout>

  <goa-callout
    *ngIf="totalProjectsAllowed == projectRecords.length"
    type="important"
    heading="{{ 'errorTitleTwentyProjects' | res: pageId }}"
  >
    {{ 'errorDescriptionTwentyProjects' | res: pageId }}
  </goa-callout>
</div>
<div class="mb-56px" *ngIf="showOtherGrantChangedToNoCallout">
  <goa-callout
    type="important"
    heading="{{ 'errorCalloutHeading' | res: 'COMMON' }}"
    id="callout-other-grant-program-removed"
  >
    The 'Funding from other grant programs' value(s) have been removed. Please review all project monetary values to
    ensure they're correct:<br />
    <a href="/grant-application#project-your-projects-table">Your projects</a>
  </goa-callout>
</div>
<div class="project-form-container">
  <project-form
    *ngIf="displayProjectForm && totalProjectsAllowed != projectRecords.length"
    (projectAdd)="addProject($event)"
    (clearProject)="clearProject()"
    [mode]="'ADD'"
    [isMapVisible]="isMapVisible"
  ></project-form>
</div>

<h3 id="project-your-projects-table" class="projects-table-title">Your projects</h3>
<div class="project-table-subtitle" data-automation-id="project-table-subtitle">
  Here's a summary of all your projects under this application. To add a new project, go back to the top of this page.
</div>
<goa-table width="100%" class="table projects-list">
  <thead>
    <tr>
      <th class="thead-exp-icon">&nbsp;</th>
      <th style="width: 14%; padding-left: 1rem">Project name</th>
      <th style="width: 14%; padding-left: 1rem">Primary capital asset</th>
      <th style="width: 14%" class="numeric">Estimated total cost</th>
      <th style="width: 14%" class="numeric">LGFF funding amount requested</th>
      <th style="width: 14%" class="numeric">Funding from other grant programs</th>
      <th style="width: 14%" class="numeric">Funding from municipal sources</th>
      <th class="thead-action">Actions</th>
    </tr>
  </thead>

  <tbody>
    <tr *ngIf="projectRecords.length === 0">
      <td class="minwidth-2rem">&nbsp;</td>
      <td colspan="7" class="empty-table-msg">No projects have been added yet.</td>
    </tr>
    <ng-container *ngFor="let record of projectRecords">
      <tr class="normal-row">
        <!-- normal row data -->
        <td>
          <div class="toggle-details-container minwidth-2rem">
            <goa-icon
              [type]="record.detailsShown ? 'chevron-down' : 'chevron-forward'"
              size="small"
              (click)="toggleProjectDetails(record.id)"
            ></goa-icon>
          </div>
        </td>
        <td>{{ record.projectName }}</td>
        <td>{{ record.primaryCapitalAssetTitle }}</td>
        <td class="numeric goa-table-number-column">{{ record.estimatedTotalCost | safeDollar }}</td>
        <td class="numeric goa-table-number-column">{{ record.lgffFundingAmountRequested | safeDollar }}</td>
        <td
          class="numeric goa-table-number-column"
          *ngIf="record.fundingFromOtherGrantPrograms && record.fundingFromOtherGrantPrograms > 0"
        >
          {{ record.fundingFromOtherGrantPrograms | safeDollar }}
        </td>
        <td
          class="numeric goa-table-number-column"
          *ngIf="!record.fundingFromOtherGrantPrograms || record.fundingFromOtherGrantPrograms === 0"
        >
          {{ blankNA }}
        </td>

        <td class="numeric goa-table-number-column">{{ record.fundingFromMunicipalSources | safeDollar }}</td>
        <td>
          <div class="action-column">
            <goa-icon type="pencil" (click)="openEditProjectDialog(record.id)"></goa-icon>
            <goa-icon type="trash" (click)="openDeleteProjectDialog(record.id)"></goa-icon>
          </div>
        </td>
      </tr>
      <tr [class.hidden]="!record.detailsShown" class="detail-head">
        <!-- expanded row-1 head -->
        <td>&nbsp;</td>
        <td class="detail-head-1">Address</td>
        <td class="detail-head-1">Coordinates</td>
        <td class="detail-head-1">Anticipated start date</td>
        <td class="detail-head-1">Anticipated end date</td>
        <td class="detail-head-1">Project type</td>
        <td class="detail-head-1">{{ getDetailExpendedCellTitleOrText(record, 1, true) }}</td>
        <td>&nbsp;</td>
      </tr>
      <tr [class.hidden]="!record.detailsShown" class="detail-data">
        <!-- expanded row-1 data -->
        <td>&nbsp;</td>
        <td class="detail-data-1">{{ record.address }}</td>
        <td class="detail-data-1">{{ record.latitude }}, {{ record.longitude }}</td>
        <td class="detail-data-1">{{ record.anticipatedStartDate }}</td>
        <td class="detail-data-1">{{ record.anticipatedEndDate }}</td>
        <td class="detail-data-1">{{ record.projectType ?? '' }}</td>
        <td class="detail-data-1" colspan="2">{{ getDetailExpendedCellTitleOrText(record, 1, false) }}</td>
      </tr>
      <tr [class.hidden]="!record.detailsShown" class="detail-head">
        <!-- expanded row-2 head -->
        <td>&nbsp;</td>
        <td class="detail-head-2">{{ getDetailExpendedCellTitleOrText(record, 2, true) }}</td>
        <td class="detail-head-2">{{ getDetailExpendedCellTitleOrText(record, 3, true) }}</td>
        <td class="detail-head-2">{{ getDetailExpendedCellTitleOrText(record, 4, true) }}</td>
        <td class="detail-head-2">{{ getDetailExpendedCellTitleOrText(record, 5, true) }}</td>
        <td class="detail-head-2">{{ getDetailExpendedCellTitleOrText(record, 6, true) }}</td>
        <td class="detail-head-2">{{ getDetailExpendedCellTitleOrText(record, 7, true) }}</td>
        <!-- <td class="detail-head-2" colspan="3">Brief project description</td> -->
        <td class="detail-head-2">&nbsp;</td>
      </tr>
      <tr [class.hidden]="!record.detailsShown" class="detail-data">
        <!-- expanded row-2 data -->
        <td>&nbsp;</td>
        <ng-container *ngFor="let item of expendedRow2Loop">
          <td
            *ngIf="getDetailExpendedCellDescSpans(record, item).length > 0"
            class="detail-data-2"
            [ngClass]="isDescriptionCell(record, item) ? 'long-desc' : 'non-desc-cell'"
            [colSpan]="getDetailExpendedCellDescSpans(record, item)"
          >
            <span>{{ getDetailExpendedCellTitleOrText(record, item, false) }}</span>
          </td>
        </ng-container>
      </tr>
    </ng-container>
  </tbody>

  <tfoot>
    <tr *ngIf="projectRecords.length === 0">
      <td colspan="8">Total</td>
    </tr>
    <tr *ngIf="projectRecords.length > 0">
      <td colspan="3">Total</td>
      <td class="numeric goa-table-number-column">
        {{ this.projectRecords | columnTotal: 'estimatedTotalCost' | safeDollar }}
      </td>
      <td class="numeric goa-table-number-column">
        {{ this.projectRecords | columnTotal: 'lgffFundingAmountRequested' | safeDollar }}
      </td>
      <td class="numeric goa-table-number-column">
        {{ this.projectRecords | columnTotal: 'fundingFromOtherGrantPrograms' | safeDollar }}
      </td>
      <td class="numeric goa-table-number-column">
        {{ this.projectRecords | columnTotal: 'fundingFromMunicipalSources' | safeDollar }}
      </td>
      <td></td>
    </tr>
  </tfoot>
</goa-table>

<div class="bottom-gap" variant="relaxed">
  <goa-button leadingIcon="arrow-up" type="secondary" (click)="scrollToTop()">Back to top</goa-button>
</div>

<project-delete
  [isOpen]="isDeleteDialogOpen"
  (afterCloseHandler)="closeDeleteProjectDialog()"
  (afterDeleteHandler)="deleteProject($event)"
  [data]="currentProjectToDelete"
></project-delete>

<project-edit
  [isOpen]="isEditDialogOpen"
  (afterCloseHandler)="closeEditProjectDialog()"
  (afterSaveHandler)="updateProject($event)"
  [project]="currentProjectToEdit"
></project-edit>
