import { ApplicationRef, ComponentRef, EmbeddedViewRef, Injectable, ViewContainerRef } from '@angular/core';
import { IncompleteModalComponent } from './incomplete-modal.component';

@Injectable({
  providedIn: 'root',
})
export class IncompleteModalService {
  private modalComponentRef: ComponentRef<IncompleteModalComponent>;

  constructor(private appRef: ApplicationRef) {}

  openModal(
    viewContainerRef: ViewContainerRef,
    title: string,
    incompleteCategoryMessage: string | undefined = undefined,
    incompleteProjectMessage: string | undefined = undefined,
    secondLineMessage: string | undefined = undefined,
  ): IncompleteModalComponent {
    this.modalComponentRef = viewContainerRef.createComponent(IncompleteModalComponent);
    this.modalComponentRef.instance.open = true;
    this.modalComponentRef.instance.title = title;
    this.modalComponentRef.instance.incompleteCategoryMessage = incompleteCategoryMessage;
    this.modalComponentRef.instance.incompleteProjectMessage = incompleteProjectMessage;
    this.modalComponentRef.instance.secondLineMessage = secondLineMessage;

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const domElem = (this.modalComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
    document.body.appendChild(domElem);
    return this.modalComponentRef.instance;
  }

  closeModal() {
    if (this.modalComponentRef) {
      this.modalComponentRef.destroy();
    }
  }
}
