<div
  class="download-button-container"
  [ngClass]="{ 'padding-top-2': linkText.length === 0 }"
  (click)="downloadPdf()"
  [class.disabled]="pdfStatus === PdfStatus.Queued || pdfStatus === PdfStatus.Requested"
>
  <div class="download-text" [class.after-icon]="textPosition == 'after'">{{ linkText }}</div>
  <div
    class="download-icon-container"
    [ngClass]="{ 'ms-0': linkText.length === 0 }"
    [class.small-icon]="iconSize == 'small'"
  >
    <div class="download-icon" *ngIf="pdfStatus !== PdfStatus.Queued && pdfStatus !== PdfStatus.Requested">
      <goa-icon type="download" [size]="iconSize"></goa-icon>
    </div>
    <div class="loading-icon" *ngIf="pdfStatus === PdfStatus.Queued || pdfStatus === PdfStatus.Requested">
      <mat-spinner class="download-inprogress-icon"></mat-spinner>
    </div>
  </div>
</div>
