<div class="form">
  <h2 id="gen-info-heading-1">{{ 'generalSectionHeading1' | res: pageId }}</h2>

  <div class="mb-56px" style="margin-top: 0.75rem" *ngIf="showErrorFieldsCallout">
    <goa-callout type="emergency" heading="{{ 'errorCalloutHeading' | res: pageId }}" *ngIf="showErrorFieldsCallout">
      <ng-container *ngFor="let uiDef of pageUiDefs">
        <a *ngIf="uiDef.errorMsg" (click)="jumpToField(uiDef.idWrap ?? '')" class="validation-message-link"
          >{{ uiDef.labelText }}<br
        /></a>
      </ng-container>
    </goa-callout>
  </div>

  <h3 id="general-application-contact-info-h3">{{ 'generalSectionHeading2' | res: pageId }}</h3>

  <form [formGroup]="generalInfoForm">
    <div class="general-section">
      <div *ngIf="UiDefFirstName; let UiDef" class="wd-500px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            autocomplete="off"
            type="text"
            width="31.25rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>

      <div *ngIf="UiDefLastName; let UiDef" class="wd-500px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            type="text"
            width="31.25rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>

      <div *ngIf="UiDefPhone; let UiDef" class="wd-500px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            type="tel"
            width="251px"
            height="42px"
            appInputFormat
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (keypress)="acceptPhoneNumberOnly($event)"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
            <div slot="leadingContent">
              <span class="leading-content-text">+1</span>
            </div>
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>

      <div *ngIf="UiDefEmail; let UiDef" class="wd-500px pb-12px">
        <goa-form-item [attr.id]="UiDef.idWrap" [label]="UiDef.labelText" [helptext]="UiDef.helptext">
          <goa-input
            type="email"
            width="31.25rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>

      <!-- <hr class="grey-bar-wd100-ht2px" />
      <h2 class="pdtop-12">{{'appSectionHeading' | res: pageId}}</h2> -->
    </div>

    <goa-divider mt="2xs"></goa-divider>
    <h3 id="general-application-info-h3" style="padding-top: 40px">{{ 'appSectionHeading' | res: pageId }}</h3>

    <div class="app-section">
      <div *ngIf="UiDefApplicationName; let UiDef" class="gene-app-info-wd">
        <goa-form-item class="app-section-item" [attr.id]="UiDef.idWrap" [label]="UiDef.labelText">
          <goa-input
            type="text"
            width="42rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            maxlength="{{ UiDef.maxLength }}"
            (focusin)="onFocusIn(UiDef)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
          </goa-input>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
        <div class="help-msg" [attr.data-automation-id]="UiDef.id + '-help'">{{ UiDef.helptext }}</div>
      </div>

      <div *ngIf="UiDefOutcomes; let UiDef" class="gene-app-info-wd">
        <goa-form-item
          class="app-section-item"
          [attr.id]="UiDef.id + '-wrap'"
          [label]="UiDef.labelText"
          [helptext]="UiDef.helptext"
        >
          <goa-dropdown
            width="42rem"
            maxheight="250px"
            relative="true"
            name="{{ UiDef.name }}"
            [attr.id]="UiDef.id"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            (focusin)="onFocusIn(UiDef, true)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
          >
            <goa-dropdown-item
              *ngFor="let primaryOutcome of primaryOutcomes$ | async"
              id="{{ UiDefOutcomes.id }} + {{ primaryOutcome.id + 1 }}"
              [value]="primaryOutcome.id"
              [label]="primaryOutcome.title"
            >
            </goa-dropdown-item>
          </goa-dropdown>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
        <!-- div class="help-msg" [id]="UiDef.id + '-help'">{{UiDef.helptext}}</div -->
      </div>

      <div *ngIf="UiDefDescription; let UiDef" class="gene-app-info-wd">
        <goa-form-item
          class="app-section-item"
          [attr.id]="UiDef.idWrap"
          [label]="UiDef.labelText"
          [helptext]="UiDef.helptext"
        >
          <goa-textarea
            class="txtdescription"
            width="42rem"
            name="{{ UiDef.name }}"
            id="{{ UiDef.id }}"
            [placeholder]="UiDef.placeholder"
            [error]="UiDef.errorMsg"
            formControlName="{{ UiDef.nameCtr }}"
            goaValue
            [value]="UiDef.formCtr.value"
            (focusin)="onFocusIn(UiDef, true)"
            (focusout)="onFocusOut(UiDef, true)"
            (blur)="autosave()"
            [maxcount]="UiDef.maxLength"
            countby="character"
            showcount="false"
          >
          </goa-textarea>
          <div *ngIf="UiDef.errorMsg" class="error-msg">
            <div *ngFor="let error of UiDef.errorMsg; index as errNum">
              <div class="error-icon-container" [id]="UiDef.nameCtr + '-error-' + (errNum + 1)">
                <goa-icon class="error-icon-spacing" type="warning" size="small" theme="filled" />
                {{ error }}
              </div>
            </div>
          </div>
        </goa-form-item>
      </div>
    </div>
    <div class="ht-120px"></div>
  </form>
</div>
