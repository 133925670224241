import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Router } from '@angular/router';
import { FeatureFlagService } from '@app-com/services/feature-flag.service';

@Injectable({
  providedIn: 'root',
})
export class FeatureFlagGuard {
  constructor(
    private router: Router,
    private featureFlagService: FeatureFlagService,
  ) {}
  canActivate(route: ActivatedRouteSnapshot) {
    const featureFlag = route.data['featureFlag'];
    let activateFeature = true;

    this.featureFlagService.getFlag(featureFlag).subscribe((isEnabled) => {
      console.log('FeatureFlagService.getFlag', isEnabled);
      activateFeature = isEnabled;
    });

    console.log('feature guard', featureFlag, activateFeature);
    if (!activateFeature) {
      this.router.navigate(['page-not-found']);
    }
  }
}
