import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'columnTotal',
})
export class ColumnTotalPipe implements PipeTransform {
  transform<T>(data: T[], column: keyof T): number {
    return data.reduce((total, row) => total + +row[column], 0);
  }
}
