<div class="form">
  <h2 id="certification-form-h2">{{ 'heading1' | res: pageId }}</h2>
  <h3 id="certification-form-h3">{{ 'cashflowHeading2' | res: pageId }}</h3>
  <div class="mb-56px" *ngIf="!certificationObject.valid && certificationObject.touched">
    <goa-callout
      type="emergency"
      heading="{{ 'errorCalloutHeading' | res: pageId }}"
      *ngIf="!certificationObject.valid && certificationObject.touched"
    >
      <a (click)="jumpToField('certification-form-acceptance')" class="validation-message-link"
        >{{ 'cfuHeading2' | res: pageId }}<br
      /></a>
    </goa-callout>
  </div>
  <form [formGroup]="certificationObject">
    <div style="width: 700px; margin-bottom: 28px" id="certification-form-acceptance">
      <goa-form-item
        id="certification-form-item"
        style="padding-bottom: 12px; line-height: 28px"
        label="{{ 'cfuHeading2' | res: pageId }}"
      >
        <goa-block>
          <goa-checkbox
            style="height: 28px"
            goaValue
            name="acceptanceCheckbox"
            id="acceptanceCheckbox"
            checked="{{ certificationObject.get('acceptanceCheckbox')?.value ? true : false }}"
            formControlName="acceptanceCheckbox"
            value="{{ certificationObject.get('acceptanceCheckbox')?.value }}"
            [error]="!certificationObject.valid && certificationObject.touched"
          ></goa-checkbox>
          <span style="font-size: 18px">
            {{ 'cashFlowCertifyMessage' | res: pageId : [username, organizationTitle ?? ''] }}
          </span>
        </goa-block>

        <div class="field-validation-error-msg" *ngIf="!certificationObject.valid && certificationObject.touched">
          <goa-icon type="warning" theme="filled"></goa-icon>
          {{ 'selectCashFlowCertificationErrorMsg' | res: pageId }}
        </div>
      </goa-form-item>
    </div>
  </form>
  <app-lgff-goa-modal
    [id]="'cash-flow-certify-submit-confirmation-modal'"
    [pageId]="pageId"
    [headingId]="'modalSubmitCashFlowHeadingId'"
    [open]="openSubmitCashFlowModal"
    [actionBtnNumber]="2"
    [okBtnLabel]="yesSubmitBtn"
    [cancelBtnLabel]="cancelBtnLabel"
    [modalContentStringId]="modalSubmitCashFlowContent"
    (okBtnClickedEvent)="onReceiveSubmitCashFlowEvent()"
    (onModalClosed)="closeSubmitCashFlowModal()"
  ></app-lgff-goa-modal>

  <goa-circular-progress
    variant="fullscreen"
    visible="{{ showWholePageProgressIndicatorModal }}"
    message="{{ 'submittingCashFlow' | res: pageId }}"
  ></goa-circular-progress>
</div>
