/* tslint:disable */
/* eslint-disable */
export enum CashFlowUpdateStatusType {
  Draft = 'Draft',
  Submitted = 'Submitted',
  InReview = 'InReview',
  Accepted = 'Accepted',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
