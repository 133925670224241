import { Component } from '@angular/core';
import '@abgov/web-components';
@Component({
  selector: 'app-pot-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  pageId = 'FOOTER';
  currentYear = new Date().getFullYear();
}
