import { Component, Input, OnInit, TemplateRef } from '@angular/core';
import { TableHeader } from './lgff-goa-nested-rows-table.types';

@Component({
  selector: 'app-lgff-goa-nested-rows-table',
  templateUrl: './lgff-goa-nested-rows-table.component.html',
  styleUrl: './lgff-goa-nested-rows-table.component.scss',
})
export class LgffGoaNestedRowsTableComponent implements OnInit {
  pageId = 'COMMON';

  @Input() headers: TableHeader[] = [];
  @Input() showAdditionalHeader: boolean = false;
  @Input() additionalHeaders: TableHeader[] = [];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() tableData: any[] = [];
  @Input() childKey: string = '';
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Input() cellTemplates: { [key: string]: TemplateRef<any> } = {};
  @Input() tableWidth: string = '100%';
  @Input() parentColumnIndent: number = 0;

  ngOnInit(): void {
    console.log(this.headers);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getValue(row: any, key: string) {
    return row[key] || '-';
  }
}
