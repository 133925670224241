<div class="center-wrapper">
  <div class="centered-div">
    <div class="lock-closed-icon-container">
      <goa-icon type="lock-closed" size="xlarge"></goa-icon>
    </div>
    <div class="lock-closed-icon-underline"></div>
    <div class="no-access-msg-title">You do not have access to this site.</div>
    <div class="no-access-msg">
      You are not authorized to view this site. Please contact your administrator for assistance.
    </div>
  </div>
</div>
