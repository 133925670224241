import { Pipe, PipeTransform } from '@angular/core';
import { STRING_RESOURCES } from '@app-com/constants/string-resource';

/**
 * With this pipe, we can have a centralized string resource file where all string literal are stored.
 * In the html template, we should use this pipe to get the static strings instead of placing the string directly into the html,
 * because we could reuse lots of static strings like btn labels, informational / warning / error msgs.
 * In case we want to change some static strings, we don't need to go over each html template, find and replace the strings.
 *
 * Take a resource Id and a page Id, return the string resource in a specific page.
 * Page Id defaults to 'COMMON' in STRING_RESOURCES
 * If resource id is falsy, return string "Resource Not Available"
 * If resource id doesn't exist in page, return resource id itself.
 * If pageId is falsy or resId cannot be found in pageId, change pageId to 'COMMON' and try to get the resource from COMMON.
 *
 * Usage:
 *   resId | res:pageId
 * Example:
 *   {{ "ok" | res }} return "OK"
 *   {{ "title" | res : ""CONTACT"" }} return "Contacts"
 *
 */

@Pipe({
  name: 'res',
})
export class ResourcePipe implements PipeTransform {
  // Token is an array of string that will be used to replace the placeholder(s) in the result string if there is any.
  // The placeholder should be %%.
  public transform(resId: string, pageId = 'COMMON', token: Array<string> = []): string {
    if (!resId || resId.length < 1) {
      return 'Resource Id Unknown';
    }
    if (!pageId || pageId.length < 1) {
      pageId = 'COMMON';
    }
    if (STRING_RESOURCES[pageId]) {
      let returnStr = STRING_RESOURCES[pageId][resId];
      if (returnStr == undefined) {
        returnStr = STRING_RESOURCES['COMMON'][resId] ?? resId;
      }
      if (returnStr && token && token.length > 0) {
        returnStr = returnStr.replace(/%%/g, () => token.shift() || '%%');
      }
      return returnStr;
    } else {
      return STRING_RESOURCES['COMMON'][resId] ?? resId;
    }
  }
}
