import { Component, Input, OnDestroy } from '@angular/core';
import { PdfGenerateResponseDto } from '@app-com/api/models';
import { PdfService } from '@app-com/api/services';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Store } from '@ngxs/store';
import { catchError, map, Observable, of, Subject, switchMap, take, takeUntil, throwError, timer } from 'rxjs';

export enum PdfStatus {
  None = 'None',
  Requested = 'Requested',
  Queued = 'Queued',
  Generated = 'Generated',
  Downloaded = 'Downloaded',
  Error = 'Error',
}

@Component({
  selector: 'app-download-pdf-link',
  templateUrl: './download-pdf-link.component.html',
  styleUrls: ['./download-pdf-link.component.scss'],
})
export class DownloadPdfLinkComponent implements OnDestroy {
  @Input() linkText = '';
  @Input() textPosition: 'before' | 'after' = 'before';
  @Input() iconSize: 'small' | 'medium' | 'large' | 'xlarge' = 'small';
  @Input() pdfGenerationApiCall: () => Observable<PdfGenerateResponseDto>;

  pdfStatus: PdfStatus = PdfStatus.None;
  private waitInterval = 1000;
  private maxRetries = 2; // Set the maximum number of retries
  private unsubscribe$ = new Subject<void>();
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  constructor(
    protected pdfService: PdfService,
    protected store: Store,
  ) {}
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();

    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  downloadPdf(): void {
    if (this.pdfStatus != PdfStatus.None) return;
    this.orchestratePdfDownload(this.pdfGenerationApiCall);
  }

  get PdfStatus(): typeof PdfStatus {
    return PdfStatus;
  }

  resetStatus() {
    this.timeoutIds.push(
      setTimeout(() => {
        this.pdfStatus = PdfStatus.None;
      }, 4000),
    );
    return true;
  }

  orchestratePdfDownload(queuePdfGeneration: () => Observable<PdfGenerateResponseDto>) {
    this.pdfStatus = PdfStatus.Requested;
    const organizationId = this.store.selectSnapshot(CurrentContextState.getCurrentOrganizationId);
    queuePdfGeneration()
      .pipe(switchMap((_) => this._checkJobStatus(organizationId, _.id)))
      .pipe(
        switchMap((_) => this._getPdf(organizationId, _.result.id).pipe(map((pdf) => ({ pdf: pdf, responseDto: _ })))),
      )
      .pipe(take(1), takeUntil(this.unsubscribe$))
      .subscribe({
        next: (_) => {
          this._downloadPdf(_.pdf, _.responseDto.result.filename);
          this.pdfStatus = PdfStatus.Downloaded;
          this.resetStatus();
        },
        error: () => (this.pdfStatus = PdfStatus.Error),
      });
  }

  private _checkJobStatus(organizationId: number, jobId: string): Observable<PdfGenerateResponseDto> {
    return this.pdfService.getPdfGenerationStatus({ organizationId, jobId }).pipe(
      switchMap((_) => {
        if (_.status === 'queued') {
          this.pdfStatus = PdfStatus.Queued;
          // If status is queued, wait and check again
          return timer(this.waitInterval).pipe(switchMap(() => this._checkJobStatus(organizationId, jobId)));
        } else if (_.status === 'completed') {
          this.pdfStatus = PdfStatus.Generated;
          return of(_);
        } else {
          this.pdfStatus = PdfStatus.Error;
          return throwError(() => 'Unknown status');
        }
      }),
    );
  }

  private _getPdf(organizationId: number, fileId: string, retryAttempt = 0): Observable<Blob> {
    return this.pdfService.getPdf({ organizationId, fileId }).pipe(
      catchError((error) => {
        if (retryAttempt <= this.maxRetries) {
          return timer(this.waitInterval).pipe(switchMap(() => this._getPdf(organizationId, fileId, ++retryAttempt)));
        } else {
          throw error;
        }
      }),
    );
  }

  private _downloadPdf(pdfBlob: Blob, fileName: string) {
    const url = window.URL.createObjectURL(pdfBlob);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    window.URL.revokeObjectURL(url);
  }
}
