import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { SetEditSepoButtonAction, SetEditSepoCurrentStep } from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { EditSepoSequence } from './enum/edit-sepo-sequence.enum';

@Component({
  selector: 'app-edit-sepo',
  templateUrl: './edit-sepo.component.html',
  styleUrl: './edit-sepo.component.scss',
})
export class EditSepoComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  EditSepoSequence = EditSepoSequence;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  @Select(EditSepoState.getEditSepoStepperStatus) stepperState$: Observable<{
    [EditSepoSequence.ContactInfo]: FormStatusCodes.NotStarted;
    [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.NotStarted;
    [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted;
    [EditSepoSequence.ImmediateOutcomes]: FormStatusCodes.NotStarted;
    [EditSepoSequence.Certification]: FormStatusCodes.NotStarted;
  }>;

  sub = new Subscription();
  currentStep: EditSepoSequence | undefined;

  constructor(private store: Store) {}

  ngOnInit(): void {
    this.sub.add(
      this.currentStep$?.subscribe((step) => {
        this.currentStep = step;
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
    this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ContactInfo));
  }

  onPreviousClicked() {
    this.store.dispatch(new SetEditSepoButtonAction(['previous']));
  }

  onNextClicked() {
    this.store.dispatch(new SetEditSepoButtonAction(['next']));
  }

  onSubmitClicked() {
    this.store.dispatch(new SetEditSepoButtonAction(['submit']));
  }
}
