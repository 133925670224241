// A marker with a custom inline SVG.
export const redMarker = `<svg width="40" height="57" viewBox="0 0 40 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9050_736945)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.792 44.4201C14.8251 47.6584 17.0238 51.582 19.1949 55.3953C19.9999 56.8028 20.7267 57.0144 21.6926 55.3447C23.7901 51.7062 25.8509 47.939 28.022 44.4247C34.6135 33.7578 45.4001 23.3576 36.8214 9.00155C29.2041 -3.74454 8.48655 -2.38299 2.2906 9.48453C-4.81152 23.0862 6.27864 34.0521 12.792 44.4201Z" fill="#EA4335"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 8C26.6263 8 32 13.3737 32 20C32 26.6263 26.6263 32 20 32C13.3737 32 8 26.6263 8 20C8 13.3737 13.3737 8 20 8Z" fill="white"/>
</g>
<defs>
<clipPath id="clip0_9050_736945">
<rect width="40" height="56.5225" fill="white"/>
</clipPath>
</defs>
</svg>
`;

export const blueMarker = `<svg width="40" height="57" viewBox="0 0 40 57" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0_9050_797109)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M12.792 44.4201C14.8251 47.6584 17.0238 51.582 19.1949 55.3953C19.9999 56.8028 20.7267 57.0144 21.6926 55.3447C23.7901 51.7062 25.8509 47.939 28.022 44.4247C34.6135 33.7578 45.4001 23.3576 36.8214 9.00155C29.2041 -3.74454 8.48655 -2.38299 2.2906 9.48453C-4.81152 23.0862 6.27864 34.0521 12.792 44.4201Z" fill="#004A8F"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M20 8C26.6263 8 32 13.3737 32 20C32 26.6263 26.6263 32 20 32C13.3737 32 8 26.6263 8 20C8 13.3737 13.3737 8 20 8Z" fill="white"/>
<path d="M27.6724 16.6533L18.4393 26.2532C18.3205 26.377 18.175 26.4777 18.0115 26.549C17.8479 26.6203 17.67 26.6608 17.4885 26.668H17.4335C17.0845 26.668 16.7494 26.545 16.5001 26.3252L12.4002 22.7102C12.2751 22.5999 12.1754 22.4685 12.1068 22.3235C12.0381 22.1785 12.0018 22.0228 12.0001 21.8652C11.9965 21.5469 12.1335 21.2404 12.381 21.013C12.6286 20.7856 12.9663 20.6561 13.32 20.6529C13.6736 20.6496 14.0142 20.773 14.2668 20.9958L17.3501 23.7175L25.6583 15.0828C25.8897 14.8421 26.2178 14.694 26.5706 14.6711C26.9233 14.6482 27.2717 14.7523 27.5391 14.9605C27.8065 15.1688 27.9711 15.4641 27.9965 15.7816C28.022 16.099 27.9063 16.4126 27.6749 16.6533H27.6724Z" fill="#004A8F"/>
</g>
<defs>
<clipPath id="clip0_9050_797109">
<rect width="40" height="56.5225" fill="white"/>
</clipPath>
</defs>
</svg>
`;
