/* tslint:disable */
/* eslint-disable */
import { HttpClient, HttpContext, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { filter, map } from 'rxjs/operators';

import { BaseService } from '../base-service';
import { ApiConfiguration } from '../api-configuration';
import { StrictHttpResponse } from '../strict-http-response';
import { RequestBuilder } from '../request-builder';

import { OrganizationDto } from '../models/organization-dto';
import { OrganizationFilter } from '../models/organization-filter';
import { ValidationConditionsDto } from '../models/validation-conditions-dto';

@Injectable({ providedIn: 'root' })
export class OrganizationService extends BaseService {
  constructor(config: ApiConfiguration, http: HttpClient) {
    super(config, http);
  }

  /** Path part for operation `count_3()` */
  static readonly Count_3Path = '/api/organizations/count';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `count()` instead.
   *
   * This method doesn't expect any request body.
   */
  count$Response(
    params?: {
      filter?: OrganizationFilter;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<number>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.Count_3Path, 'get');
    if (params) {
      rb.query('filter', params.filter, {"style":"deepObject","explode":true});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return (r as HttpResponse<any>).clone({ body: parseFloat(String((r as HttpResponse<any>).body)) }) as StrictHttpResponse<number>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `count$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  count(
    params?: {
      filter?: OrganizationFilter;
    },
    context?: HttpContext
  ): Observable<number> {
    return this.count$Response(params, context).pipe(
      map((r: StrictHttpResponse<number>): number => r.body)
    );
  }

  /** Path part for operation `findAll_4()` */
  static readonly FindAll_4Path = '/api/organizations';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findAll()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll$Response(
    params?: {
      filter?: OrganizationFilter;
      take?: number;
      skip?: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<Array<OrganizationDto>>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.FindAll_4Path, 'get');
    if (params) {
      rb.query('filter', params.filter, {"style":"deepObject","explode":true});
      rb.query('take', params.take, {});
      rb.query('skip', params.skip, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<Array<OrganizationDto>>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findAll$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findAll(
    params?: {
      filter?: OrganizationFilter;
      take?: number;
      skip?: number;
    },
    context?: HttpContext
  ): Observable<Array<OrganizationDto>> {
    return this.findAll$Response(params, context).pipe(
      map((r: StrictHttpResponse<Array<OrganizationDto>>): Array<OrganizationDto> => r.body)
    );
  }

  /** Path part for operation `findOne_4()` */
  static readonly FindOne_4Path = '/api/organizations/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `findOne()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationDto>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.FindOne_4Path, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `findOne$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  findOne(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<OrganizationDto> {
    return this.findOne$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `validationConditions()` */
  static readonly ValidationConditionsPath = '/api/organizations/validation-conditions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `validationConditions()` instead.
   *
   * This method doesn't expect any request body.
   */
  validationConditions$Response(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<ValidationConditionsDto>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.ValidationConditionsPath, 'get');
    if (params) {
      rb.path('id', params.id, {});
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<ValidationConditionsDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `validationConditions$Response()` instead.
   *
   * This method doesn't expect any request body.
   */
  validationConditions(
    params: {
      id: number;
    },
    context?: HttpContext
  ): Observable<ValidationConditionsDto> {
    return this.validationConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<ValidationConditionsDto>): ValidationConditionsDto => r.body)
    );
  }

  /** Path part for operation `updateMoaValidationConditions()` */
  static readonly UpdateMoaValidationConditionsPath = '/api/organizations/update-moa-validation-conditions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMOAValidationConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMOAValidationConditions$Response(
    params: {
      id: number;
      body: ValidationConditionsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationDto>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.UpdateMoaValidationConditionsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMOAValidationConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMOAValidationConditions(
    params: {
      id: number;
      body: ValidationConditionsDto
    },
    context?: HttpContext
  ): Observable<OrganizationDto> {
    return this.updateMOAValidationConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

  /** Path part for operation `updateMsiValidationConditions()` */
  static readonly UpdateMsiValidationConditionsPath = '/api/organizations/update-msi-validation-conditions/{id}';

  /**
   * This method provides access to the full `HttpResponse`, allowing access to response headers.
   * To access only the response body, use `updateMSIValidationConditions()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMSIValidationConditions$Response(
    params: {
      id: number;
      body: ValidationConditionsDto
    },
    context?: HttpContext
  ): Observable<StrictHttpResponse<OrganizationDto>> {
    const rb = new RequestBuilder(this.rootUrl, OrganizationService.UpdateMsiValidationConditionsPath, 'patch');
    if (params) {
      rb.path('id', params.id, {});
      rb.body(params.body, 'application/json');
    }

    return this.http.request(
      rb.build({ responseType: 'json', accept: 'application/json', context })
    ).pipe(
      filter((r: any): r is HttpResponse<any> => r instanceof HttpResponse),
      map((r: HttpResponse<any>) => {
        return r as StrictHttpResponse<OrganizationDto>;
      })
    );
  }

  /**
   * This method provides access only to the response body.
   * To access the full response (for headers, for example), `updateMSIValidationConditions$Response()` instead.
   *
   * This method sends `application/json` and handles request body of type `application/json`.
   */
  updateMSIValidationConditions(
    params: {
      id: number;
      body: ValidationConditionsDto
    },
    context?: HttpContext
  ): Observable<OrganizationDto> {
    return this.updateMSIValidationConditions$Response(params, context).pipe(
      map((r: StrictHttpResponse<OrganizationDto>): OrganizationDto => r.body)
    );
  }

}
