import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { CashFlowUpdateExtDto } from '@app-com/api/models';
import { PageIds, UiFieldCtrDef, ValidationPatterns } from '@app-com/constants/patterns';
import { ResourcePipe } from '@app-com/pipes';
import { CommUtilsService } from '@app-com/services/comm-utils.service';
import {
  AutosaveCashFlowUpdateContact,
  ResetCashFlowValidationResult,
  SetCashFlowUpdateContact,
  SetCashFlowUpdateStep,
  SetCashFlowUpdateStepperStatus,
  SetCashFlowValidationResult,
} from '@app-pot/store/actions/cash-flow-update.action';
import { CashFlowUpdateState } from '@app-pot/store/state/cash-flow-update.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription, debounceTime, skipUntil, takeWhile, timer } from 'rxjs';
import _ from 'lodash';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { CashFlowFormSequence } from '../enum';
import { SetCurrentCashFlowUpdateStep } from '@app-pot/store/actions/current-context.action';

@Component({
  selector: 'app-cash-flow-contacts',
  templateUrl: './cash-flow-contacts.component.html',
  styleUrl: './cash-flow-contacts.component.scss',
})
export class CashFlowContactsComponent implements OnInit, OnDestroy {
  public PageIds = PageIds;
  CurrentPageDef = PageIds.cashFlow.contact;
  public ValidationPatterns = ValidationPatterns;
  pageId = 'CASH_FLOW_UPDATES';

  contactForm: FormGroup;
  UiDefFirstName: UiFieldCtrDef;
  UiDefLastName: UiFieldCtrDef;
  UiDefPhone: UiFieldCtrDef;
  UiDefEmail: UiFieldCtrDef;
  pageUiDefs: UiFieldCtrDef[] = [];
  timeoutIds: ReturnType<typeof setTimeout>[] = [];
  isAlive = true;
  isAutosaveNeeded = false;
  sub = new Subscription();

  @Select(CashFlowUpdateState.getCashFlowUpdateCta) cashFlowCta$: Observable<
    'cancel' | 'confirm' | 'save' | 'previous'
  >;

  @Select(CashFlowUpdateState.getCashFlowUpdate)
  cashFlow$: Observable<CashFlowUpdateExtDto>;
  @Select(CashFlowUpdateState.getCashFlowUpdateValidationResult) cashFlowisValid$: Observable<boolean>;
  storeCashFlowUpdate: CashFlowUpdateExtDto;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private store: Store,
    private router: Router,
  ) {}

  createContactForm() {
    this.contactForm = this.formBuilder.group({});
    this.UiDefFirstName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.firstName);
    this.contactForm.addControl(this.UiDefFirstName.nameCtr, this.UiDefFirstName.formCtr);

    this.UiDefLastName = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.lastName);
    this.contactForm.addControl(this.UiDefLastName.nameCtr, this.UiDefLastName.formCtr);

    this.UiDefPhone = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.phone);
    this.contactForm.addControl(this.UiDefPhone.nameCtr, this.UiDefPhone.formCtr);

    this.UiDefEmail = CommUtilsService.makeUiFieldCtrDef(this.CurrentPageDef.email);
    this.contactForm.addControl(this.UiDefEmail.nameCtr, this.UiDefEmail.formCtr);

    this.pageUiDefs = [this.UiDefFirstName, this.UiDefLastName, this.UiDefPhone, this.UiDefEmail];

    this.contactForm.setValue({
      [this.UiDefFirstName.nameCtr]: '',
      [this.UiDefLastName.nameCtr]: '',
      [this.UiDefPhone.nameCtr]: '',
      [this.UiDefEmail.nameCtr]: '',
    });
  }

  pullInitContactData() {
    const storeCashFlowUpdate = this.store.selectSnapshot(CashFlowUpdateState.getCashFlowUpdate);

    if (storeCashFlowUpdate) {
      this.contactForm?.patchValue({
        [this.UiDefFirstName.nameCtr]: storeCashFlowUpdate.contactFirstName ?? '',
        [this.UiDefLastName.nameCtr]: storeCashFlowUpdate.contactLastName ?? '',
        [this.UiDefPhone.nameCtr]: storeCashFlowUpdate.contactPhoneNumber ?? '',
        [this.UiDefEmail.nameCtr]: storeCashFlowUpdate.contactEmailAddress ?? '',
      });
    }
  }

  jumpToField(fieldName: string) {
    const fieldElement = document.getElementById(fieldName);
    if (fieldElement) {
      fieldElement.scrollIntoView({ behavior: 'smooth', block: 'start', inline: 'nearest' });
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  acceptPhoneNumberOnly(event: { which: any; keyCode: any }): boolean {
    const charCode = event.which ? event.which : event.keyCode;
    return (
      (charCode >= 48 && charCode <= 57) || charCode == 45 // [0-9] or '-'
      // || (charCode == 40) || (charCode == 41) || (charCode == 32) // [(Space)]
    );
  }

  onFocusIn(UiDef: UiFieldCtrDef, setAsTouched = false) {
    console.log('focus in: ' + UiDef.nameCtr + ' setAsTouched= ' + setAsTouched);
    if (setAsTouched) {
      UiDef.formCtr.markAsTouched();
    }
    UiDef.focusedOutFieldByTrueBlurEvent = false;
    UiDef.focusedInNonBlankOrErrorField =
      (!!UiDef.errorMsg && UiDef.errorMsg.length > 0) ||
      CommUtilsService.isCtrValueNonBlank(UiDef.formCtr.value, UiDef.nameCtr);
  }

  onFocusOut(UiDef: UiFieldCtrDef, isBlurAway: boolean) {
    if (isBlurAway) {
      UiDef.focusedOutFieldByTrueBlurEvent = true;
    }
    this.timeoutIds.push(
      setTimeout(() => {
        // Goa-input 1.16.0 has a bug, on blur, it fires:
        // 1. focusOut-event with null value first (even user picked an item in drop-down-list
        // 2. formControl-change-event with user-selected-valid-key after focusOut event
        // 3. old library fire focusOut-event with valid-ket last
        // to support both old and new library behaviour. I delayed the focusOut-event-process to avoid wrong-empty-key-error
        CommUtilsService.getUiFieldErrorList(UiDef, isBlurAway, 'focusOut');
      }, 400),
    );
  }

  // convert page UI-data into model, before sending to back-end
  getContact(): Pick<
    CashFlowUpdateExtDto,
    'contactFirstName' | 'contactLastName' | 'contactEmailAddress' | 'contactPhoneNumber'
  > {
    const uiValue = this.contactForm.value;
    console.log('[Cash-flow-contacts]GetContact', uiValue);

    return {
      contactFirstName: uiValue[this.UiDefFirstName.nameCtr] ?? '',
      contactLastName: uiValue[this.UiDefLastName.nameCtr] ?? '',
      contactEmailAddress: uiValue[this.UiDefEmail.nameCtr] ?? '',
      contactPhoneNumber: uiValue[this.UiDefPhone.nameCtr] ?? '',
    };
  }

  get showErrorFieldsCallout(): boolean {
    const baseHasError = this.pageUiDefs.findIndex((uiDef) => (uiDef.errorMsg ?? []).length > 0) >= 0;
    return baseHasError;
  }

  ngOnDestroy(): void {
    this.isAlive = false;
    this.sub.unsubscribe();
    if (this.timeoutIds) {
      this.timeoutIds.forEach((id) => {
        clearTimeout(id);
      });
    }
  }

  ngOnInit(): void {
    this.sub.add(
      this.cashFlow$.subscribe((storeCashFlowUpdate) => {
        this.storeCashFlowUpdate = storeCashFlowUpdate;
        this.contactForm?.patchValue({
          [this.UiDefFirstName.nameCtr]: storeCashFlowUpdate.contactFirstName ?? '',
          [this.UiDefLastName.nameCtr]: storeCashFlowUpdate.contactLastName ?? '',
          [this.UiDefPhone.nameCtr]: storeCashFlowUpdate.contactPhoneNumber ?? '',
          [this.UiDefEmail.nameCtr]: storeCashFlowUpdate.contactEmailAddress ?? '',
        });
      }),
    );
    this.sub.add(
      this.cashFlowCta$.subscribe((cta) => {
        if (cta && cta.indexOf('confirm') >= 0) {
          console.log('Validate');
          if (this.contactForm && this.validateContact()) {
            this.store.dispatch(new SetCashFlowValidationResult(true));
          }
        }
        if (cta && cta.indexOf('save') >= 0) {
          console.log('Save and close');
          const contact = this.getContact();
          this.store.dispatch(new SetCashFlowUpdateContact(contact));
        }
      }),
    );
    this.sub.add(
      this.cashFlowisValid$.subscribe((isValid) => {
        console.log('IsValid', isValid);
        if (isValid) {
          this.store.dispatch(new ResetCashFlowValidationResult());
          this.store.dispatch(
            new SetCashFlowUpdateStepperStatus({
              [CashFlowFormSequence.ContactInfo]: FormStatusCodes.Complete,
              [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
            }),
          );
          this.store.dispatch(new SetCashFlowUpdateStep(2));
          this.store.dispatch(new SetCurrentCashFlowUpdateStep(2)); // Persist current cash flow update step in session storage to fix page refresh issues
          this.router.navigate(['cash-flow-updates/application-funding']);
        }
      }),
    );
    this.createContactForm();
    this.pullInitContactData();
    this.pageUiDefs.forEach((uiDef) => {
      uiDef.formCtr.valueChanges
        .pipe(takeWhile(() => this.isAlive))
        .pipe(debounceTime(300))
        .subscribe(() => {
          if (uiDef.focusedInNonBlankOrErrorField || uiDef.focusedOutFieldByTrueBlurEvent) {
            CommUtilsService.getUiFieldErrorList(uiDef, uiDef.focusedOutFieldByTrueBlurEvent ?? false, 'valueChanges');
          }
        });
    });

    this._registerChangeObserverForAutosaveFlag();
  }

  //This is to prevent autosave from being triggered when a user simply tabs through input fields.
  private _registerChangeObserverForAutosaveFlag() {
    this.contactForm.valueChanges
      .pipe(
        takeWhile(() => this.isAlive),
        skipUntil(timer(500)), //When the form is open in edit mode and values are being populated, the change event may trigger multiple times. This is to skip those initial changes.
      )
      .subscribe(() => (this.isAutosaveNeeded = true));
  }

  autosave() {
    if (!this.isAutosaveNeeded) return;
    this.isAutosaveNeeded = false;
    const contact = this.getContact(); // form contact data
    const { contactFirstName, contactLastName, contactEmailAddress, contactPhoneNumber } = this.storeCashFlowUpdate;
    const storeCashFlowUpdateContact = { contactFirstName, contactLastName, contactEmailAddress, contactPhoneNumber };

    if (
      (!contact.contactFirstName.trim() &&
        !contact.contactLastName.trim() &&
        !contact.contactEmailAddress.trim() &&
        !contact.contactPhoneNumber.trim()) ||
      _.isEqual(contact, storeCashFlowUpdateContact)
    ) {
      return;
    }

    this.store.dispatch(new AutosaveCashFlowUpdateContact(contact));
  }

  validateContact() {
    if (this.contactForm) {
      this.contactForm?.markAllAsTouched();

      this.pageUiDefs.forEach((uiDef) => {
        CommUtilsService.getUiFieldErrorList(uiDef, true, 'nextCheckError');
      });
      return this.contactForm.valid;
    }
    return true;
  }
}
