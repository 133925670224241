import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';

@Component({
  selector: 'app-edit-sepo-statement-expenditures',
  templateUrl: './edit-sepo-statement-expenditures.component.html',
  styleUrl: './edit-sepo-statement-expenditures.component.scss',
})
export class EditSepoStatementExpendituresComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  currentStep: EditSepoSequence | undefined;

  constructor(
    private store: Store,
    private router: Router,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.StatementExpenditures) {
          console.log(
            'file: edit-sepo-statement-expenditures.component.ts:45 ~ EditSepoStatementExpendituresComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.router.navigate(['edit-sepo/immediate-outcomes']);
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/asset-management-and-funds');
            this.router.navigate(['edit-sepo/asset-management-and-funds']);
          }
        }
      }),
    );
  }

  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    return true;
  }
}
