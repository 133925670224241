import { Directive, ElementRef, HostListener, Input } from '@angular/core';
/**
 * Angular Directive: PositiveDecimalDirective
 *
 * This directive allows the user to enter numbers with thousands separators and a specified number of decimal places.
 * It formats the input value by adding commas as thousands separators and limits the decimal places as per 'maxDecimalPlaces'.
 * It ensures that only positive numbers are allowed.
 * The directive also removes leading zeros and handles the entry of a decimal point correctly.
 *
 * Usage:
 * Add the 'positiveDecimal' attribute to the input element to apply this formatting and validation.
 * Example: <input type="text" ng-model="myModel" positiveDecimal>
 *
 * @Input maxDecimalPlaces: Specifies the maximum number of decimal places allowed (default: 2).
 *
 * @HostListener 'blur': Empty the input element if the value is zero.
 * @HostListener 'input': Formats the input value as the user types and enforces validation rules.
 */
@Directive({
  standalone: true,
  selector: '[positiveDecimal]',
})
export class PositiveDecimalDirective {
  @Input() maxDecimalPlaces = 2; // Default to 2 decimal places

  constructor(private el: ElementRef) {}

  @HostListener('blur', ['$event']) onBlur() {
    const inputElement = this.el.nativeElement.shadowRoot.querySelector('input');
    if (inputElement) {
      const inputValue = inputElement.value ?? '';
      if (inputValue && inputValue.length > 0) {
        const parsedNumber = parseFloat(inputValue.replace(/, /g, ''));
        if (parsedNumber === 0) {
          inputElement.value = '';
        } else {
          inputElement.value = formatDecimalWithComma(parsedNumber.toString());
        }
      }
    }
  }

  @HostListener('input', ['$event']) onInputChange() {
    const inputElement = this.el.nativeElement.shadowRoot.querySelector('input');
    let inputValue = inputElement.value;

    inputValue = formatDecimalWithComma(inputValue);

    inputElement.value = inputValue;
  }
}

/**
 * This function can format positive decimals with commas to separate thousands when
 * populating default values or editing forms from a TypeScript file.
 * @param inputValue
 * @param maxDecimalPlaces
 * @returns
 */
export function formatDecimalWithComma(
  inputValue: string | number | undefined,
  maxDecimalPlaces: number = 2,
): string | null {
  if (inputValue == null || inputValue == undefined) return null;
  const valueString = inputValue.toString();
  // Remove any non-numeric characters except the first decimal point
  inputValue = valueString.replace(/[^\d.]/g, '');

  // Split the input value into integer and decimal parts
  const parts = inputValue.split('.');
  let integerPart = parts[0] || '';
  const decimalPart = parts[1] || '';

  // Ensure the integer part is a positive number
  if (integerPart === '' && inputValue.indexOf('.') > -1) {
    integerPart = '0';
  } else if (integerPart.charAt(0) === '-') {
    integerPart = integerPart.substring(1);
  }

  // Remove leading zeros except for a single zero
  integerPart = integerPart.replace(/^0+(?!$)/, '');

  // Format the integer part with comma+space
  integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ', ');

  // Combine the formatted integer and decimal parts
  let formattedValue = integerPart;

  if (inputValue.indexOf('.') > -1) {
    // Limit decimal places to maxDecimalPlaces
    if (decimalPart.length > maxDecimalPlaces) {
      formattedValue += '.' + decimalPart.slice(0, maxDecimalPlaces);
    } else {
      formattedValue += '.' + decimalPart;
    }
  }

  return formattedValue;
}
