import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { FormStatusCodes } from '@app-pot/features/grant-application/models/enums';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import {
  SetEditSepoStepperStatus,
  SetEditSepoCurrentStep,
  SetEditSepoButtonAction,
} from '@app-pot/store/actions/edit-sepo.action';
import { EditSepoState } from '@app-pot/store/state/edit-sepo.state';
import { Select, Store } from '@ngxs/store';
import { Subscription, Observable } from 'rxjs';
import { EditSepoSequence } from '../enum/edit-sepo-sequence.enum';
import { TableHeader } from '@app-com/components/lgff-goa-nested-rows-table/lgff-goa-nested-rows-table.types';

@Component({
  selector: 'app-edit-sepo-statement-expenditures',
  templateUrl: './edit-sepo-statement-expenditures.component.html',
  styleUrl: './edit-sepo-statement-expenditures.component.scss',
})
export class EditSepoStatementExpendituresComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'SEPO';
  sub = new Subscription();
  @Select(EditSepoState.getEditSepoButtonAction) editSepoButtonAction$: Observable<
    'cancel' | 'save' | 'previous' | 'next' | 'submit' | 'empty'
  >;
  @Select(EditSepoState.getEditSepoCurrentStep) currentStep$: Observable<EditSepoSequence>;
  currentStep: EditSepoSequence | undefined;
  headers: TableHeader[] = [];
  additionalHeaders: TableHeader[] = [];

  totalLgffFundingAvailable: number = 0;
  parentColumnIndent = 3;
  //STATIC DATA FOR DEV TESTING only
  applications = [
    {
      applicationId: 'APP-00001',
      applicationName: 'Energy efficiency projects',
      applicationStatus: 'In progress',
      projects: [
        {
          projectId: 'APP-00001-01',
          projectName: 'Energy management',
          projectStatus: '',
          estimatedFunding: 140000000,
          fundingRemaining: 140000000,
        },
        {
          projectId: 'APP-00001-02',
          projectName: 'Walden intersection',
          projectStatus: '',
          estimatedFunding: 140000000,
          fundingRemaining: 140000000,
        },
      ],
    },
    {
      applicationId: 'APP-00002',
      applicationName: 'Highway construction',
      applicationStatus: 'In progress',
      projects: [
        {
          projectId: 'APP-00002-01',
          projectName: 'Queen Elizabeth Highway',
          projectStatus: '',
          estimatedFunding: 140000000,
          fundingRemaining: 140000000,
        },
        {
          projectId: 'APP-00002-02',
          projectName: 'Anthony Henday paving',
          projectStatus: '',
          estimatedFunding: 140000000,
          fundingRemaining: 140000000,
        },
        {
          projectId: 'APP-00002-03',
          projectName: 'Edgemont intersection',
          projectStatus: '',
          estimatedFunding: 140000000,
          fundingRemaining: 140000000,
        },
      ],
    },
  ];

  totals = [
    {
      key: 'totalestimatedLgffFunding',
      value: 123456,
    },
    {
      key: 'totalLgffFundingRemaining',
      value: 123456,
    },
    {
      key: 'totalActualReportingYearCost',
      value: 123456,
    },
    {
      key: 'totalPortionFundedByOther',
      value: 123456,
    },
    {
      key: 'totalLgffFundingApplied',
      value: 123456,
    },
    {
      key: 'totalPortionFundedByMunicipality',
      value: 123456,
    },
  ];

  constructor(
    private store: Store,
    private router: Router,
  ) {
    super();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.initTableConfig();
    this.sub.add(
      this.currentStep$?.subscribe((currentStep) => {
        this.currentStep = currentStep;
        if (this.currentStep < EditSepoSequence.StatementExpenditures) {
          console.log(
            'file: edit-sepo-statement-expenditures.component.ts:45 ~ EditSepoStatementExpendituresComponent ~ this.currentStep$.subscribe ~ this.currentStep:',
            this.currentStep,
          );
          //this.router.navigate(['edit-sepo']);
        }
      }),
    );
    this.sub.add(
      this.editSepoButtonAction$?.subscribe((buttonAction) => {
        if (buttonAction && buttonAction.indexOf('next') >= 0) {
          if (this.validateOnNext()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.Complete,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.ImmediateOutcomes));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            this.router.navigate(['edit-sepo/immediate-outcomes']);
          }
        }
        if (buttonAction && buttonAction.indexOf('previous') >= 0) {
          if (this.validateOnPrevious()) {
            this.store.dispatch(
              new SetEditSepoStepperStatus({
                [EditSepoSequence.ContactInfo]: FormStatusCodes.Complete,
                [EditSepoSequence.AssetManagementFunds]: FormStatusCodes.Complete,
                [EditSepoSequence.StatementExpenditures]: FormStatusCodes.NotStarted,
              }),
            );
            this.store.dispatch(new SetEditSepoCurrentStep(EditSepoSequence.AssetManagementFunds));
            //We have to reset the buttonAction to "empty" so that other sub-components don't act on the "next"
            this.store.dispatch(new SetEditSepoButtonAction(['empty']));
            console.log('return to previous : edit-sepo/asset-management-and-funds');
            this.router.navigate(['edit-sepo/asset-management-and-funds']);
          }
        }
      }),
    );
  }

  initTableConfig() {
    this.headers = [
      {
        fieldName: 'applicationId',
        displayName: 'Application ID',
        headerClassName: 'header-bg-gray w-144',
        cellClassName: 'w-144',
      },
      {
        fieldName: 'applicationName',
        displayName: 'Application name',
        headerClassName: 'header-bg-gray w-160',
        cellClassName: 'w-160',
      },
      {
        fieldName: 'applicationStatus',
        displayName: 'Application status',
        headerClassName: 'header-bg-gray w-144',
        cellClassName: 'w-144',
      },
      {
        fieldName: 'projectId',
        displayName: 'Project ID',
        headerClassName: 'header-bg-gray w-141',
        cellClassName: 'w-141',
      },
      {
        fieldName: 'projectName',
        displayName: 'Project name',
        headerClassName: 'header-bg-gray w-165',
        cellClassName: 'w-165',
      },
      { fieldName: 'edit', displayName: 'Edit', headerClassName: 'header-bg-gray w-64', cellClassName: 'w-64' },
      {
        fieldName: 'projectStatus',
        displayName: 'Project status',
        headerClassName: 'header-bg-gray w-181',
        cellClassName: 'w-181',
      },
      {
        fieldName: 'estimatedFunding',
        displayName: 'Total estimated LGFF funding',
        headerClassName: 'w-190',
        cellClassName: 'w-190',
      },
      {
        fieldName: 'fundingRemaining',
        displayName: 'LGFF funding remaining',
        headerClassName: 'w-190',
        cellClassName: 'w-190',
      },
      {
        fieldName: 'reportingYearCost',
        displayName: 'Actual reporting year cost',
        cellClassName: 'w-190',
        headerClassName: 'header-bg-gray w-190',
      },
      {
        fieldName: 'fundedByOtherGrants',
        displayName: 'Portion funded by other grant programs',
        cellClassName: 'w-190',
        headerClassName: 'header-bg-gray w-190',
      },
      {
        fieldName: 'lgffFundingApplied',
        displayName: 'LGFF funding applied',
        headerClassName: 'header-bg-gray w-190',
        cellClassName: 'w-190',
      },
      {
        fieldName: 'fundedByMunicipalSources',
        displayName: 'Portion funded from municipal sources',
        cellClassName: 'w-190',
        headerClassName: 'header-bg-gray w-190',
      },
    ];

    this.additionalHeaders = [
      { displayName: 'Application and project details', colspan: 7, headerClassName: 'header-bg-gray', fieldName: '' },
      { displayName: 'Application financials', colspan: 2, fieldName: '' },
      { displayName: 'Reporting year qualifying cost', colspan: 4, headerClassName: 'header-bg-gray', fieldName: '' },
    ];
  }

  validateOnPrevious(): boolean {
    return true;
  }
  validateOnNext(): boolean {
    return true;
  }
}
