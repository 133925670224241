import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: ['./dialog.component.scss'],
})
export class DialogComponent {
  @Input() isOpen: boolean;
  @Output() closeDialog = new EventEmitter<{ selectedLocationConfirmed: boolean }>();

  closeModal(selectedLocationConfirmed: boolean) {
    this.closeDialog.emit({ selectedLocationConfirmed });
  }
}
