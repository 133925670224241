<goa-one-column-layout>
  <section slot="header">
    <goa-microsite-header type="alpha"></goa-microsite-header>
    <div id="app-header-1">
      <div style="padding: 12px 0px 16px 0; display: flex">
        <div class="align-logo">
          <a target="_blank" href="https://www.alberta.ca/">
            <app-svg-alberta-logo></app-svg-alberta-logo>
          </a>
        </div>
        <div>
          <label id="logo-label"
            ><abbr id="logo-label-abbr" title="{{ 'LGFF' | res: 'COMMON' }}">LGFF</abbr> Capital</label
          >
        </div>
      </div>
    </div>
    <goa-divider></goa-divider>
  </section>
  <router-outlet></router-outlet>
  <section slot="footer">
    <app-pot-footer></app-pot-footer>
  </section>
</goa-one-column-layout>
