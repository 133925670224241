import { Injectable } from '@angular/core';
import {
  UiFieldCtrDef,
  UiFieldDef,
  UiFieldDefaultMaxLength,
  DateOnOrLaterThanSysStartDate,
  DateOnOrLaterThanSpecified,
  ValidationPatterns,
} from '../constants/patterns';
import { AbstractControl, FormControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { environment } from '@app-pot-env/environment';
import { MunicipalInternalAuditDto, WorknoteDto } from '@app-com/api/models';

@Injectable({
  providedIn: 'root',
})
export class CommUtilsService {
  /*America/Edmonton - Refers to the Mountain Time Zone, which includes both Mountain Standard Time (MST) and Mountain Daylight Time (MDT).
    Automatically accounts for daylight saving time changes.
    When DST is in effect, it uses MDT (UTC-6). When DST is not in effect, it uses MST (UTC-7).*/
  static TIME_ZONE = 'America/Edmonton';

  private static systemStartDate: Date = (() => {
    const systemStartDateStr = environment.systemStartDate as string;
    if (systemStartDateStr && systemStartDateStr.length > 0) {
      const setDate = new Date(systemStartDateStr);
      if (
        setDate &&
        Object.prototype.toString.call(setDate) === '[object Date]' &&
        setDate.toString() !== 'Invalid Date' &&
        !isNaN(setDate.getTime())
      ) {
        return setDate;
      }
    }
    return new Date();
  })();

  public static getIntFromStrOrNumber(inVal: string | number | null | undefined, defVal = 0): number {
    let retVal = defVal;
    if (inVal) {
      if (typeof inVal === 'number') {
        retVal = inVal;
      } else if (typeof inVal === 'string') {
        // TODO: negative - () process
        const numStr = inVal.replace(/[^0-9]/g, '');
        retVal = parseInt(numStr, 10) || defVal;
      }
    }
    return retVal;
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getAmountValueToSort = (item: any, colName: string) => {
    const amount = item[colName] ?? '0';
    const sortAmount = CommUtilsService.getIntFromStrOrNumber(amount);
    return sortAmount;
  };

  // Seems this method is not used anywhere
  public static isUrl(url: string): boolean {
    return url.indexOf('.') > 0 || url.indexOf('//') >= 0;
  }

  // Seems this method is not used anywhere
  public static isEmail(url: string): boolean {
    return url.indexOf('@') > 0 && url.indexOf('.') > 0;
  }

  // Seems this method is not used anywhere
  public static getMailTo(email: string): string {
    if (email?.length > 0) {
      return 'mailto: ' + email;
    } else {
      return '';
    }
  }

  public static combineTwoStringWithComma(str1?: string, str2?: string): string {
    const str1Valid = !!str1 && str1.length > 0;
    const str2Valid = !!str2 && str2.length > 0;
    if (str1Valid && str2Valid) {
      return str1 + ',' + str2;
    } else if (str1Valid) {
      return str1;
    } else if (str2Valid) {
      return str2;
    } else {
      return '';
    }
  }

  // Seems this method is not used anywhere
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static getErrorString(error: any): string {
    let errorStr = 'Error status code= ' + (error?.status ?? error.error?.error?.statusCode);
    // if (error?.statusText?.length > 0) {
    //   errorStr += '; ' + error?.statusText;
    // }
    errorStr += '; message= ' + (error?.message ?? error.error?.error?.message);
    return errorStr;
  }

  public static combineAddress(add1?: string, add2?: string, city?: string, prov?: string, postal?: string): string {
    let combAdd1 = '';
    let combAdd2 = '';
    if (!!add1 || !!add2) {
      combAdd1 = CommUtilsService.combineTwoStringWithComma(add1, add2);
    }
    if (!!city || !!prov) {
      combAdd2 = CommUtilsService.combineTwoStringWithComma(city, prov);
    }
    let result = '';
    if (combAdd1.trim().length > 0) {
      result += combAdd1;
    }
    if (combAdd2.trim().length > 0) {
      if (result.trim().length > 0) {
        result += '<br>';
      }
      result += combAdd2;
    }
    if (!!postal && postal.trim().length > 0) {
      if (result.trim().length > 0) {
        result += '<br>';
      }
      result += postal;
    }
    return result.trim().length > 0 ? result : '-';
  }

  // Seems this method is not used anywhere
  // return sample: 2024-01-24-T14_42_20-0720
  public static getServerDateTime25String(): string {
    // MST= Mountain Standard Time, when observing standard time
    // MDT= Mountain Daylight Time, when observing daylight saving time, not supported below
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const timeOptions: any = {
      timeZone: 'MST',
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
      hour12: false,
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
    };

    const utcDate = new Date();
    const dateTimeFormat = new Intl.DateTimeFormat('en-ca', timeOptions);
    const sdt = dateTimeFormat.format(utcDate); //03/30/2023, 16:59:24
    const fileName =
      sdt.slice(0, 4) +
      '-' +
      sdt.slice(5, 7) +
      '-' +
      sdt.slice(8, 10) +
      '-T' +
      sdt.slice(12, 14) +
      '_' +
      sdt.slice(15, 17) +
      '_' +
      sdt.slice(18, 20) +
      '-' +
      ('0000' + utcDate.getMilliseconds()).slice(-4);
    return fileName;
  }

  // return sample: 2024-01-24-T14_42_20-0720
  public static getClientDateTime25String(): string {
    const sdt = new Date();
    const fileName =
      '' +
      sdt.getFullYear() +
      '-' +
      (sdt.getMonth() + 1).toString().padStart(2, '0') +
      '-' +
      sdt.getDate().toString().padStart(2, '0') +
      '-T' +
      sdt.getHours().toString().padStart(2, '0') +
      '_' +
      sdt.getMinutes().toString().padStart(2, '0') +
      '_' +
      sdt.getSeconds().toString().padStart(2, '0') +
      '-' +
      sdt.getMilliseconds().toString().padStart(4, '0');
    return fileName;
  }

  public static wrapSpecialCharsInCsv(inStr = ''): string {
    if (!!inStr && inStr.length > 0) {
      const aftBr = inStr.replace(/<br>/g, ' ');
      if (['"', '\r', '\n', ','].some((e) => aftBr.indexOf(e) !== -1)) {
        const outStr = '"' + aftBr.replace(/"/g, '""') + '"';
        return outStr;
      }
      return aftBr;
    } else {
      return inStr;
    }
  }

  // this function accepts a timestamp string and returns a formatted date string
  // the timestamp string is in the format of "2023-03-30T22:59:24.000Z"
  // the formatted date string is in the format of "Mar 30, 2023"
  public static getDateStrMonDdYear(timeStamp: string, isLong = false): string {
    if (timeStamp?.length > 0) {
      const date = new Date(timeStamp);
      const formattedDate = isLong
        ? date.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
            timeZone: CommUtilsService.TIME_ZONE,
          })
        : date.toLocaleDateString('en-CA', {
            year: 'numeric',
            month: 'short',
            day: 'numeric',
            timeZone: CommUtilsService.TIME_ZONE,
          });
      return formattedDate;
    } else {
      return '';
    }
  }

  // this function accepts a timestamp string and returns a formatted date string
  // the timestamp string is in the format of "2023-03-30T22:59:24.000Z"
  // the formatted date string is in the format of "Mar 30, 2023 20:59"
  public static getDateStrMonDdYearHrMn(timeStamp: string): string {
    const date = new Date(timeStamp);
    const formattedDate =
      date.toLocaleDateString('en-CA', { year: 'numeric', month: 'short', day: 'numeric' }) +
      ' ' +
      date.toLocaleTimeString('en-CA', { hour: '2-digit', minute: '2-digit', hour12: false });
    return formattedDate;
  }

  // this function accepts a string and returns true if the string is empty or contains only white space
  public static isStringEmpty(str: string): boolean {
    return !str || !str.trim();
  }

  public static isNotStringEmpty(str: string): boolean {
    return !this.isStringEmpty(str);
  }

  //Works like Object.assign(target, source) but only copy non-null value from source to target.
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static copyNonNullValues(target: any, source: any): void {
    for (const key in source) {
      if (Object.prototype.hasOwnProperty.call(source, key) && source[key] !== null && source[key] !== undefined) {
        target[key] = source[key];
      }
    }
  }

  public static launchWebSite(
    auditUrl: string,
    target = '_blank',
    features = 'resizable=yes, scrollbars=yes, titlebar=yes',
  ) {
    window.open(auditUrl, target, features);
  }

  public static launchAudit(objectType: string, id = 0, subTitle = '-', additionalSubtitle = '-') {
    let launchUrl: string = window.location.origin;
    launchUrl +=
      '/audit/' + objectType + '?id=' + id + '&subTitle=' + subTitle + '&additionalSubtitle=' + additionalSubtitle;
    const width = 1440;
    const options = `width=${width},height=${window.innerHeight},top=0,left=0,resizable=yes,scrollbars=yes,status=yes`;
    CommUtilsService.launchWebSite(launchUrl, '_blank', options);
  }

  public static prepareWorkNoteText(note?: WorknoteDto): string {
    let rtnText = '';
    if (note && note.comments?.length > 0) {
      rtnText += note.comments.trim() + ' (';
      if (note.createdByName?.length > 0) {
        rtnText += note.createdByName.trim() + ', ';
      }
      if (note.createdAt?.length > 0) {
        rtnText += this.getDateStrMonDdYear(note.createdAt);
      }
      rtnText += ')';
    }
    return rtnText;
  }

  public static prepareAuditText(audit?: MunicipalInternalAuditDto, created = false): string {
    let rtnText = '';
    if (audit) {
      const createdLen = audit.createdAt?.length ?? 0;
      const updatedLen = audit.objectChangedAt?.length || 0;
      if (created && createdLen > 0) {
        // || ((createdLen > 0) && (updatedLen > 0) && (audit.createdAt === audit.updatedAt))
        // it is created
        rtnText = 'Created by ';
        rtnText += audit.objectChangedBy?.length > 0 ? audit.objectChangedBy.trim() + ' ' : '';
        rtnText += '(' + CommUtilsService.getDateStrMonDdYearHrMn(audit.createdAt) + ')';
      } else if (updatedLen > 0) {
        rtnText = 'Last updated by ';
        rtnText += audit.objectChangedBy?.length > 0 ? audit.objectChangedBy.trim() + ' ' : '';
        rtnText += '(' + CommUtilsService.getDateStrMonDdYearHrMn(audit.objectChangedAt) + ')';
      }
    }
    return rtnText;
  }

  public static makeOnlyFirstCharUpcase(inStr: string): string {
    const trimmedStr = inStr.trim();
    if (trimmedStr.length > 0) {
      const outStr = trimmedStr[0].toLocaleUpperCase();
      const remainStr = (trimmedStr.substring(1) ?? '').toLocaleLowerCase();
      return outStr + remainStr;
    } else {
      return '';
    }
  }

  // Seems this method is not used anywhere
  public static getValidateErrorRequired(def: UiFieldDef): string {
    let msg = '';
    if (def.errorRequired && def.errorRequired.length > 0) {
      msg = def.errorRequired;
    } else {
      msg = 'Enter a';
      const firstChar = def.labelText ? def.labelText[0] : 'x';
      if ('aeiouAEIOU'.indexOf(firstChar) >= 0) {
        msg += 'n';
      }
      msg += ' ' + (def.labelText?.toLocaleLowerCase() ?? '');
    }
    if (msg[msg.length - 1] !== '.') {
      msg += '.';
    }
    return msg;
  }

  // Seems this method is not used anywhere
  public static getValidateErrorPattern(def: UiFieldDef): string {
    let msg = this.makeOnlyFirstCharUpcase(def.labelText ?? '') + ' input is invalid pattern';
    msg += '.';
    return msg;
  }

  public static getValidateErrorMaxLength(def: UiFieldDef): string {
    const msg =
      this.makeOnlyFirstCharUpcase(def.labelText ?? '') + ' input length is greater than ' + def.maxLength + '.';
    return msg;
  }

  public static parseInputFloat(value?: string): number {
    const parsedValue = value?.replace(/[^0-9.]/g, '').trim();
    if (parsedValue && parsedValue.length > 0) return parseFloat(parsedValue);
    else return 0;
  }

  public static parseInputInt(value?: string): number {
    const parsedValue = value?.replace(/[^0-9]/g, '')?.trim();
    if (parsedValue && parsedValue.length > 0) return parseInt(parsedValue);
    else return 0;
  }

  // validation part

  public static isDateTypeValueValid(inputDate: Date): boolean {
    let isValid = false;
    if (inputDate && Object.prototype.toString.call(inputDate) === '[object Date]') {
      isValid = inputDate.toString() !== 'Invalid Date' && !isNaN(inputDate.getTime());
    }
    return isValid;
  }

  public static isDateStringValueValid(inputDateStr: string): boolean {
    let isValid = false;
    if (inputDateStr) {
      const inputDate = new Date(inputDateStr);
      isValid = this.isDateTypeValueValid(inputDate);
    }
    return isValid;
  }

  public static isDateInputValueValid(UiDef: UiFieldCtrDef): boolean {
    if (!UiDef.formCtr.value) {
      return false;
    }
    if (typeof UiDef.formCtr.value === 'string') {
      const inputValue = UiDef.formCtr.value;
      const numericValue = inputValue.replace(/[^0-9]/g, ''); // Remove non-numeric characters
      if (numericValue.length != 8) {
        // must have mm dd yyyy 8 digitals
        return false;
      }
      const inputDate = new Date(inputValue);
      return this.isDateTypeValueValid(inputDate);
    } else if (UiDef.formCtr.value instanceof Date) {
      const inputDate = UiDef.formCtr.value;
      return this.isDateTypeValueValid(inputDate);
    }
    return false;
  }

  public static parseInputDateString(value?: string): string | undefined {
    if (!value) return undefined;
    const date = new Date(value);
    if (date.toString() === 'Invalid Date') return undefined;
    return date.toLocaleDateString('en-CA', { month: 'short', day: 'numeric', year: 'numeric', timeZone: 'UTC' });
  }

  public static minimumDateValidator(startDateVar: Date): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const inputVal = this.parseInputDateString(control.value) ?? '';
      if (inputVal != '') {
        const inputDate = new Date(inputVal);
        if (startDateVar > inputDate) {
          return { minimumDateError: true };
        }
      }
      return null;
    };
  }

  public static compareDateLessThanValidator(fieldToCompare: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      const fieldToCompareVal = this.parseInputDateString(control.parent?.controls[fieldToCompare].value) ?? '';
      const inputVal = this.parseInputDateString(control.value) ?? '';
      if (inputVal != '' && fieldToCompareVal != '') {
        const endDate = new Date(fieldToCompareVal);
        const startDate = new Date(inputVal);
        if (endDate < startDate) {
          return { compareDateLessThanError: true };
        }
      }
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      if (control.parent?.controls[fieldToCompare].hasError('compareDateGreaterThanError')) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        delete control.parent?.controls[fieldToCompare].errors['compareDateGreaterThanError'];
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        control.parent?.controls[fieldToCompare].updateValueAndValidity();
      }
      return null;
    };
  }

  public static compareDateGreaterThanValidator(fieldToCompare: string): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      const fieldToCompareVal = this.parseInputDateString(control.parent?.controls[fieldToCompare].value) ?? '';
      const inputVal = this.parseInputDateString(control.value) ?? '';
      if (inputVal != '' && fieldToCompareVal != '') {
        const startDate = new Date(fieldToCompareVal);
        const inputDate = new Date(inputVal);
        if (startDate > inputDate) {
          return { compareDateGreaterThanError: true };
        }
      }
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      if (control.parent?.controls[fieldToCompare].hasError('compareDateLessThanError')) {
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        delete control.parent?.controls[fieldToCompare].errors['compareDateLessThanError'];
        // @ts-expect-error @typescript-eslint/ban-ts-comment
        control.parent?.controls[fieldToCompare].updateValueAndValidity();
      }
      return null;
    };
  }

  // Validation base functions
  public static makeUiFieldValidators(UiDef: UiFieldDef): ValidatorFn[] | [] {
    const rules = [];
    if (UiDef.isRequired) {
      rules.push(Validators.required);
    }

    if (UiDef.isPattern && UiDef.patternRule) {
      // @ts-expect-error @typescript-eslint/ban-ts-comment
      rules.push(Validators.pattern(ValidationPatterns[UiDef.patternRule]));
    }

    if (UiDef.maxLength) {
      // Mar 14, 2024, now require to set a max length limit on description:
      // https://goa-dio.atlassian.net/browse/LGFF-1765
      // && UiDef.name !== 'app-general-description') {
      // remove maxLength validation for general info description field https://goa-dio.atlassian.net/browse/LGFF-1421
      rules.push(Validators.maxLength(UiDef.maxLength));
    }

    if (UiDef.isMinimumDate && UiDef.minDateValue && UiDef.errorMinimumDate) {
      rules.push(this.minimumDateValidator(UiDef.minDateValue));
    }
    if (UiDef.dateLessThanField) {
      rules.push(this.compareDateLessThanValidator(UiDef.dateLessThanField));
    }
    if (UiDef.dateGreaterThanField) {
      rules.push(this.compareDateGreaterThanValidator(UiDef.dateGreaterThanField));
    }

    return rules;
  }

  public static makeUiFieldCtrDef(
    fieldDef: UiFieldDef,
    idPrefix = '',
    initState = { value: '', disabled: false },
  ): UiFieldCtrDef {
    const id = fieldDef.name;
    const idWrap = id + '-wrap';
    const isRequired = (fieldDef.errorRequired ?? '').length > 0;

    let errorPattern = fieldDef.errorPattern ?? fieldDef.errorRequired ?? '';
    const isPattern = (fieldDef.patternRule ?? '').length > 0 && errorPattern.length > 0;
    if (!isPattern) {
      errorPattern = '';
    }

    let isMinimumDate = false;
    let minDateValue: Date = new Date();
    const errorMinimumDate = fieldDef.errorMinimumDate ?? fieldDef.errorRequired ?? '';
    if (fieldDef.minimumDateRule && fieldDef.minimumDateRule.length > 0 && errorMinimumDate.length > 0) {
      if (this.areTwoSameIgnoreCase(fieldDef.minimumDateRule, DateOnOrLaterThanSysStartDate)) {
        minDateValue = CommUtilsService.systemStartDate;
        isMinimumDate = true;
      } else if (
        this.areTwoSameIgnoreCase(fieldDef.minimumDateRule, DateOnOrLaterThanSpecified) &&
        !!fieldDef.minimumDateSpecified &&
        this.isDateStringValueValid(fieldDef.minimumDateSpecified)
      ) {
        minDateValue = new Date(fieldDef.minimumDateSpecified);
        isMinimumDate = true;
      }
    }

    const maxLength = fieldDef.maxLength && fieldDef.maxLength > 0 ? fieldDef.maxLength : UiFieldDefaultMaxLength;

    let errorMinValue = fieldDef.errorMinValue ?? fieldDef.errorRequired ?? '';
    const minValue = fieldDef.minValue;
    if (!minValue) {
      errorMinValue = '';
    }

    const fieldDefParsed: UiFieldDef = {
      ...fieldDef,
      id,
      idWrap,
      isRequired,

      errorPattern,
      isPattern,

      maxLength,

      errorMinValue,
      minValue,

      errorMinimumDate,
      isMinimumDate,
      minDateValue,
    };

    const fieldRule = this.makeUiFieldValidators(fieldDefParsed);
    const fieldCtr = fieldRule.length > 0 ? new FormControl(initState, { validators: fieldRule }) : new FormControl('');
    //new FormControl(initState, { updateOn: 'blur', validators: fieldRule }) : new FormControl('');

    const fieldCtrDef = {
      ...fieldDefParsed,
      helptext: fieldDef.helpTextRaw ?? '',
      placeholder: fieldDef.placeHolderRaw ?? '',
      formCtr: fieldCtr,
      focusedInNonBlankOrErrorField: false,
      focusedOutFieldByTrueBlurEvent: false,
    };
    if (idPrefix != '') {
      fieldCtrDef.id = idPrefix + fieldCtrDef.id;
      fieldCtrDef.idWrap = idPrefix + fieldCtrDef.idWrap;
    }
    if (fieldCtrDef.helpTextRaw) {
      delete fieldCtrDef.helpTextRaw;
    }
    if (fieldCtrDef.placeHolderRaw) {
      delete fieldCtrDef.placeHolderRaw;
    }
    return fieldCtrDef;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public static isCtrValueNonBlank(value: string | number | null | undefined, ctrName: string): boolean {
    if (value == null || value == undefined) return false;
    if (typeof value === 'number') {
      // console.warn('isCtrValueNonBlank value= ' + value + ', ctrName= ' + ctrName);
      return true; // (value > 0); non-null number will treat as valid now, include 0
    } else if (typeof value === 'string') {
      return value.toString().trim().length > 0;
    } else {
      // console.warn('isCtrValueNonBlank value non-string and non-number type');
      return false;
    }
  }

  public static isStringArraySame(ary1?: string[], ary2?: string[]): boolean {
    const ary1Valid = ary1 && ary1.length > 0;
    const ary2Valid = ary2 && ary2.length > 0;
    if (ary1Valid != ary2Valid) return false;
    if (!ary1Valid && !ary2Valid) return true;
    if (ary1?.length != ary2?.length) return false;
    const beSame = ary1?.join() === ary2?.join();
    // console.log('isStringArraySame: ' + beSame + 'ary1 ' + ary1?.join() + ' ary2 ' + ary2?.join());
    return beSame;
  }

  public static getUiFieldErrorList(UiDef: UiFieldCtrDef, isBlurAway: boolean, eventName: string) {
    console.log(eventName + ': ' + UiDef.nameCtr + ' value= ' + UiDef.formCtr.value + ' blur= ' + isBlurAway);
    if (!isBlurAway && !UiDef.focusedInNonBlankOrErrorField && !UiDef.focusedOutFieldByTrueBlurEvent) return;

    // BlurAway or re-enter or previous-blur-without-value need following check
    const curCtl = UiDef.formCtr;
    const errorMsgs = [];
    const origValue = UiDef.formCtr.value;
    let trimedValue = origValue;
    if (origValue && typeof origValue == 'string') {
      trimedValue = origValue.trimStart();
    }
    if (isBlurAway && trimedValue && typeof trimedValue == 'string') {
      trimedValue = trimedValue.trimEnd();
    }

    if (UiDef.isRequired || UiDef.isPattern) {
      if (trimedValue !== origValue) {
        UiDef.formCtr.setValue(trimedValue);
        UiDef.formCtr.updateValueAndValidity(); // let angular update its valid state
      }
    }

    if (curCtl.hasError('required') && UiDef.isRequired && UiDef.errorRequired) {
      errorMsgs.push(UiDef.errorRequired);
    }
    if (
      curCtl.hasError('pattern') &&
      UiDef.isPattern &&
      UiDef.errorPattern &&
      !errorMsgs.includes(UiDef.errorPattern)
    ) {
      errorMsgs.push(UiDef.errorPattern);
    }
    if (curCtl.hasError('minValue') && UiDef.errorMinValue && !errorMsgs.includes(UiDef.errorMinValue)) {
      errorMsgs.push(UiDef.errorMinValue);
    }
    if (curCtl.hasError('maxlength')) {
      if (UiDef.errorMaxLength && !errorMsgs.includes(UiDef.errorMaxLength)) {
        errorMsgs.push(UiDef.errorMaxLength);
      } else {
        const fieldMaxLenError = this.getValidateErrorMaxLength(UiDef);
        if (fieldMaxLenError && !errorMsgs.includes(fieldMaxLenError)) {
          errorMsgs.push(fieldMaxLenError);
        }
      }
    }

    if (curCtl.hasError('minimumDateError') && UiDef.errorMinimumDate && !errorMsgs.includes(UiDef.errorMinimumDate)) {
      errorMsgs.push(UiDef.errorMinimumDate);
    }
    if (
      curCtl.hasError('compareDateLessThanError') &&
      UiDef.errorCompareDateLessThan &&
      !errorMsgs.includes(UiDef.errorCompareDateLessThan)
    ) {
      errorMsgs.push(UiDef.errorCompareDateLessThan);
    }
    if (
      curCtl.hasError('compareDateGreaterThanError') &&
      UiDef.errorCompareDateGreaterThan &&
      !errorMsgs.includes(UiDef.errorCompareDateGreaterThan)
    ) {
      errorMsgs.push(UiDef.errorCompareDateGreaterThan);
    }
    // other custom errors added here

    if (!this.isStringArraySame(UiDef.errorMsg, errorMsgs)) {
      if (errorMsgs.length > 0) {
        UiDef.errorMsg = errorMsgs;
      } else if (UiDef.errorMsg) {
        delete UiDef.errorMsg;
      }
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static areTwoSameIgnoreCase(a: any, b: any): boolean {
    if (!a || !b) return false;
    else if (typeof a === 'string' && typeof b === 'string') {
      return a.localeCompare(b, undefined, { sensitivity: 'accent' }) === 0;
    } else {
      return a === b;
    }
  }

  public static makePositiveNumber(value: number | undefined, maxDecimalPlaces: number = 2): string | undefined {
    // Ensure value is numeric
    const numericValue = Number(value);

    // Check if the value is a valid number
    if (!isNaN(numericValue)) {
      const formatter = new Intl.NumberFormat('en-CA', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDecimalPlaces,
      });

      const formattedValue = formatter.format(numericValue);
      return formattedValue;
    } else {
      return undefined;
    }
  }

  // this function accepts a timestamp string and returns a formatted time string
  // the formatted date string is in the format of "Mar 30, 2023 20:59:34"
  public static formatTimeWithTimestamp(timeStamp: string): string {
    const date = new Date(timeStamp);
    const formattedDate =
      date.toLocaleDateString('en-CA', { year: 'numeric', month: 'short', day: 'numeric' }) +
      ' ' +
      date.toLocaleTimeString('en-CA', { hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false });
    return formattedDate;
  }

  public static formatUnderscoreString(text: string): string {
    const stringWithoutUnderscore = text.replace(/_/g, ' ');
    const formattedString = stringWithoutUnderscore.charAt(0).toUpperCase() + stringWithoutUnderscore.slice(1);
    return formattedString;
  }

  // Formats a given string key by adding spaces between camel case words,
  // and ensuring the first character is capitalized.
  public static formatKey(key: string): string {
    return key
      .replace(/([a-z])([A-Z])/g, '$1 $2')
      .replace(/([A-Z])([A-Z][a-z])/g, '$1 $2')
      .replace(/^./, function (str) {
        return str.toUpperCase();
      });
  }

  public static _isAuditPage(url: string) {
    return (
      url.startsWith('/audit/application') ||
      url.startsWith('/audit/application-batch') ||
      url.startsWith('/audit/project') ||
      url.startsWith('/audit/program-budget') ||
      url.startsWith('/audit/project') ||
      url.startsWith('/audit/organization') ||
      url.startsWith('/audit/contact') ||
      url.startsWith('/audit/cost-centre') ||
      url.startsWith('/audit/allocation') ||
      url.startsWith('/audit/municipal-total') ||
      url.startsWith('/audit/payment') ||
      url.startsWith('/audit/payment_batch') ||
      url.startsWith('/audit/cash_flow_update')
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static removeNullOrUndefinedValues(obj: any) {
    for (const key in obj) {
      if (obj[key] === undefined || obj[key] === null) {
        delete obj[key];
      }
    }
    return obj;
  }

  public static jumpToField(fieldName: string) {
    if (document.getElementById(fieldName)) {
      const fieldElement = document.getElementById(fieldName);
      window.scrollTo(0, fieldElement?.offsetTop ?? 0);
    } else {
      console.error('Cannot find linked field: ' + fieldName);
    }
  }

  public static _getFormattedDate(dateString: string | undefined): string | undefined {
    if (!dateString) {
      return undefined;
    }
    const date = new Date(dateString);
    if (date.toString() === 'Invalid Date') {
      return undefined;
    }
    return date.toLocaleDateString('en-US', { month: 'short', day: 'numeric', year: 'numeric' });
  }
}
