import { FormStatusCodes } from '../grant-application/models/enums';
import { CashFlowFormSequence } from './enum/cash-flow-form-sequence';

export const createCashflowFormStatus = (
  partialStatus: Partial<{ [key in CashFlowFormSequence]: FormStatusCodes }>,
): {
  [key in CashFlowFormSequence]: FormStatusCodes;
} => {
  return {
    [CashFlowFormSequence.ContactInfo]: FormStatusCodes.NotStarted,
    [CashFlowFormSequence.ApplicationFunding]: FormStatusCodes.NotStarted,
    [CashFlowFormSequence.ProjectUpdates]: FormStatusCodes.NotStarted,
    [CashFlowFormSequence.Review]: FormStatusCodes.NotStarted,
    [CashFlowFormSequence.Certification]: FormStatusCodes.NotStarted,
    ...partialStatus,
  };
};
