export class LoadPrimaryOutcomes {
  static readonly type = '[LookupValue] Load Primary Outcomes';
}
export class LoadAssetConditionRankings {
  static readonly type = '[LookupValue] Load Asset Condition Rankings';
}
export class LoadFunctionalCategoryTypes {
  static readonly type = '[LookupValue] Load Functional Category Types';
}
export class LoadCapitalAssetTypes {
  static readonly type = '[LookupValue] Load Capital Asset Types';
  constructor(public functionalCategoryTypeId: number | undefined) {}
}
export class LoadAllCapitalAssetTypes {
  static readonly type = '[LookupValue] Load All Capital Asset Types';
}
export class LoadOrganizations {
  static readonly type = '[LookupValue] Load Organizations';
}
