/* tslint:disable */
/* eslint-disable */
export enum SepoStatusType {
  Draft = 'Draft',
  Submitted = 'Submitted',
  InReview = 'InReview',
  Cerified = 'Cerified',
  Returned = 'Returned',
  Withdrawn = 'Withdrawn'
}
