import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ProjectRecordVm } from '../../models';

@Component({
  selector: 'project-delete',
  templateUrl: './project-delete.component.html',
  styleUrls: ['./project-delete.component.scss'],
})
export class ProjectDeleteComponent {
  @Input() isOpen = false;
  @Input() data?: ProjectRecordVm;
  @Output() afterCloseHandler = new EventEmitter<void>();
  @Output() afterDeleteHandler = new EventEmitter<number>();

  constructor() {}

  closeModal() {
    this.isOpen = false;
    document.body.style.overflow = 'auto';
    this.afterCloseHandler.emit();
  }

  deleteProject() {
    this.afterDeleteHandler.emit(this.data?.id);
    this.closeModal();
  }
}
