<goa-page-block width="100%">
  <div style="height: 3rem"></div>
  <app-cash-flow-guide *ngIf="formStepContactInfo"></app-cash-flow-guide>
  <h2 id="app-heading-h2">Application cash flow updates</h2>
  <div style="display: flex; align-items: center; gap: 1rem">
    <div style="height: 2rem">
      <h3 id="app-heading-h3">{{ cashFlowApplicationTitle }}</h3>
    </div>
    <div>
      <app-download-pdf-link
        iconSize="medium"
        linkText="Download submitted PDF"
        textPosition="after"
        [pdfGenerationApiCall]="pdfGenerationApiCall"
      ></app-download-pdf-link>
    </div>
  </div>

  <goa-divider mt="xl" mb="xl"></goa-divider>
  <goa-form-stepper
    ml="s"
    mr="s"
    [step]="step$ | async"
    class="non-clickable-stepper"
    data-automation-id="cash-flow-updates-stepper"
  >
    <goa-form-step
      text="Contact information"
      status=""
      [status]="(formStatus$ | async)![CashFlowFormSequence.ContactInfo]"
    ></goa-form-step>
    <goa-form-step
      text="Application funding"
      status=""
      [status]="(formStatus$ | async)![CashFlowFormSequence.ApplicationFunding]"
    ></goa-form-step>
    <goa-form-step
      text="Project updates"
      status=""
      [status]="(formStatus$ | async)![CashFlowFormSequence.ProjectUpdates]"
    ></goa-form-step>
    <goa-form-step
      text="Review"
      status=""
      [status]="(formStatus$ | async)![CashFlowFormSequence.Review]"
    ></goa-form-step>
    <goa-form-step
      text="Certification"
      status=""
      [status]="(formStatus$ | async)![CashFlowFormSequence.Certification]"
    ></goa-form-step>
  </goa-form-stepper>
  <goa-divider mt="xl" mb="xl"></goa-divider>
  <router-outlet></router-outlet>

  <div class="btn-container"></div>
  <div class="flex-space-between">
    <div>
      <goa-button *ngIf="currentStep != 1" (click)="prev()" type="secondary">Previous</goa-button>
    </div>
    <goa-button (click)="confirm()" type="primary" *ngIf="currentStep != 5">
      Confirm and continue to next section
    </goa-button>
    <goa-button (click)="confirm()" type="primary" *ngIf="currentStep == 5">
      {{ 'btnSubmit' | res }}
    </goa-button>
  </div>
  <div class="ht-32px"></div>
  <div class="flex-space-between save-cancel-button-container">
    <goa-button type="tertiary" class="btn-neg-margin-left" (click)="cancelAndDeleteCashFlow()"
      >Cancel and delete cash flow update</goa-button
    >
    <goa-button type="tertiary" (click)="saveAndClose()">Save and close</goa-button>
  </div>
  <ng-container *ngIf="autosaveStatus$ | async as autosaveStatus">
    <ng-container *ngIf="currentStep != 5">
      <div
        data-automation-id="autosave-status-msg-container"
        class="autosave-status-msg-container"
        *ngIf="autosaveStatus !== AutosaveStatus.idle"
      >
        <div
          data-automation-id="autosave-msg-content-success"
          class="autosave-msg-content"
          *ngIf="autosaveStatus === AutosaveStatus.success"
        >
          <goa-icon class="autosave-msg-success-icon" type="checkmark" size="small"></goa-icon>
          <div class="autosave-msg-txt">Changes have been autosaved</div>
        </div>
        <div
          data-automation-id="autosave-msg-content-inprogress"
          class="autosave-msg-content"
          *ngIf="autosaveStatus === AutosaveStatus.inProgress"
        >
          <mat-spinner class="autosave-msg-inprogress-icon"></mat-spinner>
          <div class="autosave-msg-txt">Saving changes...</div>
        </div>
        <div
          data-automation-id="autosave-msg-content-failure"
          class="autosave-msg-content"
          *ngIf="autosaveStatus === AutosaveStatus.failure"
        >
          <goa-icon class="autosave-msg-failure-icon" type="close" size="small"></goa-icon>
          <div class="autosave-msg-txt">Autosave failed</div>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <app-cash-flow-cancel-dialog [isOpen]="showDialog" (closeDialog)="closeCancelCashFlowDialog($event)">
  </app-cash-flow-cancel-dialog>
</goa-page-block>
