import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MapService {
  private mapVisibility = new BehaviorSubject<boolean>(false);
  private mapVisibility$: Observable<boolean> = this.mapVisibility.asObservable();

  private editModeMapVisibility = new BehaviorSubject<boolean>(false);
  private editModeMapVisibility$: Observable<boolean> = this.editModeMapVisibility.asObservable();

  public getMapVisibility(): Observable<boolean> {
    return this.mapVisibility$;
  }

  public setMapVisibility(isMapVisible: boolean) {
    this.mapVisibility.next(isMapVisible);
  }

  public getEditModeMapVisibility(): Observable<boolean> {
    return this.editModeMapVisibility$;
  }

  public setEditModeMapVisibility(isMapVisible: boolean) {
    this.editModeMapVisibility.next(isMapVisible);
  }
}
