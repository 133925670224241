<goa-modal
  id="modal"
  [open]="_isOpen"
  heading="{{ titleMain }}"
  width="{{ dlgMaxWidth }}"
  maxwidth="{{ dlgMaxWidth }}"
  [appPreventModalFlicker]="_isOpen"
>
  <h4 class="delete-question">{{ titleSub }}</h4>
  <div slot="actions">
    <div *ngIf="!showBothOnRight" class="flex-space-between">
      <goa-button type="secondary" (click)="onNoClicked()">{{ buttonNoText }}</goa-button>
      <goa-button type="primary" (click)="onYesClicked()">{{ buttonYesText }}</goa-button>
    </div>
    <div *ngIf="showBothOnRight" class="flex-float-to-right">
      <goa-button type="secondary" (click)="onNoClicked()" class="pr-1rem">{{ buttonNoText }}</goa-button>
      <goa-button type="primary" (click)="onYesClicked()">{{ buttonYesText }}</goa-button>
    </div>
  </div>
</goa-modal>
