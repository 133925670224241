<h2 id="func-cat-heading-1">{{ 'functionalCategoriesHeading1' | res: pageId }}</h2>
<h3 id="func-cat-heading-3">{{ 'functionalCategoriesHeading3' | res: pageId }}</h3>
<h3 id="func-cat-heading-3">{{ 'functionalCategoriesHeading4' | res: pageId }}</h3>
<div
  class="mb-56px"
  *ngIf="
    (calloutAdditionalCategoryErrorOnAdd && categoryNotValid) ||
    showErrorFieldsCallout ||
    (categoryNotValid && calloutAdditionalCategoryErrorOnAdd === false)
  "
>
  <goa-callout
    type="emergency"
    heading="{{ 'errorCalloutAdditionalCategoryHeading' | res: pageId }}"
    *ngIf="calloutAdditionalCategoryErrorOnAdd && categoryNotValid"
  >
    <a
      *ngIf="errors['category'] && calloutAdditionalCategoryErrorOnAdd"
      (click)="jumpToField('fiPrimaryCategory')"
      class="validation-message-link"
      >{{ categoryLabel }}<br
    /></a>
    <a
      *ngIf="errors['cost'] && calloutAdditionalCategoryErrorOnAdd"
      (click)="jumpToField('costLabel')"
      class="validation-message-link"
      >{{ 'cost' | res: pageId }}<br
    /></a>
  </goa-callout>

  <goa-callout
    type="emergency"
    heading="{{ 'errorCalloutHeading' | res: pageId }}"
    *ngIf="showErrorFieldsCallout || (categoryNotValid && calloutAdditionalCategoryErrorOnAdd === false)"
  >
    <p *ngIf="nextClickValidation && totalCost === 0">{{ 'errorMsgAtLeastOneCategory' | res: pageId }}</p>
    <a
      *ngIf="errors['category'] && calloutAdditionalCategoryErrorOnAdd === false"
      (click)="jumpToField('fiPrimaryCategory')"
      class="validation-message-link"
      >{{ categoryLabel }}<br
    /></a>
    <a
      *ngIf="errors['cost'] && calloutAdditionalCategoryErrorOnAdd === false"
      (click)="jumpToField('costLabel')"
      class="validation-message-link"
      >{{ 'cost' | res: pageId }}<br
    /></a>
    <a
      *ngIf="isPrimaryCategoryNotMajorityPercentage"
      (click)="jumpToField('func-cat-categories-table')"
      class="validation-message-link"
      >{{ 'errorMsgPrimaryMajorityPercentage' | res: pageId }}<br
    /></a>
    <a
      *ngIf="isTotalPercentageInvalid"
      (click)="jumpToField('func-cat-categories-table')"
      class="validation-message-link"
      >{{ 'errorMsgYourCatPercent' | res: pageId }}<br
    /></a>
    <a
      *ngIf="
        shouldShowOtherFundingSourceValidationMessage ||
        shouldShowOtherSourcesOfFundingYesButNoSourcesSelected ||
        shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty
      "
      (click)="jumpToField('func-cat-radio-other-source-of-funding')"
      class="validation-message-link"
      >{{ 'labelOtherSourceFunding' | res: pageId }}<br
    /></a>
    <a
      *ngIf="
        shouldShowOwnershipValidationMessage ||
        shouldShowOwnershipNonProfitOrganizationSpecifyEmpty ||
        shouldShowOwnershipRegionalServiceCommissionSpecifyEmpty ||
        shouldShowOwnershipAnotherLocalGovtSpecifyEmpty
      "
      (click)="jumpToField('func-cat-checkbox-asset-owned-by-local-gov')"
      class="validation-message-link"
      >{{ 'labelWhoOwnAsset' | res: pageId }}<br
    /></a>
  </goa-callout>
</div>

<form [formGroup]="functionalCategories" *ngIf="displayForm">
  <div class="category-section">
    <goa-form-item id="fiPrimaryCategory" class="app-section-item" label="{{ categoryLabel }}">
      <goa-dropdown
        id="ddlPrimaryCategory"
        width="42rem"
        formControlName="category"
        filterable="true"
        relative="true"
        (focusout)="onFocusOut('category')"
        [error]="errors['category']"
        maxheight="250px"
        goaValue
        placeholder="Please select a category"
      >
        <ng-container *ngFor="let category of categories">
          <goa-dropdown-item
            *ngIf="category.disabled == false"
            [value]="category.id"
            [label]="category.name"
            goaValue
          ></goa-dropdown-item>
        </ng-container>
      </goa-dropdown>

      <div class="field-validation-error-msg" *ngIf="errors['category']">
        <goa-icon size="small" theme="filled" type="warning"></goa-icon>
        <span>{{ errors['category'] }}</span>
      </div>
      <div class="help-msg-text">{{ helpTextCategory }}</div>
    </goa-form-item>

    <goa-form-item id="costLabel" label="{{ 'cost' | res: pageId }}">
      <goa-input
        type="text"
        name="cost"
        formControlName="cost"
        width="110px"
        [value]="costValue"
        goaValue
        (focusout)="onFocusOut('cost')"
        inputPercentage
        [error]="errors['cost']"
        maxlength="3"
      >
        <div class="percentageSign" style="height: 42px" slot="trailingContent">%</div>
      </goa-input>

      <div class="field-validation-error-msg" *ngIf="errors['cost']">
        <goa-icon size="small" theme="filled" type="warning"></goa-icon>
        <span>{{ errors['cost'] }}</span>
      </div>
      <div class="help-msg-text">
        Enter the percentage of the total estimated costs for all project(s) in this category.
      </div>
    </goa-form-item>

    <div id="addCategoryDiv">
      <goa-button type="secondary" id="addCategory" (click)="addCategory()">Add</goa-button>
      <goa-button
        type="tertiary"
        style="margin-left: 16px"
        id="clearAdditionalCategory"
        *ngIf="calloutAdditionalCategoryErrorOnAdd && categoryNotValid"
        (click)="clearAdditionalCategory()"
        >Clear additional category</goa-button
      >
    </div>
  </div>
</form>

<div>
  <h3 id="functional-category-table-h3" style="padding-top: 24px">Your categories</h3>
  <goa-table width="100%" padding="0px 72px 0px 72px" id="func-cat-categories-table">
    <thead>
      <tr>
        <th style="width: auto">Category</th>
        <!-- make sure Primary take min-space -->
        <th style="width: 4.5rem; max-width: 4.5rem; padding-left: 2px; padding-right: 2px">Primary</th>
        <th style="width: 248px; min-width: 248px; text-align: right">% of total application cost</th>
        <!-- min-width: 7rem; make sure 2 action icons can show in 1 line -->
        <th style="width: 7rem; min-width: 7rem">Actions</th>
      </tr>
    </thead>

    <tbody>
      <tr *ngFor="let category of categoryList; let i = index" style="height: 48px">
        <ng-container *ngIf="category.categoryId == 0">
          <td>No categories have been added yet.</td>
          <td></td>
          <td></td>
          <td></td>
        </ng-container>
        <ng-container *ngIf="category.categoryId != 0">
          <td>{{ category.categoryTitle }}</td>
          <td *ngIf="category.isPrimary == true"><goa-icon type="checkmark"></goa-icon></td>
          <td *ngIf="category.isPrimary != true"></td>
          <td class="goa-table-number-column">{{ category.cost }} %</td>
          <td *ngIf="category.categoryId != 0" class="editDeleteCol">
            <div>
              <goa-button-group>
                <div
                  [ngClass]="{ 'div-edit-icon': isTotalPercentageInvalid || isPrimaryCategoryNotMajorityPercentage }"
                >
                  <goa-icon-button (_click)="onEditClick(i)" icon="pencil"></goa-icon-button>
                </div>
                <div>
                  <goa-icon-button
                    (_click)="openDeleteModal(i)"
                    icon="trash"
                    *ngIf="category.isPrimary == false"
                  ></goa-icon-button>
                </div>
              </goa-button-group>
            </div>
          </td>
        </ng-container>
      </tr>

      <tr style="background-color: #f1f1f1">
        <td>Total</td>
        <td></td>
        <td class="goa-table-number-column" *ngIf="totalCost != 0">{{ totalCost }} %</td>
        <td class="goa-table-number-column" *ngIf="totalCost == 0"></td>
        <td></td>
      </tr>
    </tbody>
  </goa-table>
</div>

<div>
  <div class="field-validation-error-msg" *ngIf="isPrimaryCategoryNotMajorityPercentage">
    <goa-icon size="small" theme="filled" style="padding: 12px 0px 0px 12px" type="warning"></goa-icon>
    <span class="error-msg-table-validation">{{ 'errorMsgPrimaryMajorityPercentage' | res: pageId }}</span>
  </div>
  <div class="field-validation-error-msg" *ngIf="isTotalPercentageInvalid">
    <goa-icon size="small" theme="filled" style="padding: 12px 0px 0px 12px" type="warning"></goa-icon>
    <span class="error-msg-table-validation">{{ 'errorMsgTotalPercentage' | res: pageId }}</span>
  </div>
</div>

<form [formGroup]="functionalCategoryEditForm">
  <goa-modal
    id="modalEdit"
    width="50rem"
    height="450px"
    [open]="isEditOpen"
    heading="Edit"
    [appPreventModalFlicker]="isEditOpen"
  >
    <div>
      <goa-callout type="emergency" size="large" *ngIf="mCategoryNotValid">
        <h3 style="font-weight: 400px; line-height: 32px">The following items on this page require correction:</h3>
        <div addCallOut>
          <div *ngIf="errors['mCategory']">
            <p>
              <a class="validation-message-link">{{ mCategoryLabel }}</a>
            </p>
          </div>
          <div *ngIf="errors['mCost']">
            <p>
              <a class="validation-message-link">{{ 'cost' | res: pageId }}</a>
            </p>
          </div>
        </div>
      </goa-callout>
    </div>

    <goa-callout type="important" size="medium" *ngIf="categoryProjectExisting">
      {{ 'categoryProjectCallout' | res: pageId }}
    </goa-callout>

    <goa-callout type="important" size="medium" *ngIf="categoryAlreadyExisting">
      <span style="font-weight: 700">Category: {{ selectedModalCategory }}</span>
      <div>{{ 'categoryAdditionalCallout' | res: pageId }}</div>
    </goa-callout>

    <div class="modal-field-spacing" id="modal-category-div">
      <goa-form-item id="mfiPrimaryCategory" label="{{ mCategoryLabel }}">
        <goa-dropdown
          id="mddlPrimaryCategory"
          width="32rem"
          height="42px"
          filterable="true"
          relative="true"
          formControlName="mCategory"
          maxheight="250px"
          [error]="errors['mCategory']"
          (focusout)="onModalFieldValueChange('mCategory')"
          (click)="onOptionsSelected($event)"
          goaValue
          placeholder="Please select a category"
        >
          <ng-container *ngFor="let category of categoriesAll">
            <goa-dropdown-item
              *ngIf="category.disabled == false"
              [value]="category.id"
              [label]="category.name"
              goaValue
            >
            </goa-dropdown-item>
          </ng-container>
        </goa-dropdown>

        <div class="field-validation-error-msg" *ngIf="errors['mCategory']">
          <goa-icon size="small" theme="filled" type="warning"></goa-icon>
          <span>{{ errors['mCategory'] }}</span>
        </div>
        <div class="help-msg-text">{{ mHelpTextCategory }}</div>
      </goa-form-item>
    </div>

    <div class="modal-field-spacing" id="modal-cost-div">
      <goa-form-item id="modalCostLabel" label="{{ 'cost' | res: pageId }}">
        <goa-input
          type="text"
          name="mCost"
          formControlName="mCost"
          width="110px"
          height="42px"
          [value]="currentCost"
          goaValue
          [error]="errors['mCost']"
          inputPercentage
          (focusout)="onModalFieldValueChange('mCost')"
          maxlength="3"
        >
          <div class="percentageSign" style="height: 50px" slot="trailingContent">%</div>
        </goa-input>

        <div class="field-validation-error-msg" *ngIf="errors['mCost']">
          <goa-icon size="small" theme="filled" type="warning"></goa-icon>
          <span>{{ errors['mCost'] }}</span>
        </div>

        <div class="help-msg-text">{{ 'costHelpText' | res: pageId }}</div>
      </goa-form-item>
    </div>

    <goa-button-group alignment="end">
      <goa-button type="secondary" (click)="closeEditModal()">Cancel</goa-button>
      <goa-button type="primary" (click)="updateCategory()">Save</goa-button>
    </goa-button-group>
  </goa-modal>
</form>

<goa-modal
  id="modalDelete"
  width="550px"
  height="390px"
  [open]="isOpen"
  heading="Delete"
  class="delete-modal-pane"
  [appPreventModalFlicker]="isOpen"
>
  <div style="font-size: 18px; font-weight: 400">Are you sure you want to delete this additional category?</div>
  <goa-spacer vSpacing="l"></goa-spacer>
  <div style="font-size: 18px; font-weight: 700">Additional category</div>
  <div style="font-size: 18px; font-weight: 400">{{ currentCategoryTitle }}</div>
  <goa-spacer vSpacing="xl"></goa-spacer>
  <div style="font-size: 18px; font-weight: 700">{{ 'cost' | res: pageId }}</div>
  <div style="font-size: 18px; font-weight: 400">{{ currentCost }}%</div>
  <goa-spacer vSpacing="xl"></goa-spacer>

  <goa-button-group alignment="end">
    <goa-button type="secondary" (_click)="closeDeleteModal()">Cancel</goa-button>
    <goa-button type="primary" (_click)="removeCategory()">Delete</goa-button>
  </goa-button-group>
</goa-modal>
<goa-divider mt="2xl" mb="2xl"></goa-divider>

<form [formGroup]="functionalCategoriesSources">
  <div class="question-section">
    <goa-form-item
      id="func-cat-radio-other-source-of-funding"
      class="question1-section"
      label="Are there other sources of provincial or federal grant funding?"
    >
      <goa-radio-group
        name="areThereOtherSourcesOfFunding"
        id="areThereOtherSourcesOfFunding"
        formControlName="areThereOtherSourcesOfFunding"
        goaValue
        [error]="shouldShowOtherFundingSourceValidationMessage"
        (click)="OtherSourcesOfFundingClicked(true)"
      >
        <goa-radio-item name="areThereOtherSourcesOfFunding" label="Yes" [value]="true"> </goa-radio-item>
      </goa-radio-group>

      <goa-form-item
        class="nestedStepOne"
        label="Select all that apply."
        name="questionYesItem"
        *ngIf="areThereOtherSourcesOfFunding"
      >
        <div style="height: 4px"></div>
        <goa-checkbox
          name="isFundingFromAMWWP"
          formControlName="isFundingFromAMWWP"
          text="Alberta Municipal Water/Wastewater Partnership (AMWWP)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromCCBF"
          formControlName="isFundingFromCCBF"
          text="Canada Community-Building Fund (CCBF)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromSTIP"
          formControlName="isFundingFromSTIP"
          text="Strategic Transportation Infrastructure Program (STIP)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromWaterForLife"
          formControlName="isFundingFromWaterForLife"
          text="Water for Life (W4L)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
        ></goa-checkbox>

        <goa-checkbox
          name="isFundingFromMSI"
          formControlName="isFundingFromMSI"
          text="Municipal Sustainability Initiative (MSI)"
          checked="false"
          value="true"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
        ></goa-checkbox>

        <goa-checkbox
          class="last-checkbox"
          name="isFundingFromOther"
          formControlName="isFundingFromOther"
          text="Other (specify)"
          checked="false"
          goaChecked
          [error]="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected"
        ></goa-checkbox>

        <goa-form-item
          class="nestedStepTwo specify-last"
          label="Please specify"
          *ngIf="isFundingFromOther"
          [ngClass]="{ 'checkbox-nested-input': !shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty }"
        >
          <goa-input
            focused="true"
            type="text"
            formControlName="fundingFromOther"
            goaValue
            width="600px"
            name="fundingFromOther"
            value=""
            [error]="shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty"
            (blur)="onFcsTxtBoxBlur('fundingFromOther')"
          ></goa-input>
          <div class="field-validation-error-msg" *ngIf="shouldShowOtherSourcesOfFundingYesAndOtherSpecifyButEmpty">
            <goa-icon size="small" type="warning" theme="filled"></goa-icon>
            <span>{{ 'otherNoSpecifiedErrorMsg' | res: pageId }}</span>
          </div>
        </goa-form-item>

        <div class="field-validation-error-msg" *ngIf="shouldShowOtherSourcesOfFundingYesButNoSourcesSelected">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ 'noSourceSelectedErrorMsg' | res: pageId }}</span>
        </div>
      </goa-form-item>

      <goa-radio-group
        name="questionNo"
        id="questionNo"
        formControlName="noOtherSourceOfFunding"
        goaValue
        [error]="shouldShowOtherFundingSourceValidationMessage"
        (click)="OtherSourcesOfFundingClicked(false)"
      >
        <goa-radio-item name="questionNo" label="No" [value]="false"></goa-radio-item>
      </goa-radio-group>

      <div class="field-validation-error-msg" *ngIf="shouldShowOtherFundingSourceValidationMessage">
        <goa-icon size="small" type="warning" theme="filled"></goa-icon>
        <span>{{ 'otherSourceOfFundingErrorMsg' | res: pageId }}</span>
      </div>
      <div class="help-msg-text">
        Indicate if there are funding sources from other provincial or federal grant programs for all projects on this
        application.
      </div>
    </goa-form-item>

    <goa-form-item
      id="func-cat-checkbox-asset-owned-by-local-gov"
      label="Who will own the resulting asset(s) from this application? Select all that apply."
    >
      <goa-checkbox
        name="isOwnedByLocalGovernment"
        formControlName="isOwnedByLocalGovernment"
        text="Local government"
        checked="false"
        value="true"
        goaChecked
        [error]="shouldShowOwnershipValidationMessage"
        style="padding-top: 4px"
      ></goa-checkbox>

      <goa-checkbox
        name="isNonProfitOwnership"
        formControlName="isNonProfitOwnership"
        text="A non-profit organization (specify)"
        checked="false"
        goaChecked
        value="true"
        [error]="shouldShowOwnershipValidationMessage"
      ></goa-checkbox>

      <goa-form-item
        class="nestedStepTwo specify-middle"
        label="Please specify"
        for="nonProfitOrganization"
        *ngIf="isNonProfitOwnership"
        [ngClass]="{ 'checkbox-nested-input': !shouldShowOwnershipNonProfitOrganizationSpecifyEmpty }"
      >
        <goa-input
          focused="true"
          formControlName="nonProfitOwnership"
          type="text"
          width="600px"
          name="nonProfitOrganization"
          goaValue
          [error]="shouldShowOwnershipNonProfitOrganizationSpecifyEmpty"
          (blur)="onFcsTxtBoxBlur('nonProfitOwnership')"
        ></goa-input>

        <div class="field-validation-error-msg" *ngIf="shouldShowOwnershipNonProfitOrganizationSpecifyEmpty">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ 'npoNotSpecifiedErrorMsg' | res: pageId }}</span>
        </div>
      </goa-form-item>

      <goa-checkbox
        name="isRegionalServiceCommissionOwnership"
        formControlName="isRegionalServiceCommissionOwnership"
        text="Regional Service Commission (specify)"
        checked="false"
        goaChecked
        value="true"
        [error]="shouldShowOwnershipValidationMessage"
      ></goa-checkbox>

      <goa-form-item
        class="nestedStepTwo specify-middle"
        label="Please specify"
        *ngIf="isRegionalServiceCommissionOwnership"
        [ngClass]="{ 'checkbox-nested-input': !shouldShowOwnershipRegionalServiceCommissionSpecifyEmpty }"
      >
        <goa-input
          focused="true"
          type="text"
          formControlName="regionalServiceCommissionOwnership"
          width="600px"
          name="regionalServiceCommissionOwnership"
          goaValue
          [error]="shouldShowOwnershipRegionalServiceCommissionSpecifyEmpty"
          (blur)="onFcsTxtBoxBlur('regionalServiceCommissionOwnership')"
        ></goa-input>
        <div class="field-validation-error-msg" *ngIf="shouldShowOwnershipRegionalServiceCommissionSpecifyEmpty">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ 'regionalServiceCommissionNotSpecifiedErrorMsg' | res: pageId }}</span>
        </div>
      </goa-form-item>

      <goa-checkbox
        class="last-checkbox"
        name="isLocalGovernmentOwnership"
        formControlName="isLocalGovernmentOwnership"
        text="Another local government or group of local governments (specify)"
        checked="false"
        value="true"
        goaChecked
        [error]="shouldShowOwnershipValidationMessage"
      ></goa-checkbox>

      <goa-form-item
        class="nestedStepTwo specify-last"
        label="Please specify"
        *ngIf="isLocalGovernmentOwnership"
        [ngClass]="{ 'checkbox-nested-input': !shouldShowOwnershipAnotherLocalGovtSpecifyEmpty }"
      >
        <goa-input
          focused="true"
          type="text"
          width="600px"
          formControlName="localGovernmentOwnership"
          name="localGovt"
          goaValue
          [error]="shouldShowOwnershipAnotherLocalGovtSpecifyEmpty"
          (blur)="onFcsTxtBoxBlur('localGovernmentOwnership')"
        ></goa-input>

        <div class="field-validation-error-msg" *ngIf="shouldShowOwnershipAnotherLocalGovtSpecifyEmpty">
          <goa-icon size="small" type="warning" theme="filled"></goa-icon>
          <span>{{ 'otherLocalGovNotSpecifiedErrorMsg' | res: pageId }}</span>
        </div>
      </goa-form-item>

      <div class="field-validation-error-msg" *ngIf="shouldShowOwnershipValidationMessage">
        <goa-icon size="small" type="warning" theme="filled"></goa-icon>
        <span>{{ 'assetOwnedByLocalGovErrorMsg' | res: pageId }}</span>
      </div>

      <div class="help-msg-text">
        Indicate the asset ownership(s) for all primary capital asset(s) on this application.
      </div>
    </goa-form-item>
  </div>
  <div style="height: 120px"></div>
</form>
