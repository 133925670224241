<goa-one-column-layout>
  <section slot="header">
    <goa-microsite-header type="alpha"></goa-microsite-header>
    <div id="app-header-1">
      <div style="padding: 12px 0px 16px 0; display: flex">
        <div class="align-logo">
          <a target="_blank" href="https://www.alberta.ca/">
            <app-svg-alberta-logo></app-svg-alberta-logo>
          </a>
        </div>
        <div>
          <label id="logo-label"
            ><abbr id="logo-label-abbr" title="Local Government Fiscal Framework">LGFF</abbr> Capital</label
          >
        </div>
      </div>
    </div>
    <goa-divider></goa-divider>
  </section>
  <div class="center-wrapper">
    <div class="centered-div">
      <div class="warning-icon-container">
        <goa-icon type="warning" size="xlarge"></goa-icon>
      </div>
      <div class="warning-icon-underline"></div>
      <div class="general-error-msg-title">Your cookies are disabled.</div>
      <div class="general-error-msg">
        <div>We are unable to load the site as your cookies are disabled.</div>
        <div>Please enable cookies in your browser and try again.</div>
      </div>
      <div class="buttons-container">
        <div class="reload-page-btn-container">
          <goa-button type="primary" (click)="reloadPage()">Reload page</goa-button>
        </div>
      </div>
    </div>
  </div>
  <section slot="footer">
    <app-pot-footer></app-pot-footer>
  </section>
</goa-one-column-layout>
