import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-cash-flow-cancel-dialog',
  templateUrl: './cash-flow-cancel-dialog.component.html',
  styleUrl: './cash-flow-cancel-dialog.component.scss',
})
export class CashFlowCancelDialogComponent {
  @Input() isOpen: boolean;
  @Output() closeDialog = new EventEmitter<{ cancelAndDelete: boolean }>();

  closeModal(closeModal: boolean) {
    this.closeDialog.emit({ cancelAndDelete: closeModal });
  }
}
