import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ApplicationCertifiedByRole } from '@app-com/api/models';
import { ResourcePipe } from '@app-com/pipes';
import { AuthStateService } from '@app-pot/shared/services/auth-state.service';
import { BaseStepperComponent } from '@app-pot/shared/components/base-stepper/base-stepper.component';
import { CurrentContextState } from '@app-pot/store/state/current-context.state';
import { Select, Store } from '@ngxs/store';
import { Observable, Subject, Subscription, takeUntil } from 'rxjs';
import { SetCashFlowSubmit } from '@app-pot/store/actions/cash-flow-update.action';
import { CashFlowUpdateState } from '@app-pot/store/state/cash-flow-update.state';
import { Router } from '@angular/router';
import { AutosaveStatus } from '@app-pot/features/grant-application/models/enums';

@Component({
  selector: 'app-cash-flow-submit',
  templateUrl: './cash-flow-submit.component.html',
  styleUrl: './cash-flow-submit.component.scss',
})
export class CashFlowSubmitComponent extends BaseStepperComponent implements OnInit, OnDestroy {
  pageId = 'CERTIFICATION';
  certificationObject: FormGroup;
  organizationIdSelected: number;
  organizationTitle: string | undefined;
  username: string;
  private unsubscribe$ = new Subject<void>();
  sub = new Subscription();

  openSubmitCashFlowModal = false;
  yesSubmitBtn = 'yesSubmitBtn';
  cancelBtnLabel = 'btnCancel';
  modalSubmitCashFlowContent = 'modalSubmitCashFlowContent';
  showWholePageProgressIndicatorModal = false;

  @Select(CashFlowUpdateState.getCashFlowUpdateCta) cashFlowCta$: Observable<
    'cancel' | 'confirm' | 'save' | 'previous' | 'submit'
  >;

  @Select(CashFlowUpdateState.getSubmitStatus) submitStatus$: Observable<AutosaveStatus>;

  constructor(
    private formBuilder: FormBuilder,
    public res: ResourcePipe,
    private authStateService: AuthStateService,
    private store: Store,
    private router: Router,
  ) {
    super();
    this.certificationObject = this.formBuilder.group({
      acceptanceCheckbox: ['', [Validators.required]],
    });

    this.store
      .select(CurrentContextState.getCurrentOrganizationId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((currentOrganizationId) => {
        this.organizationIdSelected = currentOrganizationId;
      });
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this.sub.unsubscribe();
  }

  ngOnInit(): void {
    this.sub.add(
      this.cashFlowCta$.subscribe((cta) => {
        if (cta && cta.indexOf('submit') >= 0) {
          if (this.certificationObject && this.validateOnNext()) {
            this.openSubmitCashFlowModal = true;
          }
        }
      }),
    );

    this.authStateService
      .getAuthenticatedUser()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((user) => {
        if (user) {
          this.username = user.name;
        }
        if (user?.roles) {
          this.organizationTitle = user.roles.find((x) => x.id == this.organizationIdSelected)?.title;
        }
      });
  }

  onReceiveSubmitCashFlowEvent() {
    this.showWholePageProgressIndicatorModal = true;
    this.openSubmitCashFlowModal = false;
    this.store.dispatch(new SetCashFlowSubmit(true));
    this.submitStatus$.subscribe({
      next: (result: AutosaveStatus) => {
        if (result == AutosaveStatus.idle) {
          return;
        }
        if (result == AutosaveStatus.success) {
          this.router.navigate(['view-applications/cash-flow-updates']);
        } else {
          this.router.navigate(['page-not-found']);
        }
      },
    });
  }

  closeSubmitCashFlowModal() {
    this.openSubmitCashFlowModal = false;
  }

  getApplicationCertifiedByRoleSelection(): ApplicationCertifiedByRole {
    return this.certificationObject.controls['acceptanceCheckbox'].value;
  }

  override validateOnPrevious(): boolean {
    return true;
  }

  override validateOnNext(): boolean {
    this.certificationObject.markAllAsTouched();
    if (!this.certificationObject.valid) {
      setTimeout(() => {
        this.jumpToField('certification-form-h3');
      }, 200);
    }
    return this.certificationObject.valid;
  }
}
