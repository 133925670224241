import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  standalone: true,
  name: 'positiveDecimal',
})
export class PositiveDecimalPipe implements PipeTransform {
  transform(value: number | undefined, maxDecimalPlaces: number = 2): string | undefined {
    // Ensure value is numeric
    const numericValue = Number(value);

    // Check if the value is a valid number
    if (!isNaN(numericValue)) {
      const formatter = new Intl.NumberFormat('en-CA', {
        minimumFractionDigits: 0,
        maximumFractionDigits: maxDecimalPlaces,
      });

      const formattedValue = formatter.format(numericValue);
      return formattedValue;
    } else {
      return undefined;
    }
  }
}
