import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { CookieCheckService } from '@app-com/services/cookie-check-service';
import { AppModule } from './app/app.module';
import { bootstrapApplication } from '@angular/platform-browser';
import { CookieDisabledComponent } from '@app-pot/features/error-pages/cookie-disabled/cookie-disabled.component';

const cookieService = new CookieCheckService();
if (cookieService.areCookiesEnabled()) {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
} else {
  bootstrapApplication(CookieDisabledComponent);
}
